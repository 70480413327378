<template>
  <div class="absolute top-0 left-0 w-screen h-screen"></div>
</template>

<script>
import { mapActions } from "vuex";
import { AUTH_CHANGE_ACTIVE_ACCOUNT } from "@/store/action-types";

export default {
  name: "Logout",
  data() {
    return {};
  },
  mounted() {
    this.changeActiveAccount();
  },
  methods: {
    ...mapActions({
      switchActiveAccount: AUTH_CHANGE_ACTIVE_ACCOUNT,
    }),
    async changeActiveAccount() {
      const { account_id = "" } = this.$route.params;
      try {
        if (account_id) {
          const selectedAccount = this.userInfo.accounts.find(
            (d) => d.id === account_id
          );
          if (selectedAccount) {
            console.log("selectedAccount", selectedAccount);
            await this.switchActiveAccount({
              account_id: selectedAccount.id,
              account_type: selectedAccount.type,
            });

            this.$sendAnalyticsEvent("login", { method: "switcher" });

            this.$router.replace("/");
            window.location = "/";
            return;
          }
        }
        throw new Error("Unable to switch account");
      } catch (e) {
        this.$wemowNotifyError(e.message);
        this.$router.replace("/logout");
      }
    },
  },
  computed: {
    loggedIn: function () {
      return this.$store.getters.isUserLoggedIn;
    },
    userInfo: function () {
      return this.$store.getters.getUserInfo;
    },
  },
};
</script>

<style></style>
