// @ts-check
import { get } from "lodash-es";
import { isBefore, subDays } from "date-fns/esm";

import {
    isPastAppointment,
    isAppointmentPending,
    isAppointmentDeleted,
    isAppointmentCanceled,
    isAppointmentComplete,
    isAppointmentPast,
    isAppointmentRainDelay,
    isAppointmentException,
    isAppointmentSuspended,
    isPastAppointmentLessThan7DaysAgo
} from "./status-checks"

export function canEditAppointment(appointment, accountType, allowEdit = true) {
    return (
        allowEdit &&
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment) &&
        !isAppointmentException(appointment)
    );
}
export function canEditVendorAppointment(appointment, accountType, allowEditVendor = true) {
    const notAllowed = ['customer', 'vendor']
    if(notAllowed.includes(accountType)){
        return false;
    }
    return (
        allowEditVendor &&
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment)
    );
}
export function canEditStartTimeAppointment(appointment, accountType, allowEditStartTime = true) {

    return (
        allowEditStartTime &&
        !isAppointmentSuspended(appointment) &&
        isPastAppointmentLessThan7DaysAgo(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment)
    );
}
export function canCancelAppointment(appointment, accountType, allowCancel = true) {
    return (
        allowCancel &&
        (accountType !== 'customer' || !isPastAppointment(appointment)) &&
        !isAppointmentSuspended(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment)
    );
}
export function canRainDelayAppointment(appointment, accountType, allowRainDelay = true) {
    const notAllowed = ['customer']
    if(notAllowed.includes(accountType)){
        return false;
    }
    return (
        allowRainDelay &&
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment)
    );
}
export function canDeleteAppointment(appointment, accountType, allowDelete = true) {
    const notAllowed = ['customer', 'vendor']
    if(notAllowed.includes(accountType)){
        return false;
    }
    return (
        allowDelete &&
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentComplete(appointment) &&
        !isAppointmentPast(appointment)
    );
}
export function canActivateAppointment(appointment, accountType, allowActivate = true) {
    return (
        allowActivate &&
        !isAppointmentSuspended(appointment) &&
        !isAppointmentComplete(appointment) &&
        !isAppointmentPast(appointment) &&
        (isAppointmentCanceled(appointment) || isAppointmentDeleted(appointment) || isAppointmentException(appointment))
    );
}
export function allowedCompletionPeriod(appointment) {
    return !isBefore(
        new Date(),
        subDays(new Date(appointment.start_time), 7)
    );
}
export function canCompleteAppointment(appointment, accountType, allowComplete = true) {
    const notAllowed = ['customer']
    if(notAllowed.includes(accountType)){
        return false;
    }
    if (
        !allowComplete ||
        isAppointmentSuspended(appointment) ||
        isAppointmentDeleted(appointment) ||
        isAppointmentCanceled(appointment) ||
        isAppointmentComplete(appointment) ||
        isAppointmentPending(appointment) ||
        isAppointmentException(appointment) ||
        !allowedCompletionPeriod(appointment)
    ) {
        return false;
    }
    return true;
}

export function canAddAddOn(appointment){
    return (
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment) &&
        !isAppointmentException(appointment) &&
        !isAppointmentRainDelay(appointment)
      );
}

export function canCallCustomer(appointment, accountType) {
    const notAllowed = ['customer']
    if(notAllowed.includes(accountType)){
        return false;
    }
    if (
        appointment.customer_data &&
        appointment.customer_data.phone &&
        appointment.customer_data.phone.length > 0 &&
        !isAppointmentComplete(appointment)
    ) {
        return true;
    }
    return false;
}

export function canMarkAsException(appointment, accountType, allowException = true) {
    const notAllowed = ['customer']
    if(notAllowed.includes(accountType)){
        return false;
    }
    if (
        allowException &&
        !isAppointmentSuspended(appointment) &&
        !isPastAppointment(appointment) &&
        !isAppointmentDeleted(appointment) &&
        !isAppointmentCanceled(appointment) &&
        !isAppointmentComplete(appointment) &&
        !isAppointmentException(appointment)
    ) {
        return true;
    }
    return false;
}

export function canViewHomeProfile(accountType) {
    const notAllowed = ['customer', "organization"]
    if(notAllowed.includes(accountType)){
        return false;
    }
    return true;
}

export function canChangeHomeSize(accountType) {
    const notAllowed = ['customer', "vendor"]
    if(notAllowed.includes(accountType)){
        return false;
    }
    return true;
}

export const canCreateChangeHomeSizeApprovalRequest = (appointment, accountType) => {
    const allowed = ['organization', 'vendor']
    if(!allowed.includes(accountType)){
        return false;
    }
    return !isPastAppointment(appointment) &&
    !isAppointmentDeleted(appointment) &&
    appointment.approval_data.filter(approval => approval.status === 'pending' && approval.type === "home_size").length === 0
}

export const canCreateChangeStartTimeApprovalRequest = (appointment, accountType) => {
    const allowed = []
    if(!allowed.includes(accountType)){
        return false;
    }
    return !isAppointmentSuspended(appointment) &&
    !isPastAppointment(appointment) &&
    !isAppointmentDeleted(appointment) &&
    !isAppointmentCanceled(appointment) &&
    !isAppointmentComplete(appointment) &&
    !isAppointmentException(appointment) &&
    appointment.approval_data.filter(approval => approval.status === 'pending' && approval.type === "start_time" && appointment._id === approval.data.appointmentId).length === 0
}

export function canCreateTicket(appointment, accountType) {
    const allowed = ['customer']
    if(!allowed.includes(accountType)){
        return false;
    }
    return !isPastAppointment(appointment) &&
           !isAppointmentDeleted(appointment)
}

export function canAddNote(appointment, accountType) {
    const notAllowed = ['customer']
    if(notAllowed.includes(accountType)){
        return false;
    }
    return !isPastAppointment(appointment) &&
           !isAppointmentDeleted(appointment)
}
export function canAddImage(appointment, accountType) {
    const allowedAccountTypes = ['vendor', 'organization']
  return allowedAccountTypes.includes(accountType) &&
    !isPastAppointment(appointment) &&
    !isAppointmentDeleted(appointment)
}

export function canViewAppointmentSubscription(appointment, accountType) {
    const allowed = ['customer', 'super']
    if(allowed.includes(accountType)){
        return get(appointment, "order_data.type", "") === "subscription"
    }
    return false
}

export function canPreAuthorizePayment(appointment, accountType) {
    const allowed = ['super', 'admin']
    if(allowed.includes(accountType) && process.env.VUE_APP_USE_PRE_AUTH_PAYMENT === '1'){
        const paymentIntentId = get(appointment, "paymentIntentId", "")
        return !paymentIntentId && appointment.status === 'active'
    }
    return false
}

export function canCancelPreAuthorizePayment(appointment, accountType) {
    const allowed = ['super', 'admin']
    if(allowed.includes(accountType) && process.env.VUE_APP_USE_PRE_AUTH_PAYMENT === '1'){
        const paymentIntentId = get(appointment, "paymentIntentId", "")
        return paymentIntentId
    }
    return false
}