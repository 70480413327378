import store from "@/store/store";
import {
  AFFILIATE_INIT_DASHBOARD
} from '@/store/action-types'
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";

function redirectIfNotOnboarded(next){
  const { meta = {}}  = store.getters["getUserActiveAccount"];
  const { initialSetup = false, skipOnboarding = false } = meta;
  if(!initialSetup && !skipOnboarding){
    store.commit(APP_STATE_WEB_CONTENT_WIDTH, "960px")
    return next('/affiliate/onboarding')
  }
}

function checkIfDashboardLoaded(next) {
  const isDashboardInit = store.getters["affiliates/getLoadedDashboard"]
  if(!isDashboardInit){
    const activeAccount = store.getters["getUserActiveAccount"];
    store.dispatch(`affiliates/${AFFILIATE_INIT_DASHBOARD}`, activeAccount._id)
    return next();
  }
}

// Guards affiliate routes if onboarding if not complete
// Redirects the user to onboarding screen
export const affiliateBeforeResolve = async (_toRoute, fromRoute, next) => {
  redirectIfNotOnboarded(next)
  store.commit(APP_STATE_WEB_CONTENT_WIDTH)
  // can add other checks in here, as it guards all affiliate routes.
  checkIfDashboardLoaded(next);
  return next()
};
