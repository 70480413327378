import { upperFirst, upperCase, lowerCase, startCase } from "lodash-es";

export default function install(Vue){
  Vue.prototype.__getTextUcFirst = function (message) {
      const getText = upperFirst(message);
      return getText;
  }
  Vue.prototype.__getTextUcEach = function (message) {
      const getText = upperCase(message);
      return getText;
  }
  Vue.prototype.__getTextLower = function (message) {
      const getText = lowerCase(message);
      return getText;
  }
    Vue.prototype.__getTextStartCase = function (message) {
      const getText = startCase(message);
      return getText;
  }
}