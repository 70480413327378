const { VUE_APP_MODE } = process.env;

export const getTicketStatusTagClasses = (status) => {
    switch (status) {
      case 'open':
        return 'bg-green-800 border-green-900 text-gray-100'
      case 'closed':
        return 'bg-gray-600 border-gray-900 text-gray-100'
      case 'danger':
        return 'bg-yellow-600' 
      case 'exception':
        return "bg-yellow-600"
      case 'active':
        return "bg-green-300"
      default:
      return 'bg-gray-500'
    }
  }
  export const getAppointmentStatusTagClasses = (status, allowActive = false) => {
    switch (status) {
      case "complete":
        return "bg-green-800 text-gray-100";
      case "canceled":
        return "bg-red-600 text-gray-100";
      case "danger":
        return "bg-yellow-600 text-gray-100";
      case "exception":
        return "bg-yellow-600 text-gray-100";
      case "active":
        return VUE_APP_MODE === "native" || allowActive ? "bg-gray-100 text-gray-600" : "bg-white text-white";
      default:
        return VUE_APP_MODE === "native" || allowActive ? "bg-gray-100 text-gray-600" : "bg-gray-500 text-gray-100";
    }
  }
export const appButton = () => {
    return [""]
}