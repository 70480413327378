

    const state = {
        invoice: []
      }

    const mutations = {
        change(state, invoice) {
          state.invoice = invoice
        }
    }

    const getters = {
        invoice: state => state.invoice
      }

      export default {
        state,
        mutations,
        getters
      }