var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col pb-32 overflow-auto" },
    [
      _vm.showMobileHeaderPhone
        ? _c(
            "span",
            {
              staticClass:
                "absolute top-0 left-0 bg-green-900 text-white text-center w-full  text-xl md:text-2xl py-2 z-10",
            },
            [_vm._v("Call Us: "), _vm._m(0)]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "bg-white w-full py-3 px-4 md:py-4 md:px-4 fixed z-10 md:h-16d shadow-sm md:shadow-none md:relative md:h-auto",
          class: {
            "top-12 md:top-0": _vm.showMobileHeaderPhone,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full mx-auto flex justify-between items-center transition relative wemow-header",
              class: _vm.headerClasses,
              style:
                "max-width:" +
                (_vm.maxWidthHeader ? _vm.maxWidthHeader : _vm.maxWidth) +
                ";",
            },
            [
              _c("HeaderLogo", {
                staticClass: "header-logo",
                class: _vm.headerLogoClass,
                attrs: {
                  showHeaderLogoAsLink: _vm.showHeaderLogoAsLink,
                  isLoading: _vm.asyncComponentLoading,
                },
              }),
              _c(
                "div",
                {
                  staticClass: "mt-0 md:mt-0 h-10 flex items-center flex-grow",
                  class: _vm.headerRightClasses,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex w-full",
                      class: _vm.headerRightInnerClasses,
                    },
                    [_vm._t("header")],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._m(1),
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center flex-col pt-20 lg:pt-16 w-full",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "bg-white px-5 py-12 md:px-12 rounded-lg shadow-md w-full relative overflow-x-hidden",
              style:
                "max-width:" +
                (_vm.maxWidthContent ? _vm.maxWidthContent : _vm.maxWidth) +
                ";min-height:400px;",
            },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.asyncComponentLoading
                  ? _c("div", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.asyncComponentLoading,
                          expression: "asyncComponentLoading",
                        },
                      ],
                      staticClass:
                        "absolute top-0 left-0 w-full h-full background-white bg-opacity-25",
                    })
                  : _vm._e(),
              ]),
              _vm._t("body"),
            ],
            2
          ),
        ]
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.asyncComponentLoading,
                expression: "!asyncComponentLoading",
              },
            ],
          },
          [_vm._t("after")],
          2
        ),
      ]),
      _c("div", { staticClass: "mt-8" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full mx-auto flex justify-center items-center text-center",
            style:
              "max-width:" +
              (_vm.maxWidthFooter ? _vm.maxWidthFooter : _vm.maxWidth) +
              ";",
          },
          [
            _c("div", [
              _c(
                "p",
                { staticClass: "text-xs text-gray-600" },
                [
                  _vm._v("© 2022 Wemow - "),
                  _vm.$wemowIsLoggedIn()
                    ? _c(
                        "router-link",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { to: "/logout" },
                        },
                        [_vm._v("Logout")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("p", { staticClass: "text-xs text-gray-600" }, [
                _vm._v("web version " + _vm._s(_vm.appVersion)),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _c("a", { attrs: { href: "tel:1-800-246-6690" } }, [
        _vm._v("1-800-246-6690"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "background-stripe" } }, [
      _c("div", { attrs: { id: "background-stripe-inner" } }, [
        _c("div", { attrs: { id: "background-stripe-background" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }