// @ts-check

export const miscStrings = {
    label: "Etiqueta",
    profile: "Perfil",
    generate: "Generar",
    passwordStrength: "seguridad de la contraseña",
    passwordRequirements: "Requisitos de contraseña",
    showPassword: "Mostrar contraseña",
    hidePassword: "Contraseña oculta",
    auto: "Auto",
    available: "Disponible",
    created: "Creado",
    used: "Usado",
    lock: "Bloquear",
    link: "Enlace",
    total: "Total",
    none: "Ninguna",
    listText: "Lista de {text}",
    sendBulkEmails: "Puede enviar correos electrónicos masivos.",
    bulkMessages: "Mensajes masivos",
    broadCastEmail: "Correo electrónico de difusión | Difundir correos electrónicos",
    details: "detalles",
    emailsHistory: "Historial de correos electrónicos",
    noEmailHistory: "No Hay Historial de Correo Electrónico",
    recipientList: "Lista de recipientes",
    message: "Mensaje | Mensajes",
    basicInformation: "Información Básica",
    welcomeSetup: "Bienvenido, debes configurar tu {text}",
    phoneType: "{text} Teléfono",
    primary: "Primario",
    otherQuestions: "Otras preguntas",
    home: "Hogar",
    hello: "Hola",
    dismissedText: "Descartado {text}",
    unread: "No leído",
    dismiss: "Descartar",
    no: "No",
    yes: "Si",
    account: "Cuenta",
    preference: "preferencia | preferencias",
    defaultTimeZone: "Zona horaria predeterminada",
    setting: "ajuste | ajustes",
    text: "Texto",
    warning: "Advertencia",
    one: "Uno",
    vendorDetails: "detalles del vendedor",
    availability: "Disponibilidad",
    info: "informacion",
    enter: "Entrar",
    general: "general",
    noScheduleCreated: "No se creó un horario para este proveedor.",
    custom: "Personalizado",
    pickDay: "Escoge un día",
    trash: "Basura",
    noSpecifiedTimes: "No hay tiempos especificados",
    pending: "Pendiente",
    reset: "Reiniciar",
    duration: "Duración",
    estimated: "Estimado",
    approximately: "Aproximadamente",
    time: "Hora",
    history: "historia",
    by: "por",
    name: "nombre",
    load: "carga",
    more: "más",
    selectPricingOption: "Seleccione la opción de precio preferida",
    note: "Nota|Notas",
    lastUpdate: "Última actualización",
    unknown: "Desconocido",
    random: "Aleatorio",
    dashboard: "tablero",
    default: "Predeterminado",
    setDefault: "Establecer como predeterminado",
    all: "todas",
    changes: "cambios",
    searchBy: "buscar por {text}",
    perVisit: "por visita",
    id: "ID ",
    here: "aquí",
    preferredLanguage: "Idioma preferido",
    serviceArea: "Área de servicio | Areas de servicio",
    add: "agregar",
    profileImage: "Imagen de perfil",
    seconds: "segundos",
    completionConfirmed: "Terminada",
    welcome: "Bienvenidos",
    pushNotifications: "Notificaciones push",
    notifications: "Notificaciones",
    receiveSMS: "Recibir SMS",
    receiveEmail: "Recibir correo",
    receivePush: "Recibir notificaciones push",
    organizationStatistics: "Estadísticas de la organización",
    basic: "Básico",
    insurance: "Seguro",
    approved: 'Aprobado',
    unapproved: 'No aprobado',
    search: "buscar",
    save: 'Salvar',
    notificationSettings: "Configuración de las notificaciones",
    manageReferralIDs: "administrar ID de referencia",
    communication: "comunicación",
    started: "Empezado",
    bankAccount: "Cuenta Bancaria"
}