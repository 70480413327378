import {
  AUTH_PING_API,
  AUTH_CHECK,
  AUTH_LOGIN_ACTION,
  AUTH_LOGIN_ACTION_ALT,
  AUTH_REFRESH_TOKEN,
  AUTH_LOGOUT_ACTION,
  AUTH_CHANGE_ACTIVE_ACCOUNT,
  AUTH_PASSWORD_RESET_ACTION,
  AUTH_VERIFY_PASSWORD_RESET_CODE,
  AUTH_VERIFY_TOKEN,
  AUTH_USER_RESET_PASSWORD_ACTION,
  AUTH_REGISTRATION_ACTION,
  AUTH_QUICK_REGISTRATION_ACTION,
  AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE,
  AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_ADDRESS,
  AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_DATE,
  AUTH_PUBLIC_GET_SERVICES,
  AUTH_ALT_REGISTRATION_ACTION,
  AUTH_QUICK_ALT_REGISTRATION_ACTION,
  AUTH_VERIFY_CONFIRMATION_TOKEN,
  AUTH_CHECK_USERNAME_IN_USE,
  AUTH_VERIFY_CODE,
  AUTH_RESEND_VERIFY_CODE,
  AUTH_RESEND_PHONE_VERIFICATION_CODE,
  AUTH_VERIFY_CLAIM_TOKEN,
  AUTH_USER_GENERATE_APP_PASSWORD,
  CONNECT_SOCIAL_ACCOUNT,
  DISCONNECT_SOCIAL_ACCOUNT,
  AUTH_NEW_PASSWORD_RESET_ACTION,
  CHECK_API_VERSION
} from "@/store/action-types";

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  CLEAR_USER_STORE,
  SETUP_USER_DATA,
  CLEAR_APP_STATE_STORE,
  USER_SET_ACTIVE_ACCOUNT,
  CLEAR_NOTIFICATIONS_STORE,
  CLEAR_TICKETS_DATA,
  CLEAR_APPOINTMENTS_STORE,
  CLEAR_CUSTOMER_STORE,
  CLEAR_ORGANIZATION_STORE,
  CLEAR_AFFILIATES_DATA,
  CLEAR_VENDORS_DATA,
  SET_SWITCHING_ACCOUNT_STATUS
} from '@/store/mutation-types'

import {
  CLEAR_ADMINS_DATA
} from '@/store/admin-mutation-types'

import {
  AuthLogIn,
  AuthVerifyAccessToken,
  authSetActiveAccount,
  AuthVerifyToken,
  AuthVerifyConfirmAccountToken,
  AuthRegister,
  AuthRegisterCustomerQuick,
  AuthAltRegister,
  AuthAltRegisterCustomerQuick,
  AuthResetPasswordAction,
  AuthSendPasswordReset,
  AuthVerifyResetPasswordCode,
  AuthLogout,
  AuthPingAPIServer,
  AuthCheckUsernameInUse,
  AuthVerifyCode,
  AuthResendVerifyCode,
  AuthResendPhoneVerifyCode,
  AuthVerifyClaimAccountToken,
  AuthRefreshToken,
  AuthGenerateAppSpecificPassword,
  AuthLogInAlt,
  connectSocialAccount,
  disconnectSocialAccount,
  AuthResetNewPassword
} from "@/services/ServiceAuthorization";

import {
healthCheck,
publicGetServices,
QuickRegisterLookupAffiliate,
QuickRegisterLookupAddress,
appointmentsGetNextAvailabilityPublic
} from "@/services/ServiceDatabaseAPI";

import { generatePassword, getErrorMessage } from "../../helpers/helpers";

const getPassword = generatePassword

export const state = {
  loggedIn: false,
  user_info: {},
  email: '',
  switchingAccounts: true
}

export const mutations = {
  resetAuthStoreToDefaults: (state) => {
    state.user_info = {}
    state.email = ''
  },

  [AUTH_LOGOUT]: (state) => {
    state.loggedIn = false
    state.user_info = {}
    state.email = ''
  },

  [AUTH_LOGIN]: (state) => {
    state.loggedIn = true
  },

  [SETUP_USER_DATA](state, user) {
    state.user_info = user
  },

  [SET_SWITCHING_ACCOUNT_STATUS](state, value) {
		state.switchingAccounts = value;
	}
}

export const actions = {
  async [AUTH_PING_API]({}) {
    try {
      const ping = await AuthPingAPIServer()
      if (!ping || ping.status !== 200) {
        throw new Error('Network Error')
      }
      return true
    } catch (e) {
      throw new Error(e.message)
    }
  },
  async [AUTH_CHECK_USERNAME_IN_USE]({}, { email }) {
    try {
      const check = await AuthCheckUsernameInUse({ email })

      if (!check || check.status !== 200) {
        throw new Error('Network Error')
      }
      return check.data
    } catch (e) {
      throw new Error(e.message)
    }
  },
  [AUTH_CHECK]: async function ({ commit, state }) {
    let token = localStorage.getItem('id_token')
    if (!token) {
      commit(AUTH_LOGOUT)
      return false
    }

    let verify = await AuthVerifyAccessToken(token)
    if (verify.data.auth && verify.data.auth === true) {
      if (!state.loggedIn) commit(AUTH_LOGIN)
    } else {
      if (state.loggedIn) commit(AUTH_LOGOUT)
    }

    return verify.data
  },
  async [AUTH_VERIFY_TOKEN]({}, payload) {
    let { token, tokenType="password_reset" } = payload
    if (typeof payload === "string") {
      token = payload
    }
    try {
      let verify = await AuthVerifyToken(token, tokenType)

      if (verify && verify.status === 200) {
        return verify.data
      }
      throw new Error('Error')
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_USER_RESET_PASSWORD_ACTION]({}, data) {
    try {
      let updatePassword = await AuthResetPasswordAction(data)

      if (updatePassword.status !== 200 || !updatePassword.data) {
        throw new Error('Error loading user accounts')
      }

      return updatePassword.data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_LOGIN_ACTION]({ commit }, creds) {
    try {
      let login = await AuthLogIn(creds)

      if (login && login.status === 200) {
        localStorage.removeItem("temporary_password_reset_token");
        if (login.data.tokenType && login.data.tokenType === "temporary_password_reset") {
          localStorage.setItem('temporary_password_reset_token', login.data.id_token)
          return {
            loggedIn: false,
            resetPasswordOnLogin: true
          }
        } else {
          localStorage.setItem('id_token', login.data.id_token)
          commit(SET_SWITCHING_ACCOUNT_STATUS, false);
          commit(AUTH_LOGIN)
          return {
            loggedIn: true,
          }
        }
      }
      throw new Error('Error logging in')
    } catch (e) {
      const errorMessage =
        e.response && e.response.data ? e.response.data.message : e.message
      const errorCode =
        e.response && e.response.data ? e.response.data.code : e.statusText
      return {
        loggedIn: false,
        message: errorMessage,
        code: errorCode,
      }
    }
  },
  async [AUTH_NEW_PASSWORD_RESET_ACTION]({ commit }, creds) {
    try {
      let login = await AuthResetNewPassword(creds)
      if (login && login.status === 200) {
        localStorage.removeItem('temporary_password_reset_token')
        localStorage.setItem('id_token', login.data.id_token)
        commit(AUTH_LOGIN)
        return {
          loggedIn: true,
        }
      }
      throw new Error('Error logging in')
    } catch (e) {
      const errorMessage =
        e.response && e.response.data ? e.response.data.message : e.message
      const errorCode =
        e.response && e.response.data ? e.response.data.code : e.statusText
      return {
        loggedIn: false,
        message: errorMessage,
        code: errorCode,
      }
    }
  },
  async [AUTH_LOGIN_ACTION_ALT]({ commit }, creds) {
    try {
      let login = await AuthLogInAlt(creds)

      if (login && login.status === 200) {
        localStorage.setItem('id_token', login.data.id_token)
        commit(AUTH_LOGIN)
        return {
          loggedIn: true,
        }
      }
      throw new Error('Error logging in')
    } catch (e) {
      const errorMessage =
        e.response && e.response.data ? e.response.data.message : e.message
      const errorCode =
        e.response && e.response.data ? e.response.data.code : e.statusText
      return {
        loggedIn: false,
        message: errorMessage,
        code: errorCode,
      }
    }
  },

  async [AUTH_REFRESH_TOKEN]({}) {
    try {
      let response = await AuthRefreshToken()

      if (response && response.status === 200) {
        localStorage.setItem('id_token', response.data.id_token)
        return {
          loggedIn: true,
        }
      }
      throw new Error('Error refreshing token')
    } catch (e) {
      const errorMessage =
        e.response && e.response.data ? e.response.data.message : e.message
      const errorCode =
        e.response && e.response.data ? e.response.data.code : e.statusText
      return {
        loggedIn: false,
        message: errorMessage,
        code: errorCode,
      }
    }
  },
  async [AUTH_CHANGE_ACTIVE_ACCOUNT]({ commit, rootState }, selectedAccount) {
    try {
      let saveUserActiveAccount = await authSetActiveAccount(selectedAccount)

      if (saveUserActiveAccount.status !== 200 || !saveUserActiveAccount.data) {
        throw new Error('Error switching user accounts')
      }

      const newToken = saveUserActiveAccount.data.token
      localStorage.removeItem('id_token')
      localStorage.setItem('id_token', newToken)

      let user = saveUserActiveAccount.data.account

      let lastUsed = user.meta.last_active_account
      let account = rootState.user.accounts.filter((a) => a._id === lastUsed)
      commit(SET_SWITCHING_ACCOUNT_STATUS, true);

      commit(SETUP_USER_DATA, user, { root: true })
      commit(USER_SET_ACTIVE_ACCOUNT, account[0], { root: true })
      commit('chat/CLEAR_MESSAGES', { root: true })

      return account[0]
    } catch (e) {
      throw new Error(e.message)
    }
  },

  async [AUTH_LOGOUT_ACTION]({ commit, rootState }, clear_all = true) {
    let token = localStorage.getItem('id_token')
    if (clear_all) {
      commit(AUTH_LOGOUT)
      commit(CLEAR_USER_STORE)
      commit(CLEAR_APP_STATE_STORE)
    }

    if (rootState['notifications']) {
      commit(`notifications/${CLEAR_NOTIFICATIONS_STORE}`, { root: true })
    }
    if (rootState['ticket']) {
      commit(`ticket/${CLEAR_TICKETS_DATA}`, { root: true })
    }
    if (rootState['customers']) {
      commit(`customers/${CLEAR_CUSTOMER_STORE}`, { root: true })
    }
    if (rootState['organizations']) {
      commit(`organizations/${CLEAR_ORGANIZATION_STORE}`, { root: true })
    }
    if (rootState['appointments']) {
      commit(`appointments/${CLEAR_APPOINTMENTS_STORE}`, { root: true })
    }
    if (rootState['affiliates']) {
      commit(`affiliates/${CLEAR_AFFILIATES_DATA}`, { root: true })
    }
    if (rootState['vendors']) {
      commit(`vendors/${CLEAR_VENDORS_DATA}`, { root: true })
    }
    if (rootState['admin']) {
      commit(`admin/${CLEAR_ADMINS_DATA}`, { root: true })
    }

    if (!clear_all) {
      return true
    }

    if (!token || _.isUndefined(token)) {
      return true
    }

    localStorage.removeItem('id_token')

    await AuthLogout(token)

    return true
  },

  async [AUTH_PASSWORD_RESET_ACTION]({}, user) {
    try {
      return await AuthSendPasswordReset(user)
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_VERIFY_PASSWORD_RESET_CODE]({}, { verification_code, email }) {
    try {
      const verificationStatus = await AuthVerifyResetPasswordCode({
        verification_code,
        email,
      })
      if (verificationStatus.data && verificationStatus.status == 200) {
        localStorage.setItem('reset_token', verificationStatus.data.reset_token)
      }
      return verificationStatus.data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_REGISTRATION_ACTION]({}, user) {
    try {
      const { data = {} } = await AuthRegister(user)
      const { userData, token } = data
      if (token) {
        localStorage.setItem('register_verify_sms', token)
      }
      return userData
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_QUICK_REGISTRATION_ACTION]({}, user) {
    try {
      const { data = {} } = await AuthRegisterCustomerQuick(user);
      const { response } = data
      const { id_token, reset_token, ...props } = response
      if (id_token) {
        localStorage.setItem('id_token', id_token)
      }
      if (reset_token) {
        localStorage.setItem('reset_token', reset_token)
      }
      return props
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE]({}, referral_id) {
    try {
      const { data } = await QuickRegisterLookupAffiliate({referral_id})
      return data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_ADDRESS]({}, address) {
    try {
      const { data } = await QuickRegisterLookupAddress(address)
      return data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_DATE]({}, address) {
    try {
      const { data } = await appointmentsGetNextAvailabilityPublic(address)
      return data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_PUBLIC_GET_SERVICES]({}) {
    try {
      const { data } = await publicGetServices()
      return data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_ALT_REGISTRATION_ACTION]({commit}, user) {
    try {
      const response = await AuthAltRegister(user)
      const { id_token } = response.data

      if (response && response.status === 200) {
        localStorage.setItem('id_token', id_token)
        commit(AUTH_LOGIN)
        return {
          loggedIn: true,
        }
      }else {
        return {
          loggedIn: false,
        }
      }
    } catch (e) {
      console.log(e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [AUTH_QUICK_ALT_REGISTRATION_ACTION]({}, user) {
    try {
      const { data = {} } = await AuthAltRegisterCustomerQuick(user);
      const { response } = data
      const { token, ...props } = response
      if (token) {
        localStorage.setItem('id_token', token)
      }
      return props
    } catch (e) {
      console.log(e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  
  async [AUTH_VERIFY_CONFIRMATION_TOKEN]({}, token) {
    try {
      return await AuthVerifyConfirmAccountToken(token)
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_VERIFY_CODE]({ commit }, data) {
    try {
      const token = localStorage.getItem('register_verify_sms')
      const verify = await AuthVerifyCode(data, token)
      if (verify && verify.status === 200) {
        localStorage.removeItem('register_verify_sms')
        localStorage.setItem('id_token', verify.data.id_token)
        commit(AUTH_LOGIN)
        return verify.data.role
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_RESEND_PHONE_VERIFICATION_CODE]({}, data) {
    try {
      const token = localStorage.getItem('register_verify_sms')
      const response = await AuthResendPhoneVerifyCode(data, token)
      if (response && response.status === 200) {
        localStorage.setItem('register_verify_sms', response.data.token)
        return response.data
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_RESEND_VERIFY_CODE]({}, data) {
    try {
      localStorage.removeItem('register_verify_sms')
      const codeData = await AuthResendVerifyCode(data)
      localStorage.setItem('register_verify_sms', codeData.data.id_token)
      return codeData
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_VERIFY_CLAIM_TOKEN]({}, token) {
    try {
      let reset_password = await AuthVerifyClaimAccountToken(token)

      if (reset_password && reset_password.status === 200) {
        localStorage.setItem('reset_token', reset_password.data.reset_token)
        return reset_password.data.reset_token
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },

  async [AUTH_USER_GENERATE_APP_PASSWORD](
    {},
    data
  ) {
    try {
      let generatePassword = getPassword();
      data.password = generatePassword;
      let generateAppSpecificPassword = await AuthGenerateAppSpecificPassword(data)

      if (generateAppSpecificPassword.status !== 200 || !generateAppSpecificPassword.data) {
        throw new Error('Error loading user accounts')
      }
      localStorage.setItem("app_password", generatePassword);
      return generateAppSpecificPassword.data
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },  
  async [CONNECT_SOCIAL_ACCOUNT]({ commit },data) {
    try {
        const response = await connectSocialAccount(data)
        console.log('sds')
        if(response && response.data) {
          console.log('it worked',response.data)
          return commit(SETUP_USER_DATA, response.data, { root: true })
        }
    } catch (e) {
      console.error(e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },  
  async [DISCONNECT_SOCIAL_ACCOUNT]({ commit },data) {
    try {
        const response = await disconnectSocialAccount(data)
        console.log('sds')
        if(response && response.data) {
          console.log('it worked',response.data)
          return commit(SETUP_USER_DATA, response.data, { root: true })
        }
    } catch (e) {
      console.error(e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },  
  async [CHECK_API_VERSION]({}) {
    try {
      const check = await healthCheck()

      const apiVersion = check.headers['x-wemow-api-version']

      return apiVersion
    } catch (e) {
      throw new Error(e.message)
    }
  },
}

const getters = {
  isUserLoggedIn: (state) => state.loggedIn,
  registrationInfo: (state) => state.user_info
}

export default {
  state,
  getters,
  actions,
  mutations,
}
