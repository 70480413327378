import io from "socket.io-client";
import { serverDatabaseURL } from "@/helpers/helpers";
import auth from '@/auth'

const path = `${serverDatabaseURL().replace('/api/v1/', '')}`

const socket = io(path, {
  path: "/socket",
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionAttempts: Infinity,
  autoConnect: false,
  withCredentials: true,
  extraHeaders: {
    Authorization: `${auth.getAuthSocketObject()}`
},
transportOptions: {
  polling: {
      extraHeaders: {
        Authorization: `${auth.getAuthSocketObject()}`
      }
    }
}
});

export default socket;