<template>
  <div class="flex gap-2">
    <div>
      <ProfileImageView :overrideAccount="resource" :showName="false" />
    </div>
    <div>
      <div class="flex items-center mb-2">
        <span @click="copyText(customer.displayName)" class="text-xl font-bold cursor-pointer">{{
          customer.displayName
        }}</span>
        <span @click="goTo" class="text-xs ml-4 cursor-pointer">View</span>
      </div>
      <div class="mb-0.5" @click="copyText(customer.email)">
        {{ customer.email }}
        <span class="ml-1 cursor-pointer"
          ><a target="_blank" :href="`mailto:${customer.email}`"
            ><i class="el-icon-link"></i></a
        ></span>
      </div>
      <div class="mb-0.5" @click="copyText(customer.phone)">
        {{ customer.phone }}
      </div>
      <div class="mb-0.5" @click="copyText(customer.address)">
        {{ customer.address }}
      </div>
      <!-- {{resource}} -->
    </div>
  </div>
</template>

<script>
import { get } from "lodash-es";

export default {
  components: {
    ProfileImageView: () =>
      import("@/components/shared/Media/ProfileImageView"),
  },
  props: {
    to: {
      type: String,
      default: "",
    },
    resource: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    goTo() {
      this.$emit("close");
      this.$router.push(this.to);
    },
    copyText(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => this.$wemowNotifySuccess(`Copied: ${text}`));
    },
  },
  computed: {
    customer() {
      const { resource } = this;
      return {
        displayName: `${get(resource, "first_name", "")} ${get(
          resource,
          "last_name",
          ""
        )}`,
        email: `${get(resource, "primary_email", "")}`,
        phone: `${get(resource, "phone[0].number", "")}`,
        address: `${get(resource, "address[0].address_formatted", "")}`,
      };
    },
  },
};
</script>
