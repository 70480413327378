
export default {
  methods: {
    proceedToUserDashboard(role, vm){
      switch(role) {
        case 'organization': 
        (this.$router||vm.$router).replace('/organization/appointments');
        break;
        case 'customer':
          (this.$router||vm.$router).replace("/dashboard");
        break;
        case 'affiliate': 
        (this.$router||vm.$router).replace('/affiliate');
        break;
        case 'vendor':
          (this.$router||vm.$router).replace('/vendor/appointments');
        break;
        default:
          (this.$router||vm.$router).replace('/login');
        break
      }
    }
  }
}