<template>
  <div class="mt-4">
    <div class="mb-4">
      <div class="border bg-gray-100 bg-opacity-50 p-4 relative">
        {{ displayData.shareText }} {{ displayData.shareLink }}
        <div
          class="absolute flex items-center justify-center bottom-0 right-0 cursor-pointer bg-gray-100"
        >
          <span @click="copyText" class="text-xs text-green-900 p-2">{{
            copiedText ? "Copied!" : "Copy"
          }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="font-medium mb-3">Share a link</div>
      <div class="border bg-gray-100 bg-opacity-50 p-4 relative">
        {{ displayData.shareLink }}
        <div
          class="absolute flex items-center justify-center bottom-0 right-0 cursor-pointer bg-gray-100"
        >
          <span @click="copyLink" class="text-xs text-green-900 p-2">{{
            copiedLink ? "Copied!" : "Copy"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingTimeout } from "@/helpers/helpers";

export default {
  props: {
    slotData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      copiedLink: false,
      copiedText: false,
    };
  },
  methods: {
    copyText() {
      navigator.clipboard
        .writeText(this.displayData.shareText)
        .then(() => this.copiedText = true && Promise.resolve())
        .then(() => loadingTimeout(3000))
        .finally(() => this.copiedText = false)
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.displayData.shareLink)
        .then(() => this.copiedLink = true && Promise.resolve())
        .then(() => loadingTimeout(3000))
        .finally(() => this.copiedLink = false)
    },
  },
  computed: {
    displayData() {
      const { slotData } = this;
      console.log(slotData);
      const { shareText, shareLink } = slotData;
      return {
        shareText,
        shareLink,
      };
    },
  },
};
</script>
