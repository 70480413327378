import store from "@/store/store";
import {
  AUTH_CHECK,
  LOAD_USER_DATA,
  AUTH_LOGOUT_ACTION,
  GET_ALERTS
} from "@/store/action-types";

import { SET_USER_STORE_STATUS } from "@/store/mutation-types";
import { setUpUserStores, resetAllUserStores } from "@/store/registerStores";

const logoutAndEmptyStore = async () => {
  if (store.getters["isUserLoggedIn"]) {
    await authDoLogout();
  }
  resetAllUserStores()
}

export async function authCheckSetupUser(force = false) {
  const hasToken = localStorage.getItem("id_token");

  if (!hasToken) {
    await logoutAndEmptyStore();
    return;
  }

  const { role, accounts, accountId } = await store.dispatch(AUTH_CHECK);

  if (role === "none") {
    console.log("User role is none")
    await logoutAndEmptyStore();
    return;
  }

  if (role !== "super" && accounts <= 0) {
    console.log("User has access to no accounts")
    await logoutAndEmptyStore();
    return;
  }

  const user = store.getters.getUserInfo;
  if (force || (!user || !user.email)) {
    resetAllUserStores()
    await store.dispatch(LOAD_USER_DATA,  { accountId, accountType: role });
  }
  await setUpUserStores(role);
  store.commit(SET_USER_STORE_STATUS, true)
  if(role === 'vendor'){
    await store.dispatch(`notifications/${GET_ALERTS}`, {account_id: accountId})
  }
  return { role, accounts }
}

export async function authDoLogout() {
  await store.dispatch(AUTH_LOGOUT_ACTION);
}
