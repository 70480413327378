import {
	LOAD_USER_DATA,
	USER_UPDATE_PASSWORD,
	USER_UPDATE_DEVICE_TOKEN,
	USER_REMOVE_DEVICE_TOKEN,
	GET_ZIP_CODES,
	ORGANIZATION_GET_SERVICE_AREAS,
	ORGANIZATION_GET_ZIP_CODES,
	USER_UPDATE,
	ORGANIZATION_GET_SURVEY_QUESTIONS,
	ORGANIZATION_GET_SURVEY_ANSWERS,
	SET_SCREEN_NAME,
	CHECK_ACCOUNT_CAN_BE_DELETED,
    DELETE_ACCOUNT
} from '../action-types';

import {
	UPDATE_USER_ACCOUNT,
	SETUP_USER_DATA,
	SETUP_USER_ACCOUNTS,
	UPDATE_USER_ACCOUNTS,
	CLEAR_USER_STORE,
	USER_SET_ACTIVE_ACCOUNT,
	SET_ZIP_CODES,
	SET_USER_STORE_STATUS,
	REMOVE_USER_ACCOUNT_BY_ID
} from '../mutation-types';

import { getField, updateField } from 'vuex-map-fields';

import {
	loadUserData,
	loadUserAccounts,
	userUpdatePassword,
	userUpdateDeviceToken,
	userRemoveDeviceToken,
	getZipCodes,
	organizationGetServiceAreas,
	organizationGetZipCodes,
	userUpdate,
	organizationGetOnboardingSurvey,
	organizationGetSurveyAnswer,
	setCurrentScreen,
	checkAccountCanBeDeleted,
    deleteAccount
} from '@/services/ServiceDatabaseAPI';

import { getErrorMessage } from "@/helpers/helpers"
const getDefaultState = () => {
	return {
		user_info: {},
		accounts: [],
		activeAccount: {
			integrations: {},
			meta: {},
			preferences: {},
			settings: {}
		},
		userStoresAreReady: false
	};
};
// root state object.
const state = getDefaultState();

const mutations = {
	resetUserStoreToDefaults: (state) => {
		state.user_info = {}
		state.accounts = []
		state.activeAccount = {
			integrations: {},
			meta: {},
			preferences: {},
			settings: {}
		}
		state.userStoresAreReady = false
	},
	updateField,
	[UPDATE_USER_ACCOUNT](state, field) {
		updateField(field);
	},
	[SETUP_USER_DATA](state, user) {
		state.user_info = user;
	},
	[SETUP_USER_ACCOUNTS](state, user_accounts) {
		state.accounts = user_accounts;
	},
	[UPDATE_USER_ACCOUNTS](state, user_accounts) {
		let acctIndex = state.accounts.map((o) => o._id).indexOf(user_accounts._id);
		if (acctIndex > -1) {
			state.accounts.splice(acctIndex, 1, user_accounts);
			if (state.activeAccount._id === user_accounts._id) {
				state.activeAccount = user_accounts;
			}
		}
	},
	[REMOVE_USER_ACCOUNT_BY_ID](state, user_account_id) {
		if(state.activeAccount._id === user_account_id){
			return
		}
		let acctIndex = state.accounts.map((o) => o._id).indexOf(user_account_id);
		if (acctIndex > -1) {
			state.accounts.splice(acctIndex, 1);
		}
	},
	[USER_SET_ACTIVE_ACCOUNT](state, account) {
		state.activeAccount = account;
	},
	[CLEAR_USER_STORE](state) {
		state = Object.assign(state, getDefaultState());
		return state;
	},
	[SET_ZIP_CODES](state, codes) {
		state.zip_codes = codes;
	},
	[SET_USER_STORE_STATUS](state, status) {
		state.userStoresAreReady = status;
	}
};

const actions = {
	async [LOAD_USER_DATA]({ commit }, { accountId }) {
		try {
			const load_user = await loadUserData();
			if (load_user.status !== 200 || !load_user.data) {
				throw new Error('Error loading user');
			}
			const user = load_user.data;
			commit(SETUP_USER_DATA, user);
			if (user.role === 'super') {
				return {};
			}
			const load_accounts = await loadUserAccounts(user);
			if (load_accounts.status !== 200 || !load_accounts.data) {
				throw new Error('Error loading user accounts');
			}
			const accounts = load_accounts.data;
			const account = accounts.find(a => a._id === accountId)
			commit(SETUP_USER_ACCOUNTS, accounts);
			commit(USER_SET_ACTIVE_ACCOUNT, account);
			return account;
		} catch (e) {
			throw new Error(e.message);
		}
	},
	async [USER_UPDATE_PASSWORD]({}, password) {
		try{
			const updatePassword = await userUpdatePassword(password);
			if (updatePassword.status !== 200 || !updatePassword.data) {
			throw new Error("Error loading user accounts");
			}
			return updatePassword;
		}catch(e){
			const errorMessage = getErrorMessage(e);
            throw new Error(errorMessage);
		}
	},
	async [USER_UPDATE_DEVICE_TOKEN]({ commit }, device_token) {
		const updatedUserData = await userUpdateDeviceToken(device_token);
		if (updatedUserData.status !== 200 || !updatedUserData.data) {
			throw new Error('Error loading user accounts');
		}
		commit(SETUP_USER_DATA, updatedUserData.data);
		return updatedUserData.data;
	},
	async [USER_REMOVE_DEVICE_TOKEN]({ commit }, device_token) {
		const updatedUserData = await userRemoveDeviceToken(device_token);
		if (updatedUserData.status !== 200 || !updatedUserData.data) {
			throw new Error('Error loading user accounts');
		}
		commit(SETUP_USER_DATA, updatedUserData.data);
		return updatedUserData.data;
	},
	async [GET_ZIP_CODES]({ commit }, { query }) {
		try {
			const codes = await getZipCodes(query);
			if (codes > 204 || !codes.data) {
				throw new Error('Error receiving zip codes');
			}
			const zip_codes = codes.data;
			commit(SET_ZIP_CODES, zip_codes);
			return zip_codes;
		} catch (e) {
			console.log('error', e);
			const errorMessage = getErrorMessage(e);
            throw new Error(errorMessage);
		}
	},
	async [ORGANIZATION_GET_SERVICE_AREAS]({}, { organization_id }) {
		try {
			const getServiceAreas = await organizationGetServiceAreas(organization_id);
			if (getServiceAreas && getServiceAreas.status === 200) {
				return getServiceAreas.data;
			}
		} catch (e) {
			const errorMessage = getErrorMessage(e);
            return new Error(errorMessage);
		}
	},
	async [ORGANIZATION_GET_ZIP_CODES]({}, organization_id) {
		try {
			const zipCodes = await organizationGetZipCodes(organization_id);
			if (zipCodes && zipCodes.status === 200) {
				return zipCodes.data;
			}
		} catch (e) {
			const errorMessage = getErrorMessage(e);
            return new Error(errorMessage);
		}
	},
	async [USER_UPDATE]({ commit }, data) {
		try{
			const updatedUserData = await userUpdate(data);
			if (updatedUserData.status !== 200 || !updatedUserData.data) {
				throw new Error('Error loading user accounts');
			}
			commit(SETUP_USER_DATA, updatedUserData.data);
			return updatedUserData.data;
		}catch(e){
			const errorMessage = getErrorMessage(e);
            return new Error(errorMessage);
		}
	},
	async [SET_SCREEN_NAME]({}, screen_name) {
		try{
			const updatedUserData = await setCurrentScreen({screen_name});
			return updatedUserData.data;
		}catch(e){
			return new Error(e.message);
		}
	},
	async [ORGANIZATION_GET_SURVEY_QUESTIONS]({ }) {
		try {
			let survey = await organizationGetOnboardingSurvey();
			return survey.data;
		} catch (e) {
			const errorMessage = getErrorMessage(e);
            throw new Error(errorMessage)
		}
	},
	async [ORGANIZATION_GET_SURVEY_ANSWERS]({ }, organization_id) {
		try {
			let survey = await organizationGetSurveyAnswer(organization_id);
			return survey.data;
		} catch (e) {
			return false;
		}
	},
	async [CHECK_ACCOUNT_CAN_BE_DELETED]({ }, account_id) {
		try {
			const response = await checkAccountCanBeDeleted(account_id);
			return response;
		} catch (e) {
			throw new Error('There was a problem checking your account');
		}
	},
	async [DELETE_ACCOUNT]({ }, account_id) {
		try {
			const response = await deleteAccount(account_id);
			const data = response.data;
			return data;
		} catch (e) {
			throw new Error('There was a problem deleting your account');
		}
	}
};

const getters = {
	getUserInfo: (state) => state.user_info,
	getUserRole: (state) => {
		if (state.user_info.role === 'super') {
			return state.user_info.role;
		}
		if (!state.user_info || !state.accounts || !state.accounts.length) {
			return 'none';
		}
		const account = state.activeAccount;
		const accountsTypes = state.user_info.accounts;
		if (!account || !account._id || !accountsTypes || !accountsTypes.length) {
			return 'none';
		}
		const findAccount = accountsTypes.find((at) => at.id === account._id);
		return findAccount.type || 'none';
	},
	getUserAccounts: (state) => state.accounts,
	getUserActiveAccount: (state) => state.activeAccount,
	getUserActiveAccountID: (state) => state.activeAccount._id,
	getUserActiveAccountType: (state) => {
		if(state.user_info.role === 'super'){
			return 'admin';
		}
		const activeAccountId = state.activeAccount._id;
		if (!activeAccountId) {
			return null;
		}
		const activeAccountObject = state.user_info.accounts.find((a) => a.id === activeAccountId);

		return activeAccountObject ? activeAccountObject.type : null;
	},
	getUserField: (state) => getField(state.user_info),
	getField,
	getStoreIsReady: (state) => state.userStoresAreReady,
	getUserLocale: (state) => state.activeAccount.settings.language_preference
};

export default {
	state,
	getters,
	actions,
	mutations
};
