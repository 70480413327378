var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-view-action-bar flex" },
    [
      _vm.showBackButton
        ? _c(
            "div",
            { staticClass: "flex items-center mr-auto" },
            [
              _c("BackToButtons", {
                attrs: {
                  defineBackToName: _vm.defineBackToName,
                  defineBackToPath: _vm.defineBackToPath,
                  useBrowserNavigation: _vm.useBrowserNavigation,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.activeListItemsWithDefaults.length
        ? _c(
            "div",
            { staticClass: "flex items-center ml-auto" },
            [
              _vm.showDisplayAddOnDialogButton
                ? _c(
                    "button",
                    {
                      staticClass:
                        "bg-green-900 text-white text-sm leading-md px-2 py-1 mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clickAddAddOnService")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.__getTextStartCase(
                            _vm.$t("formLabels.select") +
                              " " +
                              _vm.$tc("appointments.addOnService", 1)
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.showDisplayCustomAddOnDialogButton
                ? _c(
                    "button",
                    {
                      staticClass:
                        "bg-green-900 text-white text-sm leading-md px-2 py-1 mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clickDisplayCustomAddOnDialog")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.__getTextStartCase(
                            _vm.$t("buttons.createNewText", { text: "" }) +
                              " " +
                              _vm.$t("custom") +
                              " " +
                              _vm.$tc("appointments.addOnService", 1)
                          )
                        )
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.activeListItemsWithDefaults, function (ref, index) {
                var id = ref.id
                var text = ref.text
                var icon = ref.icon
                var click = ref.click
                var subItems = ref.subItems
                var disabled = ref.disabled
                return _c(
                  "div",
                  {
                    key: "view-action-r-" + index,
                    class: {
                      "ml-2": index !== 0,
                    },
                    attrs: { id: id + "-wrap" },
                  },
                  [
                    subItems &&
                    subItems.length &&
                    subItems
                      .map(function (i) {
                        return _vm.linkItemSetDefault(i)
                      })
                      .filter(function (i) {
                        return i.show
                      }).length
                      ? [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "text-sm",
                              on: { command: _vm.handleCommand },
                            },
                            [
                              _c(
                                "button",
                                {
                                  class: _vm.buttonStyleClasses,
                                  attrs: { id: id, disabled: disabled },
                                },
                                [
                                  _vm._v(" " + _vm._s(text) + " "),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  subItems
                                    .map(function (i) {
                                      return _vm.linkItemSetDefault(i)
                                    })
                                    .filter(function (i) {
                                      return i.show
                                    }),
                                  function (ref, index2) {
                                    var text = ref.text
                                    var click = ref.click
                                    var id = ref.id
                                    var disable = ref.disable
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key:
                                          "view-action-r-sub-" +
                                          index +
                                          "-" +
                                          index2,
                                        attrs: {
                                          id: id,
                                          command: click,
                                          disabled: disable,
                                        },
                                      },
                                      [_vm._v(_vm._s(text))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "button",
                            {
                              class: _vm.buttonStyleClasses,
                              attrs: { id: id, disabled: disabled },
                              on: { click: click },
                            },
                            [
                              text
                                ? _c("span", [_vm._v(_vm._s(text))])
                                : _vm._e(),
                              icon
                                ? _c("font-awesome-icon", {
                                    attrs: { icon: icon },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }