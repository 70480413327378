<template>
  <div class="">
    <template v-if="resource">
    <div class="flex items-center">
      <span @click="copyText(appointment.serviceName)" class="text-xl font-bold cursor-pointer">{{
        appointment.serviceName
      }}</span>
      <span @click="goTo" class="text-xs ml-6 cursor-pointer">View</span>
    </div>
    <div class="mb-2 text-xs" @click="copyText(appointment.addressFormatted)">
      {{ appointment.addressFormatted }}
    </div>
    <div class="mb-0.5" @click="copyText(appointment.dateDisplayFull)">
      {{ appointment.dateDisplayFull }}
    </div>
    <div class="mb-0.5" @click="copyText(appointment.vendorName)">
      Vendor: {{ appointment.vendorName }}
    </div>
    <div class="mb-0.5" @click="copyText(appointment.customerName)">
      Customer: {{ appointment.customerName }}
    </div>
    </template>
    <p v-else>No Details</p>
  </div>
</template>

<script>
import { getAppointmentDisplayFormatted } from "@/helpers/appointments"

export default {
  props: {
    to: {
      type: String,
      default: "",
    },
    resource: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    goTo() {
      this.$emit("close");
      this.$router.push(this.to);
    },
    copyText(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => this.$wemowNotifySuccess(`Copied: ${text}`));
    },
  },
  computed: {
    appointment() {
      const { resource } = this;
      if (resource) {
        return getAppointmentDisplayFormatted(resource);
      } else {
        return {};
      }
    },
  },
};
</script>
