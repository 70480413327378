import { getServicePriceByPlanId } from "@/helpers/order";
import { formatDollarAmount } from "@/helpers/helpers";


export const formatAddOnDisplayData = (addOnData) => {
    const { custom = {}, service_data, plan_id, appointment_id, subscription_id } = addOnData;

    const type = appointment_id
      ? "appointment"
      : subscription_id
      ? "subscription"
      : "";

    if (service_data) {
      return {
        addonTo: type,
        name: service_data.name,
        description: service_data.description,
        price: `$${formatDollarAmount(getServicePriceByPlanId(plan_id, service_data))}`,
        approved: addOnData.approvedByCustomer,
      };
    } else {
      return {
        addonTo: type,
        name: "Custom Request",
        description: custom.description,
        price: `$${formatDollarAmount(custom.value)}`,
        approved: addOnData.approvedByCustomer,
      };
    }
  }