var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex gap-2" }, [
    _c(
      "div",
      [
        _c("ProfileImageView", {
          attrs: { overrideAccount: _vm.resource, showName: false },
        }),
      ],
      1
    ),
    _c("div", [
      _c("div", { staticClass: "flex items-center mb-2" }, [
        _c(
          "span",
          {
            staticClass: "text-xl font-bold cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.copyText(_vm.customer.displayName)
              },
            },
          },
          [_vm._v(_vm._s(_vm.customer.displayName))]
        ),
        _c(
          "span",
          {
            staticClass: "text-xs ml-4 cursor-pointer",
            on: { click: _vm.goTo },
          },
          [_vm._v("View")]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "mb-0.5",
          on: {
            click: function ($event) {
              return _vm.copyText(_vm.customer.email)
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.customer.email) + " "),
          _c("span", { staticClass: "ml-1 cursor-pointer" }, [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "mailto:" + _vm.customer.email,
                },
              },
              [_c("i", { staticClass: "el-icon-link" })]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mb-0.5",
          on: {
            click: function ($event) {
              return _vm.copyText(_vm.customer.phone)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.customer.phone) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "mb-0.5",
          on: {
            click: function ($event) {
              return _vm.copyText(_vm.customer.address)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.customer.address) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }