import { isRouteAuthenticationRequired } from "../util"
import { authCheckSetupUser } from "../../../auth"
import { setupWemowWebChat } from "../../../../helpers/helpers"
import bugSnagClient from "../../../../lib/web/bugsnag"
import { query, config } from "vue-gtag";

import store from "@/store/store";
import { get } from "lodash-es";

const redirectToLogin = (next, from) => {
  localStorage.clear()//clean slate
  if(from === '/login' ) return next()
  if ( from === "/" ){
    return next({ path: "/login" });
  }else {
    return next({ path: "/login", query: { redirectFrom: from } });
  }
}


export const getCurrentUser = async (
  toRoute,
  fromRoute,
  next
) => {
  try {
    if(toRoute.query.id_token){
      localStorage.setItem('id_token', toRoute.query.id_token)
    }
  
    // don't need to do auth checks on same page redirects
    if(toRoute.name === fromRoute.name){
      return next();
    }

    await authCheckSetupUser()
    try {
      const user = store.getters.getUserInfo;
      if (user && user.email) {

        const type = store.getters.getUserActiveAccountType
        const account = store.getters.getUserActiveAccount
        window.__wemow_chat_config = {
          type: type,
          accountId: account._id,
          accountEmail: account.primary_email,
          displayName: get(account, 'first_name',  get(user, 'first_name', )),
          loggedIn: true
        }

        bugSnagClient.setUser(user._id, user.email)
        bugSnagClient.addMetadata('user', {
          accountType: type
        });

        config({
          'user_id': store.getters.getUserActiveAccountID
        })

        query('set', 'user_properties', {
          account_type: type,
          role: user.role ? user.role : '',
          register_version: user.registerVersion ? user.registerVersion : ''
        })

        setupWemowWebChat(type)

      }
    } catch (error) {
      console.error("Bugsnag did not init", error.message)
      /* Do nothing */
    }


    next()
  } catch (e) {
    // See if the route requires authentication.
    // If not, then they can proceed
    const { required } = isRouteAuthenticationRequired(toRoute);
    if (!required) {
      return next();
    }
    // The requested route requires authentication, and the
    // user is not authenticated. Send them to login page
    return redirectToLogin(next, toRoute.fullPath);
  }
};
