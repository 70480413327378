import { format, formatDistance, parseISO } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

const locales = { 
  en: enUS, 
  es 
}

export const i18nFormatDate = function (date, formatStr = 'PP', locale = 'en') {
  return format(date, formatStr, {
    locale: locales[locale]
  })
}

export const i18nFormatDateDistance = function (date, baseDate, options = {}, locale = 'en') {
  const d = date instanceof Date ? date : parseISO(date)
  return formatDistance(d, baseDate, Object.assign({},
      options,
      {
          locale: locales[locale]
      }))
}

