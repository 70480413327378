<template>
  <button type="submit" :class="buttonClasses" :disabled="isDisabled">
    {{ text }}
    <svg
      v-if="icon && icon === 'download'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 ml-1"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
      validator: (val) => ["primary", "text"].includes(val),
    },
    icon: {
      type: String,
      default: "",
      validator: (val) => val === "" || ["download"].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
        type: Boolean,
        default: false
    }
  },
  computed: {
    buttonClasses() {
        const { type, disabled, block, loading } = this;
      return Object.assign(
        {},
        {
          "inline-flex": true,
          "justify-center": true,
          "items-center": true,
          "py-4": true,
          "px-4": true,
          "border border-transparent": true,
          "text-sm": true,
          "font-medium": true,
          "shadow-sm": type === "primary",
          "text-white": type === "primary",
          "bg-green-900": type === "primary",
          "hover:bg-green-800": type === "primary",
          "text-green-900": type === "text",
          "w-full block": block,
          "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500": true,
          "cursor-not-allowed opacity-50": disabled,
          "cursor-pointer": !disabled && !loading
        }
      );
    },
    isDisabled(){
      const { disabled, loading } = this;
      return disabled || loading
    }
  },
};
</script>
