import {
  affiliateBeforeResolve,
  affiliateBeforeResolveOnboarding
} from "../../middleware";

export const affiliateRoutes = [
  /*
   * Affiliate Routes
   */
  {
    path: "/affiliate",
    name: "AffiliateRoot",
    redirect: "/affiliate/dashboard"
  },
  {
    path: "/affiliate/onboarding",
    name: "AffiliateOnboarding",
    component: () =>
      import(/* webpackChunkName: "affiliate-onboarding" */ `../../../../components/affiliate/setup/AffiliateSetup.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      hideNavigation: true,
      showHeaderLogoAsLink: false,
      beforeResolve: affiliateBeforeResolveOnboarding
    }
  },
  {
    path: "/affiliate/dashboard",
    name: "AffiliateDashboard",
    component: () =>
      import(/* webpackChunkName: "affiliate-dashboard" */ `../../../../components/affiliate/dashboard/AffiliateDashboard.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      showHeaderLogoAsLink: false,
      beforeResolve: affiliateBeforeResolve
    }
  },
  {
    path: "/affiliate/profile",
    name: "AffiliateProfile",
    component: () =>
      import(/* webpackChunkName: "affiliate-profile" */ `../../../../components/affiliate/profile/AffiliateProfile.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  {
    path: "/affiliate/promote",
    name: "AffiliateReportsDashboard",
    component: () =>
      import(/* webpackChunkName: "affiliate-affiliate-promote" */ `../../../../components/affiliate/promote/AffiliatePromoteDashboard.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    },
    children: [{
      path: "communication",
      name: "AffiliateCommunication",
      component: () =>
        import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/promote/AffiliatePromoteCommunicationSend.vue`),
      meta: {
        requiresAuth: true,
        allowedRoles: ["affiliate"],
        beforeResolve: affiliateBeforeResolve
      }
    }]
  },
  {
    path: "/affiliate/affiliate-id",
    name: "AffiliateID",
    component: () =>
      import(/* webpackChunkName: "affiliate-affiliate-id" */ `../../../../components/affiliate/id/AffiliateID.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  {
    path: "/affiliate/vendors-search",
    name: "AffiliateVendorsSearch",
    component: () =>
      import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/search/VendorsSearch.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  // {
  //   path: "/affiliate/marketing",
  //   name: "AffiliateMarketing",
  //   component: () =>
  //     import(/* webpackChunkName: "affiliate-marketing" */ `../../../../components/affiliate/marketing/AffiliateMarketing.vue`),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: ["affiliate"],
  //     beforeResolve: affiliateBeforeResolve
  //   }
  // },
  // {
  //   path: "/affiliate/marketing/broadcast-emails",
  //   name: "AffiliateBroadCastEmails",
  //   component: () =>
  //     import(/* webpackChunkName: "affiliate-marketing-email" */ `../../../../components/affiliate/marketing/BroadCastEmails.vue`),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: ["affiliate"],
  //     beforeResolve: affiliateBeforeResolve
  //   }
  // },
  {
    path: "/affiliate/reports",
    name: "AffiliateReports",
    component: () =>
      import(/* webpackChunkName: "affiliate-transfers" */ `../../../../components/affiliate/reports/Dashboard.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    },
    children: [
      {
        path: "transfers",
        name: "AffiliatePaymentTransfers",
        component: () =>
        import(/* webpackChunkName: "affiliate-transfers" */ `../../../../components/affiliate/transfers/AffiliatePaymentTransfers.vue` ),
        meta: {
          requiresAuth: true,
          beforeResolve: affiliateBeforeResolve,
          allowedRoles: ["affiliate"]
        },
        props: true
      },
      {
        path: "contributors",
        name: "AffiliateContributors",
        component: () =>
          import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/contributors/AffiliateContributors.vue`),
        meta: {
          requiresAuth: true,
          beforeResolve: affiliateBeforeResolve,
          allowedRoles: ["affiliate"]
        },
        props: true
      },
      {
        path: "sign-ups",
        name: "AffiliateSignUps",
        component: () =>
          import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/contributors/AffiliateSignUps.vue`),
        meta: {
          requiresAuth: true,
          beforeResolve: affiliateBeforeResolve,
          allowedRoles: ["affiliate"]
        },
        props: true
      }
    ]
  },
  {
    path: "/affiliate/communication",
    name: "AffiliateCommunications",
    component: () =>
      import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/communication/CommunicationsArchive.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  {
    path: "/affiliate/communication/new",
    name: "AffiliateCommunicationNew",
    component: () =>
      import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/communication/CommunicationAddNew.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  {
    path: "/affiliate/communication/:communication_id",
    name: "AffiliateCommunicationEdit",
    component: () =>
      import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/communication/CommunicationEdit.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["affiliate"],
      beforeResolve: affiliateBeforeResolve
    }
  },
  // {
  //   path: "/affiliate/contributors",
  //   name: "AffiliateContributors",
  //   component: () =>
  //     import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/contributors/AffiliateContributors.vue`),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: ["affiliate"],
  //     beforeResolve: affiliateBeforeResolve
  //   }
  // },
];
