import i18n from "@/plugins/i18n";

export const MenuItemsCustomer = [{
    text: 'Home',
    to: '/dashboard',
    id: 'MenuItemCustomerHome'
},{
    to: "/help-desk",
    text: i18n.t('menu.help'),
    id: 'MenuItemCustomerHelp',
    indicator: true
}
]


export const AccountMenuCustomer = [
    
{
    text: i18n.t('menu.mySettings'),
    to: '/profile',
    id: 'MenuItemCustomerSettings'
},
// {
//     text: 'Services',
//     to: '/services',
//     id: 'MenuItemCustomerServices'
// },
{
    text: i18n.t('payment.billing'),
    to: '/billing',
    id: 'MenuItemCustomerBilling'
},
// {
//     text: 'Give Back',
//     to: '/contributions',
//     id: 'MenuItemCustomerGiveBack'
// }
]


// export const AccountMenuCustomer = [{
//     text: i18n.t('menu.mySettings'),
//     to: '/profile',
//     id: 'MenuItemCustomerSettings'
// },{
//     text: 'Affiliate Accounts',
//     to: '/affiliates',
//     id: 'MenuItemCustomerAffiliates'
// }]