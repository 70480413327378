<template>
  <div class="w-[200px]">
    <router-link to="/" v-if="showHeaderLogoAsLink">
    <svg
      :class="{ 'logo-not-ready': isLoading }"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 790.04 124.66"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="194.17"
          y1="402.23"
          x2="226.15"
          y2="486.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#006838" />
          <stop offset="1" stop-color="#009444" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="188.89"
          y1="446.83"
          x2="229.4"
          y2="446.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#006838" />
          <stop offset="1" stop-color="#009444" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="227.19"
          y1="432.77"
          x2="201.86"
          y2="366.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#009444" />
          <stop offset="1" stop-color="#006838" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="239.03"
          y1="474.61"
          x2="271.59"
          y2="390.26"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="238.37"
          y1="470.38"
          x2="269.58"
          y2="389.5"
          xlink:href="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="238.13"
          y1="420.55"
          x2="261.42"
          y2="358.8"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="244.1"
          y1="476.57"
          x2="276.66"
          y2="392.22"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="198.71"
          y1="400.5"
          x2="230.7"
          y2="484.81"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="196.45"
          y1="367.11"
          x2="222.74"
          y2="433.75"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="234.62"
          y1="419.22"
          x2="257.92"
          y2="357.48"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="567.06"
          y1="449.56"
          x2="973.07"
          y2="348.45"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="575.12"
          y1="481.94"
          x2="981.13"
          y2="380.82"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="581.71"
          y1="508.39"
          x2="987.72"
          y2="407.28"
          xlink:href="#linear-gradient-3"
        />
      </defs>
      <path
        class="cls-1"
        d="M229.4,485.39l-15.23-13.66c-16.5-14.8-25.28-32.05-25.28-49.66V404.61l25,22.43c10.13,9.08,15.51,19.65,15.51,30.46Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-2"
        d="M188.89,408.27v13.8c0,17.61,8.78,34.86,25.28,49.66l15.23,13.66v-13.8l-15.23-13.66C197.67,443.13,188.89,425.88,188.89,408.27Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-3"
        d="M229.4,431.2l-11.92-10.7c-12.92-11.58-19.79-25.08-19.79-38.87V368l19.57,17.55c7.93,7.11,12.14,15.39,12.14,23.85Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-4"
        d="M235.11,473l18.24-16.36c14.54-13,22.27-28.23,22.27-43.75V392.2l-19.16,17.19c-13.94,12.5-21.35,27.06-21.35,41.93Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-5"
        d="M256.46,409.39c-13.94,12.5-21.35,27.06-21.35,41.93V469c0-14.88,7.41-29.44,21.35-41.94l19.16-17.18V392.2Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-6"
        d="M235.11,419.16l13.19-11.84c10.52-9.43,16.11-20.41,16.11-31.64V360.73l-13.86,12.44c-10.08,9-15.44,19.57-15.44,30.32Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-7"
        d="M275.62,412.87V392.2s-1.05,36.09-40.51,80.78l18.24-16.36C267.89,443.58,275.62,428.39,275.62,412.87Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-8"
        d="M213.89,427l-25-22.43s40.51,46.85,40.51,80.78V457.5C229.4,446.69,224,436.12,213.89,427Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-9"
        d="M217.48,420.5l11.92,10.7C207.58,410.05,197.69,368,197.69,368v13.66C197.69,395.42,204.56,408.92,217.48,420.5Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-10"
        d="M235.11,403.49v15.67s7-36,29.3-58.43l-13.86,12.44C240.47,382.2,235.11,392.74,235.11,403.49Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-11"
        d="M433.35,382H449l-27.93,95.86H404L381.16,406l-22.73,71.88H340.58L312.38,382h17.46l21.07,72.85L373.54,382H390l22.57,72.88Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-11"
        d="M475.92,437.63q2.49,12.83,11.79,19.93t22.91,7.1q19,0,27.61-14l14.19,8q-14,21.68-42.19,21.67-22.82,0-37.09-14.29T458.86,430q0-21.68,14-36t36.24-14.39q21.09,0,34.41,14.87t13.32,35.75a49.46,49.46,0,0,1-.57,7.48Zm33.17-42.37q-13.43,0-22.24,7.57t-10.93,20.23h64q-2.13-13.62-10.75-20.7A30.69,30.69,0,0,0,509.09,395.26Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-12"
        d="M679.53,379.53q16.1,0,26.07,10.46t10,28.27v59.63H698.89V418.83q0-11.11-5.75-17.25t-15.34-6.13q-10.74,0-17.16,7.19t-6.42,21.95v53.3H637.54V418.83q0-11.11-5.47-17.25t-14.85-6.13a23.36,23.36,0,0,0-17.55,7.28c-4.66,4.87-7,12.14-7,21.86v53.3H576V382h16.68v12.84q9.78-15.33,28.56-15.34,19.38,0,28.19,16.31Q659.39,379.55,679.53,379.53Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-13"
        d="M819.67,465.81q-14.76,14.58-35.85,14.57t-35.75-14.57Q733.4,451.23,733.4,430t14.67-35.86q14.65-14.55,35.75-14.57a49.18,49.18,0,0,1,35.85,14.57q14.77,14.58,14.76,35.86T819.67,465.81Zm-35.85-1.73A32.85,32.85,0,0,0,808,454.31q9.78-9.78,9.78-24.35T808,405.6a34.54,34.54,0,0,0-48.12,0q-9.78,9.78-9.78,24.36t9.78,24.35A32.62,32.62,0,0,0,783.82,464.08Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-14"
        d="M963.27,382h15.66L951,477.89H933.94L911.07,406l-22.72,71.88H870.5L842.3,382h17.46l21.07,72.85L903.46,382H919.9l22.57,72.88Z"
        transform="translate(-188.89 -360.73)"
      />
    </svg>
    </router-link>
    <svg
    v-else
      :class="{ 'logo-not-ready': isLoading }"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 790.04 124.66"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="194.17"
          y1="402.23"
          x2="226.15"
          y2="486.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#006838" />
          <stop offset="1" stop-color="#009444" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="188.89"
          y1="446.83"
          x2="229.4"
          y2="446.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#006838" />
          <stop offset="1" stop-color="#009444" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="227.19"
          y1="432.77"
          x2="201.86"
          y2="366.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#009444" />
          <stop offset="1" stop-color="#006838" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="239.03"
          y1="474.61"
          x2="271.59"
          y2="390.26"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="238.37"
          y1="470.38"
          x2="269.58"
          y2="389.5"
          xlink:href="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="238.13"
          y1="420.55"
          x2="261.42"
          y2="358.8"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="244.1"
          y1="476.57"
          x2="276.66"
          y2="392.22"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="198.71"
          y1="400.5"
          x2="230.7"
          y2="484.81"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="196.45"
          y1="367.11"
          x2="222.74"
          y2="433.75"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="234.62"
          y1="419.22"
          x2="257.92"
          y2="357.48"
          xlink:href="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="567.06"
          y1="449.56"
          x2="973.07"
          y2="348.45"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="575.12"
          y1="481.94"
          x2="981.13"
          y2="380.82"
          xlink:href="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="581.71"
          y1="508.39"
          x2="987.72"
          y2="407.28"
          xlink:href="#linear-gradient-3"
        />
      </defs>
      <path
        class="cls-1"
        d="M229.4,485.39l-15.23-13.66c-16.5-14.8-25.28-32.05-25.28-49.66V404.61l25,22.43c10.13,9.08,15.51,19.65,15.51,30.46Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-2"
        d="M188.89,408.27v13.8c0,17.61,8.78,34.86,25.28,49.66l15.23,13.66v-13.8l-15.23-13.66C197.67,443.13,188.89,425.88,188.89,408.27Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-3"
        d="M229.4,431.2l-11.92-10.7c-12.92-11.58-19.79-25.08-19.79-38.87V368l19.57,17.55c7.93,7.11,12.14,15.39,12.14,23.85Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-4"
        d="M235.11,473l18.24-16.36c14.54-13,22.27-28.23,22.27-43.75V392.2l-19.16,17.19c-13.94,12.5-21.35,27.06-21.35,41.93Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-5"
        d="M256.46,409.39c-13.94,12.5-21.35,27.06-21.35,41.93V469c0-14.88,7.41-29.44,21.35-41.94l19.16-17.18V392.2Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-6"
        d="M235.11,419.16l13.19-11.84c10.52-9.43,16.11-20.41,16.11-31.64V360.73l-13.86,12.44c-10.08,9-15.44,19.57-15.44,30.32Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-7"
        d="M275.62,412.87V392.2s-1.05,36.09-40.51,80.78l18.24-16.36C267.89,443.58,275.62,428.39,275.62,412.87Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-8"
        d="M213.89,427l-25-22.43s40.51,46.85,40.51,80.78V457.5C229.4,446.69,224,436.12,213.89,427Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-9"
        d="M217.48,420.5l11.92,10.7C207.58,410.05,197.69,368,197.69,368v13.66C197.69,395.42,204.56,408.92,217.48,420.5Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-10"
        d="M235.11,403.49v15.67s7-36,29.3-58.43l-13.86,12.44C240.47,382.2,235.11,392.74,235.11,403.49Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-11"
        d="M433.35,382H449l-27.93,95.86H404L381.16,406l-22.73,71.88H340.58L312.38,382h17.46l21.07,72.85L373.54,382H390l22.57,72.88Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-11"
        d="M475.92,437.63q2.49,12.83,11.79,19.93t22.91,7.1q19,0,27.61-14l14.19,8q-14,21.68-42.19,21.67-22.82,0-37.09-14.29T458.86,430q0-21.68,14-36t36.24-14.39q21.09,0,34.41,14.87t13.32,35.75a49.46,49.46,0,0,1-.57,7.48Zm33.17-42.37q-13.43,0-22.24,7.57t-10.93,20.23h64q-2.13-13.62-10.75-20.7A30.69,30.69,0,0,0,509.09,395.26Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-12"
        d="M679.53,379.53q16.1,0,26.07,10.46t10,28.27v59.63H698.89V418.83q0-11.11-5.75-17.25t-15.34-6.13q-10.74,0-17.16,7.19t-6.42,21.95v53.3H637.54V418.83q0-11.11-5.47-17.25t-14.85-6.13a23.36,23.36,0,0,0-17.55,7.28c-4.66,4.87-7,12.14-7,21.86v53.3H576V382h16.68v12.84q9.78-15.33,28.56-15.34,19.38,0,28.19,16.31Q659.39,379.55,679.53,379.53Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-13"
        d="M819.67,465.81q-14.76,14.58-35.85,14.57t-35.75-14.57Q733.4,451.23,733.4,430t14.67-35.86q14.65-14.55,35.75-14.57a49.18,49.18,0,0,1,35.85,14.57q14.77,14.58,14.76,35.86T819.67,465.81Zm-35.85-1.73A32.85,32.85,0,0,0,808,454.31q9.78-9.78,9.78-24.35T808,405.6a34.54,34.54,0,0,0-48.12,0q-9.78,9.78-9.78,24.36t9.78,24.35A32.62,32.62,0,0,0,783.82,464.08Z"
        transform="translate(-188.89 -360.73)"
      />
      <path
        class="cls-14"
        d="M963.27,382h15.66L951,477.89H933.94L911.07,406l-22.72,71.88H870.5L842.3,382h17.46l21.07,72.85L903.46,382H919.9l22.57,72.88Z"
        transform="translate(-188.89 -360.73)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    showHeaderLogoAsLink: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped>

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8,
.cls-9,
.cls-10,
.cls-11,
.cls-12,
.cls-13,
.cls-14 {
    transition: all 0.32s cubic-bezier(0.77, 0, 0.175, 1);
}


.cls-1 {
  fill: url(#linear-gradient);
}
.cls-2 {
  fill: url(#linear-gradient-2);
}
.cls-3 {
  fill: url(#linear-gradient-3);
}
.cls-4 {
  fill: url(#linear-gradient-4);
}
.cls-5 {
  fill: url(#linear-gradient-5);
}
.cls-6 {
  fill: url(#linear-gradient-6);
}
.cls-7 {
  fill: url(#linear-gradient-7);
}
.cls-8 {
  fill: url(#linear-gradient-8);
}
.cls-9 {
  fill: url(#linear-gradient-9);
}
.cls-10 {
  fill: url(#linear-gradient-10);
}
.cls-11 {
  fill: #1f2c35;
}
.cls-12 {
  fill: url(#linear-gradient-11);
}
.cls-13 {
  fill: url(#linear-gradient-12);
}
.cls-14 {
  fill: url(#linear-gradient-13);
}

/* .logo-not-ready .cls-1,
.logo-not-ready .cls-2,
.logo-not-ready .cls-3,
.logo-not-ready .cls-4,
.logo-not-ready .cls-5,
.logo-not-ready .cls-6,
.logo-not-ready .cls-7,
.logo-not-ready .cls-8,
.logo-not-ready .cls-9,
.logo-not-ready .cls-10,
.logo-not-ready .cls-11,
.logo-not-ready .cls-12,
.logo-not-ready .cls-13,
.logo-not-ready .cls-14 {
    fill: #e8eaec;
} */


</style>
