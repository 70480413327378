<template>
  <div class="global-view-action-bar flex">
    <div class="flex items-center mr-auto" v-if="showBackButton">
      <BackToButtons
        :defineBackToName="defineBackToName"
        :defineBackToPath="defineBackToPath"
        :useBrowserNavigation="useBrowserNavigation"
      />
    </div>
    <div class="flex items-center ml-auto" v-if="activeListItemsWithDefaults.length">

      <button v-if="showDisplayAddOnDialogButton" class="bg-green-900 text-white text-sm leading-md px-2 py-1 mr-2" @click="$emit('clickAddAddOnService')">{{__getTextStartCase(`${$t('formLabels.select')} ${$tc('appointments.addOnService',1)}`)}}</button>
      <button v-if="showDisplayCustomAddOnDialogButton" class="bg-green-900 text-white text-sm leading-md px-2 py-1 mr-2"  @click="$emit('clickDisplayCustomAddOnDialog')">{{__getTextStartCase(`${$t('buttons.createNewText', {text:""})} ${$t('custom')} ${$tc('appointments.addOnService',1)}`)}}</button>

      <div
        v-for="({ id, text, icon, click, subItems, disabled }, index) in activeListItemsWithDefaults"
        :key="`view-action-r-${index}`"
        :id="`${id}-wrap`"
        :class="{
          'ml-2': index !== 0
          }"
      >
        <template
          v-if="
            subItems && subItems.length && subItems.map(i => linkItemSetDefault(i)).filter(i => i.show).length
          "
        >
          <el-dropdown @command="handleCommand" class="text-sm">
            <button :id="id" :class="buttonStyleClasses" :disabled="disabled">
              {{ text }} <i class="el-icon-arrow-down el-icon--right"></i>
            </button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="({ text, click, id, disable }, index2) in subItems.map(i => linkItemSetDefault(i)).filter(i => i.show)"
                :key="`view-action-r-sub-${index}-${index2}`"
                :id="id"
                :command="click"
                :disabled="disable"
                >{{ text }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <button :id="id" @click="click" :class="buttonStyleClasses"  :disabled="disabled">
            <span v-if="text">{{ text }}</span>
            <font-awesome-icon v-if="icon" :icon="icon"></font-awesome-icon>
          </button>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {
    BackToButtons: () => import("./BackToButtons"),
  },
  props: {
    linkItems: {
      type: Array,
      default() {
        return [];
      },
    },
    defineBackToName: {
      type: String,
      default: "",
    },
    useBrowserNavigation: {
      type: Boolean,
      default: false,
    },
    defineBackToPath: {
      type: String,
      default: "",
    },
    showDisplayAddOnDialogButton: {
      type: Boolean,
      default: false,
    },
    showDisplayCustomAddOnDialogButton: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    linkItems: {
      immediate: true,
      handler(items) {
        for (const item of items) {
          if (!item.id) {
            console.log("Missing item ID");
          }
        }
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCommand(commandFunction) {
      if (typeof commandFunction === "function") {
        commandFunction();
      }
    },
    linkItemSetDefault(item){
      return Object.assign({}, {
        text: '',
        icon: '',
        show: true,
        disabled: false,
        items: [],
        click: () => {}
      }, item)
    }
  },
  computed: {
    activeListItemsWithDefaults(){
      return this.linkItems.map(i => this.linkItemSetDefault(i)).filter(i => i.show)
    },
    buttonStyleClasses(){
      return {
        'bg-green-900': true,
        'hover:bg-green-800 hover:shadow-sm': true,
        'text-white': true,
        'text-sm': true,
        'leading-md': true,
        'px-4': true,
        'py-2': true
      }
    }
  },
};
</script>

<style scoped>
.global-view-action-bar {
  margin-top:-20px;
  margin-bottom: 20px;
}
</style>
