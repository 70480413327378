<template>
  <div class="h-screen flex items-center flex-col justify-center">
    <div class="mt-4">
      <img
        src="@/assets/images/wemow-logo-primary.png"
        class="pulse mb-3 mx-auto"
        style="max-width:200px"
      />
      <div v-loading="true" element-loading-background="rgba(247, 250, 252, 0.75)" class="h-12"></div>
    </div>
    <div v-if="loadingText" class="text-sm  font-semibold text-gray-700 text-center">{{loadingText}}</div>
  </div>
</template>

<script>
export default {
  props: {
    loadingText: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.pulse {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
