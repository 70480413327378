export const appointments = {
  enableAppointment: "Sí, habilitar cita",
  setAppointmentAsRain: "Sí, establezca una cita como retraso por lluvia",
  rainDelayMessage: "¿Seguro que desea establecer esta cita como retraso por lluvia?",
  reEnableAppointmentMessage: "¿Seguro que quieres volver a habilitar esta cita?",
  completeAppointmentMessage: "¿Estás seguro de que deseas establecer esta cita como completa?",
  updateAppointmentMessage: "¿Seguro que quieres actualizar esta cita?",
  updateVendorAppointmentMessage: "¿Está seguro de que desea actualizar el proveedor?",
  updateStartTimeAppointmentMessage: "¿Está seguro de que desea actualizar la hora de inicio?",
  yesCompleteAppointment: "Sí, la cita se completó",
  yesUpdateAppointment: "Sí, actualizar cita",
  exceptionMessage: "¿Estás seguro de que deseas establecer esta cita como excepción?",
  exceptionNote: "Ingrese la nota para esta excepción",
  enterNote: "Ingrese la nota aquí",
  setAppointmentAsException: "Sí, establecer cita como excepción",
  addOnAttached: "El complemento se adjuntó correctamente a la cita",
  canceledAppointmentsAlert: "Recibir alerta de citas canceladas",
  upcomingAppointmentsAlert: "Reciba una alerta diaria de citas próximas",
  rainDelayAppointmentsAlert: "Recibir alerta de citas de retraso por lluvia",
  completedAppointmentsAlert: "Recibir alerta de citas completadas",
  canceledSubscriptionAlert: "Recibir alerta de suscripción cancelada",
  newSubscriptionAlert: "Recibir alerta de nueva suscripción",
  receiveUpcomingAppointmentsAlert: "Reciba la alerta de citas próximas",
  notifyCanceledAppointments: "Notificar citas canceladas",
  notifyDailyUpcomingAppointments: "Notificar las próximas citas diarias",
  cancelAppointmentText: "¿Seguro que quieres cancelar esta cita?",
  noAppointmentsToDisplay: "No hay citas para mostrar",
  appointmentDoesNotExist: "Esta cita ha cambiado o ya no existe.",
  noAppointmentData: "No hay datos de citas",
  noAppointmentsForDateRange: "No hay citas para mostrar para el rango de fechas seleccionado.",
  appointmentsViewing: "No puede ver un rango mayor de {maxDias} días",
  recurringAppointment: "Esta es una cita recurrente. Se repite",
  appointment: "cita | citas",
  markRainDelay: "Marcar como retraso de lluvia",
  markAllAsRainDelay: "Marcar todas las citas como retraso por lluvia",
  markAppointmentException: "Marcar como excepción",
  enableAllAppointments: "Habilitar todas las citas",
  markException: "Excepción",
  noAppointmentsForADay: "No hay citas disponibles este día",
  noUpcomingAppointments: "No hay citas próximas",
  upComingAppointments: "Próximas citas",
  customTimeOff: "Tiempo libre personalizado",
  appointmentDelayed: "Esta cita se ha retrasado debido al clima.",
  appointmentCanceled: "Esta cita ha sido cancelada.",
  customAddOnService: "Servicio de complemento personalizado",
  scheduleColor: "color de horario  ",
  schedule: "horario de trabajo",
  selectPriceOption: "Por favor seleccione un servicio adicional",
  selectAddOnService: "Por favor seleccione un servicio adicional",
  addOnService: "Servicio Complementario | Servicios complementarios",
  subscription: "Suscripción | Suscripciones",
  noActiveSubscriptions: "No hay suscripciones activas",
  viewAllSubscriptions: "Ver todas las suscripciones",
  viewAppointments: "Ver citas",
  preferredDay: "Día preferido",
  subscriptionCanceled: "Esta suscripción ha sido cancelada.",
  noSubscriptions: "Sin suscripciones",
  order: "orden | pedidos",
  nextAppointment: "Próxima cita",
  arrivingEstimated: "Al llegar estimado en",
  completeAppointment: "Cita completa",
  enableAppointmentText: "Habilitar cita",
  subscriptionDetails: "Detalles de suscripción",
  appointmentTime: "Hora de la cita",
  nextAvailableTime: "Próximo tiempo disponible",
  noTimesAvailable: "No hay horarios disponibles.",
  addCustomTime: "Agregar tiempo personalizado",
  appointmentDetails: "Detalles de la cita",
  viewAddressOnMap: "Ver dirección en el mapa",
  changeHomeSize: "Cambiar el tamaño de la casa",
  changePlan: "Cambio de plan",
  changeServiceDay: "Cambiar día de servicio",
  enableTodayAppointments: "Habilite todas las citas de hoy.",
  enableYesterdaysAppointments: "Habilite todas las citas de ayer.",
  enableTomorrowsAppointments: "Habilite todas las citas de mañana.",
  markTodayAppointmentsAsRainDelay: "Marque todas las citas de hoy como retraso por lluvia.",
  markTomorrowsAppointmentsAsRainDelay: "Marque todas las citas de mañana como retraso por lluvia.",
  markYesterdaysAppointmentsAsRainDelay: "Marcar todas las citas de ayer como retraso por lluvia.",
  homeProfileNotComplete: "Inicio Perfil Incompleto",
  viewRelatedAppointment: "Ver cita relacionada",
  viewRelatedSubscription: "Ver suscripción relacionada",
  changeFrequency: "Cambiar la frecuencia",
  manageSubscription: "Administrar suscripción",
  callCustomer: "Llamar al cliente"
}