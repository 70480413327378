var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass: "loading-shimmer-background my-1 px-2",
          class: _vm.textClass,
        },
        [
          _c(
            _vm.textTag,
            { tag: "component", staticClass: "opacity-0" },
            [_vm._v(_vm._s(_vm.text)), _vm._t("default")],
            2
          ),
        ],
        1
      )
    : _c(
        "div",
        { ref: "defaultArea", class: _vm.textClass },
        [
          _c(_vm.textTag, { tag: "component" }, [_vm._v(_vm._s(_vm.text))]),
          _vm._t("default"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }