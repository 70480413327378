import {
  customerBeforeResolve,
  customerBeforeResolveOnboarding,
  customerBeforeResolveAlerts
} from "../../middleware";


export const customerRoutes = [
    /*
     * Customer Routes
     */
    {
      path: "/customer/onboarding",
      name: "CustomerOnboarding",
      components: {
        default: () => import(
         /* webpackPrefetch: true */ /*  webpackChunkName: "customer-onboarding" */ `../../../../components/customer/setup/CustomerSetup.vue`
        ),
        // footer: () => import(  /* webpackPrefetch: true */ `../../../../components/shared/Content/WhyWemowList.vue`
        // ),
        header: () => import( /* webpackPrefetch: true */ `../../../../components/shared/Content/HeaderPhone.vue`
        ),
      },
      // loading: LoadingComponent,
      meta: { requiresAuth: true, allowedRoles: ["customer"], hideNavigation: true, beforeResolve: customerBeforeResolveOnboarding, showHeaderLogoAsLink: false, headerRightInnerClasses: "md:items-center justify-end"  }
    },
    {
      path: "/dashboard",
      name: "CustomerDashboard",
      component: () =>
        import(
          /* webpackChunkName: "customer-dashboard" */ `../../../../components/customer/dashboard/CustomerDashboard.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve, showHeaderLogoAsLink: false }
    },
    {
      path: "/profile",
      name: "CustomerProfile",
      component: () =>
        import(
          /* webpackChunkName: "customer-profile" */ `../../../../components/customer/profile/CustomerProfile.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/services",
      name: "CustomerServicesViewAll",
      component: () =>
        import(
          /* webpackChunkName: "customer-services-archive" */ `../../../../components/customer/services/ServicesViewAll.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/services/:id",
      name: "CustomerServicesViewOne",
      component: () =>
        import(
          /* webpackChunkName: "customer-services-single" */ `../../../../components/customer/services/ServicesViewOne.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/help-desk",
      name: "CustomerHelpdeskDashboard",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketDashboard.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/help-desk/history",
      name: "CustomerTicketHistory",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketHistory.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/help-desk/ticket/new",
      name: "CustomerHelpDeskAddNew",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-add-new" */ `../../../../components/shared/Tickets/TicketAddNewRedirect.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer", "vendor", "organization"], beforeResolve: customerBeforeResolve },
      props: true
    },
    {
      path: "/help-desk/ticket/:id",
      name: "CustomerHelpDeskViewSingle",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-single" */ `../../../../components/customer/tickets/TicketViewSingle.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve },
      props: true
    },
    {
      path: "/help-desk/ticket/:id/draft",
      name: "CustomerHelpDeskViewSingleDraft",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-single" */ `../../../../components/customer/tickets/TicketDraft.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer", "vendor", "organization"], beforeResolve: customerBeforeResolve },
      props: true
    },
    {
      path: "/billing",
      name: "CustomerBilling",
      component: () =>
        import(
          /* webpackChunkName: "customer-billing" */ `../../../../components/customer/billing/CustomerBilling.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      //:upcoming_invoice_id
      path: "/billing/invoice/upcoming",
      name: "UpcomingInvoice",
      component: () =>
        import(
          /* webpackChunkName: "customer-upcoming-invoice" */ `../../../../components/customer/billing/UpcomingInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"] }
    },
    {
      path: "/billing/past/invoice/:invoice_id",
      name: "CustomerPastInvoice",
      component: () =>
        import(
          /* webpackChunkName: "invoice" */ `../../../../components/customer/billing/PastInvoices.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"] }
    },

    {
      path: "/appointment/:id",
      name: "CustomerAppointmentSingle",
      component: () =>
        import(
          /* webpackChunkName: "customer-appointment-single" */ `../../../../components/v2/shared/appointments/SingleView.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve },
      props: {
        userType: "customer"
      }
    },
    {
      path: "/appointments",
      name: "CustomerAppointmentsArchive",
      component: () =>
        import(
          /* webpackChunkName: "customer-appointment-archive" */ `../../../../components/shared/AppointmentsDisplay/AppointmentsDisplay.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve },
      props: {
        roleType: "customer",
        defaultView: 'default',
        linkPath: "customer/appointment",
      }
    },

    {
      path: "/subscriptions/:id",
      name: "CustomerSubscriptionSingle",
      component: () =>
        import(
          /* webpackChunkName: "customer-subscription-single" */ `../../../../components/v2/shared/subscriptions/SingleView.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/subscriptions",
      name: "CustomerSubscriptionsArchive",
      component: () =>
        import(
          /* webpackChunkName: "customer-subscription-archive" */ `../../../../components/customer/subscription/CustomerSubscriptionArchive.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/manage-subscriptions",
      name: "CustomerManageSubscriptions",
      component: () =>
        import(
          /* webpackChunkName: "customer-subscription-archive" */ `../../../../components/customer/subscription/CustomerManageSubscriptions.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },

    {
      path: "/cart",
      name: "CustomerOrderCart",
      component: () =>
        import(
          /* webpackChunkName: "customer-cart" */ `../../../../components/customer/cart/CustomerCart.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/order",
      name: "CustomerOrder",
      component: () =>
        import(
          /* webpackChunkName: "customer-order" */ `../../../../components/customer/order/OrderCompleteOrder.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/order-success/:id",
      name: "CustomerOrderSuccess",
      component: () =>
        import(
          /* webpackChunkName: "customer-order-success" */ `../../../../components/customer/order/OrderSuccess.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/contributions",
      name: "CustomerAffiliateContributions",
      component: () =>
        import(
          /* webpackChunkName: "customer-contributions" */ `../../../../components/customer/contributions/CustomerContributions.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/customer/list-invoices",
      name: "CustomerListInvoices",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/customer/invoice/InvoicesList.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/customer/view-invoice/:id",
      name: "CustomerViewInvoice",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/customer/invoice/ViewInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/give-back",
      name: "CustomerGiveBack",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/customer/give/GiveBack.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/browse-affiliates",
      name: "CustomerBrowseAffiliates",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/customer/give/BrowseAffiliates.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/add-affiliate/:id",
      name: "CustomerAddPublicAffiliate",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/customer/give/AddPublicAffiliate.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/alert",
      name: "Alert",
      component: () =>
        import(
          /* webpackChunkName: "customer-onboarding" */ `../../../../components/shared/Alerts/Alerts.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer", "vendor"], hideNavigation: true, beforeResolve: customerBeforeResolveAlerts  }
    },
    {
      path: "/affiliates",
      name: "CustomerAffiiates",
      component: () =>
        import(
          /* webpackChunkName: "customer-affiliates" */ `../../../../components/customer/affiliates/Affiliates.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/connect/affiliate/payments",
      name: "CustomerAffiiatePayments",
      component: () =>
        import(
          /* webpackChunkName: "customer-affiliates" */ `../../../../components/customer/affiliates/ConnectPayments.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer","affiliate"], beforeResolve: customerBeforeResolve }
    },
    {
      path: "/public/reviews",
      name: "PublicReviews",
      component: () =>
        import(
          /* webpackChunkName: "customer-affiliates" */ `../../../../components/customer/alerts/PublicReview.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["customer"], hideNavigation: true, beforeResolve: customerBeforeResolveAlerts },
    }
  ];
