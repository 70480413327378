var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicViewWrap", {
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "h-full w-full flex items-center justify-center",
              },
              [
                _c("div", { staticClass: "text-center w-1/3 bg-white p-12" }, [
                  !_vm.resendCode
                    ? _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("registration.checkPhoneConfirmationCode")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("registration.smsSentTo", {
                                    text: _vm.phoneNumber,
                                  })
                                )
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("registration.enterVerificationCode")
                                )
                              ),
                            ]),
                            _c("el-input", {
                              staticClass: "verificationCode",
                              attrs: {
                                placeholder: "Enter verification code here",
                                type: "number",
                              },
                              model: {
                                value: _vm.verificationCode,
                                callback: function ($$v) {
                                  _vm.verificationCode = $$v
                                },
                                expression: "verificationCode",
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("registration.wrongNumber")) +
                                  " " +
                                  _vm._s(_vm.$t("information.click")) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "link-style",
                                  on: { click: _vm.toggleResendCode },
                                },
                                [_vm._v(_vm._s(_vm.$t("here")))]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("registration.changeNumber"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.resendCode
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("registration.enterNewNumberToSendCode")
                              ) +
                              " "
                          ),
                          _c("text-field", {
                            ref: "field_phone_number",
                            attrs: {
                              value: _vm.resendPhone,
                              placeholder: _vm.$tc("formLabels.phoneNumber", 1),
                              required: true,
                              type: "phone",
                              validations: [
                                {
                                  method: "minLength",
                                  value: 12,
                                  error: _vm.__getTextUcFirst(
                                    _vm.$t("errorMessages.invalidText", {
                                      text: _vm.$tc(
                                        "formLabels.phoneNumber",
                                        1
                                      ),
                                    })
                                  ),
                                },
                                {
                                  method: "maxLength",
                                  value: 12,
                                  error: _vm.__getTextUcFirst(
                                    _vm.$t("errorMessages.invalidText", {
                                      text: _vm.$tc(
                                        "formLabels.phoneNumber",
                                        1
                                      ),
                                    })
                                  ),
                                },
                              ],
                            },
                            on: {
                              input: function ($event) {
                                _vm.resendPhone = $event
                              },
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "mt-4 mb-2",
                              attrs: {
                                type: "primary",
                                disabled:
                                  !_vm.isPhoneValid &&
                                  _vm.resendPhone.length !== 12,
                              },
                              on: { click: _vm.resendPhoneHandler },
                            },
                            [_vm._v(_vm._s(_vm.$t("registration.resendCode")))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "link-style",
                              on: { click: _vm.toggleResendCode },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "registration.returnToCodeVerification"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }