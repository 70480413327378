import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const { VUE_APP_BUGSNAG_KEY, VUE_APP_ENV, VUE_APP_VERSION, VUE_APP_BUILD } = process.env;


const bugSnagClient = VUE_APP_BUGSNAG_KEY ? Bugsnag.start({
  plugins: [new BugsnagPluginVue(Vue)],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: VUE_APP_ENV,
  apiKey: VUE_APP_BUGSNAG_KEY,
  appVersion: VUE_APP_ENV === 'production' 
        ? VUE_APP_VERSION
        : `${VUE_APP_VERSION}${VUE_APP_BUILD ? '.beta-'+VUE_APP_BUILD : '.dev'}`,
  appType: 'client',
  redactedKeys: [
    'access_token', // exact match: "access_token"
    /^password$/i,  // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    /^cc_/      // prefix match: "cc_number" "cc_cvv" "cc_expiry"
  ]
}) : { setUser: () => {}, addMetadata: () => {}};


export default bugSnagClient;
