const SocketIOMixin = {
    watch: {
        $wemowActiveUserType: {
            immediate: true,
            handler(userType){
                if(userType === "admin"){
                this.$nextTick(() => {
                    this.$socket.client.on('IO_CONNECT', payload => {
                      const { accountId, accountType, connectType, preview, fromAddress} = payload;
                      this.$sendPushNotification(`New ${connectType} Message from ${fromAddress}`, {body: preview, link:  `/admin/${accountType}/${accountId}`})
                    });
                  })                    
                }

            }
        }
    },
      beforeDestroy(){
        this.$socket.client.off('IO_CONNECT', payload => {
          const { accountId, accountType, connectType, preview, fromAddress} = payload;
          this.$sendPushNotification(`New ${connectType} Message from ${fromAddress}`, {body: preview, link:  `/admin/${accountType}/${accountId}`})
        });
      },
};


export { SocketIOMixin }