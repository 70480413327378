import { format, getDay, addMinutes, parseISO, addDays, subDays, addYears, setMonth, setDate, formatDuration, intervalToDuration } from "date-fns/esm";
import { transform, isEqual, isObject, get, capitalize } from "lodash-es";


import { allDayTimes } from "@/helpers/dropdownItems";

export * from "./css"

const {
  VUE_APP_DOMAIN,
  VUE_APP_API_SERVER,
  VUE_APP_MEDIA_SERVER,
  VUE_APP_STRIPE_PK,
  VUE_APP_COOKIE_DOMAIN=""
} = process.env;

export const loadingTimeout = time => {
  let timeout = time || 540;
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, timeout);
  });
};

export const getTimestamp = () => {
  let dateNow = new Date();
  let dateMM = dateNow.getMonth() + 1;
  let dateDD = dateNow.getDate();
  let h = dateNow.getHours();
  let m = dateNow.getMinutes();
  return (
    dateNow.getFullYear() +
    "" +
    (dateMM <= 9 ? "0" + dateMM : dateMM) +
    "" +
    (dateDD <= 9 ? "0" + dateDD : dateDD) +
    (h <= 9 ? "0" + h : h) +
    (m <= 9 ? "0" + m : m)
  );
};

export function getCreatedDateAndTime(dateCreated) {
  if (!dateCreated) return "N/A"

  const dateToFormat =
    typeof dateCreated === "string" ? new Date(dateCreated) : dateCreated;

  return `${format(dateToFormat, "cccc M/d/yyyy")} at ${format(
    dateToFormat,
    "h:mm a"
  )}`;
}

export const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const generateRandom = length => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const keyFromName = name => {
  if (!name) {
    return "";
  }
  let mod = name.replace(" ", "_").toLowerCase();
  return mod;
};

export const allowOnlyAlphaNumericUnderscore = val => {
  if (!val) {
    return val;
  }
  return val.replace(/[^A-Z0-9]+/gi, "_");
};

export const nextChar = char => {
  if (!char) return "a";
  let i = (parseInt(char, 36) + 1) % 36;
  return (!i * 10 + i).toString(36);
};

export const largestChar = () => {
  // eslint-disable-next-line
  return Array.prototype.pop.call(Array.prototype.sort.call(arguments));
};

export const resetFocus = () => {
  let scrollTop = document.body.scrollTop;
  let body = document.body;
  let tmp = document.createElement("input");
  tmp.style.opacity = 0;
  body.appendChild(tmp);
  tmp.focus();
  body.removeChild(tmp);
  body.scrollTop = scrollTop;
};

export const renderYears = (add, year) => {
  let this_year = new Date().getFullYear();
  let start = year || this_year;
  let addUp = parseInt(start) + parseInt(add);
  return addUp;
};

export const mergeArraysUnique = arrays => {
  var args = arrays;
  var hash = {};
  var arr = [];
  for (var i = 0; i < args.length; i++) {
    for (var j = 0; j < args[i].length; j++) {
      if (hash[args[i][j]] !== true) {
        arr[arr.length] = args[i][j];
        hash[args[i][j]] = true;
      }
    }
  }
  return arr;
};

export const mergeArraysUniqueByLength = arrays => {
  var args = arrays;
  var num = args.length;
  var hash = {};
  var hashCounter = {};

  var arr = [];
  for (var i = 0; i < args.length; i++) {
    for (var j = 0; j < args[i].length; j++) {
      if (hash[args[i][j]] !== true) {
        if (!hashCounter[args[i][j]]) {
          hashCounter[args[i][j]] = 1;
        }
        if (hashCounter[args[i][j]] === num) {
          arr[arr.length] = args[i][j];
          hash[args[i][j]] = true;
        }
      }
      hashCounter[args[i][j]]++;
    }
  }
  return arr;
};

export function isRestError(error) {
  var err = error;
  console.log(error);
  if (err) {
    if (err.name === "InternalServerError" || err.code === "InternalServer") {
      return true;
    }
  }
  return false;
}

export function generatePassword() {
  let length = Math.floor(Math.random() * (36 - 24 + 1) + 24);
  let charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let pw = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    pw += charset.charAt(Math.floor(Math.random() * n));
  }

  return pw;
}

export function passwordStrengthMeter(pass) {
  var password = pass;
  var numEx = /\d/;
  var lcEx = /[a-z]/;
  var ucEx = /[A-Z]/;
  var symbols = [
    "/",
    "@",
    "#",
    "%",
    "&",
    ".",
    "!",
    "*",
    "+",
    "?",
    "|",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
    "\\"
  ];
  var meterMult = 1;

  for (var k = 0; k < password.length; k++) {
    var pchar = password.charAt(k);
    if (numEx.test(pchar)) {
      meterMult += 0.75;
    }

    if (ucEx.test(pchar)) {
      meterMult += 1;
    }

    if (lcEx.test(pchar)) {
      meterMult += 0.25;
    }
  }

  for (var i = 0; i < symbols.length; i++) {
    if (password.indexOf(symbols[i]) >= 0) {
      meterMult += 1;
    }
  }

  // assume that 100% is a meterMult of 15
  var fullStrength = 15;

  return {
    percent: (Math.min(fullStrength, meterMult) / fullStrength) * 100,
    pass: meterMult >= 6
  };
}

export function getExcerpt(text, length) {
  if (!length || !text || text.length < length || typeof text !== "string") {
    return text;
  }

  let len = length;
  let lastIndex =  text.lastIndexOf(" ", len)
  if(lastIndex === -1) {
    lastIndex = length
  }
  let shortText = text.substr(0, lastIndex) + "…";
  return shortText;
}

export function convertMinsToHrs(mins) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? "" + h : h;
  m = m < 10 ? "" + m : m;
  return `${h}:${m}`;
}

export function formatDurationMinAndHour(value) {
  let minToHr = convertMinsToHrs(value);
  let splitMins = minToHr.split(":");
  let hourText = splitMins[0] > 1 ? " Hours" : " Hour";
  let minuteText = splitMins[1] > 1 ? " Minutes" : " Minute";
  let hours = splitMins[0] > 0 ? `${splitMins[0]}${hourText}` : "";
  let minutes = splitMins[1] > 0 ? `${splitMins[1]}${minuteText}` : "";
  return `${hours} ${minutes}`;
}

export function formatDateFromTimeSlot(date, time) {
  date = typeof date === "string" ? parseISO(date) : date;
  const day = format(date, "yyyy-MM-dd", { awareOfUnicodeTokens: true });
  const format1 = time.substr(0, 2);
  const format2 = time.substr(2, 4);
  const formatTime = `${format1}:${format2}`;
  const newDate = parseISO(`${day} ${formatTime}`);
  return newDate;
}

export function getWeekDay(date) {
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  if (typeof date === "number") {
    return days[date];
  } else {
    let dayIndex = getDay(new Date(date));
    return days[dayIndex];
  }
}

export function getWeekDayNumber(day) {
  if (!day || typeof day !== "string") {
    return -1;
  }
  let days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  return days.indexOf(day.toLowerCase());
}

export function convert24HourTo12(time) {
  if (time.indexOf(":") === -1 && time.length === 4) {
    let format1 = time.substr(0, 2);
    let format2 = time.substr(2, 4);
    time = `${format1}:${format2}`;
  }

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export async function asyncForEach(array, callback) {
  for (var k in array) {
    if (array.hasOwnProperty(k)) {
      var FileObj = array[k];
      await callback(FileObj);
    }
  }
}

export function buildTimeSlotDuration(start_time, duration) {
  start_time =
    typeof start_time === "string" ? parseISO(start_time) : start_time;
  let startTime = format(start_time, "HHmm");
  let endTime = format(addMinutes(start_time, duration), "HHmm");
  let times = allDayTimes;
  times = times.filter(el => el >= startTime && el <= endTime);
  return times;
}

export function isMorningOrAfternoon(time) {
  let hour = format(new Date(time), "H");
  return hour >= 12 ? "afternoon" : "morning";
}

export function getTimeOfDayString(capitalizeString = false) {
  const hour = format(new Date(), "H");
  let str = ""

  if(hour > 8 && hour <= 12){
    str = "morning"
  }
  else if(hour > 12 && hour <= 17){
    str = "afternoon"
  }
  else{
    str = "evening"
  }
  
  if(capitalizeString){
    str = capitalize(str)
  }
  return str;
}

export function serializeObjectForUrl(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? serializeObjectForUrl(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

export function affiliateGraphDefaultColors(index) {
  const defaultColors = ["#000", "#333", "#666", "#999"];
  return defaultColors[index];
}

export function renderUserEmailAddress(email) {
  const em = email.replace(/dfw-app-removed-[0-9]*-/, "");
  return em;
}

export function pluralizeText(text, num) {
  return num > 1 ? `${text}s` : `${text}`;
}

export function validateFields(refs) {
  let refKeys = Object.keys(refs);
  for (let i = 0; i < refKeys.length; i++) {
    if (refKeys[i].substr(0, 6) === "field_") {
      const element = refs[refKeys[i]];
      element.$v["sample_text"].$touch();
      if (element.$v["sample_text"].$error) {
        console.log("error with", refKeys[i]);
        return false;
      }
    }
  }
  return true;
}

export function validateName(name, requiredMinLength, requiredMaxLength) {
if(name && name.length < requiredMinLength){
    return false
  }
  else if(!/^[A-Za-z- ]+$/.test(name)){
    return false
  }
  else if( name && requiredMaxLength && name.length > requiredMaxLength) {
    return false
  }else{
    return true
  }
}

export function validateAddOnName(name) {
  if(name.startsWith(" ")){
      return "Invalid"
  }else if(!/^[A-Za-z-_, ]+$/.test(name) && name){   
      return "Invalid"
  }else if(name.length < 4 && name){
      return "Minimum"
  }
  else if(name == ""){
      return "Empty"
  }else{
      return "Valid" 
  }
}

export function serverURL() {
  return VUE_APP_MEDIA_SERVER;
}

export function serverDatabaseURL() {
  return VUE_APP_API_SERVER;
}

export function formatDollarAmount(amt,stringify=true) {
  const value = amt / 100;
  let amount = value.toFixed(2);
  const format = parseFloat(amount).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedValue = format.indexOf(".") < 0 ? `${format}.00` : format.split(".")[1].length === 1 ? `${format}0` : format;
  return stringify ? formattedValue : value;
}

export function formatAmountWithCoupon(plan, coupon){
  const planPrice = get(plan,'amount',0)
  let newPrice = 0
  
  if(coupon.percent_off) {
    newPrice = planPrice - (planPrice * (coupon.percent_off/100))
  }else if (coupon.amount_off){
    newPrice = planPrice  - coupon.amount_off
  }else{
    newPrice = planPrice
  }
  return newPrice >= 0 ? newPrice : 0
}


export const formatAmountPriceInputValue = (value) => {

  if(!value) return ""

  let l = "";
  let r = ""

  let input = value.toString()
  input = input.replace(/[^0-9|.]/g, '')
  if(input.includes('.')){
    let [left, right] = input.split('.')
    if(right.length === 0){
      r = `00`
    }else if(right.length === 1){
      r = `${right}0`
    }else if(right.length === 2){
      r = `${right}`
    }else {
      r = right.substr(0, 2)
    }
    l = left;
  }else{
    l = input
    r = '00'
  }

  const asAmount = `${l}.${r}`
  return asAmount
}



export function appDomainURL() {
  return VUE_APP_DOMAIN;
}

export function ObjectDifference(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
        if(Array.isArray(value) && Array.isArray(base[key])){
          result[key] = value
        }else{
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;

        }
			}
		});
	}
	return changes(object, base);
}

export const appStripePK = VUE_APP_STRIPE_PK;

export const formatUser = (data) => {
      const dataBasic  = data
      return dataBasic
}

export const getBrowserLanguage = function() {
  const allowed_languages = ["en", "es"];

  let lang = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage

    if(lang.split('-').length === 2){
      lang = lang.split('-')[0]
    }

    if(!allowed_languages.includes(lang)){
      lang = 'en';
    }

  return lang
}

export const setCookie = (cookieName, cookieValue, expirationDays = 30) =>  {
  let d = new Date();
  const cookieDomain = VUE_APP_COOKIE_DOMAIN;
  d.setTime(d.getTime() + (expirationDays*24*60*60*1000));
  const expires = "expires="+ d.toUTCString();
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/;SameSite=Lax;domain=${cookieDomain};`;
}

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const eraseCookie = (name)  => {
  document.cookie = name+'=; Max-Age=-99999999;';
}

export const wemowParseJSON = (str)  => {
  let res = {}
  try {
    const parsed = JSON.parse(str)
    res = parsed;
  }catch(e){ /* Do nothing */ }
  
  return res;
}


export const setupWemowWebChat = (userType = "") => {
    const {
      VUE_APP_ENV = "",
      VUE_APP_PLATFORM = ""
    } = process.env
  if(VUE_APP_PLATFORM === "web" && ["production", "staging", "development"].includes(VUE_APP_ENV)){
    if(!["super", "admin"].includes(userType)){
      if(window.wemowChatController && typeof window.wemowChatController.init === "function"){
        window.wemowChatController.init((wemowChatController) => {
          if(wemowChatController.status === "open"){
            if(!wemowChatController.hasActiveSession){
              wemowChatController.authorize().then(() => wemowChatController.show())
              }else{
              wemowChatController.show()
            }
          }
        })
      }
    }
  }
}

export const wemowDefaultDisabled = (userType)  => {
  const today = new Date();

      // don't disable days for admins
  if(userType === 'admin'){
    return [];
  }else if(userType === 'organization' || userType === 'vendor'){
    return []
  }

  return [
    today,
    addDays(today, 1),
    addDays(today, 2),
    // addDays(today, 3),
    // addDays(today, 4),
  ];
}

export const validateDateIsFuture = (date) => {
  const theDate = new Date(date)
  const currentDate = new Date();
  const minDate =  subDays(currentDate,1);
  let maxDate = addYears(currentDate,2)
  maxDate = setMonth(maxDate,0)
  maxDate = setDate(maxDate,1)
  return !(theDate > minDate) || !(theDate < maxDate);
}

export  function getErrorMessage(error) {
    return error.response && error.response.data ? error.response.data.message : error.message;
}

export function getReadableDuration(seconds) {
  //2 years 11 months 3 days 12 hours 45 minutes and 22 seconds
  if (!seconds) return "";
  const time = intervalToDuration({start: 0, end: seconds});
  return formatDuration(time);
}

export const camelCaseToSentence = str => str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);

export function promiseAllSettled(promises){
  return Promise.allSettled ? Promise.allSettled(promises) : ((promises) => Promise.all(
    promises.map(p => p
        .then(value => ({
            status: "fulfilled",
            value
        }))
        .catch(reason => ({
            status: "rejected",
            reason
        }))
    )
  ));
}

export function removeSubstrings(inputString, arrayOfSubstringsToRemove) {
  for (const substring of arrayOfSubstringsToRemove) {
    inputString = inputString.replace(substring, "");
  }
  return inputString;
}