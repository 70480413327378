
export const MenuItemsAdmin = [{
    text: 'Customers',
    to: '/admin/customers',
    id: 'MenuItemAdminCustomers'
},{
    text: 'Vendors',
    to: '/admin/vendors',
    id: 'MenuItemAdminVendors',
    subLinks: [{
        text: 'Vendor Search',
        to: '/admin/vendors-search',
        id: 'MenuItemAdminVendorSearch'
    }]
},{
    text: 'Organizations',
    to: '/admin/organizations',
    id: 'MenuItemAdminOrganizations'
},{
    text: 'Affiliates',
    to: '/admin/affiliates',
    id: 'MenuItemAdminAffiliates',
},{
    text: 'Help',
    to: '/admin/help-desk',
    id: 'MenuItemAdminHelp',
    indicator: true
},
// {
//     text: 'Inbox',
//     to: '/admin/connect-inbox',
//     indicator: true,
//     id: 'MenuItemAdminInbox'
// },
{
    text: 'More',
    to: "false",
    id: 'MenuItemAdminMore',
    subLinks: [{
        text: 'Create Invoice',
        to: '/admin/create-invoice',
        id: 'MenuItemAdminInvoice'
    },{
        text: 'Services',
        to: '/admin/services',
        id: 'MenuItemAdminServices',
    },{
        text: 'Categories',
        to: '/admin/service-categories',
       id: 'MenuItemAdminServiceCategories'
    },{
        text: 'Coupons',
        to: '/admin/coupons',
        id: 'MenuItemAdminCoupons'
    },{
        text: 'Users',
        to: '/admin/users',
        id: 'MenuItemAdminUsers'
    },{
        text: 'Communications',
        to: '/admin/communications',
        id: 'MenuItemAdminCommunications'
    },{
        text: 'Reports',
        to: '/admin/reports',
        id: 'MenuItemAdminReports'
    },{
        text: 'Lists',
        to: '/admin/lists',
        id: 'MenuItemAdminLists'
    },
    {
        text: 'Zip Codes',
        to: '/admin/zipcodes',
        id: 'MenuItemAdminZipCodes'
    },{
        text: 'Reviews',
        to: '/admin/reviews',
        id: 'MenuItemAdminReviews'
    },
    // {
    //     text: 'SMS Conversations',
    //     to: '/admin/sms',
    //     id: 'MenuItemAdminSMS'
    // },
    {
        text: 'Emailing',
        to: '/admin/emailing',
        id:"MenuItemAdminEmailing"
    },
    {
        text: 'Move Vendor Appointments',
        to: '/admin/move-vendor-appointments',
        id:"MenuItemAdminMoveVendorAppointments"
    },
    {
        text: 'Appointment Images',
        to: '/admin/appointment-images',
        id:"MenuItemAAppointmentImages"
    },
    {
        text: 'Old Inbox',
        to: '/admin/connect-inbox',
        indicator: true,
        id: 'MenuItemAdminInbox'
    },
    ]
}]

export const AccountMenuAdmin = []