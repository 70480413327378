<template>
<PublicViewWrap>
<!-- <transition name="slide-fade"> -->
   <template v-slot:body>
  <div class="h-full w-full flex items-center justify-center" v-loading="loading">
    <div class="text-center w-1/3 bg-white p-12">
    <div v-if="!resendCode">
      <p>{{$t('registration.checkPhoneConfirmationCode')}}</p>
      <div>
        <p>{{$t('registration.smsSentTo', {text: phoneNumber})}}</p>
        <p>{{$t('registration.enterVerificationCode')}}</p>
          <el-input class="verificationCode" placeholder="Enter verification code here" v-model="verificationCode" type="number"></el-input>
        <p>{{$t('registration.wrongNumber')}} {{$t('information.click')}} 
          <span class="link-style" @click="toggleResendCode">{{$t('here')}}</span> 
        {{$t('registration.changeNumber')}}</p>
      </div>
    </div>

      <div v-if="resendCode">
        {{$t('registration.enterNewNumberToSendCode')}}
          <text-field
            ref="field_phone_number"
            :value="resendPhone"
            v-on:input="resendPhone = $event"
            :placeholder="$tc('formLabels.phoneNumber',1)"
            :required="true"
            type="phone"
            :validations="[
            { method: 'minLength', value: 12, error: __getTextUcFirst($t('errorMessages.invalidText', {text: $tc('formLabels.phoneNumber',1)}))},
            { method: 'maxLength', value: 12, error: __getTextUcFirst($t('errorMessages.invalidText', {text: $tc('formLabels.phoneNumber',1)}))}
            ]">
          </text-field>

        <el-button
          type="primary"
          class="mt-4 mb-2"
          @click="resendPhoneHandler"
          :disabled="!isPhoneValid && resendPhone.length !== 12"
          >{{$t('registration.resendCode')}}</el-button>
          <span class="link-style" @click="toggleResendCode">{{$t('registration.returnToCodeVerification')}}</span>
      </div>
    </div>
  </div>
  </template>
<!-- </transition> -->
</PublicViewWrap>
</template>

<script>
import { 
  AUTH_VERIFY_CODE, 
  AUTH_RESEND_PHONE_VERIFICATION_CODE
} from "@/store/action-types";
import { mapActions } from "vuex";
import Vue from "vue"
import { validateFields } from "@/helpers/helpers";
export default Vue.extend({
  name:"PhoneVerification",
  components: {
    PublicViewWrap: () => import("@/components/public/views/PublicViewWrap"),
  },
  mounted(){
    this.phoneNumber = this.$route.query['phoneNumber'] ? this.$route.query['phoneNumber'] : ""
    let route = this.$route
    if (Object.keys(route.query).includes('phoneNumber')) {
      this.$router.push({ path: '/register/verify/sms', query: {} })
    }
    this.$nextTick(function() {
      const refs = this.$refs;
      if(refs && refs.field_phone_number){
        validateFields(refs);
      }
    });
  },
  data() {
    return {
      verificationCode: "",
      phoneNumber:"",
      loading: false,
      resendPhone: "",
      resendCode: false,
      isPhoneValid: false
    }
  },
  methods: {
    ...mapActions({
      verifyCode: AUTH_VERIFY_CODE,
      resendPhoneVerificationCode: AUTH_RESEND_PHONE_VERIFICATION_CODE
    }),
    async confirmPhone() {
      this.loading = true;
      const data = { verification_code: this.verificationCode }
      try {
        await this.verifyCode(data)
        this.$message({
          message: this.$t('successMessages.accountVerification'),
          type: "success"
        })
        // this.$router.push("/")
      } catch(error) {
        this.$message({
          message: `${this.$t('errorMessages.codeVerificationFailed')}: ${error}`,
          type: "error"
        })
      } finally {
        this.loading = false;
      }
    },
    async resendPhoneHandler(){
      this.loading = true;
      const data = { phone: this.resendPhone }
      try {
        await this.resendPhoneVerificationCode(data)
        this.$message({
          message: this.$t('successMessages.phoneNumberChanged'),
          type: "info"
        })
        this.resendCode = false;
        this.phoneNumber = this.resendPhone;
        this.resendPhone = ""
      } catch (error) {
        this.$message({
          message: `${this.$t('errorMessages.confirmationCodeFailed')}: ${error}`,
          type: "error"
        })
      } finally {
        this.loading = false;
      }
    },
    toggleResendCode() {
      this.resendCode = !this.resendCode;
    },
    validateForm() {
      this.$nextTick(function() {
        const refs = this.$refs;
        if(refs && refs.field_phone_number){
          const isValid = validateFields(refs);
          this.isPhoneValid = isValid;
          return isValid;
        }
      });
    },
  },
  watch: {
    "verificationCode": {
      handler(code) {
        if (code.length === 4) {
          this.confirmPhone();
        }
      }
    },
    "resendPhone": function() {
      this.validateForm();
    }
  }
})
</script>

<style scoped>
.confirmButton {
  margin-top: 0.75rem;
}

.full-height {
  height: 100vh;
}


.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.link-style {
  color: blue;
}

.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

</style>