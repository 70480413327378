var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.getRegisterTemplateVersionComponent, {
        tag: "component",
        attrs: {
          defaultEmailValue: _vm.user.email,
          loading: _vm.loading,
          loaded: _vm.loaded,
          error: _vm.error,
          version: _vm.version,
          referrer: _vm.referrer,
        },
        on: {
          doAltRegisterAction: _vm.doAltRegisterAction,
          updateUserFields: _vm.updateUserFields,
          doQuickRegisterAction: _vm.doQuickRegisterAction,
        },
      }),
      _vm.version !== "default" &&
      _vm.referrer &&
      _vm.referrer.affiliate_id &&
      _vm.referrer.name &&
      !_vm.hideReferrerPopup
        ? _c(
            "div",
            {
              staticClass:
                "fixed bg-white  border shadow-lg w-full sm:w-[480px] bottom-1 sm:bottom-4 right-1 sm:right-4 py-4 px-6",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-gray-500 hover:text-red-800 cursor-pointer absolute top-2 right-2",
                  on: {
                    click: function ($event) {
                      _vm.hideReferrerPopup = true
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                1
              ),
              _vm.referrer && _vm.referrer.name
                ? _c(
                    "div",
                    {
                      staticClass:
                        "pt-2 md:pt-8 font-medium text-gray-800 mb-6 text-center",
                    },
                    [
                      _c("span", { staticClass: "block text-lg md:text-xl" }, [
                        _vm._v(
                          _vm._s(_vm.referrer.name) +
                            " wanted you to check out Wemow!"
                        ),
                      ]),
                      _c("span", { staticClass: "block text-lg" }, [
                        _vm._v(
                          "Best part is we give back $1 every time we service your lawn, so get started now!"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "text-center flex flex-col justify-center items-center",
                },
                [
                  _c("div", {
                    style:
                      "width:120px;height:120px;border-radius:999px;overflow:hidden;background-image:url(" +
                      _vm.referrer.image +
                      ");background-size:cover;background-position: center;",
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center text-sm font-semibold text-gray-700 mt-3 mb-2 max-w-[180px] truncate",
                      attrs: { title: _vm.referrer.name },
                    },
                    [_vm._v(_vm._s(_vm.referrer.name))]
                  ),
                ]
              ),
              _vm.referrer.bio
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-sm font-light text-gray-700 mt-5 mb-2 p-4 bg-gray-100 text-center",
                    },
                    [_vm._v(" " + _vm._s(_vm.referrer.bio) + " ")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }