<template>
  <div class="h-full w-full flex justify-between items-start flex-col px-4 ">
    <div class="x-bg-red-400 w-full h-full flex items-center justify-center">
      <div
        class="w-full px-4 x-bg-red-500 grid grid-flow-row-dense grid-cols-4"
      >
        <div
          v-if="icon"
          class="col-span-1 h-24 mr-8 bg-red-600 flex items-center justify-center"
        >
        {{icon}}
        </div>
        <div :class="icon ? 'col-span-3' : 'col-span-4'">
          <div v-if="title" class="text-2xl font-medium">{{title}}</div>
          <div v-if="description" class="mt-2">{{description}}</div>
          <component v-if="hasInnerData" :is="hasInnerData" :slotData="slotData" />
        </div>
      </div>
    </div>
    <div class="w-full h-24 flex flex-row-reverse justify-between items-center">
      <WemowButton
        :text="primaryButtonText"
        :disabled="false"
        :block="false"
        @mousedown.native="onClickConfirm"
      />
      <WemowButton
        type="text"
        class="mr-8"
        :text="secondaryButtonText"
        :disabled="false"
        :block="false"
        @mousedown.native="onClickCancel"
      />
    </div>
  </div>
</template>
<script>
import{ get } from "lodash-es";
import TemplateAddonService from "./InnerDataTemplates/AddonService"
import TemplateApprovalService from "./InnerDataTemplates/ApprovalService"
import TemplateAffiliateShareLink from "./InnerDataTemplates/AffiliateShareLink";

export default {
  props: {
    title: {
      type: String,
      default: "",
    }, 
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "icon",
    },
    slotTemplate: {
      type: String,
      default: "none",
    },
    slotData: {
        type: Object,
        default(){
            return {}
        }
    },
    primaryButtonText: {
      type: String,
      default: "Confirm",
    },
    secondaryButtonText: {
      type: String,
      default: "Cancel",
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
      async onClickConfirm(){
          if(typeof this.onConfirm === 'function'){
              await this.onConfirm()
          }
          this.$emit('close')
      },
      async onClickCancel(){
          if(typeof this.onCancel === 'function'){
              await this.onCancel()
          }
          this.$emit('close')
      },
  },
  computed: {
      hasInnerData(){
          const { slotTemplate } = this;
          const innerDataMap = {
              none: "",
              addOnService: TemplateAddonService,
              approvalService: TemplateApprovalService,
              affiliateShareLink : TemplateAffiliateShareLink
          }
          return get(innerDataMap, slotTemplate, get(innerDataMap, 'none'))
      }
  }
};
</script>
