'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fal';
var iconName = 'sms';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'f7cd';
var svgPathData = 'M304 176h-16c-6.1 0-11.6 3.4-14.3 8.8L256 220.2l-17.7-35.4c-2.7-5.4-8.2-8.8-14.3-8.8h-16c-8.8 0-16 7.2-16 16v104c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-68.2l24.8 55.8c2.9 5.9 11.4 5.9 14.3 0l24.8-55.8V296c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V192c.1-8.8-7.1-16-15.9-16zm-168.6 42.5c-1.4-1.2-2.1-2.5-2.1-3.8 0-3.1 4.5-6.6 10.4-6.6H156c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-12.2c-23.4 0-42.4 17.3-42.4 38.6 0 10.7 4.9 20.9 13.3 28.1l21.9 18.8c1.4 1.2 2.1 2.5 2.1 3.8 0 3.1-4.5 6.6-10.4 6.6H116c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h12.3c23.4 0 42.4-17.3 42.4-38.6 0-10.7-4.9-20.9-13.3-28.1l-22-18.8zm240 0c-1.4-1.2-2.1-2.5-2.1-3.8 0-3.1 4.5-6.6 10.4-6.6H396c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-12.3c-23.4 0-42.4 17.3-42.4 38.6 0 10.7 4.9 20.9 13.3 28.1l21.9 18.8c1.4 1.2 2.1 2.5 2.1 3.8 0 3.1-4.5 6.6-10.4 6.6H356c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h12.3c23.4 0 42.4-17.3 42.4-38.6 0-10.7-4.9-20.9-13.3-28.1l-22-18.8zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26 3.8 8.8 12.4 14.5 22 14.5 61.5 0 110-25.7 139.1-46.3 29 9.1 60.2 14.3 93 14.3 141.4 0 256-93.1 256-208S397.4 32 256 32zm0 384c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.4 313.7 32.1 277.6 32.1 240c0-97 100.5-176 224-176s224 79 224 176S379.5 416 256 416z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.faSms = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;