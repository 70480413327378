import {
    GET_MEDIA_SRC_BY_ID
} from '@/store/action-types'

import {
  SET_CACHED_IMAGE_SRC
} from '@/store/mutation-types'

import {
  mediaGetSrcFromID
}from '@/services/ServiceDatabaseAPI'

import {
  fetchPresignedUploadUrl,
  fetchPresignedUploadUrlForAccount,
  uploadToPresignedUrl,
  getPresignedFileUrl,
  deleteMediaFile
}from '@/services/ServiceMedia'



const state = {
  images: [],
  mediaReady: true
}

const mutations = {
  [SET_CACHED_IMAGE_SRC]: (state, image) => {
    const imageIndex = state.images.map(o => o._id).indexOf(image._id)
    if (imageIndex > -1) {
        state.images.splice(imageIndex, 1, image)
    } else {
        state.images.push(image)
    }
  },
}

const actions = {
  async getPresignedUploadUrl({}, { data, file}){
    if( data.type === "helpdesk" || data.type === "appointment" ) {
      const { data: urlObject } = await fetchPresignedUploadUrl({ data, file })
      return urlObject;
    } else {
      const { data: urlObject } = await fetchPresignedUploadUrlForAccount({ data, file })
      return urlObject;
    }
  },
  async getPresignedUploadUrlForProfile({}, { data, file }){
    const { data: urlObject } = await fetchPresignedUploadUrl({ data, file })
    return urlObject;
  },

  async sendToPresignedUploadUrl({}, {url, file, fields, param}){
      const formData = new FormData();
      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append("Content-Type", file.type);
      formData.append("file", file); // must be the last one

      await uploadToPresignedUrl(url, formData, param)
      return {
        file: {
          type: file.type,
          name: file.name,
          size: file.size,
          path: fields.Key
        }
      }
  },

  async getPresignedGetUrl({}, {key}){
    const {data} = await getPresignedFileUrl({key})
    return data
  },

  async removeMediaFile({}, {key, ticket_id}){
    console.log('deleteMediaFile from key', key)

    const {data} = await deleteMediaFile({key, ticket_id})
    return data
  },
  
  async [GET_MEDIA_SRC_BY_ID] ({
    commit,
    state
  }, query) {

    const imageIndex = state.images.map(o => o._id).indexOf(query)
    if (imageIndex > -1) {
      return state.images[imageIndex]
    }

    try {
        const src = await mediaGetSrcFromID(query)
        if (src.status > 204 || !src.data) {
            throw (new Error('Error loading media'))
        }

        const media_src = src.data
        commit(SET_CACHED_IMAGE_SRC, media_src)

        return media_src
    } catch (e) {
        return false
    }
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state
}