import { render, staticRenderFns } from "./PhoneVerification.vue?vue&type=template&id=36e3c023&scoped=true"
import script from "./PhoneVerification.vue?vue&type=script&lang=js"
export * from "./PhoneVerification.vue?vue&type=script&lang=js"
import style0 from "./PhoneVerification.vue?vue&type=style&index=0&id=36e3c023&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e3c023",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/wemow-app/wemow-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36e3c023')) {
      api.createRecord('36e3c023', component.options)
    } else {
      api.reload('36e3c023', component.options)
    }
    module.hot.accept("./PhoneVerification.vue?vue&type=template&id=36e3c023&scoped=true", function () {
      api.rerender('36e3c023', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/public/register/PhoneVerification.vue"
export default component.exports