var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full w-full flex flex-col items-center justify-center" },
    [
      _vm.imageLink
        ? _c("div", { staticClass: "flex justify-center mt-7 mb-6" }, [
            _c("img", {
              attrs: { src: require("@/assets/web/images/" + _vm.imageLink) },
            }),
          ])
        : _vm._e(),
      _vm.fullPath
        ? _c("div", { staticClass: "flex justify-center mt-7 mb-6" }, [
            _c("img", { attrs: { src: _vm.fullPath } }),
          ])
        : _vm._e(),
      _vm.messageText
        ? _c(
            "div",
            { staticClass: "pt-2 flex justify-center font-medium text-xl" },
            [_vm._v(" " + _vm._s(_vm.messageText) + " ")]
          )
        : _vm._e(),
      _vm.descriptionText
        ? _c("div", { staticClass: "text-xs flex justify-center" }, [
            _vm._v(" " + _vm._s(_vm.descriptionText) + " "),
          ])
        : _vm._e(),
      _vm.showButton
        ? _c(
            "div",
            { staticClass: "pt-4 flex justify-center" },
            [
              _c("WemowButton", {
                attrs: { text: _vm.buttonText, disabled: false, block: false },
                nativeOn: {
                  mousedown: function ($event) {
                    return _vm.buttonAction.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }