import {
	APP_SET_WINDOW,
	APP_SET_SCROLL,
	CLEAR_APP_STATE_STORE,
	APP_STATE_TOGGLE_MENU,
	APP_STATE_WEB_CONTENT_WIDTH,
	APP_STATE_TOGGLE_NOTIFICATIONS,
	APP_STATE_TOGGLE_LOADING,
	APP_MUTATE_STATE,
	APP_MUTATE_UNIVERSAL_LINK,
	APP_CLEAR_UNIVERSAL_LINKS,
	APP_MUTATE_PUSH_DATA,
	APP_CLEAR_PUSH_DATA,
	APP_SET_ASYNC_COMPONENT_LOADING,
	APP_STATE_NAVIGATED_TO_ANOTHER_APP,
	APP_STATE_CURRENT_PAGE_ID,
	APP_STATE_SET_LOCATION_WATCH_ID
} from '../mutation-types';

const WEB_DEFAULT_WIDTH = '1240px'
const getDefaultState = () => {
	return {
		menuOpen: process.env.VUE_APP_MODE === 'native' ? false : true,
		notificationsOpen: false,
		screenSize: '',
		mainContentWebWidth: WEB_DEFAULT_WIDTH,
		scrollTop: 0,
		window: {
			width: 0,
			height: 0
		},
		loading: false,
		app_launched: false,
		universalLink: null,
		universalLinks: [],
		push_data: null,
		asyncComponentLoading: false,
		navigated_to_app: false,
		default_page: "",
		current_page_id: "",
		locationWatchId: null
	};
};
// root state object.
export const state = getDefaultState();

const mutations = {
	[CLEAR_APP_STATE_STORE](state) {
		// eslint-disable-next-line no-unused-vars
		state = Object.assign(state, getDefaultState());
	},
	[APP_STATE_TOGGLE_MENU](state, toggleState) {
		state.menuOpen = toggleState;
	},
	[APP_STATE_WEB_CONTENT_WIDTH](state, width) {
		state.mainContentWebWidth = width ? width : WEB_DEFAULT_WIDTH;
	},
	[APP_STATE_TOGGLE_NOTIFICATIONS](state, toggleState) {
		state.notificationsOpen = toggleState;
	},
	[APP_SET_SCROLL](state, scrollEvent) {
		const { scrollTop = 0 } = scrollEvent.target;
		state.scrollTop = scrollTop;
	},
	[APP_SET_WINDOW](state, windowObject) {
		if (!windowObject) {
			return;
		}

		const { innerWidth: width, innerHeight: height } = windowObject;
		let screenSize = 'xxl';
		if (width <= 640) {
			screenSize = 'sm';
		} else if (width <= 768) {
			screenSize = 'md';
		} else if (width <= 1024) {
			screenSize = 'lg';
		} else if (width <= 1280) {
			screenSize = 'xl';
		}
		state.window = { width, height };
		state.screenSize = screenSize;
	},
	[APP_STATE_TOGGLE_LOADING](state, toggleState) {
		state.loading = toggleState;
	},
	[APP_STATE_NAVIGATED_TO_ANOTHER_APP](state, toggleState) {
		state.navigated_to_app = toggleState;
	},
	[APP_MUTATE_STATE](state) {
		state.app_launched = true;
	},
	[APP_MUTATE_UNIVERSAL_LINK](state, universal_link) {
		state.universalLinks.push(universal_link);
	},
	[APP_CLEAR_UNIVERSAL_LINKS](state, pathname) {
		state.universalLinks = state.universalLinks.filter(ul => ul.pathname !== pathname);
	},
	[APP_MUTATE_PUSH_DATA](state, push_notification_data) {
		state.push_data = push_notification_data;
	},
	[APP_CLEAR_PUSH_DATA](state) {
		state.push_data = null;
	},
	[APP_SET_ASYNC_COMPONENT_LOADING](state, bool) {
		state.asyncComponentLoading = bool
	},
	[APP_STATE_CURRENT_PAGE_ID](state, id) {
		state.current_page_id = id
	},
	[APP_STATE_SET_LOCATION_WATCH_ID](state, id) {
		state.locationWatchId = id
	}
};

const getters = {
	isMenuOpen: (state) => state.menuOpen,
	isNotificationsOpen: (state) => state.notificationsOpen,
	getWindowSize: (state) => state.window,
	getScrollTop: (state) => state.scrollTop,
	getScreenSize: (state) => state.screenSize,
	loading: (state) => state.loading,
	getUniversalLink: (state) => state.universalLink,
	getUniversalLinks: (state) => state.universalLinks,
	getPushNotificationData: (state) => state.push_data,
	isAppLaunched: (state) => state.app_launched,
	getWebContentWidth: (state) => state.mainContentWebWidth,
	getNavigatedToAnotherApp: (state) => state.navigated_to_app,
	getCurrentPageId: (state) => state.current_page_id,
	getLocationWatchId: (state) => state.locationWatchId,
};

export default {
	state,
	getters,
	mutations
};
