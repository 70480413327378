export const timeOfDay = {
    morning: "La mañana",
    afternoon: "La tarde",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    minute: "minuto",
    hour: "hora"
}