import store from "@/store/store";

// Guards the onboarding screen(s).
// Redirect the user to dashboard if onboarding is already complete and they try to access the page
export const organizationBeforeResolveOnboarding = async (
  _toRoute,
  _fromRoute,
  next
) => {
  const { meta = {}, settings = {}, } = store.getters["getUserActiveAccount"];
  const { stripe_id = "" } = settings;
  const { initialSetup = "" } = meta;
  // if (!initialSetup || !stripe_id || !agreedToTAndC) {
  if (!initialSetup || !stripe_id ) {
    // store.commit(APP_STATE_WEB_CONTENT_WIDTH, "960px")

    // store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    return next();
  } else {
    return next("/organization");
  }
};
