<template >
  <div class=" h-full w-full divide-y overflow-auto " >
    <!-- starting balance and new balance -->
    <div class="pt-4"></div>
    <div class="py-4 flex flex-row justify-evenly w-30 border-solid ">
      <div class="w-3/6 flex flex-col border-r items-center flex-grow" >
        <h3 class="text-gray-600">Starting Balance</h3>
        <span class="text-2xl">{{formattedStartingBalance}} </span>
      </div>
      <div class="w-3/6 flex flex-col items-center flex-grow">
        <h3 class="text-gray-600">New Balance</h3>
        <span class="text-2xl text-green-700"> {{formattedNewBalance}}</span>
        <span class="text-center text-gray-600 text-xs p-2" v-if="credit.amount">{{amountDescription}}</span>
      </div>
    </div>
   <el-form class="py-2 flex justify-center flex-col" @submit.native.prevent label-position="top">
    <el-form-item class="w-full sm:w-60" label="Adjustment Type">
        <el-select  v-model="adjustmentType" placeholder="Select">
          <el-option
            v-for="item in adjustmentTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
      </el-select>
    </el-form-item>
   <el-form-item
      :label="'Amount'"
      class="w-full sm:w-60"
    >
      <text-field
        :ref="`field_amount`"
        :value="formattedValue"
        @input="setAmount($event)"
        @change="setAmount($event)"
        placeholder="Amount"
        :required="false"
        type="text"
        :validations="[]"
      >
      <span slot="prepend" class="text-black text-xl">$</span>
      </text-field>

    </el-form-item>
    <el-form-item
      :label="'Add internal note'"
      class="w-full"
    >
      <text-field
        ref="field_description"
        :value="credit.description"
        v-on:input="credit.description = $event"
        :type="'textarea'"
        placeholder=""
        :rows="5"
        :validations="[
          {
            method: 'maxLength',
            value: 500,
            error: 'Maximum 500 characters',
          },
        ]"
      ></text-field>
     </el-form-item>
       <p class="text-xs  border-solid  text-gray-600">This space can be used to provide additional information about your adjustment.This is an internal note and will not be visible to your customer</p>
   
      <WemowButton
        class="py-4 mt-4 mb-8"
        text="Apply to customer balance"
        @mousedown.native="creditCustomer"
        :disabled="!this.credit.amount"
        :block="false"
      />
   </el-form>
  </div>
</template>

<script>
  import CustomerInit from '@/mixins/CustomerInit'
import {get} from "lodash-es"
import { formatDollarAmount } from "@/helpers/helpers";
  import {cloneDeep} from "lodash-es"
  export default {
    props: {
      slotData: {
        type: Object,
        required: true
      }
    },
    mixins: [CustomerInit],
    data() {
      return {
          loading: false,
          credit: {
            amount: 0,
            description: ""
          },
          isVisible: false,
          adjustmentType: "credit",
          adjustmentTypeOptions: [
            {
              label: "Credit",
              value: "credit"
            },
            {
              label: "Debit",
              value: "debit"
            }]
      };
    },
    computed:{
      //starting balance
      startingBalance(){
        return parseFloat(formatDollarAmount(this.slotData.startingBalance || 0) || 0)
      },
      formattedStartingBalance(){
        const amountFixed = this.startingBalance.toFixed(2)
        const formatted= `$${amountFixed.replace("-", "")}`
        return amountFixed[0] === '-'?`(${formatted})`:formatted
      },
      //new balance
      newBalance(){
        let creditAmt = this.credit.amount || 0
        //adjustment type
        if(this.adjustmentType === "credit"){
          creditAmt = creditAmt * -1
        }
        return creditAmt + parseFloat(this.startingBalance)
      },
      formattedValue(){
        return parseFloat(this.credit.amount || 0).toFixed(2)
      },
      formattedNewBalance(){
        //newBalance will always be whole numbers
        if(!this.credit.amount) {
          return "-";
        }
        const amountFixed = this.newBalance.toFixed(2)
        const formatted= `$${amountFixed.replace("-", "")}`
        return amountFixed[0] ==='-'? `(${formatted})`: formatted
      },
      amountDescription(){
        let str = ` the amount due on the customer's next invoice by ${this.formattedNewBalance.replace("(", "").replace(")", "")}`
        if(this.adjustmentType === "credit"){
          str = "Decrease" + str
        }else{
          str = "Increase" + str
        }
        return str
      }
    },
    methods: {
      setAmount(val){
        if(!val){
          return 0
        }
        val = parseFloat(val)
        // check if ts a whole number or
        // check if it is a whole number or floating point number
        if(!Number.isInteger(val) && !Number.isFinite(val)){
          return this.credit.amount
        }
        const textRef = get(this.$refs,`field_amount.$refs.textField.$refs.input`) || {}
        const startPos = textRef.selectionStart;
        const amount = parseFloat(`${val}`.replace(/[^0-9.]/g, ""))
        this.credit.amount = parseFloat(amount.toFixed(2))
        this.$nextTick(() => this.setCursorPosition(textRef, startPos));
      },
      setCursorPosition(el, pos) {
        el.focus();
        el.setSelectionRange(pos, pos);
      },
      async creditCustomer(){

        if(this.loading){
          return
        }

        this.loading = true;

        try {
          const data = cloneDeep(this.credit)
          data.amount =(data.amount * 100).toFixed(0)
          if(this.adjustmentType === "credit"){
            data.amount = data.amount * -1
          }
          await this.$emit('confirm',{
              id: this.slotData.customerId,
              data: {
                ...data,
                currency: 'usd'
              }
          });
        } catch(e){
          this.$wemowNotifyError("There was an error:" + e.message)
        } finally {
          this.loading = false;
        }

      }
    }
  }
</script>
<!-- <style>

.button-icon {
  opacity: 0.9;
  color: #ffffff;
  background: rgb(3, 115, 60);
</style> -->