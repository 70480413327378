<template>
  <div class="container">
    <div class="text-lg font-medium">{{message}}</div>
    <div v-if="description">{{description}}</div>
  </div>
</template>


<script>
export default {
    props: {
        message: {
            type: String
        },
        description: {
            type: String
        }
    }
}
</script>