var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("el-row", [
        _c("h1", [_vm._v("Home Page")]),
        _c(
          "p",
          [
            _vm._v("Hello. "),
            _c("router-link", { attrs: { to: "login" } }, [
              _vm._v("Click here to login"),
            ]),
            _vm._v(" or "),
            _c("router-link", { attrs: { to: "register" } }, [
              _vm._v("Click here to register"),
            ]),
            _vm._v("."),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }