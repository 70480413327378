// this is aliased in webpack config based on server/client build

import axios from '../lib/axios'
import auth from '../auth'

const {
  VUE_APP_API_SERVER: DB_URL,
} = process.env;


const LOGOUT_URL = DB_URL + 'auth/logout'
const LOGIN_URL = DB_URL + 'auth/login'
const ALT_LOGIN_URL = DB_URL + 'auth/alt-login'
const REFRESH_URL = DB_URL + 'auth/refresh-token'

const RESET_NEW_PASSWORD_URL = DB_URL + "auth/reset-new-password"

export function AuthPingAPIServer() {
  return axios.head(DB_URL + 'ping')
    .then((ping) => Promise.resolve(ping))
    .catch((error) => Promise.reject(error))
}

export function AuthLogIn(creds) {
  return axios.post(LOGIN_URL, creds)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthResetNewPassword(creds) {
  const token = localStorage.getItem("temporary_password_reset_token")
  return axios.post(RESET_NEW_PASSWORD_URL, creds, {
    headers: auth.getAuthHeader(token),
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthLogInAlt(creds) {
  return axios.post(ALT_LOGIN_URL, creds)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthRefreshToken() {
  return axios.get(REFRESH_URL,{
      headers: auth.getAuthHeader()
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthVerifyAccessToken() {
  return axios.get(DB_URL + 'auth/check', {
      headers: auth.getAuthHeader()
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthSendPasswordReset(user) {
  return axios.post(DB_URL + 'auth/lost-password', user)
    .then((status) => Promise.resolve(status))
    .catch((error) => Promise.reject(error))
}

export function AuthVerifyToken(token, tokenType) {
  return axios.post(DB_URL + 'auth/validate-token', { tokenType }, {
    headers: auth.getAuthHeader(token)
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthVerifyResetPasswordCode({ verification_code, email }) {
  return axios.post(DB_URL + 'auth/confirm-password-code', { verification_code, email })
    .then((status) => Promise.resolve(status))
    .catch((error) => Promise.reject(error))
}

export function AuthResetPasswordAction({ password, platform = 'web' }) {
  return axios.post(DB_URL + 'auth/reset-password-action', { password, platform }, {
    headers: auth.getPasswordResetAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthVerifyConfirmAccountToken(token) {
  return axios.post(DB_URL + 'auth/confirm-account', token, {
    headers: auth.getAuthHeader(token)
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthLogout(token) {
  return axios.post(LOGOUT_URL, {}, {
    headers: auth.getAuthHeader(token)
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}


export function AuthRegister(newUser) {
  return axios.post(DB_URL + `auth/register`, newUser)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}


export function AuthRegisterCustomerQuick(newUser) {
  return axios.post(DB_URL + `auth/register-quick`, newUser)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthAltRegister(newUser) {
  return axios.post(DB_URL + `auth/alt-register`, newUser)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function AuthAltRegisterCustomerQuick(newUser) {
  return axios.post(DB_URL + `auth/alt-register-quick`, newUser)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function authSetActiveAccount({account_id, account_type}){
  return axios.post(DB_URL + 'auth/change-account', {account_id, account_type}, {
    headers: auth.getAuthHeader()
  })
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function AuthCheckUsernameInUse({email}){
  return axios.post(DB_URL + 'auth/check-username', {email})
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function AuthVerifyCode(data,token){
  return axios.put(DB_URL + 'auth/confirm-account-code', data,{
    headers: auth.getAuthHeader(token)
  })
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function AuthResendPhoneVerifyCode(data,token){
  //data ought to hold only phone number
  return axios.put(DB_URL + 'auth/resend-phone-confirm-account-code', data,{
    headers: auth.getAuthHeader(token)
  })
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function AuthResendVerifyCode(data){
  return axios.put(DB_URL + 'auth/resend-confirm-account-code', data)
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function AuthVerifyClaimAccountToken(token) {
  return axios
    .post(DB_URL + "auth/claim-account", token, {
      headers: auth.getAuthHeader(token),
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error));
}


export function AuthGenerateAppSpecificPassword(data) {
  return axios
    .post(DB_URL + "auth/generate-app-password", data, {
      headers: auth.getAuthHeader(),
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error));
}


export function connectSocialAccount(payload) {
  return axios.put(DB_URL + `auth/connect-social`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}
export function disconnectSocialAccount(payload) {
  return axios.put(DB_URL + `auth/disconnect-social`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}