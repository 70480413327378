import store from "@/store/store";

// Guards the onboarding screen(s).
// Redirect the user to dashboard if onboarding is already complete and they try to access the page
export const vendorBeforeResolveOnboarding = async (
  _toRoute,
  _fromRoute,
  next
) => {
  const { meta = {} } = store.getters["getUserActiveAccount"];
  const { initialSetup = "" } = meta;
  if (!initialSetup ) {
    return next();
  } else {
    return next("/vendor");
  }
};
