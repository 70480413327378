import store from "@/store/store";
import {
  ORGANIZATION_INIT_DASHBOARD
} from '@/store/action-types'
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";

function redirectIfNotOnboarded() {
  const { meta = {}, settings = {} } = store.getters["getUserActiveAccount"];
  const { initialSetup = "" } = meta;
  const { stripe_id = "" } = settings;
  
  // if (!initialSetup || !stripe_id || !agreedToTAndC) {
  if (!initialSetup || !stripe_id ) {
    store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    return "/organization/onboarding";
  }
}

function redirectIfNotAccountSetup() {
  const { meta = {} } = store.getters["getUserActiveAccount"];
  const { isApproved } = meta
  if (!isApproved ) {
      return "/organization/pending-approval";
  }
}

function checkIfDashboardLoaded(next) {
  const isDashboardInit = store.getters["organizations/getLoadedDashboard"]
  if(!isDashboardInit){
    const activeAccount = store.getters["getUserActiveAccount"];
    store.dispatch(`organizations/${ORGANIZATION_INIT_DASHBOARD}`, activeAccount._id)
    return next();
  }
}

export const organizationBeforeResolve = async (toRoute, fromRoute, next) => {
  store.commit(APP_STATE_WEB_CONTENT_WIDTH)
  const maybeGoToOnboarding = redirectIfNotOnboarded();
  if(maybeGoToOnboarding){
    return next(maybeGoToOnboarding)
  }

  const maybeGoToOAccountPending = redirectIfNotAccountSetup(next, toRoute, fromRoute)
  if(maybeGoToOAccountPending){
    return next(maybeGoToOAccountPending)
  }

  checkIfDashboardLoaded(next);
  return next();
};
