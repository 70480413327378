// import Vue from "vue";
import { get } from "lodash-es";
import AlertDefault from "@/components/global/GlobalAlertNotification/AlertDefault"

const pluginAuth = {
  install(Vue) {
    Vue.component('AlertDefault', AlertDefault),
    Vue.prototype.$wemowNotifyError = async function (message = "", description = "", opts = {}) {
      return this.$wemowNotify({message, description}, 'error', opts)
    },
    Vue.prototype.$wemowNotifySuccess = async function (message = "", description = "", opts = {}) {
      return this.$wemowNotify({message, description}, 'success', opts)
    },
    Vue.prototype.$wemowNotify = async function ({message = "", description = ""}, type = 'info', opts = {}) {
      if(!message) return;
      if(!['info', 'success', 'warning', 'error'].includes(type)) return
  
      const defaults = {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      }

      const typeFunctionMap = {
        info: this.$toast,
        success: this.$toast.success,
        warning: this.$toast.warning,
        error: this.$toast.error,
      }

      const alertFunction = get(typeFunctionMap, type, typeFunctionMap['info'])
      if(typeof alertFunction === 'function'){
        alertFunction({
          name: "AlertDefaultElement",
          template: `<AlertDefault message="${message}" description="${description}" />`,
        }, Object.assign({}, defaults, opts ));
      }
    }
  },
}

export default pluginAuth
