// @ts-check
import { es } from "./es";
// import { en } from "./es";


export const messages = {
  'en': {
    affiliate: {
      topContributors: 'Top Contributors',
      manageIDs: "Manage Affiliate IDs",
      noContributors: 'No Contributors. Sign some up!',
      noAffiliateIDs: " No Affiliate ID's.",
      affiliateID: 'Affiliate ID | Affiliate IDs',
      affiliateLink: 'Your affiliate link is ',
      marketingScreen: 'Marketing Screen',
      contributor: "contributor | contributors",
      contributions: "contribution | contributions",
      totalLessThanHundred: "Total must be less than 100%",
      selectAffiliate: "Please select an affiliate",
      contributionsBeingMadeTo: "Contributions being made to",
      fundraising: "Fundraising",
      vendorsSearch: "Vendors Search",
      contributors: "Contributors",
      aboutMe: "Write something about yourself...",
      makePublic: "Make my account public"
    },
    formLabels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'email | emails',
      amount: 'amount',
      phone: 'phone | phones',
      address: 'address | addresses',
      username: 'Username',
      password: 'Password',
      phoneNumber: "Phone Number | Phone Numbers",
      emailAddress: "Email Address",
      status: "Status",
      subject: "subject",
      description: "Description",
      contact: "contact | contacts",
      serviceAreas: "service areas",
      companyName: "Company Name",
      qualifications: "Qualifications",
      resetPassword: "Reset Password",
      jobTitle: "Job Title",
      recipientEmail: "recipient email",
      sourceType: "Source Type",
      broadCastMessage: "Broadcast Message",
      typeReply: "Type reply...",
      priority: "Priority",
      type: "Type",
      uploadImages: "Upload Images",
      frequency: "Frequency",
      recurringLength: "Recurring Length",
      selectService: "Select Service",
      selectPlan: "Select Plan",
      date: 'Date',
      selectDateAndTime: "Select date and time",
      select: "Select",
      selectServiceAreas: "Select Service Areas",
      defaultAddress: "Default Address",
      homeSize: "Home Size",
      zipCode: "Zip code",
      city: "City",
      state: "State",
      streetAddress: "Street Address",
      verifyPhoneNumber: "Verify Phone Number",
      phoneVerified: "Phone verified",
      phoneUnverified: "Phone unverified",
      phoneNumberType: "Type (Cell, Office, Home, etc.)",
      startDate: "Start date",
      endDate: "End date",
      confirmPassword: "Confirm password",
      newPassword: "New password",
      category: "category",
      grid: "grid",
      table: "table",
      selectDay: "Select day",
      selectTimeOfDay: "Select time of day",
      logo: "logo",
      selectedAddress: "Your selected address",
      images: "Images",
      expiryDate: "Expiry Date",
      frequencyUpdate: "Frequency Update",
      houseSize: "Lawn size",
      addressInformation: "Address Information",
      small: "Small",
      medium: "Medium",
      large: "Large",
      enterFirstName: "Enter first name",
      enterLastName: "Enter last name",
      enterPhoneNumber: "Enter phone number",
      enterCode: "Enter Code",
      mobilePhone: "Mobile Phone",
      quantity: "Qty",
      itemDescription: "Item & Description",
      enterStreetAddress: "Enter Street address",
      enterState: "Enter State",
      enterCity: "Enter City",
      enterZipCode: "Enter Zip code",
      secondary: "Secondary",
      customLink: "Custom Link",
      nameFieldsMustBeAlpha: "First name fields must  contain only letters | Last name fields must not contain only letters",
      selectCustomer: "Select customer",
      selectVendor: "Select vendor",
      addCustomService: "Add Custom Service",
      checklist: "Checklist",
      answer: "Answer",
      enableBiometric: "Enable Biometric Authentication",
      oldPassword: "Old Password",
      appointmentOptional: "Appointment - optional",
      requirePasswordResetOnLogin: "Require password reset on login",
      emailPasswordToUser: "Email password to user",
      selectCustomTemplate: "Select custom communication template",
      displayName: "Display Name",
      closedBy: 'Closed By',
      changePaymentMethod: "Change Payment Method",
      rain: "Rain",
      photo: "Photos"
    },
    errorMessages: {
      errorAlphaNumeric: "{text} may only contain only numbers and letters",
      errorSaving: 'Error saving {collection}',
      errorRemoving: 'Error removing {collection}',
      errorUpdating: 'Error updating {collection}',
      errorGetting: 'Error getting {collection}',
      errorReceiving: 'Error receiving {collection}',
      errorSending: 'Error sending {collection}',
      errorAdding: 'Error adding {collection}',
      errorDeleting: 'Error deleting {collection}',
      errorApproving: 'Error approving {collection}',
      errorCancelling: 'Error cancelling {collection}',
      errorRetrieving: 'Error retrieving {collection}',
      errorValidating: 'Error validating',
      minimumCharacters: 'Minimum {num} characters!',
      maximumCharacters: 'Maximum {num} characters!',
      missingFields: 'Missing required fields',
      allFieldsRequired: "All fields are required",
      invalidText: "Invalid {text}",
      addRecipient: "Please add at least one recipient",
      enterFields: "Please enter {text}",
      helpDeskAccountIssue: "There is an issue with your helpdesk account. Please contact support.",
      ticketNotCreated: "Could not create ticket. Try again later.",
      errorUploadingImage: "Error uploading image`",
      error: "Error",
      notAvailable: "Not Available",
      appointmentTimeNotFound: "Unable to find appointment time",
      maxVendorsReached: "Max vendors for your organization has been reached. You cannot add any more vendors.",
      vendorNotPartOfOrg: "Vendor not found or not part of this organization",
      notInServiceArea: "not in service area",
      timeOffError: "Error sending vendor time off request",
      loginError: "Login Error",
      signupError: "Signup Error",
      defaultAddressError: "Error: Your selected or default address is not in our service areas.We can't render our services there at the moment",
      zipCodeError: "is not valid for parameter: zip_code",
      addressNotInServiceArea: "Your default address is not in serviceable area.",
      confirmationCodeFailed: "Phone Confirmation Code Resend failed:",
      codeVerificationFailed: "Code verification failed:",
      profileImageChange: "Profile image change failed",
      profileImageRemoved: "Error removing profile image",
      uploadType: "Selected document must be {types} format!",
      uploadSize: "File size can not exceed {size}{fileSizeLabel}!",
      noAddressInServiceArea: "You have no address in service area",
      addressNotInServiceAreaError: "Your location is not currently in the Wemow service area but this isn't goodbye forever! We're expanding to new locations each month so even more neighborhoods can experience the best local lawn care that creates local impact.",
      addressNotInServiceAreaErrorLine2: "Keep an eye on your inbox, and we'll let you know once we're ready to mow!",
      skippingOnboarding: "Aborting onboarding!",
      invalidAddressEntered: "Invalid Address Entered",
      resolveAddressErrors: "Resolve issue with address",
      insuranceDocumentUpload: "Error uploading insurance document",
      insuranceDocumentRemoved: "Error removing insurance document",
      noInsuranceDocument: "No Insurance Document",
      invalidEmailEntered: "Invalid Email Entered",
      enterVendorFirstName: "Please enter vendor's first name",
      enterVendorLastName: "Please enter vendor's last name",
      enterVendorEmail: "Please enter vendor's email",
      enterVendorPassword: "Please enter vendor's password",
      enterVendorPhoneNumber: "Please enter vendor's phone number",
      enterName: "Please enter name",
      enterPrice: "Please enter price",
      enterSubject: "Please enter subject",
      enterMessage: "Please enter message",
      isInFuture: "Date is not in future",
      futureDateLimit: "The start date can only be non conflicting dates between this year and next year",
      resetTokenExpired: "Your token has expired. Please try resetting your password again to receive a new link.",
      confirmAccountTokenExpired: "Your confirmation token has expired. please try login to request new confirmation link.",
      invalidTicketId: "The received ticket id is invalid or does not exist.",
      invalidAppointmentInstanceId: "The received appointment reference is invalid or does not exist.",
      gpsOff: 'Your devices location services is either disabled or appropriate permissions have not been granted. Please enable location for Wemow in settings. ',
      passwordStrengthError: "Please enter a stronger password",
      passwordsDoNotMatch: "Passwords do not match",
      errorChangingPrice: "No price available for selected frequency",
      noMapCompatibleApp: "You do not have any compatible app to view address visually.",
      errorShowingAddressOnMap: "There was an error showing appointment's address on the map. If the issue persist please call support.",
      invalidPhoneNumber: "Invalid phone number format.",
      notAllowed: "You are not allowed to view this route",
      select: "Select one {text}",
      noCustomerCard: "Customer does not have a card attached",
      notValidEmail: "This is not a valid email",
      emailExists: "Email already exists",
      noSpacesReferralId: "Referral ID should not contain spaces",
      codeIsRequired: "Code is required.",
      timeNotInRange: "Time should be in range of 8AM to 8PM.",
      errorLoggingIn: "There was an error logging you in.",
      errorRestoring: "There was an error restoring the subscription",
      errorSuspending: "There was an error restoring the subscription",
      errorNoteRequired: "Note is required!"
    },
    confirmMessages: {
      approveMessage: "This will approve this {text}.",
      unApproveMessage: "This will un-approve this {text}.",
      removeMessage: "This will remove this {text}.",
      permanentlyDeleteText: "This will permanently delete the {text}.",
      appointmentChangingAddress: `Changing addresses will affect home size, which will change this subscriptions price.
            The home size is changing from {currentAddressSize} to {newAddressSize}, and pricing will {priceDirection} from $ {currentPlan} to $ {newPlan}.`,
      updateFutureInstances: "Do you want to update every future instance of this appointment?",
      removeAffiliate: "This will remove affiliate from your contributions.",
      cancelSubscription: "Are you sure you want to cancel this subscription? This will cancel billing and all future appointments related to this subscription.",
      changeSubscription: "Are you sure you want to change the subscription frequency? This will adjust your service frequency to be {text}ly",
      changePlan: `Changing the service plan will change this subscription's price.
            The subscription plan is changing from {currentPlan} to {newPlan}, and price from $ {currentPrice} to $ {newPrice}.`,
      suspendSubscription: "Are you sure you want to suspend the subscription? This will suspend all future appointments until the next successful billing",
      restoreSubscription: "Are you sure you want to restore the subscription? This will restore all future appointments until the next failed billing"
    },
    successMessages: {
      verificationCodeSent: 'Verification code has been sent successful',
      phoneNumberVerified: 'Phone number verified successful',
      updatedMessage: '{collection} updated',
      deletedMessage: '{collection} deleted',
      canceledMessage: '{collection} canceled',
      savedText: "{text} saved!",
      updatedText: "{text} updated successful",
      sentSuccessful: "{text} sent successful!",
      scheduleSavedText: "{text} schedule saved successfully",
      scheduleRemovedText: "{text} schedule removed successfully",
      scheduleApprovedText: "{text} schedule approved",
      scheduleUnapprovedText: "Vendor schedule unapproved",
      timeOffMessages: "Your request for time off has been sent successfully. Please wait for approval",
      accountConfirmed: "Success! Your account has been confirmed. You may now login below using your email and password.",
      paymentSourceRemoved: "Payment source has been removed successfully",
      defaultPaymentSourceUpdated: "Default payment source has been updated successfully",
      paymentSourceAdded: "Payment source has been added successfully",
      success: "Success",
      orderProcessedSuccessfully: "Subscription Processed Successfully.",
      phoneNumberChanged: "Request to change phone number and resend verification code was successful",
      accountVerification: "Account verification successful",
      profileImageChange: "Profile image changed",
      profileImageRemoved: "Profile image removed",
      addressInServiceArea: "Success! Your address is in service area",
      profileUpdated: "Profile Updated",
      insuranceDocumentUpload: "Insurance document uploaded successfully",
      insuranceDocumentRemoved: "Insurance document removed",
      invoiceCreationSuccess: "Invoice created successfully",
      vendorsInLocationText: "There are {text} vendors in location specified",
      submittedTicket: "Ticket submitted",
      vendorsInLocationTextSingle: "There is only 1 vendor at the specified location",
      vendorsNearLocationText: "There are {text} other vendors nearby",
      vendorsNearLocationTextSingle: "There is only 1 other vendor nearby",
      restoredMessage: "Successfully restored subscription",
      suspendMessage: "Successfully suspended subscription"
    },
    appointments: {
      homeProfileNotComplete: "Home Profile Incomplete",
      viewHomeProfile: "View Home Profile",
      enableAppointment: "Yes, Enable Appointment",
      setAppointmentAsRain: "Yes, Set Appointment As Rain",
      rainDelayMessage: "Are you sure you want to set this appointment as rain delay?",
      reEnableAppointmentMessage: "Are you sure you want to re-enable this appointment?",
      completeAppointmentMessage: "Mark appointment as complete?",
      updateAppointmentMessage: "Are you sure you want to update this appointment?",
      updateVendorAppointmentMessage: "Are you sure you want to update the vendor?",
      updateStartTimeAppointmentMessage: "Are you sure you want to update the start time?",
      yesCompleteAppointment: "Yes, Appointment Was Completed",
      exceptionMessage: "Are you sure you want to set this appointment as exception?",
      setAppointmentAsException: "Yes, Set Appointment As Exception",
      exceptionNote: "Enter note for this exception",
      enterNote: "Enter note here",
      yesUpdateAppointment: "Yes, Update Appointment",
      addOnAttached: "Add on was successfully attached to appointment",
      canceledAppointmentsAlert: "Receive Canceled Appointments Alert",
      upcomingAppointmentsAlert: "Receive Daily Upcoming Appointments Alert",
      rainDelayAppointmentsAlert: "Receive Rain Delay Appointments Alert",
      completedAppointmentsAlert: "Receive Completed Appointments Alert",
      canceledSubscriptionAlert: "Receive Canceled Subscription Alert",
      notifyCanceledAppointments: "Notify Canceled Appointments",
      receiveUpcomingAppointmentsAlert: "Receive Upcoming Appointments Alert",
      notifyDailyUpcomingAppointments: "Notify Daily Upcoming Appointments",
      newSubscriptionAlert: "Receive New Subscription Alert",
      cancelAppointmentText: "Are you sure you want to cancel this appointment?",
      noAppointmentsToDisplay: "No Appointments to display",
      appointmentDoesNotExist: "This appointment has changed or no longer exists.",
      noAppointmentData: "No Appointment Data",
      noAppointmentsForDateRange: "No Appointments to show for the selected date range.",
      appointmentsViewing: "You can't view a range longer then {maxDays} days",
      recurringAppointment: "This is a recurring appointment. It repeats",
      appointment: "appointment | appointments",
      markRainDelay: "Mark As Rain Delay",
      markAppointmentException: "Mark As Exception",
      markAllAsRainDelay: "Mark all appointments as rain delay",
      enableAllAppointments: "Enable all appointments",
      markException: "Exception",
      noAppointmentsForADay: "No Appointments Available this day",
      noUpcomingAppointments: "No Upcoming Appointments",
      upComingAppointments: "Upcoming Appointments",
      customTimeOff: "Custom Time Off",
      appointmentDelayed: "This appointment has been delayed due to weather.",
      appointmentCanceled: "This appointment has been canceled.",
      customAddOnService: "Custom Add On Service",
      scheduleColor: "schedule color",
      schedule: "schedule",
      selectPriceOption: "Please Select an AddOn Service",
      selectAddOnService: "Please Select an AddOn Service",
      addOnService: "Add-On Service | Add-On Services",
      subscription: "Subscription | Subscriptions",
      noActiveSubscriptions: "No active subscriptions",
      viewAllSubscriptions: "View All Subscriptions",
      viewAppointments: "View Appointments",
      preferredDay: "Preferred Day",
      subscriptionCanceled: "This subscription has been canceled.",
      noSubscriptions: "No Subscriptions",
      order: "order | orders",
      nextAppointment: "Next Appointment",
      arrivingEstimated: "Estimated arrival:",
      completeAppointment: "Complete Appointment",
      enableAppointmentText: "Enable Appointment",
      subscriptionDetails: "Subscription Details",
      appointmentTime: "Appointment Time",
      nextAvailableTime: "Next available time",
      noTimesAvailable: "There are no times available.",
      addCustomTime: "Add custom time",
      subscriptionAddOnServices: "Subscription Add-On Services",
      appointmentDetails: "Appointment Details",
      viewAddressOnMap: "View address on map",
      changeHomeSize: "Change Home Size",
      changePlan: "Change Plan",
      changeServiceDay: "Change Service Day",
      changeFrequency: "Change Frequency",
      enableTodayAppointments: "Enable all today appointments.",
      enableYesterdaysAppointments: "Enable all yesterday's appointments.",
      enableTomorrowsAppointments: "Enable all tomorrow's appointments.",
      markTodayAppointmentsAsRainDelay: "Mark all today appointments as rain delay",
      markTomorrowsAppointmentsAsRainDelay: "Mark all tomorrow's appointments as rain delay",
      markYesterdaysAppointmentsAsRainDelay: "Mark all yesterdays's appointments as rain delay",
      viewRelatedAppointment: "View related appointment",
      viewRelatedSubscription: "View related subscription",
      manageSubscription: "Manage Subscription",
      callCustomer: "Call Customer"
    },
    buttons: {
      send: "Send {text}",
      newText: "New {text}",
      currentText: "Current {text}",
      confirmText: "Confirm {text}",
      saveText: "Save {text}",
      clear: "Clear",
      cancel: "Cancel",
      confirm: "Confirm",
      sendText: "Send {text}",
      addText: "Add {text}",
      createNewText: "Create {text}",
      viewText: "View {text}",
      yesCancelText: "Yes, Cancel {text}",
      deleteText: "Delete {text}",
      downloadText: "Download {text}",
      replaceText: "Replace {text}",
      uploadText: "Upload {text}",
      doNotCancel: "No, Don't Cancel",
      noLeaveAlone: "No, Leave Alone",
      enable: "enable",
      disable: "disable",
      complete: "complete",
      continue: "Continue",
      revokeApproval: "revoke approval",
      ok: "ok",
      back: "Back",
      viewLink: 'View Link',
      copyLink: 'Copy Link',
      prev: "Prev",
      close: "Close",
      remove: "Remove",
      editText: 'Edit {text}',
      approve: "Approve",
      unApprove: "Un-approve",
      clickToActivate: "Click to activate",
      action: 'Action | Actions',
      connectPayments: "Connect Payments",
      paymentSetup: "Next, Let's Set a Way To Get You Paid",
      addToText: "Add to {text}",
      addOrgAsVendor: "Add Myself As Vendor",
      viewTickets: "View All Tickets",
      submit: "Submit {text}",
      discardText: "Discard {text}",
      ticketHistory: "View Full Ticket History",
      selectFile: "select file",
      done: "done",
      refresh: "Refresh",
      viewAppointments: "View All Appointments",
      signIn: "Sign in",
      sendReset: "Send Reset",
      reactivate: "Reactivate",
      confirmChange: "Yes, Confirm Change.",
      doNotChange: "No, Don't Change",
      doNotReactivate: "No, Don't Reactivate",
      updateFutureAppointments: "Update All Future Appointments",
      goBack: "Go Back",
      changeServiceFrequency: "Yes, Change Service Frequency",
      viewOrganization: "View Organization",
      skipOnboarding: "Do this later",
      update: "Update",
      finish: "Finish",
      cancelChanges: "Cancel Changes",
      addMyselfAsVendor: "Add Myself as a vendor",
      referralLinks: "{text}Referral Links",
      manage: "Manage",
      updateText: "Update {text}",
      deny: "Deny",
      readMore: "Read more",
      readLess: "Read less",
      createNewOrganization: "Create New Organization",
      backTo: "Back To",
      addNewZipCode: "Add Zip Code",
      browseAffiliates: 'Browse Affiliates',
      viewTransactions: 'View Transactions',
      pause: "Pause",
      unpause: "Unpause",
      suspend: "Suspend",
      restore: "Restore",
      yesSuspendText: "Yes, Suspend {text}",
      yesRestoreText: "Yes, Restore {text}",
      doNotSuspend: "No, Don't Suspend",
      doNotRestore: "No, Don't Restore",
      customerBalanceAdjustment: "Customer Balance Adjustments",
      balanceAdjustment: "Balance Adjustment",
      next: "Next {text}",
      previous: "Previous {text}",
      later: "Later"
    },
    menu: {
      logout: "Logout",
      mySettings: "My Settings",
      fundraisingStats: "Fundraising Stats",
      marketingScreen: "Marketing Screen",
      overview: "Overview",
      transfers: "Transfers",
      manageTeam: "Manage Team",
      myTickets: "My Tickets",
      workSchedule: "Work Schedule",
      searchAccounts: "Search Accounts",
      home: "Home",
      help: "Help",
      dashboard: "Dashboard",
      helloAdmin: "Hello, Admin",
      manageServiceAreas: "Service Areas",
      yourReferrals: "Manage your referrals",
      referrals: "Referral | Referrals",
      referralIDs: "Referral ID | Referral IDs",
      affiliateId: "Affiliate ID",
      activitiesTomorrow: "Activities - Tomorrow",
      activitiesToday: "Activities - Today",
      activitiesForYesterday: "Activities - Yesterday",
      reports: "Reports",
      approval: "Approvals",
    },
    tickets: {
      appointmentFollowUp: "Follow-up to appointment",
      subscriptionFollowUp: "Follow-up to subscription",
      attachedFiles: "Attached files",
      noCommentsAvailable: "No comments available. Create your first comment.",
      ticketStatus: "This ticket has been {text}.",
      ticket: "ticket | tickets",
      noSupportTickets: "No Support Tickets",
      helpDesk: "Helpdesk",
      ticketsClosed: "Tickets Closed in the past 7 days.",
      openTickets: "Open Tickets",
      draftTickets: "Draft Tickets",
      fileType: "{fileTypeLabels} files with a size less than {maxFileSize} {sizeLabel}",
      helpDeskTicket: "Helpdesk Ticket",
      ticketUpdatesAlert: "Receive Ticket Updates",
      activeHelpdeskTicket: "Active Helpdesk Ticket",
      viewHelpdeskTicket: "View Related Ticket",
      viewSupportTickets: "View your support tickets",
      lookingForHelp: "Hello! Are you looking for help?",
      createSupportTickets: "You can create support tickets to get help.",
      subscriptionTicket: "This ticket is associated with the subscription below",
      appointmentTicket: "This ticket is associated with the appointment below",
      receiveTicketUpdates: "Receive ticket updates alert",
      activeTickets: "Active Tickets",
      createdOn: "Created On",
      updatedOn: "Updated On",
      priority: {
        low: "Low",
        normal: "Normal",
        high: "High",
        urgent: "Urgent"
      },
      type: {
        general: "General",
        appointment: "Appointment",
        subscription: "Subscription"
      },
      status: {
        closed: "closed",
        open: "open",
        draft: "draft"
      },
      noNewTickets: "You do not have any recent tickets.",
      swipeToEnableFullHistory: "Swipe the switch button above to enable full ticket history"
    },
    accountTypes: {
      vendor: "vendor | vendors",
      customer: "customer | customers",
      organization: "organization | organizations",
      user: "User | Users",
      affiliate: "affiliate | affiliates",
      activeVendors: "Active Vendors"
    },
    timeOfDay: {
      morning: "morning",
      afternoon: "afternoon",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      minute: "minute",
      hour: "hour"

    },
    information: {
      clickToReset: "Click here to reset your password",
      setupPayment: "Let's Setup a Way To Get You Paid",
      setupPaymentDescription: " You can earn $1 for every lawn we mow that you referred. That adds up to alot of cash!",
      enterPublicAccount:"Tell Us More About Your Cause",
      enterPublicAccountDescription:"Share some details about your cause in the profile section below. This information will be public so contributors know who they're supporting in their community!",
      enterMailingAddress: "Tell Us Your Mailing Address",
      enterMailingAddressDescription: "Let's make sure we have active providers in your area! This address will also be used for verification and payment purposes.",
      enterMailingAddressDisclaimer:"The address above will be used for account approval and verification.",
      enterContactDisclaimer:"This information will be used for verification and payment purposes. Setting up your child's college fund? Provide your contact info above.",
      enterContact: "Enter Your Contact Info",
      enterContactDescription: "Enter the contact info of the individual who will be managing the Wemow affiliate account.",
      publicAccountInfo:"Public Account Info",
      control: "Control how others see you.",
      information: "information",
      orgServiceAreas: "Select the zip codes that your organization can service.",
      wayToGetPaid: "Add a Way To Get Paid",
      updateSomething: "Update Something ",
      searchAddress: "Search for a new address",
      startTypingAddress: "Start typing your address",
      enterVerificationCode: "Enter your verification code.",
      smsMessageSent: "SMS message sent to",
      enterVerificationCodeHere: "Enter verification code here",
      verifyPhone: "Phone Not Verified. Click here to verify phone",
      codeNotReceived: "Did not receive a code? Check your phone number, or",
      resendCode: "click here to send verification code again",
      verified: "Verified",
      unVerified: "Not Verified",
      setupBasicInfo: "Welcome, you need to setup your basic information",
      loginInfo: "Log in to your account.",
      resetPassword: "Reset Password",
      resetPasswordInfo: "Enter email address to set new password",
      passwordLength: "Password must be at least 5 characters",
      resetPasswordForm: "Use the form below to reset your password",
      claimAccountInfo: "Please reset your password below to complete the claim account process.",
      verificationCodeAlert: "A new verification code has been sent to your phone.",
      enterNote: "Please enter your note",
      saveAddressInfo: "You need to save address information",
      setupAddress: "Get started by adding your primary service address",
      currentlyContributingTo: "You are currently contributing to:",
      wayToGo: "Way To Go!",
      loading: "Loading",
      loadMore: "Load More",
      noMoreToLoad: "No more to load",
      click: "Click",
      selectZipInRadiusOfOrg: "You can select zip codes to be added within {mileRadius} mile radius of your {addressOwner} address",
      selectedZipInRadiusOfOrg: "You have selected service areas within {mileRadius} mile radius of your {addressOwner} address",
      aFewSeconds: 'This will take a few seconds',
      settingUp: 'Setting up your account',
      noUpdates: "No updates to save",
      addressBooked: "Selected Address is already attached to a different subscription. Choose a different address",
      showVerificationLinkIn: "The link to resend verification code will be shown in",
      completeOnboarding: "Complete onboarding",
      getStarted: "Get Started",
      findOutMore: "Find out more",
      recommendServices: "We recommend also for your active services",
      exitWindow: "Exit Window",
      changePassword: "Change Password",
      otherSettings: "Other settings",
      addNewAddress: "Start typing to add new address",
      setupBillingInfo: "Complete account setup by entering your billing details and preferences.",
      goBackToLogin: "Go back to login",
      viewOrganizationVendors: "View your organization vendors",
      vendorProfile: "Vendor's Profile",
      addVendorToOrg: "Add a vendor to your organization",
      editDayAvailability: "Edit Day Availability",
      applyAddOnServiceToAppointments: "Do you want to apply add-on service to all appointments?",
      phoneNumberCannotBeChanged: "You cannot modify this number because it's already been verified",
      tapToVerifyPhone: "Phone not verified. Tap here to verify phone",
      tapResendCode: "tap here to send verification code again",
      noAddressForSlider: "Valid address required for slider does not exist. Enter a valid address and refresh to use slider.",
      insuranceDocumentUploaded: "Insurance Document Uploaded",
      insuranceDocumentValidityPeriod: "Insurance Validity Period",
      yourReferrals: "Manage your referrals",
      newReferrals: "{text} new referrals",
      comparedReferralsPercent: "{text}% compared to last week",
      setupInsurance: "Setup Insurance",
      viewInsuranceInfo: "View insurance your setup",
      addAddOnService: "Add add on services",
      selectAppointmentAddOn: "Select add on services to attach to appointment",
      selectSubscriptionAddOn: "Select add on services to attach to subscription",
      createCustomAddOn: "Create a custom add on service",
      editCustomAddOn: "Edit add on service",
      noAppointmentsYet: "No Appointments Yet",
      noAppointmentsYetDetailed: "You do not have any appointments available. Once you do, we will show it here.",
      noPayoutHistory: "No Payouts History",
      noPayoutHistoryDetailed: "You do not have any payout history available. Once you do, we will show it here.",
      futureAppointment: "Future Appointments",
      pastAppointment: "Past Appointments",
      noTicketsYet: "No Tickets Yet",
      noTicketsYetDetailed: "You do not have any tickets available. Once you do, we will show it here.",
      currentTickets: "Current Tickets",
      pastTickets: "Past History",
      selectOption: "Select an option",
      vendorToday: "Today",
      vendorTodayTitle: "Activities",
      noteTitle: "Note",
      noteMessage: "Please enter note for this action.",
      proceedText: "Proceed",
      noteError: "Please provide the note for this action before proceeding.",
      enableAllDayAppointments: "Enable Today Appointments",
      confirmEnableAppointmentsForTheDay: "Confirm enable all appointments for the day?",
      confirmCreateInvoice: "Creating an invoice would charge the customer's card and cannot be reversed. Do you want to continue?",
      noVendorsAvailable: "No vendors available",
      noVendorsAvailableDetailed: "You have not added any vendors to this organization",
      yesterday: "yesterday",
      tomorrow: "tomorrow",
      plannedActivities: "Your planned activities for {day}",
      activitiesForYesterday: "Your activities for yesterday",
      noActivity: "You have no activity for {day}.",
      noActivityYesterday: "You had no activity for yesterday.",
      addAddressTodayScreen: "Add an address to view appointments you have for the day",
      viewAllVendors: "View All Vendors",
      enterTextHere: "Enter text here",
      viewTickets: "View Tickets",
      addNewAffiliate: "Add New Affiliate",
      searchAffiliates: "Search affiliates",
      searchingAffiliates: "Searching Affiliates",
      noAffiliateFound: "No Affiliate Found",
      selectAppointmentForInvoice: "Select an appointment to add to invoice",
      promoInfoDuration:"Applies to all recurring charges on this subscription. | Only applies to first  charge on this subscription, subsequent charges will be billed at original price",
      promoInfoOff: "{text} off your subscription.",
      noNotifications: "No Notifications",
      noNotificationsDetailed: "You do not have any notifications. Once you do, we will show it here.",
      noContributionsAvailable: "No contributions available",
      noContributionsAvailableDetailed: "You have not added any affiliates",
      applyPromoCode: "Apply Promo Code",
      registerAs: "Register As",
      allSubscriptionsPaused: "All Subscriptions have been paused",
      credits: "Credits",
      accountsSearchInfo: "Search against first name, last name, company name or email",
      accountSearch: "Search Accounts (Enter 3 or more characters to search)",
      selectAppointment: "Select an appointment",
      newSubscriptionsDetailed: "You have new customers that have not yet been serviced. Click the button to view the new subscriptions",
      noAccountRequirements: "No Account Requirements",
      noAccountRequirementsDetailed: "Congratulations! All your account requirements have been provided. You can go back and continue working on Wemow. You'll receive an alert whenever there are any future updates",
      verificationInfoMissingVendor: "Your organization requires account updates to ensure smooth payouts. Please contact your organization so your next payments are not affected.",
      verificationInfoMissingOrg: "Your account is missing some verification information. Please update to prevent issues with payout.",
      accountUpdateRequired: "Account update required",
      updateDeadlineText: "Deadline for updates is {deadline}"
    },
    payment: {
      paymentTransfers: "No Payment Transfers | Payment Transfers | {count} Payment Transfers",
      transferSuccess: "Transfer success",
      billing: "Billing",
      noPaymentInfo: "No Payment Info",
      payouts: "payouts",
      balance: "Balance",
      price: "price",
      creditCard: "Credit Card",
      noPaymentHistory: "No payment history.",
      invoicesAndCharges: "All invoices and processed payments will show here",
      card: "card",
      paymentInformation: "payment information",
      paymentHistory: "payment history",
      upcomingInvoice: "Upcoming Invoice | Upcoming Invoices",
      payment: "payment",
      invoice: "Invoice",
      cardNumber: "Card Number",
      noSavedPaymentInfo: "No Saved Payment Information",
      percentage: "percentage | percentages",
      paymentSource: "Payment Source",
      paymentMethod: "Payment Method",
      addPaymentMethod: "You need to add a payment method.",
      billingInformation: "Setup Your Billing Preferences",
      setupBillingInfo: "Wemow's automatic billing system makes paying easy. Just enter your billing details and preferences below.",
      setupBillingInfoLine2: "Don't worry - we won't charge anything until you're ready to start. ",
      coupon: "coupon | coupons",
      paymentFailed: "Payment failed",
      paymentSuccess: "Payment success",
      cvcCode: "CVC Code",
      addNewCreditCard: "Add new credit card",
      defaultPaymentMethod: "Default payment method",
      billingInformationText: "Billing Information",
      invoiceDetails: "Invoice Details",
      amountPaid: "Amount Paid",
      plan: "plan",
      createInvoice: "Create Invoice",
      addInvoiceItem: "Add Invoice Item",
      customerInvoice: "Customer Invoice",
      chargeCustomer: "Create and charge customer",
      payments: "Payments",
      transactions: "Transactions",
      paymentTransactions: "No Transactions | Transactions | {count} Transactions",
    },
    services: {
      useDefaultAddress: "Use Default Address",
      manageAddresses: "Manage Addresses",
      useDefaultPaymentMethod: "Use Default Payment Method",
      service: "service | services",
      useRandomVendor: "Use Random Vendor",
      nextAvailableTime: "Next Available Time",
      nextAvailableSlot: "Next available slot is",
      orderService: "Order Service",
      subscriptionFrequency: "Subscription Frequency",
      selectServiceFrequency: "Select how often you would like service.",
      serviceRepeat: "The service will repeat",
      selectSubscriptionTime: "Select the time you would like your subscription to start on.",
      subscriptionStartDate: "Your subscription will start on this date.",
      serviceAddress: "Service Address",
      preferredAddress: "Which address would you like the service performed at?",
      selectPaymentForSubscription: "Select a payment method for the subscription.",
      paymentSourceForSubscription: "The payment source below will be charged for the subscription.",
      startSubscription: "Start Subscription Now!",
      completeOrder: "Complete Order",
      noItems: "No Items!",
      pricePerVisit: "{price} is your price per visit based on your home size",
      featuredServices: "Featured Services",
      priceStartingAt: "Starting at: {price}",
      servicePreferences: "Service Preferences",
      setupServiceTimes: "We know you're busy! Let us know your preferred day of service so we can do our best to work around your schedule.",
      serviceFrequency: "Service Frequency",
      basicServices: "Basic Gardening Services",
      exploreServices: "Explore our full selection of lawn and yard services",
      noService: "No service available",
      selectedFilters: "for selected filters",
      refreshServices: "Refresh Services",
      serviceDay: "Service Day",
      serviceTime: "Service Time",
      servicePlan: "Service Plan",
      lawn: "Lawn",
      trees: "Trees",
      pools: "Pools",
      sprinkler: "Sprinkler",
      viewServices: "View Services",
      noAddressInServiceArea: 'Sorry, you have no address in this service area',
      noPayments: 'Sorry, you have no payment method setup',
      noPriceForHomeSize: 'No price available for home size',
      selectedPlan: "Selected Plan",
      noAddOnServices: "No Add-On Services",
      plans: "Plans",
      addSubscription: "Add Subscription",
      selectFrequency: "Select Frequency"
    },
    registration: {
      smsConfirmationCode: "An SMS confirmation will be sent to confirm account.",
      emailAccountConfirmation: "An email confirmation will be sent to confirm account.",
      emailSentTo: "Email sent to: {text}",
      returnToLogin: "Return to login page",
      checkEmailForConfirmationLink: "Check your email for a confirmation link",
      registrationComplete: "Registration Complete!",
      useConfirmationMethod: "to use {text} instead.",
      checkPhoneConfirmationCode: "Check your phone for the confirmation code",
      smsSentTo: "SMS message sent to: {text}",
      enterVerificationCode: "Enter your verification code.",
      wrongNumber: "Entered a wrong number?",
      changeNumber: "to change the number",
      enterNewNumberToSendCode: "Please enter a new phone number to re-send the code",
      returnToCodeVerification: "Return to code verification",
      resendCode: "Resend Code",
      emailInUse: "Email already in use",
      notACustomer: "Not a customer? Register as an",
      notAnAffiliate: "Not an affiliate? Register as a",
      notAnOrganization: "Not an organization? Register as a",
      alreadyHaveAccount: "Already have an account?",
      instead: "instead",
      orAn: "or an",
      login: "login",
      signup: "Sign up",
      sidebarHeader: "Welcome to Wemow.",
      sidebarSubheader: "Your instant lawn care delivery service.",
      customerSidebarLineOne: "We've simplified lawn care with a subscription based approach. Finally, you can now schedule, pay, and manage everything outside your home in 1 easy to use application.",
      customerSidebarLineTwo: "Starting is easy, just answer three simple questions and our crew and 24/7 support will take it from there.",
      forgotPassword: "Forgot Password?",
      createAccount: "Create an account",
      registerAsCustomer: "Register as a Customer",
      registerAsServiceProvider: "Register as a Provider",
      registerAsAffiliate: "Register as an Affiliate",
      verifyCode: "Verify Code",
      verifying: "Verifying",
      verificationComplete: "Verification Complete",
      verificationCodeSentText: "Enter verification code sent to your",
      verificationCodeNotSent: "Didn't get verification code?",
      dontHaveAccount: "Don't have an account?"
    },
    collections: {
      zipCode: "zipcode | zipcodes"
    },
    fileSizeLabels: {
      kb: "kb",
      mb:"MB"
    },
    languages: {
      en: "English",
      es: "Spanish"
    },
    label: 'Label',
    profile: 'Profile',
    generate: "Generate",
    passwordStrength: "Password Strength",
    passwordRequirements: "Password Requirements",
    showPassword: "Show Password",
    hidePassword: "Hide Password",
    auto: "Auto",
    available: "Available",
    created: "Created",
    used: "Used",
    lock: "Lock",
    link: "Link",
    total: "Total",
    none: "None",
    listText: "List of {text}",
    sendBulkEmails: "You can send bulk emails.",
    bulkMessages: "Bulk Messages",
    broadCastEmail: "Broadcast Email | Broadcast Emails",
    details: "details",
    emailsHistory: "Emails History",
    noEmailHistory: "No Email History",
    recipientList: "Recipient List",
    message: "Message | Messages",
    basicInformation: "Basic Information",
    welcomeSetup: "Welcome, you need to setup your {text}",
    phoneType: "{text} Phone",
    primary: "Primary",
    otherQuestions: "Other Questions",
    home: "Home",
    hello: "Hello",
    dismissedText: "Dismissed {text}",
    unread: "Unread",
    read: "Read",
    dismiss: "Dismiss",
    no: "No",
    yes: "Yes",
    account: "Account",
    preference: "preference | preferences",
    defaultTimeZone: "Default Time Zone",
    setting: "setting | settings",
    text: "Text",
    warning: "Warning",
    one: "One",
    vendorDetails: "vendor's details",
    availability: "Availability",
    info: "info",
    enter: "Enter",
    general: "general",
    noScheduleCreated: "No Schedule created for this vendor.",
    custom: "Custom",
    pickDay: "Pick a day",
    trash: "Trash",
    noSpecifiedTimes: "No specified times",
    pending: "Pending",
    reset: "reset",
    duration: "Duration",
    estimated: "Estimated",
    approximately: "Approximately",
    time: "Time",
    history: "history",
    by: "by",
    name: "name",
    load: "load",
    more: "more",
    selectPricingOption: "Select Preferred Pricing Option",
    note: "Note|Notes",
    lastUpdate: "Last Update",
    unknown: "Unknown",
    random: "Random",
    dashboard: "dashboard",
    default: "Default",
    setDefault: "Set as default",
    all: "all",
    changes: "changes",
    searchBy: "search by {text}",
    perVisit: "per visit",
    id: "ID",
    here: "here",
    preferredLanguage: "Preferred Language",
    serviceArea: "Service Area | Service Areas",
    add: "add",
    profileImage: "Profile Image",
    seconds: "seconds",
    completionConfirmed: "Completed",
    welcome: "Welcome ",
    pushNotifications: "Push Notifications",
    notifications: "Notifications",
    notificationSettings: "Notification Settings",
    receiveSMS: "Receive SMS",
    receiveEmail: "Receive Email",
    receivePush: "Receive Push",
    organizationStatistics: "Organization Statistics",
    basic: "Basic",
    insurance: "insurance",
    document: 'document',
    search: "search",
    approved: 'Approved',
    unapproved: 'Unapproved',
    save: 'Save',
    uploadInProgress: '{text} upload in progress, please hold on.',
    manageReferralIDs: "Manage Referral IDs",
    communication: "Communication",
    started: "Started",
    bankAccount: "Bank Account",
    timezones: {
      pacific: "Pacific",
      mountain: "Mountain",
      central: "Central",
      eastern: "Eastern"
    },
    common: {
      a: 'a',
      an: 'an',
      the: 'the',
      new: "New"
    }

  },
  ...{ es }
};