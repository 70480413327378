// @ts-check
export const information = {
    clickToReset: "Haga clic aquí para restablecer su contraseña",
    setupPayment: "Configuremos una forma de que le paguen",
    setupPaymentDescription: "Puede ganar $ 1 por cada césped que cortemos y que nos refiera. ¡Eso suma mucho dinero en efectivo! Háganos saber cómo podemos conseguirle dinero",
    enterPublicAccount: "Cuéntenos más sobre su causa",
    enterPublicAccountDescription: "Comparta algunos detalles sobre su causa en la sección de perfil a continuación. ¡Esta información será pública para que los contribuyentes sepan a quién apoyan en su comunidad!",
    enterMailingAddress: "Díganos su dirección postal",
    enterMailingAddressDescription: "¡Asegurémonos de tener proveedores activos en su área! Esta dirección también se utilizará para fines de verificación y pago",
    enterMailingAddressDisclaimer: "La dirección anterior se utilizará para la aprobación y verificación de la cuenta",
    enterContactDisclaimer: "Esta información se utilizará para fines de verificación y pago. ¿Configurar el fondo universitario de su hijo? Proporcione su información de contacto arriba",
    enterContactDescription:"Ingrese la información de contacto de la persona que administrará la cuenta de afiliado de Wemow.",
    enterContact:"Ingrese su información de contacto",
    publicAccountInfo: "Información de cuenta pública",
    control: "Controla cómo te ven las demás.",
    information: "información",
    orgServiceAreas: "Proporcione información sobre las regiones a las que servirá su organización.",
    wayToGetPaid: "Agregue una forma de pago",
    updateSomething: "Actualizar algo",
    searchAddress: "Buscar una nueva dirección",
    startTypingAddress: "Empieza escribiendo tu dirección",
    enterVerificationCode: "Ingrese su código de verificación.",
    smsMessageSent: "Mensaje SMS enviado a",
    enterVerificationCodeHere: "Ingrese el código de verificación aquí",
    verifyPhone: "Teléfono no verificado. Haga clic aquí para verificar el teléfono.",
    codeNotReceived: "¿No recibió un código? Verifica tu número de teléfono o",
    resendCode: "haga clic aquí para enviar el código de verificación nuevamente",
    verified: "Verificado",
    unVerified: "No verificado",
    setupBasicInfo: "Bienvenido, necesitas configurar tu información básica",
    loginInfo: "Ingrese a su cuenta.",
    resetPassword: "Restablecer la contraseña",
    resetPasswordInfo: "Ingrese la dirección de correo electrónico para establecer una nueva contraseña",
    passwordLength: "La contraseña debe tener al menos 5 caracteres",
    resetPasswordForm: "Use el siguiente formulario para restablecer su contraseña",
    claimAccountInfo: "Restablezca su contraseña a continuación para completar el proceso de la cuenta de reclamo.",
    verificationCodeAlert: "Se ha enviado un nuevo código de verificación a su teléfono.",
    enterNote: "Por favor ingrese su nota",
    saveAddressInfo: "Necesita guardar la información de la dirección",
    setupAddress: "Necesita configurar la información de su dirección",
    currentlyContributingTo: "Actualmente estás contribuyendo a:",
    wayToGo: "¡Bien Hecho!",
    loading: "Cargando",
    loadMore: "Carga más",
    noMoreToLoad: "No más para cargar",
    click: "Hacer clic",
    selectZipInRadiusOfOrg: "Puede seleccionar códigos postales para agregarlos dentro del radio de {mileRadius} millas de su dirección de {addressOwner}",
    aFewSeconds: "Esto tomará unos segundos",
    settingUp: "Configurando su cuenta",
    noUpdates: "No hay actualizaciones para guardar",
    showVerificationLinkIn: "El enlace para reenviar el código de verificación se mostrará en",
    getStarted: "Empezar",
    exitWindow: "Ventana de salida",
    changePassword: "Cambia la contraseña",
    otherSettings: "Otros ajustes",
    setupBillingInfo: "Necesita configurar su información de facturación",
    goBackToLogin: "Regresar al inicio de sesión",
    viewOrganizationVendors: "Ver los proveedores de su organización",
    vendorProfile: "Perfil del vendedor",
    addVendorToOrg: "Agregar un proveedor a su organización",
    editDayAvailability: "Editar disponibilidad del día",
    recommendServices: "Recomendamos también para sus servicios activos",
    applyAddOnServiceToAppointments: "¿Desea aplicar el servicio complementario a todas las citas?",
    phoneNumberCannotBeChanged: "No puede modificar este número porque ya se ha verificado",
    tapToVerifyPhone: "Teléfono no verificado. Toque aquí para verificar el teléfono",
    tapResendCode: "toca aquí para enviar el código de verificación nuevamente",
    noAddressForSlider : "La dirección válida requerida para el control deslizante no existe. Ingrese una dirección válida y actualice para usar el control deslizante.",
    insuranceDocumentUploaded: "Documento de seguro cargado",
    insuranceDocumentValidityPeriod: "Período de validez del seguro",
    setupInsurance: "Configurar seguro",
    viewInsuranceInfo: "Ver seguro su configuración",
    addAddOnService: "Agregar servicios adicionales",
    selectAppointmentAddOn: "Seleccione agregar servicios para adjuntar a la cita",
    createCustomAddOn: "Crear un servicio complementario personalizado",
    noAppointmentsYet: "Aún no hay citas",
    noAppointmentsYetDetailed: "No tiene citas disponibles. Una vez que lo hagas, lo mostraremos aquí.",
    noPayoutHistory: "Sin historial de pagos",
    noPayoutHistoryDetailed: "No tienes ningún historial de pagos disponible. Una vez que lo hagas, lo mostraremos aquí.",
    futureAppointment: "Nombramientos futuros",
    pastAppointment: "Nombramientos anteriores",
    noTicketsYet: "Aún no hay boletos",
    noTicketsYetDetailed: "No tienes entradas disponibles. Una vez que lo hagas, lo mostraremos aquí.",
    currentTickets: "Entradas actuales",
    pastTickets: "Historia pasada",
    selectOption: "Seleccione una opción",
    vendorToday: "Hoy",
    vendorTodayTitle: "Actividades",
    noteTitle: "Nota",
    noteMessage: "Introduzca una nota para esta acción.",
    proceedText: "Continuar",
    noteError: "Proporcione la nota para esta acción antes de continuar.",
    enableAllDayAppointments: "Habilitar citas para hoy",
    confirmEnableAppointmentsForTheDay: "¿Confirmar habilitar todas las citas del día?",
    confirmCreateInvoice: "Crear una factura cargaría la tarjeta del cliente y no se puede revertir. ¿Quieres continuar?",
    selectedZipInRadiusOfOrg :"Ha seleccionado áreas de servicio dentro de un radio de {mileRadius} millas de su dirección {addressOwner}",
    noVendorsAvailable: "No hay proveedores disponibles",
    noVendorsAvailableDetailed: "No ha agregado ningún proveedor a esta organización",
    yesterday: "ayer",
    tomorrow: "mañana",
    plannedActivities: "Tus actividades planificadas para {day}",
    activitiesForYesterday: "Tus actividades para ayer",
    noActivity: "No tienes actividad durante {day}.",
    noActivityYesterday: "No tenías actividad ayer.",
    viewAllVendors: "Ver todos los proveedores",
    enterTextHere: "Introducir texto aquí",
    viewTickets: "Ver entradas",
    addNewAffiliate: "Agregar nuevo afiliado",
    searchAffiliates: "Buscar afiliados",
    searchingAffiliates: "Búsqueda de afiliados",
    noAffiliateFound: "No se encontró afiliado",
    addAddressTodayScreen: "Agregue una dirección para ver las citas que tiene para el día",
    selectAppointmentForInvoice: "Seleccione una cita para agregar a la factura",
    promoInfoDuration: "Se aplica a todos los cargos recurrentes de esta suscripción. | Solo se aplica al primer cargo de esta suscripción, los cargos posteriores se facturarán al precio original",
    promoInfoOff: "{text} de descuento en su suscripción",
    noNotifications: "No Notificaciones",
    noNotificationsDetailed: "No tienes ninguna notificación. Una vez que lo haga, lo mostraremos aquí.",
    applyPromoCode: "Aplicar código promocional",
    registerAs: "Registrarse como",
    allSubscriptionsPaused: "Todas las suscripciones se han detenido",
    credits: "Créditos",
    accountsSearchInfo: "Busque por nombre, apellido, nombre de la empresa o correo electrónico",
    accountSearch: "Buscar Cuentas (Ingrese 3 o más caracteres para buscar)",
    selectAppointment: "Seleccione una cita",
    newSubscriptionsDetailed: "Tiene nuevos clientes que aún no han sido atendidos. Haga clic en el botón para ver las nuevas suscripciones",
    noAccountRequirements: "Sin requisitos de cuenta",
    noAccountRequirementsDetailed: "¡Felicidades! Se han proporcionado todos los requisitos de su cuenta. Puedes volver atrás y seguir trabajando en Wemow. Recibirás una alerta cada vez que haya actualizaciones futuras.",
    verificationInfoMissingVendor: "Su organización requiere actualizaciones de cuenta para garantizar pagos fluidos. Comuníquese con su organización para que sus próximos pagos no se vean afectados.",
    verificationInfoMissingOrg: "A su cuenta le falta información de verificación. Actualice para evitar problemas con el pago.",
    accountUpdateRequired: "actualización de cuentaRequerido",
    updateDeadlineText: "La fecha límite para las actualizaciones es: {deadline}"
}