import { resolveHomeRoute, registerVerifyPhoneBeforeResolve } from "../../middleware";
import Register from "@/components/public/register/Register.vue"
import RegisterQuick from "@/components/public/register/Quick-Register.vue"
import RegisterVerifySms from  "@/components/public/register/PhoneVerification.vue"
import AppHome from "@/components/public/home/AppHome.vue"
import Login from "@/components/public/login/Login.vue"
import Logout from "@/components/public/Logout.vue"
import SwitchAccount from "@/components/public/SwitchAccount.vue"

export const generalRoutes = [
  /*
   * General Routes
   */
  {
    path: "/login",
    name: "Login",
    components: {
      public: Login
    },
    meta: { requiresAuth: false, beforeResolve: resolveHomeRoute, hideNavigation: true, },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { requiresAuth: false, hideNavigation: true, layoutTemplate: "loading-block" },
  },
  {
    path: "/auth/switch-account/:account_id",
    name: "SwitchAccount",
    component: SwitchAccount,
    meta: { requiresAuth: false, hideNavigation: true, layoutTemplate: "loading-block" },
  },
  {
    path: "/register",
    name: "RegisterCustomer",

    components: {
      public: RegisterQuick
    },
    meta: { requiresAuth: false, beforeResolve: resolveHomeRoute, hideNavigation: true, },
    props: {

      public: {
        registrationType: "customer",
      }
    
    },
  },
  {
    path: "/register-customer-quick",
    components: {
      public: RegisterQuick
    },
    meta: { requiresAuth: false,  beforeResolve: resolveHomeRoute, hideNavigation: true, },
    props: {

      public: {
        registrationType: "customer",
      }
    
    },
  },
  {
    path: "/register-organization",
    name: "RegisterOrganization",
    components: {
      public:Register
    },
    meta: { requiresAuth: false,  beforeResolve: resolveHomeRoute, hideNavigation: true, },
    props: {

      public: {
        registrationType: "organization",
      }
    
    },
  },
  {
    path: "/register-affiliate",
    name: "RegisterAffiliate",
    components: {
      public:Register
    },
    meta: { requiresAuth: false,  beforeResolve: resolveHomeRoute, hideNavigation: true, },
    props: {

      public: {
        registrationType: "affiliate",
      }
    
    },
  },
  {
    path: "/register/verify/sms",
    name: "VerifySMS",
    components: {
      public: RegisterVerifySms
    },
    meta: { requiresAuth: false, beforeResolve: registerVerifyPhoneBeforeResolve, hideNavigation: true, }
  },
  {
    path: "/",
    components: {
      public:AppHome
    },
    meta: {
      requiresAuth: true,
      hideNavigation: true,
      allowedRoles: [
        "super",
        "customer",
        "vendor",
        "affiliate",
        "organization",
      ],
      beforeResolve: resolveHomeRoute,
    },
  },
  {
    path: "/error-not-authorized",
    name: "errorNotAuthorized",
    component: () =>
      import(
        /* webpackChunkName: "app-error-401" */ `@/components/errors/error-401.vue`
      ),
    meta: { requiresAuth: false, hideNavigation: true, },
  },
  {
    path: "/error-no-accounts",
    name: "errorNoAccounts",
    component: () =>
      import(
        /* webpackChunkName: "app-error-no-accounts" */ `@/components/errors/no-accounts.vue`
      ),
    meta: { requiresAuth: false, hideNavigation: true, },
  },
  {
    path: "/error-network",
    name: "errorNetworkDown",
    component: () =>
      import(
        /* webpackChunkName: "app-error-network-error" */ `@/components/errors/network-error.vue`
      ),
    meta: { requiresAuth: false, hideNavigation: true, },
  },
  {
    path: "*",
    components: {
        default : () => import( /* webpackChunkName: "app-error-404" */ `@/components/errors/error-404.vue`
      ),
      public: () => import( /* webpackChunkName: "app-error-404" */ `@/components/errors/error-404.vue`
      ),
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/tickets/ticket-history",
    name: "TicketHistory",
    component: () =>
      import(
        /* webpackChunkName: "customer-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketHistory.vue`
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: ["customer", "vendor", "organization", "super"],
    },
  },
  {
    path: "/actions/appointments",
    name: "AppointmentQuickAction",
    component: () =>
      import(
        /* webpackChunkName: "customer-helpdesk-archive" */ `../../../../components/shared/BotReplyAction/BotReplyActionView.vue`
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: ["vendor", "organization"],
    },
  },
  {
    path: "/requirements",
    name: "AccountRequirements",
    component: () =>
      import(
        /* webpackChunkName: "customer-helpdesk-archive" */ `../../../../components/shared/AccountRequirements/UpdateRequirements.vue`
      ),
    meta: {
      requiresAuth: true,
      // hideNavigation: true,
      allowedRoles: ["vendor", "organization"],
    },
  },
];
