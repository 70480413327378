export const X_SOCKET_CONNECT = `X_SOCKET_CONNECT`
export const X_SOCKET_DISCONNECT = `X_SOCKET_DISCONNECT`

export const AUTH_PING_API = `AUTH_PING_API`
export const AUTH_REGISTRATION_ACTION = `AUTH_REGISTRATION_ACTION`
export const AUTH_QUICK_REGISTRATION_ACTION = `AUTH_QUICK_REGISTRATION_ACTION`
export const AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE = `AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE`
export const AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_ADDRESS = `AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_ADDRESS`
export const AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_DATE = `AUTH_QUICK_CUSTOMER_REGISTRATION_CHECK_DATE`
export const AUTH_PUBLIC_GET_SERVICES = `AUTH_PUBLIC_GET_SERVICES`

export const AUTH_QUICK_ALT_REGISTRATION_ACTION = `AUTH_QUICK_ALT_REGISTRATION_ACTION`
export const AUTH_ALT_REGISTRATION_ACTION = `AUTH_ALT_REGISTRATION_ACTION`
export const AUTH_VERIFY_CONFIRMATION_TOKEN = `AUTH_VERIFY_CONFIRMATION_TOKEN`
export const AUTH_PASSWORD_RESET_ACTION = `AUTH_PASSWORD_RESET_ACTION`
export const AUTH_VERIFY_TOKEN = `AUTH_VERIFY_TOKEN`
export const AUTH_VERIFY_PASSWORD_RESET_CODE = `AUTH_VERIFY_PASSWORD_RESET_CODE`
export const AUTH_USER_RESET_PASSWORD_ACTION = `AUTH_USER_RESET_PASSWORD_ACTION`
export const AUTH_LOGOUT_ACTION = `AUTH_LOGOUT_ACTION`
export const AUTH_LOGIN_ACTION = `AUTH_LOGIN_ACTION`
export const AUTH_LOGIN_ACTION_ALT = `AUTH_LOGIN_ACTION_ALT`
export const AUTH_REFRESH_TOKEN = `AUTH_REFRESH_TOKEN`
export const AUTH_CHANGE_ACTIVE_ACCOUNT = `AUTH_CHANGE_ACTIVE_ACCOUNT`
export const AUTH_CHECK = `AUTH_CHECK`
export const AUTH_CHECK_USERNAME_IN_USE = `AUTH_CHECK_USERNAME_IN_USE`
export const AUTH_VERIFY_CODE = `AUTH_VERIFY_CODE`;
export const AUTH_RESEND_VERIFY_CODE = `AUTH_RESEND_VERIFY_CODE`
export const AUTH_RESEND_PHONE_VERIFICATION_CODE = `AUTH_RESEND_PHONE_VERIFICATION_CODE`
export const AUTH_VERIFY_CLAIM_TOKEN = `AUTH_VERIFY_CLAIM_TOKEN`;
export const AUTH_USER_GENERATE_APP_PASSWORD = `AUTH_USER_GENERATE_APP_PASSWORD`

export const USER_ACTIVE_ACCOUNT = `USER_ACTIVE_ACCOUNT`
export const LOAD_USER_DATA = `LOAD_USER_DATA`
export const USER_UPDATE_PASSWORD = `USER_UPDATE_PASSWORD`
export const USER_UPDATE_DEVICE_TOKEN =  `USER_UPDATE_DEVICE_TOKEN`
export const USER_REMOVE_DEVICE_TOKEN = `USER_REMOVE_DEVICE_TOKEN`
export const USER_UPDATE = `USER_UPDATE`
export const SET_SCREEN_NAME = `SET_SCREEN_NAME`

export const SERVICES_GET_ONE = `SERVICES_GET_ONE`
export const SERVICES_GET_ALL = `SERVICES_GET_ALL`
export const SERVICES_GET_BY_ORDER = `SERVICES_GET_BY_ORDER`
export const SERVICES_MAKE_INVOICE = `SERVICES_MAKE_INVOICE`

export const CUSTOMER_INIT_DASHBOARD = `CUSTOMER_INIT_DASHBOARD`
export const CUSTOMER_GET_ONE = `CUSTOMER_GET_ONE`
export const CUSTOMER_SAVE_INFO = `CUSTOMER_SAVE_INFO`
export const CUSTOMER_SAVE_ADDRESS = `CUSTOMER_SAVE_ADDRESS`
export const CUSTOMER_DELETE_ADDRESS = `CUSTOMER_DELETE_ADDRESS`
export const CUSTOMER_VERIFY_PHONE_REQUEST = `CUSTOMER_VERIFY_PHONE_REQUEST`
export const CUSTOMER_CONFIRM_PHONE_REQUEST = `CUSTOMER_CONFIRM_PHONE_REQUEST`
export const CUSTOMER_SAVE_PHONE = `CUSTOMER_SAVE_PHONE`
export const CUSTOMER_DELETE_PHONE = `CUSTOMER_DELETE_PHONE`
export const CUSTOMER_GET_PAYMENT_INFO = `CUSTOMER_GET_PAYMENT_INFO`
export const CUSTOMER_SAVE_PAYMENT_INFO = `CUSTOMER_SAVE_PAYMENT_INFO`
export const CUSTOMER_DELETE_PAYMENT_INFO = `CUSTOMER_DELETE_PAYMENT_INFO`
export const CUSTOMER_GET_PAYMENT_HISTORY = `CUSTOMER_GET_PAYMENT_HISTORY`
export const CUSTOMER_ADD_SUBSCRIPTION = `CUSTOMER_ADD_SUBSCRIPTION`
export const CUSTOMER_UPDATE_SUBSCRIPTION = `CUSTOMER_UPDATE_SUBSCRIPTION`
export const CUSTOMER_CANCEL_SUBSCRIPTION = `CUSTOMER_CANCEL_SUBSCRIPTION`
export const CUSTOMER_READ_NOTE = `CUSTOMER_READ_NOTE`
export const CUSTOMER_DISMISS_NOTE = `CUSTOMER_DISMISS_NOTE`
export const CUSTOMER_DEFAULT_PAYMENT_METHOD = `CUSTOMER_DEFAULT_PAYMENT_METHOD`
export const CUSTOMER_GET_ALL_AFFILIATES = `CUSTOMER_GET_ALL_AFFILIATES`
export const CUSTOMER_ATTACH_AFFILIATE = `CUSTOMER_ATTACH_AFFILIATE`
export const CUSTOMER_ATTACH_RANDOM_AFFILIATE = `CUSTOMER_ATTACH_RANDOM_AFFILIATE`
export const CUSTOMER_REMOVE_AFFILIATE = `CUSTOMER_REMOVE_AFFILIATE`
export const CUSTOMER_UPDATE_AFFILIATE = `CUSTOMER_UPDATE_AFFILIATE`
export const CUSTOMER_GET_HELPDESK_TICKETS = `CUSTOMER_GET_HELPDESK_TICKETS`
export const CUSTOMER_GET_UPCOMING_INVOICE = `CUSTOMER_GET_UPCOMING_INVOICE`
export const CUSTOMER_GET_INVOICE = `CUSTOMER_GET_INVOICE`
export const CUSTOMER_APPROVE_ADD_ON = `CUSTOMER_APPROVE_ADD_ON`
export const CUSTOMER_PAY_INVOICE = `CUSTOMER_PAY_INVOICE`
export const CUSTOMER_GET_INVOICES = `CUSTOMER_GET_INVOICES`
export const CUSTOMER_DECLINE_INVOICE = `CUSTOMER_DECLINE_INVOICE`
export const CUSTOMER_PAUSE_SUBSCRIPTION = `CUSTOMER_PAUSE_SUBSCRIPTION`
export const CUSTOMER_UNPAUSE_SUBSCRIPTION = `CUSTOMER_UNPAUSE_SUBSCRIPTION`
export const CUSTOMER_SUSPEND_SUBSCRIPTION = `CUSTOMER_SUSPEND_SUBSCRIPTION`
export const CUSTOMER_ADD_CREDIT = `CUSTOMER_ADD_CREDIT`
export const CUSTOMER_LIST_CREDITS = `CUSTOMER_LIST_CREDITS`
export const CUSTOMER_RESTORE_SUBSCRIPTION = `CUSTOMER_RESTORE_SUBSCRIPTION`
export const CUSTOMER_UPDATE_PUBLIC_REVIEWS = `CUSTOMER_UPDATE_PUBLIC_REVIEWS`
export const GENERATE_REVIEWS = `GENERATE_REVIEWS`

export const HELP_DESK_ADD_TICKET = `HELP_DESK_ADD_TICKET`
export const HELP_DESK_DELETE_TICKET = `HELP_DESK_DELETE_TICKET`
export const HELP_DESK_GET_TICKET_RESPONSES = `HELP_DESK_GET_TICKET_RESPONSES`
export const GET_HELPDESK_TICKETS = `GET_HELPDESK_TICKETS`
export const GET_ONE_HELPDESK_TICKET = `GET_ONE_HELPDESK_TICKET`
export const ADD_HELPDESK_TICKET_COMMENT = `ADD_HELPDESK_TICKET_COMMENT`
export const ADD_TICKET_ATTACHMENT = `ADD_TICKET_ATTACHMENT`
export const HELPDESK_TICKET_UPDATE = `HELPDESK_TICKET_UPDATE`

export const CUSTOMER_ADD_HELPDESK_TICKETS = `CUSTOMER_ADD_HELPDESK_TICKETS`
export const CUSTOMER_ADD_HELPDESK_TICKETS_ATTACHMENT = `CUSTOMER_ADD_HELPDESK_TICKETS_ATTACHMENT`
export const CUSTOMER_ADD_HELPDESK_TICKET_COMMENT = `CUSTOMER_ADD_HELPDESK_TICKET_COMMENT`
export const CUSTOMER_GET_ONE_HELPDESK_TICKET = `CUSTOMER_GET_ONE_HELPDESK_TICKET`
export const CUSTOMER_GET_UPCOMING_APPOINTMENTS = `CUSTOMER_GET_UPCOMING_APPOINTMENTS`
export const CUSTOMER_REFRESH_APPOINTMENTS = `CUSTOMER_REFRESH_APPOINTMENTS`

export const CUSTOMER_GET_ONE_UPCOMING_APPOINTMENT = `CUSTOMER_GET_ONE_UPCOMING_APPOINTMENT`
export const CUSTOMER_GET_SUBSCRIPTIONS_FROM_STRIPE = `CUSTOMER_GET_SUBSCRIPTIONS_FROM_STRIPE`
export const CUSTOMER_GET_ONE_SUBSCRIPTIONS_FROM_STRIPE = `CUSTOMER_GET_ONE_SUBSCRIPTIONS_FROM_STRIPE`
export const CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS = `CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS`
export const CUSTOMER_GET_AFFILIATES = `CUSTOMER_GET_AFFILIATES`
export const CUSTOMER_GET_SINGLE_AFFILIATE = `CUSTOMER_GET_SINGLE_AFFILIATE`
export const CUSTOMER_CREATE_NEW_AFFILIATE = `CUSTOMER_CREATE_NEW_AFFILIATE`
export const CUSTOMER_SAVE_REVIEW = `CUSTOMER_SAVE_REVIEW`


export const CART_ADD_ITEM_TO_CART = `CART_ADD_ITEM_TO_CART`
export const CART_REMOVE_ITEM_FROM_CART = `CART_REMOVE_ITEM_FROM_CART`
export const CART_EMPTY_CART = `CART_EMPTY_CART`

export const ORDER_GET_ONE = `ORDER_GET_ONE`
export const ORDERS_EDIT_ADD_ON = `ORDERS_EDIT_ADD_ON`

export const APPOINTMENTS_SCHEDULE_CHECK_AVAILABILITY = `APPOINTMENTS_SCHEDULE_CHECK_AVAILABILITY`
export const APPOINTMENTS_SCHEDULE_GET_TIMES_BY_DAY = `APPOINTMENTS_SCHEDULE_GET_TIMES_BY_DAY`
export const APPOINTMENTS_SCHEDULE_GET_NEXT_AVAILABLE = `APPOINTMENTS_SCHEDULE_GET_NEXT_AVAILABLE`
export const APPOINTMENTS_SCHEDULE_GET_AVAILABILITY = `APPOINTMENTS_SCHEDULE_GET_AVAILABILITY`
export const APPOINTMENTS_SCHEDULE_GET_AVAILABILITY_RANGE = `APPOINTMENTS_SCHEDULE_GET_AVAILABILITY_RANGE`
export const APPOINTMENTS_INSTANCE_UPDATE = `APPOINTMENTS_INSTANCE_UPDATE`
export const APPOINTMENTS_ASSIGN_ADD_ON = `APPOINTMENTS_ASSIGN_ADD_ON`
export const APPOINTMENTS_UPDATE_ADD_ONS = `APPOINTMENTS_UPDATE_ADD_ONS`
export const APPOINTMENTS_REMOVE_ADD_ON = `APPOINTMENTS_REMOVE_ADD_ON`
export const APPOINTMENT_CREATE_SINGLE = `APPOINTMENT_CREATE_SINGLE`
export const APPOINTMENTS_GET_ONE = `APPOINTMENTS_GET_ONE`
export const APPOINTMENTS_GET_QUERY = `APPOINTMENTS_GET_QUERY`


export const VENDOR_AUTHORIZE_WITH_STRIPE = `VENDOR_AUTHORIZE_WITH_STRIPE`
export const VENDOR_AUTHORIZE_WITH_STRIPE_TOKEN = `VENDOR_AUTHORIZE_WITH_STRIPE_TOKEN`
export const VENDOR_SAVE_UPDATE_PROFILE = `VENDOR_SAVE_UPDATE_PROFILE`
export const VENDOR_SAVE_INFO = `VENDOR_SAVE_INFO`
export const VENDOR_SAVE_ADDRESS = `VENDOR_SAVE_ADDRESS`
export const VENDOR_DELETE_ADDRESS = `VENDOR_DELETE_ADDRESS`
export const VENDOR_SAVE_PHONE = `VENDOR_SAVE_PHONE`
export const VENDOR_DELETE_PHONE = `VENDOR_DELETE_PHONE`
export const VENDOR_REQUEST_TIME_OFF = `VENDOR_REQUEST_TIME_OFF`
export const VENDOR_SAVE_SCHEDULE = `VENDOR_SAVE_SCHEDULE`
export const VENDOR_GET_UPCOMING_APPOINTMENTS = `VENDOR_GET_UPCOMING_APPOINTMENTS`
export const VENDOR_GET_ONE_UPCOMING_APPOINTMENT = `VENDOR_GET_ONE_UPCOMING_APPOINTMENT`
export const VENDOR_VERIFY_PHONE_REQUEST = `VENDOR_VERIFY_PHONE_REQUEST`
export const VENDOR_CONFIRM_PHONE_REQUEST = `VENDOR_CONFIRM_PHONE_REQUEST`
export const VENDOR_SAVE_LOCATION = `VENDOR_SAVE_LOCATION`
export const VENDOR_GET_TODAY_APPOINTMENTS = `VENDOR_GET_TODAY_APPOINTMENTS`
export const VENDOR_UPDATE_TODAYS_APPOINTMENTS = `VENDOR_UPDATE_TODAYS_APPOINTMENTS`
export const VENDOR_UPDATE_CUSTOMER_CHECKLIST_ANSWERS = `VENDOR_UPDATE_CUSTOMER_CHECKLIST_ANSWSER`
export const VENDOR_GET_CUSTOMER_CHECKLIST_ANSWERS = `VENDOR_GET_CUSTOMER_CHECKLIST_ANSWERS`
export const VENDOR_INIT_DASHBOARD = `VENDOR_INIT_DASHBOARD`
export const VENDOR_GET_INVOICES = `VENDOR_GET_INVOICES`
export const VENDOR_GET_INVOICE = `VENDOR_GET_INVOICE`
export const VENDOR_UPDATE_INVOICE = `VENDOR_UPDATE_INVOICE`
export const VENDOR_DELETE_INVOICE = `VENDOR_DELETE_INVOICE`

export const AFFILIATE_AUTHORIZE_WITH_STRIPE = `AFFILIATE_AUTHORIZE_WITH_STRIPE`
export const AFFILIATE_AUTHORIZE_WITH_STRIPE_TOKEN = `AFFILIATE_AUTHORIZE_WITH_STRIPE_TOKEN`
export const AFFILIATE_SAVE_UPDATE_PROFILE = `AFFILIATE_SAVE_UPDATE_PROFILE`
export const AFFILIATE_SAVE_ADDRESS = `AFFILIATE_SAVE_ADDRESS`
export const AFFILIATE_DELETE_ADDRESS = `AFFILIATE_DELETE_ADDRESS`
export const AFFILIATE_SAVE_PHONE = `AFFILIATE_SAVE_PHONE`
export const AFFILIATE_DELETE_PHONE = `AFFILIATE_DELETE_PHONE`
export const AFFILIATE_ADD_REFERRAL_ID = `AFFILIATE_ADD_REFERRAL_ID`
export const AFFILIATE_UPDATE_REFERRAL_ID = `AFFILIATE_UPDATE_REFERRAL_ID`
export const AFFILIATE_DELETE_REFERRAL_ID = `AFFILIATE_DELETE_REFERRAL_ID`
export const AFFILIATE_GET_REFERRAL_IDS = `AFFILIATE_GET_REFERRAL_IDS`
export const AFFILIATE_CHECK_IF_REFERRAL_ID_USED = `AFFILIATE_CHECK_IF_REFERRAL_ID_USED`
export const AFFILIATE_GET_CONTRIBUTORS = `AFFILIATE_GET_CONTRIBUTORS`
export const AFFILIATE_GET_BALANCE = `AFFILIATE_GET_BALANCE`
export const AFFILIATE_SEND_BROADCAST_EMAIL = `AFFILIATE_SEND_BROADCAST_EMAIL`
export const AFFILIATE_GET_BROADCAST_EMAILS = `AFFILIATE_GET_BROADCAST_EMAILS`
export const AFFILIATE_VERIFY_PHONE_REQUEST = `AFFILIATE_VERIFY_PHONE_REQUEST`
export const AFFILIATE_CONFIRM_PHONE_REQUEST = `AFFILIATE_CONFIRM_PHONE_REQUEST`
export const AFFILIATE_GET_PAYMENT_TRANSFERS = `AFFILIATE_GET_PAYMENT_TRANSFERS`
export const AFFILIATE_GET_PAYMENT_PAYOUTS = `AFFILIATE_GET_PAYMENT_PAYOUTS`
export const AFFILIATE_GET_ALL_PAYMENT_TRANSFERS = `AFFILIATE_GET_ALL_PAYMENT_TRANSFERS`
export const AFFILIATE_GET_BANK_ACCOUNT = `AFFILIATE_GET_BANK_ACCOUNT`
export const AFFILIATE_SAVE_BASIC = `AFFILIATE_SAVE_BASIC`
export const AFFILIATE_INIT_DASHBOARD = `AFFILIATE_INIT_DASHBOARD`
export const AFFILIATE_SAVE_BANK_ACCOUNT = `AFFILIATE_SAVE_BANK_ACCOUNT`
export const AFFILIATE_FINISH_ACCOUNT_SETUP = `AFFILIATE_FINISH_ACCOUNT_SETUP`
export const AFFILIATE_GET_METRICS = `AFFILIATE_GET_METRICS`

export const GET_MEDIA_SRC_BY_ID = `GET_MEDIA_SRC_BY_ID`

export const ORGANIZATION_INIT_DASHBOARD = `ORGANIZATION_INIT_DASHBOARD`
export const ORGANIZATION_ADD_CONTACT = `ORGANIZATION_ADD_CONTACT`
export const ORGANIZATION_REMOVE_CONTACT = `ORGANIZATION_REMOVE_CONTACT`
export const ORGANIZATION_SAVE_UPDATE_PROFILE = `ORGANIZATION_SAVE_UPDATE_PROFILE`
export const ORGANIZATION_SAVE_INFO = `ORGANIZATION_SAVE_INFO`
export const ORGANIZATION_SAVE_ADDRESS = `ORGANIZATION_SAVE_ADDRESS`
export const ORGANIZATION_DELETE_ADDRESS = `ORGANIZATION_DELETE_ADDRESS`
export const ORGANIZATION_SAVE_PHONE = `ORGANIZATION_SAVE_PHONE`
export const ORGANIZATION_VERIFY_PHONE_REQUEST = `ORGANIZATION_VERIFY_PHONE_REQUEST`
export const ORGANIZATION_CONFIRM_PHONE_REQUEST = `ORGANIZATION_CONFIRM_PHONE_REQUEST`
export const ORGANIZATION_DELETE_PHONE = `ORGANIZATION_DELETE_PHONE`
export const ORGANIZATION_GET_ALL_VENDORS = `ORGANIZATION_GET_ALL_VENDORS`
export const ORGANIZATION_GET_ONE_VENDOR = `ORGANIZATION_GET_ONE_VENDOR`
export const ORGANIZATION_VENDOR_SAVE_UPDATE = `ORGANIZATION_VENDOR_SAVE_UPDATE`
export const ORGANIZATION_VENDOR_SAVE_ADDRESS = `ORGANIZATION_VENDOR_SAVE_ADDRESS`
export const ORGANIZATION_VENDOR_DELETE_ADDRESS = `ORGANIZATION_VENDOR_DELETE_ADDRESS`
export const ORGANIZATION_SAVE_VENDOR_SCHEDULE = `ORGANIZATION_SAVE_VENDOR_SCHEDULE`
export const ORGANIZATION_APPROVE_SCHEDULE_ACTION = `ORGANIZATION_APPROVE_SCHEDULE_ACTION`
export const ORGANIZATION_REMOVE_SCHEDULE = `ORGANIZATION_REMOVE_SCHEDULE`
export const ORGANIZATION_VENDOR_DELETE_ONE = `ORGANIZATION_VENDOR_DELETE_ONE`
export const ORGANIZATION_CREATE_NEW_VENDOR = `ORGANIZATION_CREATE_NEW_VENDOR`
export const ORGANIZATION_CUSTOMER_GET_ONE = `ORGANIZATION_CUSTOMER_GET_ONE`
export const ORGANIZATION_SAVE_NEW_APPOINTMENT = `ORGANIZATION_SAVE_NEW_APPOINTMENT`
export const ORGANIZATION_CUSTOMER_GET_ALL_QUERY = `ORGANIZATION_CUSTOMER_GET_ALL_QUERY`
export const ORGANIZATION_SAVE_BASIC = `ORGANIZATION_SAVE_BASIC`
export const ORGANIZATION_SERVICES_GET_ALL = `ORGANIZATION_SERVICES_GET_ALL`
export const ORGANIZATION_AUTHORIZE_WITH_STRIPE = `ORGANIZATION_AUTHORIZE_WITH_STRIPE`
export const ORGANIZATION_AUTHORIZE_WITH_STRIPE_TOKEN = `ORGANIZATION_AUTHORIZE_WITH_STRIPE_TOKEN`
export const ORGANIZATION_GET_UPCOMING_APPOINTMENTS = `ORGANIZATION_GET_UPCOMING_APPOINTMENTS`
export const ORGANIZATION_GET_ONE_UPCOMING_APPOINTMENT = `ORGANIZATION_GET_ONE_UPCOMING_APPOINTMENT`
export const GET_ZIP_CODES = `GET_ZIP_CODES`
export const ORGANIZATION_GET_SERVICE_AREAS = `ORGANIZATION_GET_SERVICE_AREAS`
export const ORGANIZATION_GET_ZIP_CODES = `ORGANIZATION_GET_ZIP_CODES`
export const ORGANIZATION_GET_PAYMENT_TRANSFERS = `ORGANIZATION_GET_PAYMENT_TRANSFERS`
export const ORGANIZATION_GET_PAYOUTS = `ORGANIZATION_GET_PAYOUTS`
export const ORGANIZATION_EXPORT_PAYOUTS = `ORGANIZATION_EXPORT_PAYOUTS`
export const ORGANIZATION_EXPORT_TRANSFERS = `ORGANIZATION_EXPORT_TRANSFERS`
export const ORGANIZATION_GET_PAYOUT_SUMMARY = `ORGANIZATION_GET_PAYOUT_SUMMARY`
export const ORGANIZATION_GET_PAYMENT_TRANSFER_SUMMARY = `ORGANIZATION_GET_PAYMENT_TRANSFER_SUMMARY`
export const ORGANIZATION_UPDATE_CUSTOMER_ADDRESS = `ORGANIZATION_UPDATE_CUSTOMER_ADDRESS`
export const ORGANIZATION_GET_INVOICES = `ORGANIZATION_GET_INVOICES`
export const ORGANIZATION_UPDATE_INVOICE = `ORGANIZATION_UPDATE_INVOICE`
export const ORGANIZATION_DELETE_INVOICE = `ORGANIZATION_DELETE_INVOICE`
export const ORGANIZATION_GET_INVOICE = `ORGANIZATION_GET_INVOICE`
export const ORGANIZATION_GET_ACCOUNT_DETAILS = `ORGANIZATION_GET_ACCOUNT_DETAILS`
export const ORGANIZATION_SAVE_BANK_ACCOUNT = `ORGANIZATION_SAVE_BANK_ACCOUNT`
export const ORGANIZATION_FINISH_ACCOUNT_SETUP = `ORGANIZATION_FINISH_ACCOUNT_SETUP`
export const ORGANIZATION_GET_VERIFICATION_DOCUMENT_DATA = `ORGANIZATION_GET_VERIFICATION_DOCUMENT_DATA`
export const ORGANIZATION_GET_NEW_SUBSCRIPTIONS = `ORGANIZATION_GET_NEW_SUBSCRIPTIONS`
export const ORGANIZATION_ACCEPT_STRIPE_TOS = `ORGANIZATION_ACCEPT_STRIPE_TOS`

export const APPOINTMENTS_SCHEDULE_GET_RANGE = `APPOINTMENTS_SCHEDULE_GET_RANGE`
export const APPOINTMENTS_SCHEDULE_DELETE_ONE = `APPOINTMENTS_SCHEDULE_DELETE_ONE`
export const APPOINTMENTS_COMPLETE_APPOINTMENT = `APPOINTMENTS_COMPLETE_APPOINTMENT`
export const SORT_APPOINTMENTS = `SORT_APPOINTMENTS`

export const SEARCH_VENDOR_QUERY = `SEARCH_VENDOR_QUERY`
export const SEARCH_ORGANIZATION_QUERY = `SEARCH_ORGANIZATION_QUERY`
export const SEARCH_CUSTOMER_QUERY = `SEARCH_CUSTOMER_QUERY`
export const SEARCH_USER_QUERY = `SEARCH_USER_QUERY`
export const SEARCH_AFFILIATE_QUERY = `SEARCH_AFFILIATE_QUERY`
export const SEARCH_AFFILIATE_ID_QUERY = `SEARCH_AFFILIATE_ID_QUERY`
export const SEARCH_SERVICES = `SEARCH_SERVICES`
export const SEARCH_VENDORS_IN_LOCATION = `SEARCH_VENDORS_IN_LOCATION`
export const SEARCH_ZIPCODES = `SEARCH_ZIPCODES`
export const SEARCH_ACCOUNTS = `SEARCH_ACCOUNTS`

export const GET_NOTIFICATION = `GET_NOTIFICATION`
export const GET_NOTIFICATIONS = `GET_NOTIFICATIONS`
export const MARK_NOTIFICATION_AS_READ = `MARK_NOTIFICATION_AS_READ`
export const MARK_NOTIFICATION_AS_DISMISSED = `MARK_NOTIFICATION_AS_DISMISSED`
export const MARK_NOTIFICATION_AS_UNDISMISSED = `MARK_NOTIFICATION_AS_UNDISMISSED`
export const GET_ALERTS = `GET_ALERTS`


export const CONNECT_SOCIAL_ACCOUNT = `CONNECT_SOCIAL_ACCOUNT`
export const DISCONNECT_SOCIAL_ACCOUNT = `DISCONNECT_SOCIAL_ACCOUNT`

export const VALIDATE_PROMO_CODE = `VALIDATE_PROMO_CODE`
export const CREATE_APPROVAL_REQUEST = `CREATE_APPROVAL_REQUEST`
export const APPROVE_APPROVAL_REQUEST = `APPROVE_APPROVAL_REQUEST`
export const GET_APPROVAL_REQUEST = `GET_APPROVAL_REQUEST`


export const AUTH_NEW_PASSWORD_RESET_ACTION = "AUTH_NEW_PASSWORD_RESET_ACTION"

export const ORGANIZATION_GET_SURVEY_QUESTIONS = "ORGANIZATION_GET_SURVEY_QUESTIONS"
export const ORGANIZATION_GET_SURVEY_ANSWERS = "ORGANIZATION_GET_SURVEY_ANSWERS"
export const CHECK_API_VERSION = `CHECK_API_VERSION`

export const COMMUNICATION_GET_ONE = `COMMUNICATION_GET_ONE`;
export const COMMUNICATION_GET_ALL_QUERY = `COMMUNICATION_GET_ALL_QUERY`;
export const COMMUNICATION_CREATE_NEW = `COMMUNICATION_CREATE_NEW`;
export const COMMUNICATION_QUICK_REPLY = `COMMUNICATION_QUICK_REPLY`;
export const COMMUNICATION_DELETE = `COMMUNICATION_DELETE`;
export const COMMUNICATION_UPDATE = `COMMUNICATION_UPDATE`;
export const CHECK_ACCOUNT_CAN_BE_DELETED = `CHECK_ACCOUNT_CAN_BE_DELETED`
export const DELETE_ACCOUNT = `DELETE_ACCOUNT`

export const AFFILIATE_GET_FLYER = `AFFILIATE_GET_FLYER`
export const AFFILIATE_GET_SIGN_UPS = `AFFILIATE_GET_SIGN_UPS`

export const START_CHAT_GET_TOKEN = `START_CHAT_GET_TOKEN`
export const CHECK_AUTH = `CHECK_AUTH`
export const RETRIEVE_CHAT_STATUS = `RETRIEVE_CHAT_STATUS`
export const GET_CHAT_TRANSCRIPTS = `GET_CHAT_TRANSCRIPTS`
export const START_CUSTOMER_SESSION = `START_CUSTOMER_SESSION`
export const RESTART_CUSTOMER_SESSION = `RESTART_CUSTOMER_SESSION`
export const RESET_CUSTOMER_SESSION = `RESET_CUSTOMER_SESSION`
export const SEND_MESSAGE = `SEND_MESSAGE`
export const SEND_TYPING_EVENT = `SEND_TYPING_EVENT`
export const END_CHAT = `END_CHAT`
export const GET_SUBSCRIPTIONS = `GET_SUBSCRIPTIONS`
