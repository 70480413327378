/* eslint-disable */
import { getPlanFromServiceByHomeSize } from "@/helpers/order";
import { formatDollarAmount } from "@/helpers/helpers";


import { get } from "lodash-es";

class MeasurePerformance {
  constructor(nameOfTimer, label, category, gtag) {
    this.name = nameOfTimer;
    this.category = category;
    this.label = label;
    this.startTime = performance.now();
    this.endTime = 0;
    this.gtag = gtag;
  }
  end() {
    this.endTime = performance.now();
  }
  total() {
    Math.round(this.endTime - this.startTime);
  }

  done(silent) {
    this.end();

    const gtagObject = {
      name: this.name,
      value: this.total(),
    };

    if (this.category) {
      gtagObject["event_category"] = this.category;
    }

    if (this.label) {
      gtagObject["event_label"] = this.label;
    }

    this.gtag.time(gtagObject);

    if (!silent) {
      console.log(gtagObject);
    }
  }
}

function doGtagException(args) {
    if(process.env.NODE_ENV === 'development'){
      console.log("NODE_ENV", this.$gtag, args);
    }
}


function formatItemsFromService(service){
    return service.price.map(p => ({
        id: get(p, 'plan_id'),
        name: get(service, 'name'),
        category: get(service, 'category.name'),
        price: get(p, 'value'),
        quantity: 1,
        variant: get(p, 'label')
    }))
}

function formatItemsFromServices(services){
  return services.map(s => formatItemFromService(s))
}

function goGtagEvent(eventName, args) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const formattedData = {}
    switch (eventName) {
      case "screen_view": {
        const { screen_name } = args;
        formattedData['screen_name'] = screen_name;
        break;
      }
      case "login": {
        const { method } = args;
        formattedData['method'] = method;
        break;
      }
      case "sign_up": {
        const { method } = args;
        formattedData['method'] = method;
        break;
      }
      case "add_payment_info": {
        const { payment_type } = args;
        formattedData['payment_type'] = payment_type;
        break;
      }
      case "view_item": {
        const { items } = args;
        formattedData['items'] = items;
        break;
      }
      case "select_item": {
        const { items, item_list_name, item_list_id } = args;
        formattedData['item_list_name'] = item_list_name;
        formattedData['item_list_id'] = item_list_id;
        formattedData['items'] = items;
      }
      break
      case "view_item_list": {
        const { items, item_list_name, item_list_id } = args;
        formattedData['item_list_name'] = item_list_name;
        formattedData['item_list_id'] = item_list_id;
        formattedData['items'] = items;
        break;
      }
      case "purchase": {
        const { items, affiliation, coupon, currency, transaction_id, value } = args;
        formattedData['items'] = items;
        formattedData['affiliation'] = affiliation;
        formattedData['coupon'] = coupon;
        formattedData['currency'] = currency;
        formattedData['transaction_id'] = transaction_id;
        formattedData['value'] = value;
        break;
      }
    }

    if(Object.keys(formattedData).length){
      this.$gtag.query("event", eventName, formattedData);
    }else{
        if(process.env.NODE_ENV === 'development'){
            console.log("analytics event error. No properties provided");
        }
    }
    if(process.env.NODE_ENV === 'development'){
        console.log("analytics event", eventName, formattedData);
    }
  } catch (error) {}
}

export default {
  install(Vue) {
    Vue.prototype.$analyticsMeasureTiming = function(
      name,
      label = "",
      category = ""
    ) {
      new MeasurePerformance(name, label, category, this.$gtag);
    };
    Vue.prototype.$sendAnalyticsEvent = function(eventName, args) {
      goGtagEvent.call(this, eventName, args);
    };
    Vue.prototype.$analyticsEventItemFormat = function(serviceOrServices, homeSize = 'small') {
      const processItem = (service, sizeVariation) => {
        const plan = getPlanFromServiceByHomeSize(sizeVariation, service);
        return {
          item_id: get(service, 'id'),
          item_name: get(service, 'name'),
          item_category: get(service, 'category.name'),
          item_variant: get(plan, 'label'),
          price: formatDollarAmount( get(plan, 'value') ),
          currency: 'USD',
        }
      }
      return !Array.isArray(serviceOrServices) ? [processItem(serviceOrServices, homeSize)] : serviceOrServices.map((s, index) => processItem(s, homeSize, index));
    };
    Vue.prototype.$sendAnalyticsException = function(eventName, args) {
      doGtagException.call(this, eventName, args);
    };
    Vue.prototype.$sendAnalyticsAdWordsConversion = function(eventName, args) {
      if(process.env.NODE_ENV === 'production'){
        this.$gtag.query("event", 'conversion', {'send_to': 'AW-628158058/2TAVCLrzvuwBEOrcw6sC'});
      }
    };
  },
};
