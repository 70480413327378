import {
  START_CHAT_GET_TOKEN,
  RETRIEVE_CHAT_STATUS,
  GET_CHAT_TRANSCRIPTS,
  START_CUSTOMER_SESSION,
  RESTART_CUSTOMER_SESSION,
  RESET_CUSTOMER_SESSION,
  SEND_MESSAGE,
  SEND_TYPING_EVENT,
  END_CHAT,
} from "@/store/action-types";

import {
  connectParticipant,
  disconnectParticipant,
  sendMessage,
  sendEvent,
  getChatTranscript,
  startChatToken,
  getChatStatus
} from "@/services/ServiceChatAPI";

import {
  SET_STATE,
  SET_CUSTOMER_SESSION,
  ON_CONNECTION_ESTABLISHED,
  ON_MESSAGE,
  SET_ALL_MESSAGES,
  ON_TYPING,
  ON_CONNECTION_BROKEN,
  END_CHAT_MUTATION,
  CLEAR_MESSAGES
} from "@/store/mutation-types";
import { has } from "lodash";
import { maybeParseJSON } from "../../../helpers/json";

if(process.env.VUE_APP_MODE == 'native'){
    require('@valor/nativescript-websockets');
}
let ws = {};

const state = {
  darkMode: true,
  isAuthenticated: false,
  userEmailAddress: "",
  userDisplayName: "",
  chatAppInitialized: false,
  chatAppInitializing: false,
  chatAppStatus: "",
  chatAppAgentName: "",
  chatAppStatusMessage: "",
  showChatApp: false,
  chatSession: null,
  chatSessionEnded: true,
  chatSessionInitialized: false,
  chatSessionInitializing: false,
  chatSessionConnectionEstablished: false,
  floatingBubbleShowMessage: false,
  chatSessionAgentTyping: false,
  chatSessionAgentTypingTimeout: null,
  showChatContainerView: false,
  chatMessages: [],
  showExternalIndicator: false,
  chatSessionActiveAgentName: "",
  loadingGet: false,
  globalError: "",
  isLoggedIn: false,
};

const mutations = {
  [SET_STATE](state, { key, value }) {
    if (has(state, key)) {
      state[key] = value;
    }
  },
  [SET_CUSTOMER_SESSION](state) {
    state.chatSession = false;
  },
  [ON_CONNECTION_ESTABLISHED](state, data) {
    console.log("Established!", data);
    state.chatSessionConnectionEstablished = true;
  },
  [ON_MESSAGE](state, message) {
    console.log("Received message _onMessage: ", message);
    if (message && message?.ParticipantRole === "AGENT") {
      if (
        message &&
        message?.ContentType ===
          "application/vnd.amazonaws.connect.event.participant.joined"
      ) {
        state.chatSessionActiveAgentName = message?.DisplayName || "";
      }

      if (!state.showChatContainerView) {
        state.showExternalIndicator = true;
      }

      if (state.chatSessionAgentTypingTimeout) {
        clearTimeout(state.chatSessionAgentTypingTimeout);
      }
      state.chatSessionAgentTyping = false;
    }
    
    state.chatMessages.push({
      participantRole: message.ParticipantRole,
      displayName: message.DisplayName,
      type: message.Type,
      contentType: message.ContentType,
      content: message.Content,
      time: message.AbsoluteTime,
    });
  },
  [SET_ALL_MESSAGES](state, messages) {
    if (Array.isArray(messages)) {
      state.chatMessages = messages
        .filter((m) => m)
        .map((message) => ({
          participantRole: message?.ParticipantRole,
          displayName: message?.DisplayName,
          type: message?.Type,
          contentType: message?.ContentType,
          content: message?.Content,
          time: message?.AbsoluteTime,
        }));
    }
  },
  [CLEAR_MESSAGES](state) {
    state.chatMessages = []
  },
  [ON_TYPING](state, typingEvent) {
    console.log("Received typing event: " + JSON.stringify(typingEvent));
    if (typingEvent && typingEvent?.ParticipantRole === "AGENT") {
      if (state.chatSessionAgentTypingTimeout) {
        clearTimeout(state.chatSessionAgentTypingTimeout);
      }
      state.chatSessionAgentTyping = true;
      // state.chatSessionAgentTypingTimeout = setTimeout(() => {
      //   state.chatSessionAgentTyping = false;
      // }, 5000);
    }
  },
  [ON_CONNECTION_BROKEN](state, data) {
    console.log("Connection broken.", data);
    state.chatSessionConnectionEstablished = false;
  },
  [END_CHAT_MUTATION](state) {
    state.chatSessionEnded = true;
  },
};

async function startChat(dispatch, commit) {
  const response = await dispatch("START_CHAT_GET_TOKEN");
  localStorage.setItem("participantToken", response.ParticipantToken);
  const result = await connectParticipant({
    ConnectParticipant: false,
    Type: ["WEBSOCKET", "CONNECTION_CREDENTIALS"],
  });

  localStorage.setItem(
    "connectionToken",
    result.data.ConnectionCredentials.ConnectionToken
  );
  const websocketUrl = result.data.Websocket.Url;
  ws = new WebSocket(websocketUrl);

  ws.onopen = (message) => {
    message = maybeParseJSON(message.data);
    commit("ON_CONNECTION_ESTABLISHED", message);

    const initialMessage = {
      topic: "aws/subscribe",
      content: {
        topics: ["aws/chat"],
      },
    };
    ws.send(JSON.stringify(initialMessage));
  };

  ws.onclose = (message) => {
    message = maybeParseJSON(message.data);
    commit("ON_CONNECTION_BROKEN", message);
  };

  ws.onmessage = (message) => {
    message = maybeParseJSON(message.data);
    const content = maybeParseJSON(message.content);
    console.log("message", content);
    if (
      content.ContentType == "application/vnd.amazonaws.connect.event.typing"
    ) {
      commit("ON_TYPING", content);
    } else if (
      content.ContentType !==
      "application/vnd.amazonaws.connect.event.message.metadata"
    ) {
      commit("ON_MESSAGE", content);
    }
  };

  commit("SET_CUSTOMER_SESSION", websocketUrl);
  return websocketUrl;
}

const actions = {
  async [START_CHAT_GET_TOKEN](
    { state, commit }
  ) {
    if (state.loadingGet) {
      return;
    }

    commit("SET_STATE", {
      key: "chatSessionInitializing",
      value: true,
    });

    try {
      const { data = {} } = await startChatToken();
      const startChatResult = data;
      return startChatResult.data;
    } catch (error) {
      const errorMessage =
        error instanceof Error && error.message
          ? error.message
          : "There was an error loading the token. Try again.";
      commit("SET_STATE", {
        key: "globalError",
        value: errorMessage,
      });
      throw new Error(errorMessage);
    }
  },

  async [RETRIEVE_CHAT_STATUS]({ commit }) {
    try {
      const { data = {} } = await getChatStatus();
      const startChatResult = data;
      commit("SET_STATE", {
        key: "chatAppStatus",
        value: startChatResult.status,
      });
      commit("SET_STATE", {
        key: "chatAppStatusMessage",
        value: startChatResult.message,
      });
      commit("SET_STATE", {
        key: "chatAppAgentName",
        value: startChatResult.availableAgentName,
      });

      return startChatResult;
    } catch (error) {
      const errorMessage =
        error instanceof Error && error.message
          ? error.message
          : "There was an error loading the status. Try again.";
      commit("SET_STATE", {
        key: "globalError",
        value: errorMessage,
      });
      throw new Error(errorMessage);
    }
  },

  async [GET_CHAT_TRANSCRIPTS]({ }) {
    const response = await getChatTranscript({
      scanDirection: "BACKWARD",
      sortOrder: "ASCENDING",
      maxResults: 15,
    });

    console.log("response.data.Transcript", response.data.Transcript);
    return response.data.Transcript;
  },

  async [START_CUSTOMER_SESSION]({ commit, dispatch }, payload) {
    commit("SET_STATE", {
      key: "chatSessionInitializing",
      value: true,
    });

    try {
      await startChat(dispatch, commit);
      localStorage.setItem(
        "wemow_chat_participant",
        `${payload.firstName}|${payload.emailAddress}|${payload.accountId}`
      );
      commit("SET_STATE", {
        key: "chatSessionInitialized",
        value: true,
      });
    } catch (error) {
      commit("SET_STATE", {
        key: "chatSessionInitialized",
        value: false,
      });
      throw new Error(error.message)
    }finally{
      commit("SET_STATE", {
        key: "chatSessionInitializing",
        value: false,
      });
    }
  },

  async [RESTART_CUSTOMER_SESSION](
    { dispatch, commit },
    startChatResult
  ) {
    await dispatch("startCustomerSession", startChatResult);
    const response = await getChatTranscript({
      scanDirection: "BACKWARD",
      sortOrder: "ASCENDING",
      maxResults: 50,
    });

    console.log("response.data.Transcript", response.data.Transcript);

    for (const message of response.data.Transcript) {
      commit("ON_MESSAGE", { data: message });
    }

    return response.data.Transcript;
  },

  async [RESET_CUSTOMER_SESSION](
    { dispatch, commit },
    startChatPayload = undefined
  ) {
    // eraseCookie("wemow_chat_session");
    localStorage.removeItem("wemow_chat_session");
    localStorage.removeItem("wemow_chat_participant");
    localStorage.removeItem("connectionToken");
    localStorage.removeItem("participantToken");
    console.log("??", startChatPayload);
    commit("SET_STATE", {
      key: "chatSessionInitialized",
      value: false,
    });

    if (!startChatPayload) {
      return;
    }

    commit("SET_STATE", {
      key: "chatSessionInitializing",
      value: true,
    });

    try {
      await startChat(dispatch, commit);

      commit("SET_STATE", {
        key: "chatSessionInitialized",
        value: true,
      });
    } finally {
      commit("SET_STATE", {
        key: "chatSessionInitializing",
        value: false,
      });
    }
  },

  async [SEND_MESSAGE]({}, message) {
    try {
      const result = await sendMessage(message);
      console.log("message", result);
    } catch (error) {
      console.log("Error sending message" + JSON.stringify(error));
    }
  },

  async [SEND_TYPING_EVENT]({}) {
    try {
      const event = "application/vnd.amazonaws.connect.event.typing";
      await sendEvent(event);
    } catch (error) {
      console.log("Error sending event" + JSON.stringify(error));
    }
  },

  async [END_CHAT]({ commit }) {
    try {
      ws.close();
      const result = await disconnectParticipant();
      console.log("result", result);
      commit("END_CHAT_MUTATION");
    } catch (error) {
      console.log("Unsuccessful disconnection " + JSON.stringify(error));
    }
  },
};

const getters = {
  getChatSession: (state) => state.chatSession,
  isChatSessionInitialized: (state) => state.chatSessionInitialized,
  isInitializingChatSession: (state) => state.chatSessionInitializing,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
