var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md:w-full" },
    [
      _c("div", { staticClass: "mx-auto px-4 sm:px-6 lg:px-8" }, [
        _c("div", { staticClass: "flex justify-end h-16 mr-auto" }, [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "-ml-2 mr-2 flex items-center md:hidden justify-end",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500",
                    on: {
                      click: function ($event) {
                        _vm.drawer = !_vm.drawer
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-6 w-6",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                          fill: "currentColor",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z",
                            "clip-rule": "evenodd",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "hidden md:ml-6 md:flex md:space-x-8" },
              [
                _vm._l(_vm.menuItems, function (ref, index) {
                  var to = ref.to
                  var id = ref.id
                  var text = ref.text
                  var subLinks = ref.subLinks
                  var indicator = ref.indicator
                  return _c("MenuItem", {
                    key: "menu-item-" + index,
                    attrs: {
                      to: to,
                      id: id,
                      text: text,
                      subLinks: subLinks,
                      isMobileMenu: false,
                      indicator: indicator,
                      newTickets: _vm.newTickets,
                    },
                    on: { menuItemClicked: _vm.onMenuItemClicked },
                  })
                }),
                _vm.userRole === "super"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-center items-center",
                        on: { click: _vm.onMenuItemClicked },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center bg-green-900 text-white px-3 py-1 rounded-md cursor-pointer hover:bg-green-800",
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "w-4 h-4",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  "stroke-width": "1.5",
                                  stroke: "currentColor",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    d: "M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "ml-1 text-sm  font-medium " },
                              [_vm._v("CCP")]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "flex items-center" }, [
            _c("div", { staticClass: "flex-shrink-0" }),
            _c(
              "div",
              {
                staticClass:
                  "hidden md:ml-12 md:flex-shrink-0 md:flex md:items-center",
              },
              [
                _c("AccountMenuItem", {
                  attrs: { dropdownItems: _vm.menuItemsAccount },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-drawer",
        {
          staticClass: "z-50 bg-white",
          attrs: {
            size: "100%",
            direction: "ttb",
            modal: false,
            visible: _vm.drawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("HeaderLogo", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          showHeaderLogoAsLink: false,
                          isLoading: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "px-6" },
            _vm._l(_vm.menuItems, function (ref, index) {
              var to = ref.to
              var id = ref.id
              var text = ref.text
              var subLinks = ref.subLinks
              var indicator = ref.indicator
              return _c("MenuItem", {
                key: "menu-item-" + index,
                staticClass: "block py-4",
                attrs: {
                  to: to,
                  id: id,
                  text: text,
                  subLinks: subLinks,
                  isMobileMenu: true,
                  indicator: indicator,
                  newTickets: _vm.newTickets,
                },
                on: { menuItemClicked: _vm.onMenuItemClicked },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "px-6 py-4" },
            [
              _c("AccountMenuItem", {
                attrs: {
                  dropdownItems: _vm.menuItemsAccount,
                  isMobileMenu: true,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }