import { AUTH_LOGOUT_ACTION } from '@/store/action-types'

const pluginAuth = {
  install(Vue, options) {
    Vue.prototype.$authDoLogout = async (clear_all = true) => await options.store.dispatch(AUTH_LOGOUT_ACTION, clear_all),
    Vue.prototype.$wemowActiveUserType = () => options.store.getters['getUserActiveAccountType']
    Vue.prototype.$wemowActiveAccount = () => options.store.getters['getUserActiveAccount']
    Vue.prototype.$wemowUserInfo = () => options.store.getters['getUserInfo']
    Vue.prototype.$wemowActiveAccountId = () => options.store.getters['getUserActiveAccountID']
    Vue.prototype.$wemowIsLoggedIn = () => options.store.getters['isUserLoggedIn']
  },
}

export default pluginAuth