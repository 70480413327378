<template>
  <div class="md:w-full">
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-end h-16 mr-auto">
        <div class="flex">
          <div class="-ml-2 mr-2 flex items-center md:hidden justify-end">
            <!-- Mobile menu button -->
            <div @click="drawer = !drawer" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>
          <div class="hidden md:ml-6 md:flex md:space-x-8">
          <MenuItem
            v-on:menuItemClicked="onMenuItemClicked"
            v-for="({ to, id, text, subLinks, indicator }, index) in menuItems"
            :key="`menu-item-${index}`"
            :to="to"
            :id="id"
            :text="text"
            :subLinks="subLinks"
            :isMobileMenu="false"
            :indicator="indicator"
            :newTickets="newTickets"
          />
          <div
          v-if="userRole === 'super'"
          class="flex justify-center items-center"
            @click="onMenuItemClicked"
          >
          <div class="flex justify-center items-center bg-green-900 text-white px-3 py-1 rounded-md cursor-pointer hover:bg-green-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
          </svg>

          <span class="ml-1 text-sm  font-medium ">CCP</span>
          </div>
 
          
          </div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <!-- <button type="button" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span>New Job</span>
            </button> -->
          </div>
          <div class="hidden md:ml-12 md:flex-shrink-0 md:flex md:items-center">
            <!-- <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">View notifications</span>
            </button> -->
            <AccountMenuItem :dropdownItems="menuItemsAccount" />
          </div>
        </div>
      </div>
    </div>

    <el-drawer
    class="z-50 bg-white"
      size="100%"
      direction="ttb"
      :modal="false"
      :visible.sync="drawer">
      <template v-slot:title>
       <div><HeaderLogo style="width:200px;" :showHeaderLogoAsLink="false" :isLoading="false" /></div>
      </template>

      <div class="px-6">
  
        <MenuItem
          v-on:menuItemClicked="onMenuItemClicked"
v-for="({ to, id, text, subLinks, indicator }, index) in menuItems"
          :key="`menu-item-${index}`"
          :to="to"
          :id="id"
          :text="text"
          :subLinks="subLinks"
          :isMobileMenu="true"
          :indicator="indicator"
          :newTickets="newTickets"
          class="block py-4"
        />
        </div>
      <div class="px-6 py-4">
         <AccountMenuItem :dropdownItems="menuItemsAccount" :isMobileMenu="true" />


</div>
    </el-drawer>
    <!-- <template v-if="['sm'].includes(getScreenSize)">
    <el-button
      @click="drawer = !drawer"
      class="menu-toggle"
      icon="el-icon-more-outline"
      size="mini"
    ></el-button>
    <el-drawer
    class="z-50 bg-white"
      size="100%"
      direction="rtl"
      :modal="false"
      :visible.sync="drawer">
      <template v-slot:title>
        <AccountMenuItem :dropdownItems="menuItemsAccount" :isMobileMenu="true" />
      </template>
      <div class="px-6">

        <MenuItem
          v-on:menuItemClicked="onMenuItemClicked"
          v-for="({ to, id, text, subLinks, indicator }, index) in menuItems"
          :key="`menu-item-${index}`"
          :to="to"
          :id="id"
          :text="text"
          :subLinks="subLinks"
          :isMobileMenu="true"
          :indicator="indicator"
          :newTickets="newTickets"
        />
        </div>
    </el-drawer>
    </template>
    <template v-else>
      <div class="flex items-center">
        <MenuItem
          v-for="({ to, id, text, subLinks, indicator }, index) in menuItems"
          :key="`menu-item-${index}`"
          :to="to"
          :id="id"
          :text="text"
          :subLinks="subLinks"
          :indicator="indicator"
          :newTickets="newTickets"
        />
        <div class="flex items-center justify-between ml-6">
          <NotificationMenuButton class="mr-6" />
          <AccountMenuItem :dropdownItems="menuItemsAccount" />
        </div>
      </div>
    </template> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { get } from "lodash-es";

import { APP_STATE_TOGGLE_MENU } from "@/store/mutation-types";

import { MenuItemsCustomer, AccountMenuCustomer } from "./MenuItemsCustomer";
import { MenuItemsAffiliate, AccountMenuAffiliate } from "./MenuItemsAffiliate";
import { MenuItemsVendor, AccountMenuVendor } from "./MenuItemsVendor";
import {
  MenuItemsOrganization,
  AccountMenuOrganization,
} from "./MenuItemsOrganization";
import { MenuItemsAdmin, AccountMenuAdmin } from "./MenuItemsAdmin";

export default {
  components: {
    MenuItem: () =>
      import("@/components/global/app-menu-horizontal/AppMenuItem"),
    AccountMenuItem: () =>
      import("@/components/global/app-menu/modules/AccountMenuItem"),
    NotificationMenuButton: () =>
      import(
        "@/components/global/notification-menu/NotificationMenuButton.vue"
      ),
      HeaderLogo: () =>
      import("@/components/public/views/parts/HeaderLogo"),
  },
  props: {
    role: {
      type: String,
      default: "none",
    },
  },
  watch: {
    routePath: function() {
      if (["sm"].includes(this.getScreenSize)) {
        // this.closeMenu();
        this.drawer = false;
      }
    },
    // screenSize: function(v){
    //   if(['sm'].includes(v)){
    //     this.closeMenu()
    //   }else{
    //     this.toggleAppMenu(true)
    //   }
    // }
  },
  data: function() {
    return {
      drawer: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    const unlockScroll = { overflow: "auto" };
    Object.assign(document.body.style, unlockScroll);
    document.removeEventListener("keyup", this.escToCloseMenu);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations({
      toggleAppMenu: APP_STATE_TOGGLE_MENU,
    }),
    onMenuItemClicked(){
    if(this.userRole === "super"){
      var y = window.top.outerHeight / 2 + window.top.screenY - 400 / 2;
    var x = window.top.outerWidth / 2 + window.top.screenX - 500 / 2;
    window
      .open(
        process.env.VUE_APP_CCP_URL,
        "CCP",
        "width=1140,height=800,resizable=no,top=" + y + ",left=" + x
      )
      .focus();
    }

    },
    closeMenu() {
      this.toggleAppMenu(false);
    },
    escToCloseMenu(e) {
      if (this.showMenu && e.keyCode === 27) {
        this.closeMenu();
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  computed: {
    menuItems() {
      switch (this.userRole) {
        case "customer":
          return MenuItemsCustomer;
        case "affiliate":
          return MenuItemsAffiliate;
        case "vendor":
          return MenuItemsVendor;
        case "organization":
          return MenuItemsOrganization;
        case "super":
          return MenuItemsAdmin;
        default:
          return [];
      }
    },
    menuItemsAccount() {
      switch (this.userRole) {
        case "customer":
          return AccountMenuCustomer;
        case "affiliate":
          return AccountMenuAffiliate;
        case "vendor":
          return AccountMenuVendor;
        case "organization":
          return AccountMenuOrganization;
        case "super":
          return AccountMenuAdmin;
        default:
          return [];
      }
    },
    ...mapGetters({
      showMenu: "isMenuOpen",
      screenSize: "getScreenSize",
    }),
    routePath() {
      return get(this.$route, "path", "");
    },
    getScreenSize() {
      return this.$store.getters["getScreenSize"];
    },
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
    activeAccount: function() {
      return this.$store.getters.getUserActiveAccount;
    },
    newTickets() {
      switch (this.userRole) {
        case "customer":
          return this.$store.getters["ticket/getNewTicketsCount"](this.activeAccount._id);
        case "vendor":
        case "organization":
        case "super":
          return this.$store.getters["ticket/getOrgAndVendorNewTicketsCount"];
        default:
          return 0;
      }
    },
  },
};
</script>