<template web>
<div>
  <PublicScreenNotReady />
  <div v-show="false">
    <slot name="body"></slot>
  </div>
</div>
</template> 

<script>
import PublicScreenNotReady from "@/components/public/views/PublicScreenNotReady"
import { mapState } from "vuex";
import { version } from '../../../../package.json'

export default {
  components: {
    PublicScreenNotReady
  },
  data(){
    return {
      appVersion: version,
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "1240px",
    },
    maxWidthHeader: {
      type: String,
    },
    maxWidthContent: {
      type: String,
    },
    maxWidthFooter: {
      type: String,
    },
    showHeaderLogoAsLink: {
      type: Boolean,
      default: true,
    },
    headerClasses: {
      type: String,
      default: ""
    },
    headerRightClasses: {
      type: String,
      default: ""
    },
    headerLogoClass: {
      type: String,
      default: ""
    },
    showMobileHeaderPhone: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      asyncComponentLoading: state => state.app.asyncComponentLoading
    }),
  }
};
</script>

<style scoped>
#background-stripe {
  background-image: linear-gradient(210deg, #068942 20%, #3fbb68 86%);
  border-bottom-color: rgb(0, 0, 0);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
}

#background-stripe-background {
  /* background-image: url(); */
  background-position-x: center;
  background-position-y: -60px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  height: 25%;
  max-height: 500px;
  margin-left: -37px;
  margin-right: -37px;
  width: calc(100% + 74px);
}

#background-stripe-inner {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 320px;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  #background-stripe-inner {
    min-width: 768px;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  #background-stripe-inner {
    min-width: 1024px;
    max-width: 100%;
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media (min-width: 1280px) {
  #background-stripe-inner {
    min-width: 1280px;
    max-width: 1280px;
    padding-left: 37px;
    padding-right: 37px;
  }
}

.big-check {
  font-size: 60px;
  background: #03733d;
  color: #fff;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link-style {
  color: blue;
}

.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.fade-enter-active #register-sidebar {
  transition: all 0.3s ease;
}
.fade-leave-active #register-sidebar {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-sidebar,
.fade-leave-to #register-sidebar {
  transform: translateX(-500px);
  opacity: 0;
}

.fade-enter-active #register-area {
  transition: all 0.3s ease;
}
.fade-leave-active #register-area {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-area,
.fade-leave-to #register-area {
  transform: translateX(500px);
  opacity: 0;
}

.pulse {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
