export const AUTH_LOGOUT = `AUTH_LOGOUT`
export const AUTH_LOGIN = `AUTH_LOGIN`

export const CLEAR_APP_STATE_STORE = `CLEAR_APP_STATE_STORE`
export const APP_STATE_TOGGLE_MENU = `APP_STATE_TOGGLE_MENU`
export const APP_STATE_TOGGLE_NOTIFICATIONS = `APP_STATE_TOGGLE_NOTIFICATIONS`
export const APP_STATE_TOGGLE_LOADING = `APP_STATE_TOGGLE_LOADING`
export const APP_MUTATE_STATE = `APP_MUTATE_STATE`
export const APP_MUTATE_UNIVERSAL_LINK = `APP_MUTATE_UNIVERSAL_LINK`
export const APP_CLEAR_UNIVERSAL_LINKS = `APP_CLEAR_UNIVERSAL_LINKS`
export const APP_MUTATE_PUSH_DATA = `APP_MUTATE_PUSH_DATA`
export const APP_CLEAR_PUSH_DATA = `APP_CLEAR_PUSH_DATA`
export const APP_STATE_WEB_CONTENT_WIDTH = `APP_STATE_WEB_CONTENT_WIDTH`;
export const APP_SET_ASYNC_COMPONENT_LOADING = `APP_SET_ASYNC_COMPONENT_LOADING`
export const APP_STATE_NAVIGATED_TO_ANOTHER_APP = `APP_STATE_NAVIGATED_TO_ANOTHER_APP`
export const APP_STATE_CURRENT_PAGE_ID = `APP_STATE_CURRENT_PAGE_ID`
export const APP_STATE_SET_LOCATION_WATCH_ID = `APP_STATE_SET_LOCATION_WATCH_ID`
export const APP_SET_WINDOW = `APP_SET_WINDOW`
export const APP_SET_SCROLL = `APP_SET_SCROLL`

export const USER_SET_ACTIVE_ACCOUNT = `USER_SET_ACTIVE_ACCOUNT`
export const UPDATE_USER_ACCOUNT = `UPDATE_USER_ACCOUNT`
export const SETUP_USER_DATA = `SETUP_USER_DATA`
export const SETUP_USER_ACCOUNTS = `SETUP_USER_ACCOUNTS`
export const UPDATE_USER_ACCOUNTS = `UPDATE_USER_ACCOUNTS`
export const REMOVE_USER_ACCOUNT_BY_ID = `REMOVE_USER_ACCOUNT_BY_ID`
export const CLEAR_USER_STORE = `CLEAR_USER_STORE`
export const SET_USER_EMAIL = `SET_USER_EMAIL`
export const SET_USER_STORE_STATUS = `SET_USER_STORE_STATUS`
export const SET_SWITCHING_ACCOUNT_STATUS = `SET_SWITCHING_ACCOUNT_STATUS`

export const SETUP_ALL_SERVICES = `SETUP_ALL_SERVICES`

export const TICKETS_SET_TICKETS = `TICKETS_SET_TICKETS`
export const TICKET_RESET_STORE = `TICKET_RESET_STORE`
export const TICKETS_SET_RESPONSES = `TICKETS_SET_RESPONSES`
export const TICKETS_REMOVE_FROM_STORE = `TICKETS_REMOVE_FROM_STORE`
export const TICKETS_SET_TOTAL_COUNT = `TICKETS_SET_TOTAL_COUNT`
export const CUSTOMER_ALL_HELPDESK_TICKETS = `CUSTOMER_ALL_HELPDESK_TICKETS`
export const CUSTOMER_UPDATE_HELPDESK_TICKETS = `CUSTOMER_UPDATE_HELPDESK_TICKETS`
export const SET_CUSTOMER_AFFILIATES = `SET_CUSTOMER_AFFILIATES`
export const SET_CUSTOMER_AFFILIATE_CONTRIBUTIONS = `SET_CUSTOMER_AFFILIATE_CONTRIBUTIONS`
export const TICKETS_SET_PAST_TOTAL_COUNT = `TICKETS_SET_PAST_TOTAL_COUNT`
export const CLEAR_TICKETS_DATA = `CLEAR_TICKETS_DATA`
export const SET_CUSTOMER_OPEN_INVOICES_COUNT = `SET_CUSTOMER_OPEN_INVOICES_COUNT`
export const DECREMENT_CUSTOMER_OPEN_INVOICES_COUNT = `DECREMENT_CUSTOMER_OPEN_INVOICES_COUNT`
export const TICKETS_SET_ACTIVE_TOTAL_COUNT = `TICKETS_SET_ACTIVE_TOTAL_COUNT`

export const SET_CACHED_IMAGE_SRC = `SET_CACHED_IMAGE_SRC`

export const CART_ADD_ITEM = `CART_ADD_ITEM`
export const CART_REMOVE_ITEM = `CART_REMOVE_ITEM`
export const CART_EMPTY_ALL = `CART_EMPTY_ALL`

export const CUSTOMER_APPOINTMENT_GET_TRASH = `CUSTOMER_APPOINTMENT_GET_TRASH`
export const CUSTOMER_SUBSCRIPTION_GET_TRASH = `CUSTOMER_SUBSCRIPTION_GET_TRASH`
export const SET_CUSTOMER_APPOINTMENTS = `SET_CUSTOMER_APPOINTMENTS`
export const SET_CUSTOMER_SUBSCRIPTIONS = `SET_CUSTOMER_SUBSCRIPTIONS`
export const SET_ONE_CUSTOMER_SUBSCRIPTION = `SET_ONE_CUSTOMER_SUBSCRIPTION`
export const REMOVE_APPOINTMENTS_BY_SUBSCRIPTION_ID = `REMOVE_APPOINTMENTS_BY_SUBSCRIPTION_ID`
export const SET_REMOVE_SUBSCRIPTION = `SET_REMOVE_SUBSCRIPTION`
export const SET_CUSTOMER_REMOVE_AFFILIATE = `SET_CUSTOMER_REMOVE_AFFILIATE`
export const CLEAR_CUSTOMER_STORE = `CLEAR_CUSTOMER_STORE`
export const CUSTOMER_SET_INVOICES = `CUSTOMER_SET_INVOICES`
export const CUSTOMER_INVOICE_DELETE = `CUSTOMER_INVOICE_DELETE`
export const SET_EDITING_SUBSCRIPTION = `SET_EDITING_SUBSCRIPTION`
export const CUSTOMER_SET_LOADED_DASHBOARD_INIT = `CUSTOMER_SET_LOADED_DASHBOARD_INIT`

export const AFFILIATE_MUTATE_ID = `AFFILIATE_MUTATE_ID`
export const AFFILIATE_MUTATE_CONTRIBUTORS = `AFFILIATE_MUTATE_CONTRIBUTORS`
export const AFFILIATE_MUTATE_BALANCE = `AFFILIATE_MUTATE_BALANCE`
export const AFFILIATE_ADD_TRANSFERS = `AFFILIATE_ADD_TRANSFERS`
export const AFFILIATE_MUTATE_PAYMENT_TRANSFERS = `AFFILIATE_MUTATE_PAYMENT_TRANSFERS`
export const AFFILIATE_MUTATE_BANK_ACCOUNT = `AFFILIATE_MUTATE_BANK_ACCOUNT `
export const AFFILIATE_SET_LOADED_DASHBOARD_INIT = `AFFILIATE_SET_LOADED_DASHBOARD_INIT`


export const SET_VENDOR_BALANCES = `SET_VENDOR_BALANCES`
export const SET_VENDOR_HELPDESK_TICKETS = `SET_VENDOR_HELPDESK_TICKETS`
export const VENDOR_MUTATE_PAYMENT_TRANSFERS = `VENDOR_MUTATE_PAYMENT_TRANSFERS`
export const VENDOR_SET_LOADED_DASHBOARD_INIT = `VENDOR_SET_LOADED_DASHBOARD_INIT`
export const CLEAR_VENDORS_DATA = `CLEAR_VENDORS_DATA`
export const SET_APPOINTMENT_DATES = `SET_APPOINTMENT_DATES`

export const ORGANIZATION_SET_VENDORS = `ORGANIZATION_SET_VENDORS`
export const ORGANIZATION_TRASH_VENDOR = `ORGANIZATION_TRASH_VENDOR`
export const ORGANIZATION_UPDATE_VENDOR = `ORGANIZATION_UPDATE_VENDOR`
export const ORGANIZATION_EDIT_VENDOR = `ORGANIZATION_EDIT_VENDOR`
export const ORGANIZATION_VENDOR_DELETE = `ORGANIZATION_VENDOR_DELETE`
export const ORGANIZATION_VIEW_APPOINTMENT = `ORGANIZATION_VIEW_APPOINTMENT`
export const ORGANIZATION_EDIT_CUSTOMER = `ORGANIZATION_EDIT_CUSTOMER`
export const SET_ORGANIZATION_UPCOMING_APPOINTMENTS = `SET_ORGANIZATION_UPCOMING_APPOINTMENTS`
export const SET_ZIP_CODES = `SET_ZIP_CODES`
export const ORGANIZATION_MUTATE_PAYMENT_TRANSFERS = `ORGANIZATION_MUTATE_PAYMENT_TRANSFERS`
export const ORGANIZATION_SET_PAYOUTS = `ORGANIZATION_SET_PAYOUTS`
export const ORGANIZATION_SET_PAYOUT_LINKS = `ORGANIZATION_SET_PAYOUT_LINKS`
export const ORGANIZATION_SET_PAYOUT_SUMMARY = `ORGANIZATION_SET_PAYOUT_SUMMARY`
export const ORGANIZATION_SET_TRANSFER_SUMMARY = `ORGANIZATION_SET_TRANSFER_SUMMARY`
export const ORGANIZATION_DROP_VENDORS = `ORGANIZATION_DROP_VENDORS`
export const CLEAR_ORGANIZATION_STORE = `CLEAR_ORGANIZATION_STORE`
export const ORGANIZATION_GET_INVOICES = `ORGANIZATION_GET_INVOICES`
export const ORGANIZATION_INVOICE_DELETE = `ORGANIZATION_INVOICE_DELETE`
export const ORGANIZATION_SET_INVOICES = `ORGANIZATION_SET_INVOICES`
export const ORGANIZATION_SET_INVOICE = `ORGANIZATION_SET_INVOICE`
export const ORGANIZATION_SET_LOADED_DASHBOARD_INIT = `ORGANIZATION_SET_LOADED_DASHBOARD_INIT`

export const APPOINTMENTS_LOAD_SET = `APPOINTMENTS_LOAD_SET`
export const APPOINTMENTS_SET_PAST_COUNT = `APPOINTMENTS_SET_PAST_COUNT`
export const ADDON_DELETE = `ADDON_DELETE`
export const SET_ACTIVE_NOTIFICATION = `SET_ACTIVE_NOTIFICATION`
export const SET_NOTIFICATIONS = `SET_NOTIFICATIONS`
export const CLEAR_NOTIFICATIONS_STORE = `CLEAR_NOTIFICATIONS_STORE`
export const SET_ALERTS_COUNT = `SET_ALERTS_COUNT`
export const SET_ALERT_NOTIFICATIONS = `SET_ALERT_NOTIFICATIONS`
export const DECREMENT_ALERTS_COUNT = `DECREMENT_ALERTS_COUNT`
export const SET_VENDOR_TODAY_APPOINTMENTS = `SET_VENDOR_TODAY_APPOINTMENTS`
export const REPLACE_SORTED_APPOINTMENTS = `REPLACE_SORTED_APPOINTMENTS`
export const SET_NEW_SUBSCRIPTIONS = `SET_NEW_SUBSCRIPTIONS`
export const VENDOR_SET_INVOICES = `VENDOR_SET_INVOICES`
export const VENDOR_SET_INVOICE = `VENDOR_SET_INVOICE`
export const VENDOR_INVOICE_DELETE = `VENDOR_INVOICE_DELETE`

export const CLEAR_APPOINTMENTS_STORE = `CLEAR_APPOINTMENTS_STORE`
export const UPDATE_APPOINTMENT_ADDRESS_CHECKLIST = `UPDATE_APPOINTMENT_ADDRESS_CHECKLIST`
export const SET_APPOINTMENT_CHECKLIST_QUESTIONS = `SET_APPOINTMENT_CHECKLIST_QUESTIONS`
export const MUTATE_MODAL_STATE = `MUTATE_MODAL_STATE`
export const TOGGLE_MODAL_LOADING_STATE = `TOGGLE_MODAL_LOADING_STATE`

export const SET_ORDER = `SET_ORDER`;
export const SET_ORDER_ITEM = `SET_ORDER_ITEM`;
export const SET_ORDER_DEFAULTS = `SET_ORDER_DEFAULTS`;

export const SET_SELECTED_SEARCH_VALUE = `SET_SELECTED_SEARCH_VALUE`;

//list_view_mutations
export const SET_LIST_VIEW_MODULE_STATE = "SET_LIST_VIEW_MODULE_STATE"
export const SET_ACTIVE_TABLE_COLUMNS = "SET_ACTIVE_TABLE_COLUMNS"
export const RESET_LIST_VIEW_MODULE_STORE = "RESET_LIST_VIEW_MODULE_STORE"

export const CLEAR_AFFILIATES_DATA = `CLEAR_AFFILIATES_DATA`

export const SET_ADD_ON_OPTIONS = `SET_ADD_ON_OPTIONS`
export const CUSTOMER_SET_PUBLIC_AFFILIATES = `CUSTOMER_SET_PUBLIC_AFFILIATES`
export const SET_CUSTOMER_HAS_DONE_INIT = `SET_CUSTOMER_HAS_DONE_INIT`
export const SUBSCRIPTION_REMOVE_ADD_ON = `SUBSCRIPTION_REMOVE_ADD_ON`


export const COMMUNICATION_GET_ALL = `COMMUNICATION_GET_ALL`;
export const COMMUNICATION_EDIT = `COMMUNICATION_EDIT`;
export const COMMUNICATION_UPDATE_ONE = `COMMUNICATION_UPDATE_ONE`;
export const COMMUNICATION_GET_TRASH = `COMMUNICATION_GET_TRASH`;
export const COMMUNICATION_DELETED = `COMMUNICATION_DELETED`;
export const COMMUNICATION_DROP_COMMUNICATIONS = `COMMUNICATION_DROP_COMMUNICATIONS`;
export const COMMUNICATION_SET_COMMUNICATION_LINKS = `COMMUNICATION_SET_COMMUNICATION_LINKS`;
export const TRANSFERS_SET_QUERY_LINKS = `TRANSFERS_SET_QUERY_LINKS`

export const SET_STATE = `SET_STATE`
export const SET_CUSTOMER_SESSION = `SET_CUSTOMER_SESSION`
export const ON_CONNECTION_ESTABLISHED = `ON_CONNECTION_ESTABLISHED`
export const ON_MESSAGE = `ON_MESSAGE`
export const SET_ALL_MESSAGES = `SET_ALL_MESSAGES`
export const ON_TYPING = `ON_TYPING`
export const ON_CONNECTION_BROKEN = `ON_CONNECTION_BROKEN`
export const END_CHAT_MUTATION = `END_CHAT_MUTATION`
export const CLEAR_MESSAGES = `CLEAR_MESSAGES`
export const SET_SUBSCRIPTIONS = `SET_SUBSCRIPTIONS`
