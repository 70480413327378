<template>
  <CustomDropdownRoot
    :dropDownItems="dropDownItems"
    :selectedValue="selectedValue"
    :preventPopover="preventPopover"
    @onClickPlaceholder="$emit('onClickPlaceholder', $event)"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @customAction="$emit('customAction', $event)"
  >
    <template v-slot:labelHasValue="{ selected }">
      <div class="flex items-center justify-start" >
        <div class="flex justify-between items-center"  v-if="selected && selected.data">
          <div>
              <div>
                  <span class="text-sm font-medium">{{selected.data.service}}</span> <span class="text-xs text-gray-600 ml-3">{{selected.data.status}}</span>
              </div>
              <div class="text-gray-900">{{ getDisplayDate(selected.data.start_time) }}</div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:labelNoValue>Select an Appointment</template>
    
    <template v-slot:innerDropdownItem="{ item }">
      <div class="flex justify-start items-center">
        <div class="flex justify-between items-center flex-grow">
          <div>
            <div>
                <span class="text-sm font-medium">{{item.data.service}}</span> <span class="text-xs text-gray-600 ml-3">{{item.data.status}}</span>
            </div>
            <div>{{ getDisplayDate(item.data.start_time) }}</div>
          </div>

        </div>

      </div>
    </template>
  </CustomDropdownRoot>
</template>
      
<script>
import {
  DateFormatMixin
} from '@/mixins/DateFormat.js'
import CustomDropdownRoot from "./CustomDropdownRoot";
export default {
  name: "customDropdownPayment",
  mixins: [DateFormatMixin],
  components: { CustomDropdownRoot },
  props: {
    selectedValue: {
      type: [Array, Object, String, Number, Date],
      default() {
        return null;
      },
    },
    dropDownItems: {
      type: [Array],
      default() {
        return null;
      },
    },
    preventPopover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getDisplayDate(d){
      return this.$_dateFullNiceWithDay(d)
    }
  }
};
</script>
