var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.buttonClasses,
      attrs: { type: "submit", disabled: _vm.isDisabled },
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.icon && _vm.icon === "download"
        ? _c(
            "svg",
            {
              staticClass: "h-4 w-4 ml-1",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 20 20",
                fill: "currentColor",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d: "M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
                  "clip-rule": "evenodd",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }