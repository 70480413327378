import store from "@/store/store";

export const resolveHomeRoute = async (toRoute, fromRoute, next) => {
  const { path, query } = toRoute;
  const { redirectFrom } = fromRoute.query;
  if (redirectFrom && path !== redirectFrom) {
    if (['/auth/', '/logout', '/login', '/register', '/register-organization','/register-quick','/register-affiliate', '/register-customer-quick'].includes(toRoute.path)) {
      return next()
    }
    return next(redirectFrom);
  }

  if (query.redirectTo) {
    return next(query.redirectTo);
  }

  if(!store.getters["isUserLoggedIn"]){
    return next()
  }

  const role = store.getters["getUserRole"];

  switch (role) {
    case "super":
      return next("/admin");
    case "vendor":
      return next("/vendor/appointments");
    case "customer":
      return next("/dashboard");
    case "affiliate":
      return next("/affiliate");
    case "organization":
      return next("/organization/appointments");
  }

  next(new Error("Unknown role"));
};
