<template>
    <div :class="width">
        <div class="rounded-lg w-full" :class="backgroundColor">
            <p :class="textClass" class="text-center" style="font-size:10px;">{{tagText}}</p>
        </div>
    </div>
</template> 

<script>
export default {
    props:{
        tagText:{
            type: String,
            required: true
        },
        textClass:{
            type: String,
            default: ""
        },
        backgroundColor:{
            type: String,
            default: ""
        },
        width:{
            type: String,
            default: "w-20"
        }
    }
}
</script>