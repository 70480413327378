<template>
  <component
    :is="getDropdownComponent"
    :selectedValue="selectedValue"
    :dropDownItems="dropDownItems"
    :disabled="disabled"
    :preventPopover="preventPopover"
    @onClickPlaceholder="$emit('onClickPlaceholder', $event)"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @customAction="onCustomAction"
  >
    <template v-slot:labelHasValue="{ selected }">
             <!-- {{selected}} -->
      <span class="text-gray-900 text-lg"
        >{{ selected.label }}</span
      >
    </template>
    <template v-slot:labelNoValue>{{ labelNoValue }}</template>
    <template v-slot:innerDropdownItem="{ item }">
      <div class="flex justify-between items-center">
        <span class="text-lg">{{ item.label }}</span>
        <span class="text-xs">{{ item.sub }}</span>
      </div>
    </template>

  </component>
</template>

<script>
import { get } from "lodash-es"
import CustomDropdownRoot from "./CustomDropdownTemplates/CustomDropdownRoot.vue";
import Payment from "./CustomDropdownTemplates/Payment.vue";
import AddOns from "./CustomDropdownTemplates/AddOns.vue";
import SubscriptionFrequency from "./CustomDropdownTemplates/SubscriptionFrequency.vue";
import SubscriptionService from "./CustomDropdownTemplates/SubscriptionService.vue";
import CustomerAppointments from "./CustomDropdownTemplates/CustomerAppointments.vue";

export default {
  components: {
    CustomDropdownRoot,
  },
  props: {
    template: {
      type: String,
      default: "none"
    },
    selectedValue: {
      type: [Array, Object, String, Number, Date],
      default() {
        return null;
      },
    },
    dropDownItems: {
      type: [Array],
      default() {
        return null;
      },
    },
    labelNoValue: {
      type: String,
      default: "Select"
    },
    preventPopover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    onCustomAction($event){
      this.$emit('customAction', $event)
    }
  },
  computed: {
    getDropdownComponent() {
      const componentMap = {
        none: CustomDropdownRoot,
        payment: Payment,
        addOn: AddOns,
        customerAppointments: CustomerAppointments,
        frequency: SubscriptionFrequency,
        subscriptionService: SubscriptionService
      }
      return get(componentMap, this.template, this.template['none'])
    },
  },
};
</script>
