var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4" }, [
    _c("div", { staticClass: "mb-4" }, [
      _c(
        "div",
        { staticClass: "border bg-gray-100 bg-opacity-50 p-4 relative" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.displayData.shareText) +
              " " +
              _vm._s(_vm.displayData.shareLink) +
              " "
          ),
          _c(
            "div",
            {
              staticClass:
                "absolute flex items-center justify-center bottom-0 right-0 cursor-pointer bg-gray-100",
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-xs text-green-900 p-2",
                  on: { click: _vm.copyText },
                },
                [_vm._v(_vm._s(_vm.copiedText ? "Copied!" : "Copy"))]
              ),
            ]
          ),
        ]
      ),
    ]),
    _c("div", [
      _c("div", { staticClass: "font-medium mb-3" }, [_vm._v("Share a link")]),
      _c(
        "div",
        { staticClass: "border bg-gray-100 bg-opacity-50 p-4 relative" },
        [
          _vm._v(" " + _vm._s(_vm.displayData.shareLink) + " "),
          _c(
            "div",
            {
              staticClass:
                "absolute flex items-center justify-center bottom-0 right-0 cursor-pointer bg-gray-100",
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-xs text-green-900 p-2",
                  on: { click: _vm.copyLink },
                },
                [_vm._v(_vm._s(_vm.copiedLink ? "Copied!" : "Copy"))]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }