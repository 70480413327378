var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      attrs: { placement: "top", width: "340", trigger: "manual" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        _vm.element,
        {
          tag: "component",
          staticClass: "inline-flex items-center",
          attrs: { slot: "reference" },
          on: {
            mouseover: _vm.onMouseOver,
            mouseleave: _vm.onMouseOut,
            mousedown: _vm.onMouseDown,
            mouseup: _vm.onMouseUp,
            click: _vm.onClickItem,
          },
          slot: "reference",
        },
        [
          !_vm.iconBefore
            ? _c(
                "span",
                {
                  staticClass: "cursor-pointer",
                  class: _vm.textClass,
                  on: { click: _vm.onClickGoTo },
                },
                [
                  _vm.text
                    ? _c("span", [_vm._v(_vm._s(_vm.text))])
                    : _c("span", [_vm._v("No Title")]),
                ]
              )
            : _vm._e(),
          !_vm.disable
            ? _c(
                "span",
                {
                  staticClass:
                    "w-4 h-4 flex items-center justify-center cursor-pointer",
                  class: _vm.iconBefore ? "mr-1" : "ml-1",
                  on: {
                    mouseover: _vm.onMouseOverIcon,
                    mouseleave: _vm.onMouseOutIcon,
                  },
                },
                [
                  _vm.loadingResource
                    ? _c("font-awesome-icon", {
                        staticClass: "fa-spin text-xs h-4 w-4",
                        attrs: { icon: ["fa", "spinner"] },
                      })
                    : _vm._e(),
                  _c(
                    "svg",
                    {
                      staticClass: "h-4 w-4",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("path", {
                        attrs: { d: "M10 12a2 2 0 100-4 2 2 0 000 4z" },
                      }),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z",
                          "clip-rule": "evenodd",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.iconBefore
            ? _c(
                "span",
                {
                  staticClass: "cursor-pointer",
                  class: _vm.textClass,
                  on: { click: _vm.onClickGoTo },
                },
                [
                  _vm.text
                    ? _c("span", [_vm._v(_vm._s(_vm.text))])
                    : _c("span", [_vm._v("No Title")]),
                ]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
      _c(_vm.getTemplateComponent, {
        tag: "component",
        attrs: { resource: _vm.resourceData, to: _vm.to },
        on: {
          close: function ($event) {
            _vm.visible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }