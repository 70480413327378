const {
  VUE_APP_ENV,
  VUE_APP_GOOGLE_ANALYTICS_ID,
  VUE_APP_ENABLE_GOOGLE_ANALYTICS = false
} = process.env;

import Vue from 'vue'

import VueSocketio from "vue-socket.io-extended";
import socket from "@/services/web/SocketIO";

require('./lib/web/bugsnag')

import '@/assets/web/css/theme-defaults.scss'
import elementUIEnglish from 'element-ui/lib/locale/lang/en'
import elementUISpanish from 'element-ui/lib/locale/lang/es'
import ElementLocale from 'element-ui/lib/locale'
require('./assets/web/css/style.css')
import getText from "@/plugins/getText.js";
import i18n from "@/plugins/i18n";
import globalMixin from "@/mixins/GlobalMixin"
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});


// configure language
i18n.mergeLocaleMessage('en', elementUIEnglish)
i18n.mergeLocaleMessage('es', elementUISpanish)
ElementLocale.i18n((key, value) => i18n.t(key, value))


Vue.mixin(globalMixin);


import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'
Vue.use(GSignInButton)
Vue.use(FBSignInButton)
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  // Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  // Progress,
  // Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  // Collapse,
  // CollapseItem,
  // Cascader,
  ColorPicker,
  // Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  // Link,
  // Divider,
  // Image,
  // Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  Drawer,
  Loading,
  MessageBox,
  Message,
  Notification,
  Avatar
} from 'element-ui';

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
// Vue.use(Breadcrumb);
// Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
// Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
// Vue.use(Progress);
// Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
// Vue.use(Cascader);
Vue.use(ColorPicker);
// Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
// Vue.use(Divider);
// Vue.use(Image);
// Vue.use(Calendar);
// Vue.use(Backtop);
// Vue.use(PageHeader);
// Vue.use(CascaderPanel);
Vue.use(Avatar)
Vue.use(getText);
Vue.use(Loading.directive);
Vue.use(Drawer)
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faFilter as freeSolidFaFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons/faGripHorizontal'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faCheck as freeFaCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTrashAlt as freeSolidFaTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons/faCalendarWeek'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons/faCalendarDay'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faAsterisk as freeSolidFaAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk'
import { faSignOutAlt as freeSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF.js'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn'
import { faRetweet } from '@fortawesome/free-solid-svg-icons/faRetweet'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faCreditCard as faFreeSolidCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons/faPauseCircle'
import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons/faCloudShowersHeavy'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff'
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift'
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot'


import { faSignOutAlt } from '@/assets/@fortawesome/pro-light-svg-icons/faSignOutAlt'
import { faUser } from '@/assets/@fortawesome/pro-light-svg-icons/faUser'
import { faUsersCog } from '@/assets/@fortawesome/pro-light-svg-icons/faUsersCog'
import { faUserHeadset } from '@/assets/@fortawesome/pro-light-svg-icons/faUserHeadset'
import { faHome } from '@/assets/@fortawesome/pro-light-svg-icons/faHome'
import { faReceipt } from '@/assets/@fortawesome/pro-light-svg-icons/faReceipt'
import { faCreditCard } from '@/assets/@fortawesome/pro-light-svg-icons/faCreditCard'
import { faChartLine } from '@/assets/@fortawesome/pro-light-svg-icons/faChartLine'
import { faLifeRing } from '@/assets/@fortawesome/pro-light-svg-icons/faLifeRing'
import { faCalendarAlt } from '@/assets/@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faCalendarEdit } from '@/assets/@fortawesome/pro-light-svg-icons/faCalendarEdit'
import { faClipboardList } from '@/assets/@fortawesome/pro-light-svg-icons/faClipboardList'
import { faFileInvoiceDollar } from '@/assets/@fortawesome/pro-light-svg-icons/faFileInvoiceDollar'
import { faWrench } from '@/assets/@fortawesome/pro-light-svg-icons/faWrench'
import { faMegaphone } from '@/assets/@fortawesome/pro-light-svg-icons/faMegaphone'
import { faFingerprint } from '@/assets/@fortawesome/pro-light-svg-icons/faFingerprint'
import { faBell } from '@/assets/@fortawesome/pro-light-svg-icons/faBell'
import { faExclamation } from "@/assets/@fortawesome/pro-light-svg-icons/faExclamation";
import { faMailBulk } from "@/assets/@fortawesome/pro-light-svg-icons/faMailBulk";
import { faEnvelopeOpenText } from "@/assets/@fortawesome/pro-light-svg-icons/faEnvelopeOpenText";
import { faSms } from "@/assets/@fortawesome/pro-light-svg-icons/faSms";
import { faChartArea } from "@/assets/@fortawesome/pro-light-svg-icons/faChartArea";
import { faChartBar } from "@/assets/@fortawesome/pro-light-svg-icons/faChartBar";
import { faSortDown } from "@/assets/@fortawesome/pro-light-svg-icons/faSortDown";
import { faSortUp } from "@/assets/@fortawesome/pro-light-svg-icons/faSortUp";
import { faColumns } from '@/assets/@fortawesome/pro-light-svg-icons/faColumns';
import { faFilter } from '@/assets/@fortawesome/pro-light-svg-icons/faFilter';
import { faGavel } from '@/assets/@fortawesome/pro-light-svg-icons/faGavel';
import { faAsterisk } from '@/assets/@fortawesome/pro-light-svg-icons/faAsterisk';
import { faWindowClose } from '@/assets/@fortawesome/pro-light-svg-icons/faWindowClose';
import { faTrashAlt } from "@/assets/@fortawesome/pro-light-svg-icons/faTrashAlt";
import { faCheck } from '@/assets/@fortawesome/pro-light-svg-icons/faCheck'
import { faTicketAlt } from '@/assets/@fortawesome/pro-light-svg-icons/faTicketAlt'
import { faMapMarkerAlt } from '@/assets/@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faFlag } from '@/assets/@fortawesome/pro-light-svg-icons/faFlag'
import { faCalendarCheck, faCamera, faCaretUp, faComment, faDesktop, faDollarSign, faExchangeAlt, faLocationArrow, faPaperclip, faPhone, faQuoteLeft, faShieldAlt, faWifi, faDownload } from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple, faAws } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight, faEllipsisV, faFile, faMicrophone, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

const icons = [
  faUserCircle,
  faSignOutAlt,
  faTimes,
  faPlus,
  faPencilAlt,
  faFilter,
  faSpinner,
  faGripHorizontal,
  faList,
  faCheck,
  faTrashAlt,
  faChevronRight,
  faChevronDown,
  faEye,
  faEyeSlash,
  faSync,
  faCalendarWeek,
  faCalendarDay,
  faClock,
  faAngleRight,
  faAngleLeft,
  faStar,
  faSearch,
  faAsterisk,
  faUser,
  faUsersCog,
  faHome,
  faUserHeadset,
  faReceipt,
  faCreditCard,
  faChartLine,
  faLifeRing,
  faCalendarAlt,
  faCalendarEdit,
  faFileInvoiceDollar,
  faMegaphone,
  faClipboardList,
  faWrench,
  faFingerprint,
  faBell,
  faExclamation,
  faMailBulk,
  faEnvelopeOpenText,
  faSms,
  faChartBar,
  faChartArea,
  faSortUp,
  faSortDown,
  faColumns,
  faGavel,
  faWindowClose,
  freeFaCheck,
  freeSignOutAlt,
  freeSolidFaAsterisk,
  freeSolidFaFilter,
  freeSolidFaTrashAlt,
  faFacebookF,
  faInfoCircle,
  faTicketAlt,
  faMapMarkerAlt,
  faFlag,
  faExclamationTriangle,
  faToggleOn,
  faRetweet,
  faTag,
  faFreeSolidCreditCard,
  faPauseCircle,
  faCloudShowersHeavy,
  faPlusCircle,
  faPowerOff,
  faGift,
  faRobot,
  faCaretUp,
  faDesktop,
  faApple,
  faAndroid,
  faShieldAlt,
  faDollarSign,
  faCalendarCheck,
  faLocationArrow,
  faWifi,
  faQuoteLeft,
  faExchangeAlt,
  faEllipsisV,
  faArrowRight,
  faMicrophone,
  faFile,
  faClock,
  faCaretUp,
  faMailBulk,
  faPhone,
  faSms,
  faAws,
  faCamera,
  faComment,
  faPaperclip,
  faDownload,
  faMoneyBill
];

library.add(...icons)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


import App from './App'
import router from './router/web'

import store from './store/store'
import {
  sync
} from 'vuex-router-sync'

import pluginAuth from './plugins/pluginAuth.js'

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.use(pluginAuth, {
  store: store,
  router: router
})

import pluginPush from './plugins/pluginPush.js'
Vue.use(pluginPush, {
  store: store,
  router: router
})

import pluginAlertNotification from './plugins/pluginAlertNotification.js'
Vue.use(pluginAlertNotification, {
  store: store,
  router: router
})


import pluginWemowDialog from './plugins/pluginWemowDialog.js'
Vue.use(pluginWemowDialog, {
  store: store,
  router: router
})

import pluginWemowDialogFull from './plugins/pluginWemowDialogFull.js'
Vue.use(pluginWemowDialogFull, {
  store: store,
  router: router
})

import {
  cloneDeep,
  isUndefined,
  isBoolean,
  isError,
  isEqual,
  has,
  get,
  uniq,
  merge,
  omit,
  orderBy,
  isEmpty,
  isNull,
  sortBy,
  defaultsDeep,
  pick,
  upperFirst,
  upperCase,
  lowerCase,
  startCase
} from "lodash-es";

// eslint-disable-next-line no-unused-vars
const _ = (window._ = {
  cloneDeep,
  isUndefined,
  isBoolean,
  isError,
  isEqual,
  merge,
  omit,
  has,
  get,
  uniq,
  orderBy,
  isEmpty,
  isNull,
  sortBy,
  defaultsDeep,
  pick,
  upperFirst,
  upperCase,
  lowerCase,
  startCase
});

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  appName: 'Wemow',
  pageTrackerScreenviewEnabled: true,
  config: {
    id: VUE_APP_GOOGLE_ANALYTICS_ID,
    debug_mode: VUE_APP_ENV === 'development',
    params: {
      send_page_view: false
    }
  },
  includes: [
    VUE_APP_ENV === 'production' ? { id: 'AW-628158058' } : {},
  ],
  enabled: !!VUE_APP_ENABLE_GOOGLE_ANALYTICS,
});

import analyticsTiming from "./plugins/analytics.js"
Vue.use(analyticsTiming)

import GlobalViewActionBar from "./components/global/view-action-bar/GlobalViewActionBar.vue"
Vue.component('GlobalViewActionBar', GlobalViewActionBar)

import globalMenuButtons from "./components/global/globalMenuButtons.vue"
Vue.component('globalMenuButtons', globalMenuButtons)

import divShimmer from "./components/global/text-shimmer.vue"
Vue.component('divShimmer', divShimmer)

import WemowHotLink from "./components/shared/HotLinks/HotLink.vue"
Vue.component('WemowHotLink', WemowHotLink)

import TagComponent from "./components/shared/Tag/Tag.vue"
Vue.component('Tag', TagComponent)

import TextField from "@/components/shared/FormFields/TextField"
Vue.component('TextField', TextField)

import RadioBlockField from "@/components/shared/FormFields/RadioBlockField"
Vue.component('RadioBlockField', RadioBlockField)


import CustomDropdown from "@/components/shared/FormFields/CustomDropdown"
Vue.component('CustomDropdown', CustomDropdown)


import Placeholder from "@/components/shared/Placeholder/Placeholder"
Vue.component('Placeholder', Placeholder)
 
import WemowButton from "@/components/shared/Button/WemowButton"
Vue.component('WemowButton', WemowButton)



Vue.use(VueSocketio, socket, {
  store
});

import VModal from 'vue-js-modal'
Vue.use(VModal)


Vue.config.productionTip = false

// eslint-disable-next-line no-unused-vars
const unsync = sync(store, router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
  i18n
})
