var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex relative" },
    [
      ["sm"].includes(_vm.screenSize)
        ? _c("AppMenuButton", { staticStyle: { "margin-right": "8px" } })
        : _vm._e(),
      _vm.userRole && _vm.userRole !== "none" && _vm.userRole !== "super"
        ? _c("NotificationMenuButton")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }