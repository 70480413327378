// @ts-check
import { isBefore, differenceInCalendarDays, startOfDay } from "date-fns/esm";

export function isPastAppointment(appointment) {
    return differenceInCalendarDays(startOfDay(new Date()), startOfDay(new Date(appointment.start_time))) >= 1;
}

export function isFutureAppointment(appointment) {
    return isBefore(new Date(), new Date(appointment.start_time));
}

export function isAppointmentPending(appointment) {
    return "pending" === appointment.status;
}

export function isAppointmentDeleted(appointment) {
    return "deleted" === appointment.status;
}

export function isAppointmentCanceled(appointment) {
    return "canceled" === appointment.status;
}

export function isAppointmentComplete(appointment) {
    return "complete" === appointment.status;
}
export function isAppointmentPast(appointment) {
    return "past" === appointment.status;
}

export function isAppointmentRainDelay(appointment) {
    return "rain" === appointment.status;
}
export function isAppointmentActive(appointment) {
    return "active" === appointment.status;
}
export function isAppointmentException(appointment) {
    return "exception" === appointment.status;
}
export function isAppointmentSuspended(appointment) {
    return "suspended" === appointment.status;
}
export function isPastAppointmentLessThan7DaysAgo(appointment) {
    return differenceInCalendarDays(startOfDay(new Date()), startOfDay(new Date(appointment.start_time))) < 7;
}

export function getAppointmentStatusBackgroundColorClass(appointment){
    if(!appointment) return "bg-white"
    switch (appointment.status) {
        case  'complete':
        return 'bg-green-600'
        case  'canceled':
        return 'bg-red-600'
        case  'past':
        return 'bg-yellow-600'
        default:
        return 'bg-gray-400'
    }
}

export function getAppointmentStatusTagColorClass(appointment){
    if(!appointment) return "bg-white"
    switch (appointment.status) {
        case  'active':
        case  'complete':
        return 'bg-green-800'
        case  'canceled':
        return 'bg-red-600'
        default:
        return 'bg-yellow-600'
    }
}