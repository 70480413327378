import {
  TICKETS_SET_TICKETS,
  TICKETS_SET_TOTAL_COUNT,
  TICKET_RESET_STORE,
  TICKETS_SET_RESPONSES,
  TICKETS_REMOVE_FROM_STORE,
  TICKETS_SET_PAST_TOTAL_COUNT,
  CLEAR_TICKETS_DATA,
  TICKETS_SET_ACTIVE_TOTAL_COUNT
} from '@/store/mutation-types'

import {
  HELP_DESK_ADD_TICKET,
  GET_HELPDESK_TICKETS,
  GET_ONE_HELPDESK_TICKET,
  ADD_HELPDESK_TICKET_COMMENT,
  HELPDESK_TICKET_UPDATE,
  HELP_DESK_DELETE_TICKET,
  HELP_DESK_GET_TICKET_RESPONSES,
} from '@/store/action-types'

import {
  helpdeskAddTicket,
  getAllTickets,
  getOneTicket,
  ticketAddComment,
  updateTicket,
  deleteTicket,
  getVendorResponses,
} from '@/services/ServiceDatabaseAPI'
import { getErrorMessage } from "@/helpers/helpers"
const getDefaultState = () => {
  return {
    helpdesk_tickets: [],
    all_tickets_count: 0,
    past_tickets_count: 0,
    active_tickets_count: 0
  }
  
}
const state = getDefaultState()

const mutations = {
  [TICKET_RESET_STORE](state) {
    state.helpdesk_tickets = []
  },
  [TICKETS_SET_TICKETS](state, { tickets, clear_data = false }) {
    if (clear_data) {
      state.helpdesk_tickets = tickets
      return;
    }

    if (Array.isArray(tickets)) {
      tickets.forEach((a) => {
        const ticketsIndex = state.helpdesk_tickets
          .map((o) => o._id)
          .indexOf(a._id)
        if (ticketsIndex > -1) {
          state.helpdesk_tickets.splice(ticketsIndex, 1, a)
        } else {
          state.helpdesk_tickets.push(a)
        }
      })
    } else {
      const ticketsIndex = state.helpdesk_tickets
        .map((o) => o._id)
        .indexOf(tickets._id)
      if (ticketsIndex > -1) {
        state.helpdesk_tickets.splice(ticketsIndex, 1, tickets)
      } else {
        state.helpdesk_tickets.unshift(tickets)
      }
    }
  },
  [TICKETS_SET_TOTAL_COUNT](state, count) {
    state.all_tickets_count = count
  },
  [TICKETS_SET_ACTIVE_TOTAL_COUNT](state, count) {
    state.active_tickets_count = count
  },
  [TICKETS_SET_PAST_TOTAL_COUNT](state, count) {
    state.past_tickets_count = count
  },
  [TICKETS_SET_RESPONSES](state, responses) {
    state.ticket_responses = responses
  },
  [TICKETS_REMOVE_FROM_STORE](state, ticket_id) {
    const ticketsIndex = state.helpdesk_tickets
      .map((ticket) => ticket._id)
      .indexOf(ticket_id)
    if (ticketsIndex !== -1) {
      state.helpdesk_tickets.splice(ticketsIndex, 1)
    }
  },
  [CLEAR_TICKETS_DATA](state) {
    state = Object.assign(state, getDefaultState())
    return state
  },
}

const actions = {
  async [HELP_DESK_ADD_TICKET]({ commit }, { ticket }) {
    try {
      const load_helpdesk = await helpdeskAddTicket({ ticket })

      if (load_helpdesk.status > 204 || !load_helpdesk.data) {
        throw new Error('Error loading zendesk tickets')
      }

      const ticketData = load_helpdesk.data

      commit(TICKETS_SET_TICKETS, { tickets: ticketData })
      return ticketData
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [GET_HELPDESK_TICKETS]({ commit }, { query, drop_store = false }) {
    try {

      const load_helpdesk = await getAllTickets(query)
      if (load_helpdesk.status > 204 || !load_helpdesk.data) {
        throw new Error('Error loading zendesk tickets')
      }
      const tickets = load_helpdesk.data
      const total_tickets_count = load_helpdesk.headers['x-total-tickets']
      const total_past_tickets_count =
        load_helpdesk.headers['x-total-past-tickets']

      // if ( drop_store ){
      //   commit(TICKET_RESET_STORE)
      // }

      commit(TICKETS_SET_TICKETS, { tickets, clear_data: drop_store })
      commit(TICKETS_SET_TOTAL_COUNT, total_tickets_count)
      commit(TICKETS_SET_PAST_TOTAL_COUNT, total_past_tickets_count)
      return tickets
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [GET_ONE_HELPDESK_TICKET]({ commit }, { ticket_id }) {
    try {
      const load_helpdesk = await getOneTicket(ticket_id)

      if (load_helpdesk.status > 204 || !load_helpdesk.data) {
        throw new Error('Error loading helpdesk tickets')
      }

      const ticket = load_helpdesk.data

      commit(TICKETS_SET_TICKETS, { tickets: ticket })
      return ticket
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [ADD_HELPDESK_TICKET_COMMENT](
    { commit },
    { account_id, ticket_id, account_type, comment },
  ) {
    try {
      const load_helpdesk = await ticketAddComment({
        account_id,
        ticket_id,
        account_type,
        comment,
      })

      if (load_helpdesk.status > 204 || !load_helpdesk.data) {
        throw new Error('Error loading helpdesk tickets')
      }

      const ticket = load_helpdesk.data
      commit(TICKETS_SET_TICKETS, { tickets: ticket })
      return ticket
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
  async [HELPDESK_TICKET_UPDATE]({ commit }, { ticket_id, fields }) {
    const update = await updateTicket({ ticket_id, fields })

    if (update.status > 204 || !update.data) {
      throw new Error('Error loading helpdesk tickets')
    }

    const ticket = update.data
    commit(TICKETS_SET_TICKETS, { tickets: ticket })
    return ticket
  },
  async [HELP_DESK_DELETE_TICKET]({ commit }, ticket_id) {
    const update = await deleteTicket(ticket_id)

    if (update.status !== 200) {
      throw new Error('Error loading deleting ticket')
    }
    commit(TICKETS_REMOVE_FROM_STORE, ticket_id)
    return update
  },
  async [HELP_DESK_GET_TICKET_RESPONSES]({ commit }) {
    try {
      const response = await getVendorResponses()

      if (response.status > 204 || !response.data) {
        throw new Error('Error loading ticket responses')
      }

      const responses = response.data
      commit(TICKETS_SET_RESPONSES, responses)
      return responses
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage)
    }
  },
}

const getters = {
  getAllTicketsCount: (state) => state.all_tickets_count,
  getPastTicketsCount: (state) => state.past_tickets_count,
  getActiveTicketsCount: (state) => state.active_tickets_count,
  getAllTickets: (state) => state.helpdesk_tickets,
  getOpenTickets: (state) =>
    state.helpdesk_tickets.filter((ticket) => ticket.status === 'open'),
  getClosedTickets: (state) =>
    state.helpdesk_tickets.filter((ticket) => ticket.status === 'closed'),
  getDraftTickets: (state) =>
    state.helpdesk_tickets.filter((ticket) => ticket.status === 'draft'),
  getOneTicket: (state) => (ticket_id) =>
    state.helpdesk_tickets.find((t) => t._id === ticket_id),
  getNewTicketsCount: (state) => (id) =>
    state.helpdesk_tickets.filter((ticket) => 
    ticket.comments.length > 0 && ticket.status === 'open' && ticket.comments[ticket.comments.length - 1].sender_id != id).length,
  getOrgAndVendorNewTicketsCount: (state) => state.helpdesk_tickets.filter((ticket) => (ticket.comments.length <= 0 || ticket.comments[ticket.comments.length - 1].sender_account_type === 'customer') && ticket.status === 'open').length,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
