var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-t pt-4 mt-2" }, [
    _c("div", { staticClass: "font-medium" }, [
      _vm._v(_vm._s(_vm.addOnDisplayData.name)),
    ]),
    _c("div", { staticClass: "text-sm" }, [
      _vm._v(_vm._s(_vm.addOnDisplayData.description)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }