export const registration = {
    smsConfirmationCode: "Se enviará un SMS con el código de confirmación de su cuenta a su número de teléfono.",
    emailAccountConfirmation: "Se le enviará un correo electrónico con el enlace de confirmación de su cuenta para confirmar su cuenta.",
    emailSentTo: "Correo electrónico enviado a: {text}",
    returnToLogin: "Regresar a la página de inicio de sesión",
    checkEmailForConfirmationLink: "Revise su correo electrónico para obtener un enlace de confirmación",
    registrationComplete: "¡Registro completo!",
    useConfirmationMethod: "usar {text} en su lugar.",
    checkPhoneConfirmationCode: "Revise su teléfono para el código de confirmación",
    smsSentTo: "Mensaje SMS enviado a: {text}",
    enterVerificationCode: "Ingrese su código de verificación.",
    wrongNumber: "Ingresó un número incorrecto?",
    changeNumber: "para cambiar el número",
    enterNewNumberToSendCode: "Ingrese un nuevo número de teléfono para volver a enviar el código",
    returnToCodeVerification: "Regresar a la verificación del código",
    resendCode: "Reenviar codigo",
    emailInUse: "Correo electrónico ya en uso",
    notACustomer: "¿No eres cliente? Registrarse como",
    notAnAffiliate: "¿No eres afiliado? Registrarse como",
    notAnOrganization: "¿No es una organización? Registrarse como",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    instead: "en lugar",
    orAn: "o un",
    login: "iniciar sesión",
    forgotPassword: "¿Se te olvidó tu contraseña?",
    createAccount: "Crea una cuenta",
    registerAsCustomer: "Registrarse como cliente",
    registerAsServiceProvider: "Registrarse como proveedor de servicios",
    registerAsAffiliate: "Registrarse como afiliado de servicio",
    verifyCode: "Código de verificación",
    verifying: "Verificando",
    verificationComplete: "Verificación completada",
    verificationCodeSentText: "Ingrese el código de verificación enviado a su",
    verificationCodeNotSent: "¿No recibió el código de verificación?",
    enterVerificationCodeInfo: "Ingrese el código de verificación enviado a su número de teléfono a continuación",
}