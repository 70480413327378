var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "text-lg font-medium" }, [
      _vm._v(_vm._s(_vm.message)),
    ]),
    _vm.description ? _c("div", [_vm._v(_vm._s(_vm.description))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }