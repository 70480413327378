<template web>
  <div class="bg-green-900 login-container h-screen w-screen absolute top-0 left-0" v-if="!loggedIn" v-loading="loading">
    <div class="flex items-start justify-end h-full" v-if="!resetPasswordRequest && !resetPasswordAction && !resetTemporaryPasswordRequest">
     
     <div class="p-24 w-full h-full hidden md:flex items-center justify-end overflow-x-hidden">
      <img src="https://assets.wemow.com/images/login-background.png" class="w-full max-w-full absolute opacity-5 right-0 top-12 z-0" />
       <div class="max-w-[340px]">
        <p class="text-4xl text-white font-medium text-right leading-tight mb-6">Subscription <br>Lawn Care</p>
        <p class="text-white text-right">Schedule, pay & get access to dedicated support instantly with Wemow.</p>
       </div>
      </div>

      <div class="w-full h-full max-w-[600px] bg-white shadow-md flex flex-col relative z-10">

        <el-form class="bg-white px-4 sm:px-16 py-4 sm:py-10 border-0 border-gray-200 lg:mt-24 flex w-full items-center flex-col rounded-md" @submit="login" v-loading="loading">
      <img src="@/assets/images/wemow-logo-primary.png" class="mb-12 mx-auto" style="max-width:200px" /> 

          <el-alert
            v-if="message"
            :title="message"
            type="success">
          </el-alert>

          <el-alert
              v-if="error"
              :title="error"
              type="error">
          </el-alert>

          <!-- <h2 class="form-signin-heading">{{$t('buttons.signIn')}}</h2> -->
          <!-- <p>{{$t('information.loginInfo')}}</p> -->

          <el-form-item :label="$t('formLabels.emailAddress')" style="width:100%;">
            <el-input
              id="loginEmailInput"
              type="text"
              class="form-control"
              :placeholder="$t('formLabels.emailAddress')"
              v-model="credentials.username"
              required
              @keyup.enter.native="focusPassword"></el-input>
          </el-form-item>

          <el-form-item :label="$t('formLabels.password')" style="width:100%;">
            <el-input
              id="loginPasswordInput"
              class="form-control"
              :placeholder="$t('formLabels.password')"
              v-model="credentials.password"
              ref="passwordField"
              :type="showPassword ? 'text' : 'password'"
              @keyup.enter.native="login">
                <font-awesome-icon 
                    v-if="showPassword" 
                    @click="showPassword = false" 
                    slot="suffix" 
                    class="cursor-pointer el-input__icon mr-2" 
                    icon="eye-slash"
                />
                <font-awesome-icon 
                    v-else slot="suffix" 
                    @click="showPassword = true"  
                    class="cursor-pointer el-input__icon mr-2" 
                    icon="eye"
                />
            </el-input>
          </el-form-item>
        <div class="w-full flex flex-row-reverse"> 
          <el-tooltip :content="$t('information.clickToReset')" :manual="true" placement="left" effect="light" :value="!!error" :disabled="!error">
            <div>
              <a class="text-xs text-gray-600 cursor-pointer hover:text-gray-700" v-if="!resetPasswordRequest && !resetPasswordAction && !loading" @click="toggleResetPassword">Reset Password</a>
            </div>
          </el-tooltip>
        </div >
        <!-- <div class="flex items-center flex-col " > -->
          <el-button id="loginSubmitButton" type="primary" class="block w-full mt-8" @click="login">{{$t('buttons.signIn')}}</el-button>
        <!-- </div> -->
        <SignInWith @signIn="loginAlt" @signInError="error =$t('errorMessages.errorLoggingIn')"/>
        </el-form>
        <div class="mt-4" style="width:100%; display:flex;align-items:center;justify-content:center;">
         <p class="text-sm text-gray-700">{{$t('registration.dontHaveAccount')}}
        <router-link to="/register" class="highlight-link">{{$t('registration.signup')}}!</router-link></p
      >
    </div>

    <p class="text-xs text-center mt-auto mb-4 text-gray-500">version {{appVersion}}</p>
      </div>
    </div>

    <el-row type="flex" justify="center" align="middle" v-if="resetPasswordRequest" style="position:relative;z-index:1;">
      <el-col :sm="11" :md="10" :lg="8" :xl="6">
        <el-form class="form-signin form-password" v-loading="loading">
          <div class="alert alert-success" v-if="resetPasswordSent">
            <span>{{message}}</span>
          </div>
          <el-alert
              v-if="error"
              :title="error"
              type="error">
          </el-alert>
          <h2 class="form-signin-heading">{{$t('information.resetPassword')}}</h2>
          <p>{{$t('information.resetPasswordInfo')}}</p>
          <el-form-item :label="$t('formLabels.emailAddress')">
            <el-input
              type="text"
              class="form-control"
              :placeholder="$t('formLabels.emailAddress')"
              v-model="credentials.username"
              key="resetpasswordusername">
            </el-input>
          </el-form-item>
          <a style="float:right;font-size:12px;margin:-24px 11px 0 0;" v-if="!resetPasswordAction && resetPasswordRequest" @click="toggleResetPassword">Login</a>
          <el-button v-if="!loading" type="primary" @click="passwordReset">{{$t('buttons.sendReset')}} </el-button>
        </el-form>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" align="middle" v-if="resetPasswordAction" style="position:relative;z-index:1;">
    <el-col :sm="11" :md="10" :lg="8" :xl="6">
    <el-form class="form-signin form-reset-password" v-loading="loading">
      <div class="alert alert-success" v-if="resetPasswordSent">
        <span>{{message}}</span>
      </div>
      <!-- <div class="alert alert-warning" v-if="message !==''">
        <span>{{message}}</span>
      </div> -->
      <el-alert
        v-if="message"
        :title="message"
        type="success">
      </el-alert>
      <h2 class="form-signin-heading">{{$t('information.resetPassword')}}</h2>
      <p>{{$t('information.resetPasswordForm')}}</p>
      <p>{{$t('information.passwordLength')}}</p>
      <el-form-item :label="$t('formLabels.newPassword')">
        <text-field
        ref="field_password"
        :value="credentials.password"
        v-on:input="credentials.password = $event"
        :required="true"
        :placeholder="$t('formLabels.newPassword')"
        :type="'password'"
        :customValidator="() => isPasswordValid"
        :validations="[
            {
              method: 'custom',
              error: 'Invalid Password'
            }
          ]"
        ></text-field>
      </el-form-item>
      <div v-if="passwordErrors.length > 0 && credentials.password">
        <el-popover
          placement="top-start"
          title="Your password must:"
          width="300"
          trigger="hover">

           <p slot="reference" class="text-xs text-red-500">Password Requirements</p>
        <div v-for="error in passwordErrors" v-bind:key="error">
            <p style="color: red">
            <span>
            <font-awesome-icon :icon="['fal', 'exclamation']" />
            </span>
            {{ error }}
            </p>
        </div>
        </el-popover>
      </div>
      <el-form-item v-if="credentials.password.length > 4 && !passwordErrors.length" :label="$t('formLabels.confirmPassword')">
        <text-field
        ref="field_password"
        :value="credentials.password_confirm"
        v-on:input="credentials.password_confirm = $event"
        :required="true"
        :placeholder="$t('formLabels.confirmPassword')"
        :type="'password'"
        :customValidator="() => passwordConfirmed"
        :validations="[
            {
              method: 'custom',
              error: 'Passwords do not match'
            }
          ]"
        ></text-field>
      </el-form-item>
      <el-button v-if="!loading" :disabled="!canSubmitPassword" type="primary" @click="setNewPassword">{{$t('information.resetPassword')}}</el-button>
    </el-form>
    </el-col>
    </el-row>

    <TemporaryPasswordReset 
      v-if="resetTemporaryPasswordRequest"
      :loading="loading"
      :error="error"
      :credentials="credentials"
      :isPasswordValid="isPasswordValid"
      :hasValidOldPassword="hasValidOldPassword"
      :passwordErrors="passwordErrors"
      :passwordConfirmed="passwordConfirmed"
      :canSubmitPassword="canSubmitPassword"
      @set-new-password="setPermanenNewPassword"
      style="position: relative; z-index: 1"
    />
  </div>
</template>

<script>
var owasp = require("owasp-password-strength-test");

 owasp.config({
   allowPassphrases: true,
   maxLength: 128,
   minLength: 6,
   minPhraseLength: 20,
   minOptionalTestsToPass: 0
 });

import { Strings } from '@/helpers/strings'
import { version } from '../../../../package.json'

import {
  AUTH_LOGIN_ACTION,
  AUTH_LOGIN_ACTION_ALT,
  AUTH_PASSWORD_RESET_ACTION,
  AUTH_VERIFY_TOKEN,
  AUTH_USER_RESET_PASSWORD_ACTION,
  AUTH_VERIFY_CONFIRMATION_TOKEN,
  AUTH_RESEND_VERIFY_CODE,
  AUTH_VERIFY_CLAIM_TOKEN,
  AUTH_NEW_PASSWORD_RESET_ACTION
} from '@/store/action-types'

import { mapActions } from 'vuex';

export default {
  components: {
    SignInWith: () => import("./SignInWith2"),
    TemporaryPasswordReset: () => import("@/components/public/login/TemporaryPasswordReset.vue"),
  },
  data() {
    return {
      loading: false,
      resetPasswordRequest: false,
      resetPasswordAction: false,
      resetPasswordSent: false,
      resetTemporaryPasswordRequest: false,
      appVersion: version,
      credentials: {
        username: '',
        password: '',
        password_confirm: '',
        oldPassword: ''
      },
      showPassword:false,
      error: '',
      message: '',
    }
  },
  created() {
    // localStorage.removeItem('id_token')
    // localStorage.removeItem('reset_token')
    if (this.isPasswordResetAction()) {
      this.canResetPassword()
        .then(token => {
          this.resetPasswordAction = true
          localStorage.setItem('reset_token', token)
        })
        .catch(error => {
          let error_message = error.message || Strings.login_token_invalid
          if (['expired'].indexOf(error.message) > -1) {
            error_message = Strings.login_token_expired
          }
          this.resetPasswordAction = false
          this.error = error_message
        })
    }else if(this.isConfirmAccountAction()){
      this.doConfirmAccount()
        .then(() => {
          this.message = this.$t('successMessages.accountConfirmed')
          this.error = ''
        })
        .catch(error => {
          let error_message = error.message || Strings.login_token_invalid
          if (['expired'].indexOf(error.message) > -1) {
            error_message = Strings.login_token_expired
          }
          this.message = ''
          this.error = error_message
        })
    }else if(this.isClaimAccountAction()){
      this.doClaimAccount()
        .then(token => {
          this.message = this.$t('information.claimAccountInfo')
          this.error = ''
          this.resetPasswordAction = true
          localStorage.setItem('reset_token', token)
        })
        .catch(error => {
          let error_message = error.message || Strings.login_token_invalid
          if (['expired'].indexOf(error.message) > -1) {
            error_message = Strings.login_token_expired
          }
          this.message = ''
          this.error = error_message
        })
    }else if(this.isLoginLink){
      localStorage.setItem('id_token', this.$route.query['id_token'])
    }
    const tempResetToken = localStorage.getItem("temporary_password_reset_token")
    if (tempResetToken) {
      this.loading = true
      this.verifyPasswordResetToken({ token: tempResetToken, tokenType: "temporary_password_reset" })
      .then(()=> {
        this.resetTemporaryPasswordRequest  = true
        this.credentials.password=""
        this.credentials.oldPassword=""
        this.credentials.username=""
        this.error=""
      })
      .catch((error) => console.error(error))
      .finally(() => this.loading = false)
    }
  }, 
  mounted(){
    // console.log("Login.vue - mounted()")
  },
  beforeDestroy(){
    // console.log("Login.vue - beforeDestroy()")
  },
  destroyed(){
    // console.log("Login.vue - destroyed()")
  },
  beforeRouteEnter(t,f,n){
    // console.log("Login.vue - beforeRouteEnter()")
    n()
  },
  beforeRouteLeave(t,f,n){
    // console.log("Login.vue - beforeRouteLeave()")
    n()
  },
  beforeRouteUpdate(t,f,n){
    // console.log("Login.vue - beforeRouteUpdate()")
    n()
  },
  methods: {
    ...mapActions({
      doLogin: AUTH_LOGIN_ACTION,
      doLoginAlt: AUTH_LOGIN_ACTION_ALT,
      sendPasswordResetEmail: AUTH_PASSWORD_RESET_ACTION,
      verifyPasswordResetToken: AUTH_VERIFY_TOKEN,
      resetPassword: AUTH_USER_RESET_PASSWORD_ACTION,
      confirmAccount: AUTH_VERIFY_CONFIRMATION_TOKEN,
      resendSMSVerificationCode: AUTH_RESEND_VERIFY_CODE,
      claimAccount: AUTH_VERIFY_CLAIM_TOKEN,
      resetNewPassword: AUTH_NEW_PASSWORD_RESET_ACTION
    }),
      onSignInSuccess () {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      // const profile = googleUser.getBasicProfile() // etc
      // console.log(profile)
    },
    onSignInError () {
      // `error` contains any error occurred.
      // console.log('OH NOES', error)
    },
    focusPassword() {
      let {passwordField} = this.$refs
      passwordField.focus()
    },
    isConfirmAccountAction() {
      return this.$route.query['confirm_account'] && this.$route.query['confirm_account'] !== ''
    },

    async doConfirmAccount(){
     try {
        const reset_token = this.$route.query['confirm_account']
        this.$router.replace({ query: {} })

        await this.confirmAccount(reset_token)
        return reset_token

      }catch(e){
        console.log('doConfirmAccount Error ', e.message)
        throw new Error(e.message)
      }
    },
    isPasswordResetAction() {
      return this.$route.query['password_reset'] && this.$route.query['password_reset'] !== ''
    },
    async canResetPassword() {
      try {
        const reset_token = this.$route.query['password_reset']
        this.$router.replace({ query: {} })

        const verifyToken = await this.verifyPasswordResetToken(reset_token)

        if(verifyToken){
          return reset_token
        }

      }catch(e){
        console.log('canResetPassword Error ', e.message)
        throw new Error(e.message)
      }
    },
    toggleResetPassword() {
      let reset_password = !this.resetPasswordRequest
      this.resetPasswordAction = false
      this.resetPasswordRequest = reset_password
    },
    async login() {

      if(!this.credentials.username || !this.credentials.username){
        return false
      }

      this.loading = true

      try {
        const get_auth = await this.doLogin({
          username: this.credentials.username,
          password: this.credentials.password,
          platform: "web"
        })
        const hasError = get_auth.message || get_auth.code
        if (hasError) {
          this.message = ''
          const errorMessage = get_auth.message ? get_auth.message : this.$t('errorMessages.loginError')
          const errorCode = get_auth.code ? get_auth.code : 'Unauthorized' // don't translate
          this.error = errorMessage
          if(errorCode === "AccountRequiresSMSConfirmation"){
            await this.resendSmsVerificationCodeHandler(this.credentials.username);
          }
          this.$sendAnalyticsException(errorMessage)
          return;
        }
        if (get_auth.resetPasswordOnLogin && !hasError) {
          this.resetTemporaryPasswordRequest  = true
          this.credentials.password=""
          this.credentials.oldPassword=""
          this.credentials.username=""
          this.error=""
        }

        if(get_auth.loggedIn){
          this.$sendAnalyticsEvent('login', {
            method: 'email'
          });
        }
      } catch (e) {
        const message = e.message || Strings.login_db_connection_err
        this.error = message
      }finally{
        this.loading = false;
      }
    },
    async loginAlt(cred) {
      this.loading = true
      try {
        const get_auth = await this.doLoginAlt(Object.assign({}, cred, { platform: "web" }))
        if (!get_auth.loggedIn) {
          this.message = ''
          const errorMessage = get_auth.message ? get_auth.message : this.$t('errorMessages.loginError')
          this.error = errorMessage
          this.$sendAnalyticsException(errorMessage)
        }else{
          const { source_type } = cred;
          this.$sendAnalyticsEvent('login', {
            method: source_type
          });
        }
      } catch (e) {
        this.error = e.message || Strings.login_db_connection_err
      }finally{
        this.loading = false
      }
    },
    async resendSmsVerificationCodeHandler(email) {
      try {
        await this.resendSMSVerificationCode({email})
        this.$message({
          type:"info",
          message: this.$t('information.verificationCodeAlert')
        })
        this.$router.push("/register/verify/sms")
      } catch(error) {
        this.error = error.message
      }
    },
    async passwordReset() {
      let credentials = {username: this.credentials.username}
      try{
        this.loading = true
        await this.sendPasswordResetEmail(credentials)
        this.resetPasswordSent = true
        this.resetPasswordRequest = false
        this.message = Strings.login_password_reset_email_sent
        this.error = ''
        setTimeout(() => {
          this.message = ''
          return true
        }, 4000)
      }catch(e){
        this.message = ''
        this.error = e.message
      }finally {
        this.loading = false
      }

    },
    async setNewPassword() {

      try{
        this.loading = true

          let savePass = await this.resetPassword({ password: this.credentials.password })
          if(savePass){
            this.resetPasswordRequest = false
            this.resetPasswordAction = false
            this.message = Strings.login_password_updated
            this.credentials.password = ''
            localStorage.removeItem('reset_token')
            setTimeout(() => {
              this.message = ''
              this.error = ''
                return true
              }, 4000)
          }
      }catch(e){
          this.message = ''
          this.error = e.message
      }finally {
        this.loading = false
      }

    },
    async setPermanenNewPassword() {
      try {
        this.loading = true
        const response = await this.resetNewPassword({ password: this.credentials.password, oldPassword: this.credentials.oldPassword })
        if (response.loggedIn) {
          this.$notify.success("New password successfully set")
        } else {
          this.error = response.message
        }
      } finally {
        this.loading = false
      }
    },
    checkPasswordsMatch() {
      return this.credentials.password === this.credentials.password_confirm
    },
    isClaimAccountAction() {
      return this.$route.query['claim_account'] && this.$route.query['claim_account'] !== ''
    },
    async doClaimAccount(){
     try {
        const reset_token = this.$route.query['claim_account']
        this.$router.replace({ query: {} })

        const verifyToken = await this.claimAccount(reset_token)
        return verifyToken

      }catch(e){
        throw new Error(e.message)
      }
    },
  },
  computed: {
    loggedIn: function(){
      return this.$store.getters.isUserLoggedIn
    },
    passwordErrors() {
      const isStrongPassword = owasp.test(this.credentials.password);
      return isStrongPassword.requiredTestErrors.map(e =>
        e
          .replace(".", "")
          .replace("The password must ", "")
          .replace("The password must ", "")
          .replace("The password may ", "")
          .replace(" at least", "")
      );
    },
    isPasswordValid() {
      if(!this.credentials.password) {
        return true
      }
      const isStrongPassword = owasp.test(this.credentials.password);
      return isStrongPassword.strong;
    },
    passwordConfirmed() {
      if(!this.credentials.password_confirm) {
        return true
      }
      return this.credentials.password === this.credentials.password_confirm;
    },
    canSubmitPassword() {
      const confirmed = this.credentials.password === this.credentials.password_confirm
      const isStrongPassword = owasp.test(this.credentials.password);
      const validPassword = isStrongPassword.strong
      return validPassword && confirmed;
    },
    hasValidOldPassword() {
      return owasp.test(this.credentials.oldPassword).strong
    },
    isLoginLink() {
      return this.$route.query['id_token']
    },
  }
}
</script>

<style scoped>
body {
  background: #f1f1f1;
}

.btn-primary {
  color: #fff;
  background-color: #0055a5;
  border-color: #0055a5;
}

.form-signin {
  margin-top: 12vh;
  padding: 44px 42px;
  background: #fff;
  border: 4px solid #e6e6e1;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.login-utilities {
  font-size: 13px;
  margin: 24px 0 0;
  color: #888;
  text-align:center;
}

.slideup-enter-active {
  animation: slideup-in 0.32s;
}
.slideup-leave-active {
  animation: slideup-in 0s reverse;
}
@keyframes slideup-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  90% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  margin: 7px;
}
.google-button:focus, .google-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}
.google-button:active {
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 10ms;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

.g-signin-button {
   height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
  margin: 7px;
}

.g-signin-button:focus, .g-signin-button:hover {
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
}
.g-signin-button:active {
  background-color: #e5e5e5;
  box-shadow: none;
  transition-duration: 10ms;
}

.g-signin-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.g-signin-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

</style>
