<template>
  <el-popover
    placement="bottom"
    :width="getPopoverWidth"
    trigger="manual"
    v-model="showPopover"
    popper-class="p-0"
    v-click-outside="onClickOutside">

    <div slot="reference"
      ref="referenceContainer"
      @click="onClickPlaceholder"
      @mousedown="isFocusSelect = true"
      @mouseup="isFocusSelect = false"
      @mouseout="isFocusSelect = false"
      class="border border-gray-300  bg-gray-200 f py-4 pr-2 pl-4 flex items-center justify-between outline-none cursor-pointer"
        :class="{
        'border-green-600': showPopover || isFocusSelect,
        'hover:border-green-600': showPopover,
        'hover:border-gray-400': !isFocusSelect && !showPopover,
        'opacity-50': disabled,
        'cursor-not-allowed': disabled
        }"
    >

    <div v-if="selectedValue">
      <slot name="labelHasValue" v-bind:selected="dropDownItems.length ? dropDownItems.find(i => i.value === selectedValue) : selectedValue"></slot>
    </div>
    <div v-else>
        <slot name="labelNoValue">Select</slot>
    </div>

      <i v-if="!selectedValue && dropDownItems && dropDownItems.length === 0" class="text-gray-500 el-icon-plus el-icon--plus"></i>
      <i v-else class="text-gray-600 el-icon-arrow-down el-icon--right transition-all duration-200 transform rotate-0" :class="{
        '-rotate-180': showPopover
      }"></i>


    </div><!-- slot:reference -->

    <div v-if="dropDownItems" class="max-h-96 overflow-auto">
        <div
            v-for="(item, index) in dropDownItems"
            :key="`item-${index}`"
            @click="onClickDropdownItem(item)"
            :class="{
              'py-5': true,
              'px-4': true,
              'border-b': index < dropDownItems.length - 1, 
              'hover:bg-gray-100 cursor-pointer': !isItemSelected(item.value),
              'opacity-60 cursor-not-allowed': isItemSelected(item.value)
            }"
        >
            <slot name="innerDropdownItem" v-bind:item="item"></slot>
        </div>
    </div>

    <slot name="innerContent"  v-bind:closePopover="close" v-bind:emitCustomAction="onEmitCustomAction"></slot>

  </el-popover>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from "vuex"

export default {
  name: "customDropdownRoot",
  props: {
      selectedValue: {
          type: [Array, Object, String, Number, Date],
          default(){
              return null
          }
      },
      dropDownItems: {
          type: [Array],
          default(){
              return []
          }
      },
      preventPopover: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  mounted(){
      const { referenceContainer } = this.$refs; 
      if(referenceContainer && referenceContainer.offsetWidth > 100){
        this.popoverWidth = referenceContainer.offsetWidth
      }
  },
  data() {
    return {
      showPopover: false,
      popoverWidth: 400,
      isFocusSelect: false,
    };
  },
  methods: {
    onClickDropdownItem(item){
      const { value } = item;
      this.$emit("input", value);
      this.$emit("change", value);
      this.showPopover = false;
    },
    onClickOutside () {
      this.$emit('onClickOutside')
      if(this.showPopover){
        this.showPopover = false;
      }
    },
    onClickPlaceholder() {
      const { preventPopover, disabled } = this;
      if(!disabled){
        this.$emit('onClickPlaceholder')
        if(!preventPopover){
          this.showPopover = true;
        }
      }
    },
    close(){
        this.showPopover = false;
    },
    onEmitCustomAction(actionObject){

      console.log('emitCustomActionemitCustomAction')
      this.$emit('customAction', actionObject)

      // (event) => $emit('customAction', event)
    },
    isItemSelected(itemValue){
      const { dropDownItems, selectedValue } = this;
      const item = dropDownItems.find(i => i.value === selectedValue)
      if(item && item.value === itemValue) {
        return true
      }
      return false
    }
  },
  computed:{
    ...mapGetters({
      screenSize: 'getScreenSize'
    }),
    getPopoverWidth(){
      return this.popoverWidth
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
};
</script>
