import store from "@/store/store";
import {
  CUSTOMER_INIT_DASHBOARD
} from '@/store/action-types'
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";

function redirectIfNotOnBoarded(next) {
  const { meta = {} } = store.getters["getUserActiveAccount"];
  const { initialSetup = "", skipOnboarding = "" } = meta;
  if (!initialSetup && !skipOnboarding) {
    next("/customer/onboarding");
    return true
  }
  return false
}

function redirectIfAlert(next) {
      const hasAlert = store.getters["notifications/hasAlertNotification"];
      if(hasAlert){
        store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
        next("/alert");
        return true
      }
      return false
}

async function checkIfDashboardLoaded(next) {
  const isDashboardInit = store.getters["customers/getLoadedDashboard"]
  if(!isDashboardInit){
    const activeAccount = store.getters["getUserActiveAccount"];
    await store.dispatch(`customers/${CUSTOMER_INIT_DASHBOARD}`, activeAccount._id)
    return next();
  }
}

export const customerBeforeResolve = async (_toRoute, fromRoute, next) => {
  await checkIfDashboardLoaded(next)
  if (
    redirectIfNotOnBoarded(next) ||
    redirectIfAlert(next)
  ){
    return
  }
  store.commit(APP_STATE_WEB_CONTENT_WIDTH)
  return next();
};
