var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.width }, [
    _c(
      "div",
      { staticClass: "rounded-lg w-full", class: _vm.backgroundColor },
      [
        _c(
          "p",
          {
            staticClass: "text-center",
            class: _vm.textClass,
            staticStyle: { "font-size": "10px" },
          },
          [_vm._v(_vm._s(_vm.tagText))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }