var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loggedIn
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "bg-green-900 login-container h-screen w-screen absolute top-0 left-0",
        },
        [
          !_vm.resetPasswordRequest &&
          !_vm.resetPasswordAction &&
          !_vm.resetTemporaryPasswordRequest
            ? _c(
                "div",
                { staticClass: "flex items-start justify-end h-full" },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full h-full max-w-[600px] bg-white shadow-md flex flex-col relative z-10",
                    },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass:
                            "bg-white px-4 sm:px-16 py-4 sm:py-10 border-0 border-gray-200 lg:mt-24 flex w-full items-center flex-col rounded-md",
                          on: { submit: _vm.login },
                        },
                        [
                          _c("img", {
                            staticClass: "mb-12 mx-auto",
                            staticStyle: { "max-width": "200px" },
                            attrs: {
                              src: require("@/assets/images/wemow-logo-primary.png"),
                            },
                          }),
                          _vm.message
                            ? _c("el-alert", {
                                attrs: { title: _vm.message, type: "success" },
                              })
                            : _vm._e(),
                          _vm.error
                            ? _c("el-alert", {
                                attrs: { title: _vm.error, type: "error" },
                              })
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: _vm.$t("formLabels.emailAddress"),
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "form-control",
                                attrs: {
                                  id: "loginEmailInput",
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "formLabels.emailAddress"
                                  ),
                                  required: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.focusPassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.credentials.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.credentials, "username", $$v)
                                  },
                                  expression: "credentials.username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { label: _vm.$t("formLabels.password") },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  ref: "passwordField",
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "loginPasswordInput",
                                    placeholder: _vm.$t("formLabels.password"),
                                    type: _vm.showPassword
                                      ? "text"
                                      : "password",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login.apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.credentials.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.credentials, "password", $$v)
                                    },
                                    expression: "credentials.password",
                                  },
                                },
                                [
                                  _vm.showPassword
                                    ? _c("font-awesome-icon", {
                                        staticClass:
                                          "cursor-pointer el-input__icon mr-2",
                                        attrs: {
                                          slot: "suffix",
                                          icon: "eye-slash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showPassword = false
                                          },
                                        },
                                        slot: "suffix",
                                      })
                                    : _c("font-awesome-icon", {
                                        staticClass:
                                          "cursor-pointer el-input__icon mr-2",
                                        attrs: { slot: "suffix", icon: "eye" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showPassword = true
                                          },
                                        },
                                        slot: "suffix",
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-full flex flex-row-reverse" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("information.clickToReset"),
                                    manual: true,
                                    placement: "left",
                                    effect: "light",
                                    value: !!_vm.error,
                                    disabled: !_vm.error,
                                  },
                                },
                                [
                                  _c("div", [
                                    !_vm.resetPasswordRequest &&
                                    !_vm.resetPasswordAction &&
                                    !_vm.loading
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-xs text-gray-600 cursor-pointer hover:text-gray-700",
                                            on: {
                                              click: _vm.toggleResetPassword,
                                            },
                                          },
                                          [_vm._v("Reset Password")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "block w-full mt-8",
                              attrs: {
                                id: "loginSubmitButton",
                                type: "primary",
                              },
                              on: { click: _vm.login },
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.signIn")))]
                          ),
                          _c("SignInWith", {
                            on: {
                              signIn: _vm.loginAlt,
                              signInError: function ($event) {
                                _vm.error = _vm.$t(
                                  "errorMessages.errorLoggingIn"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "text-sm text-gray-700" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("registration.dontHaveAccount")) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "highlight-link",
                                  attrs: { to: "/register" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("registration.signup")) + "!"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-xs text-center mt-auto mb-4 text-gray-500",
                        },
                        [_vm._v("version " + _vm._s(_vm.appVersion))]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.resetPasswordRequest
            ? _c(
                "el-row",
                {
                  staticStyle: { position: "relative", "z-index": "1" },
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 11, md: 10, lg: 8, xl: 6 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "form-signin form-password",
                        },
                        [
                          _vm.resetPasswordSent
                            ? _c(
                                "div",
                                { staticClass: "alert alert-success" },
                                [_c("span", [_vm._v(_vm._s(_vm.message))])]
                              )
                            : _vm._e(),
                          _vm.error
                            ? _c("el-alert", {
                                attrs: { title: _vm.error, type: "error" },
                              })
                            : _vm._e(),
                          _c("h2", { staticClass: "form-signin-heading" }, [
                            _vm._v(_vm._s(_vm.$t("information.resetPassword"))),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("information.resetPasswordInfo"))
                            ),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("formLabels.emailAddress"),
                              },
                            },
                            [
                              _c("el-input", {
                                key: "resetpasswordusername",
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t(
                                    "formLabels.emailAddress"
                                  ),
                                },
                                model: {
                                  value: _vm.credentials.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.credentials, "username", $$v)
                                  },
                                  expression: "credentials.username",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.resetPasswordAction && _vm.resetPasswordRequest
                            ? _c(
                                "a",
                                {
                                  staticStyle: {
                                    float: "right",
                                    "font-size": "12px",
                                    margin: "-24px 11px 0 0",
                                  },
                                  on: { click: _vm.toggleResetPassword },
                                },
                                [_vm._v("Login")]
                              )
                            : _vm._e(),
                          !_vm.loading
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.passwordReset },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("buttons.sendReset")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.resetPasswordAction
            ? _c(
                "el-row",
                {
                  staticStyle: { position: "relative", "z-index": "1" },
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 11, md: 10, lg: 8, xl: 6 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "form-signin form-reset-password",
                        },
                        [
                          _vm.resetPasswordSent
                            ? _c(
                                "div",
                                { staticClass: "alert alert-success" },
                                [_c("span", [_vm._v(_vm._s(_vm.message))])]
                              )
                            : _vm._e(),
                          _vm.message
                            ? _c("el-alert", {
                                attrs: { title: _vm.message, type: "success" },
                              })
                            : _vm._e(),
                          _c("h2", { staticClass: "form-signin-heading" }, [
                            _vm._v(_vm._s(_vm.$t("information.resetPassword"))),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("information.resetPasswordForm"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("information.passwordLength"))
                            ),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("formLabels.newPassword"),
                              },
                            },
                            [
                              _c("text-field", {
                                ref: "field_password",
                                attrs: {
                                  value: _vm.credentials.password,
                                  required: true,
                                  placeholder: _vm.$t("formLabels.newPassword"),
                                  type: "password",
                                  customValidator: function () {
                                    return _vm.isPasswordValid
                                  },
                                  validations: [
                                    {
                                      method: "custom",
                                      error: "Invalid Password",
                                    },
                                  ],
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.credentials.password = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.passwordErrors.length > 0 &&
                          _vm.credentials.password
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "Your password must:",
                                        width: "300",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "text-xs text-red-500",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [_vm._v("Password Requirements")]
                                      ),
                                      _vm._l(
                                        _vm.passwordErrors,
                                        function (error) {
                                          return _c("div", { key: error }, [
                                            _c(
                                              "p",
                                              { staticStyle: { color: "red" } },
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "exclamation",
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(error) + " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.credentials.password.length > 4 &&
                          !_vm.passwordErrors.length
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("formLabels.confirmPassword"),
                                  },
                                },
                                [
                                  _c("text-field", {
                                    ref: "field_password",
                                    attrs: {
                                      value: _vm.credentials.password_confirm,
                                      required: true,
                                      placeholder: _vm.$t(
                                        "formLabels.confirmPassword"
                                      ),
                                      type: "password",
                                      customValidator: function () {
                                        return _vm.passwordConfirmed
                                      },
                                      validations: [
                                        {
                                          method: "custom",
                                          error: "Passwords do not match",
                                        },
                                      ],
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.credentials.password_confirm =
                                          $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.loading
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.canSubmitPassword,
                                    type: "primary",
                                  },
                                  on: { click: _vm.setNewPassword },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("information.resetPassword"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.resetTemporaryPasswordRequest
            ? _c("TemporaryPasswordReset", {
                staticStyle: { position: "relative", "z-index": "1" },
                attrs: {
                  loading: _vm.loading,
                  error: _vm.error,
                  credentials: _vm.credentials,
                  isPasswordValid: _vm.isPasswordValid,
                  hasValidOldPassword: _vm.hasValidOldPassword,
                  passwordErrors: _vm.passwordErrors,
                  passwordConfirmed: _vm.passwordConfirmed,
                  canSubmitPassword: _vm.canSubmitPassword,
                },
                on: { "set-new-password": _vm.setPermanenNewPassword },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "p-24 w-full h-full hidden md:flex items-center justify-end overflow-x-hidden",
      },
      [
        _c("img", {
          staticClass:
            "w-full max-w-full absolute opacity-5 right-0 top-12 z-0",
          attrs: {
            src: "https://assets.wemow.com/images/login-background.png",
          },
        }),
        _c("div", { staticClass: "max-w-[340px]" }, [
          _c(
            "p",
            {
              staticClass:
                "text-4xl text-white font-medium text-right leading-tight mb-6",
            },
            [_vm._v("Subscription "), _c("br"), _vm._v("Lawn Care")]
          ),
          _c("p", { staticClass: "text-white text-right" }, [
            _vm._v(
              "Schedule, pay & get access to dedicated support instantly with Wemow."
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }