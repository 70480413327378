import {
    getAppointmentDateDay,
    getAppointmentDateDisplay,
    getAppointmentDateDisplayFull,
    getAppointmentDateFormat,
    getAppointmentCustomerName,
    getAppointmentCustomerEmail,
    getAppointmentCustomerId,
    getAppointmentServiceName,
    getAppointmentServiceCategoryName,
    getAppointmentPlanAmount,
    getAppointmentAddOns,
    getAddOnsTotalPrice,
    getAppointmentAddressFormatted,
    getAppointmentAddressStreet,
    getAppointmentAddressCity,
    getAppointmentAddressZip,
    getAppointmentAddressState,
    getAppointmentVendorName,
    getAppointmentVendorEmail,
    getAppointmentVendorId,
    getAppointmentPlanName,
    getAppointmentAddressNotes
} from "./helpers"

import {
    formatDollarAmount
  } from "@/helpers/helpers";
  

export const getAppointmentDisplayFormatted = (appointment = {}) => {
    const { _id = "" } = appointment
    const dateDayOfWeek = getAppointmentDateDay(appointment)
    const dateDisplay = getAppointmentDateDisplay(appointment)
    const dateDisplayFull = getAppointmentDateDisplayFull(appointment)
    const dateDisplayFormatted = getAppointmentDateFormat(appointment)
    const customerId = getAppointmentCustomerId(appointment)
    const customerName = getAppointmentCustomerName(appointment)
    const customerEmail = getAppointmentCustomerEmail(appointment)
    const serviceName = getAppointmentServiceName(appointment)
    const serviceCategory = getAppointmentServiceCategoryName(appointment)
    const servicePlanName = getAppointmentPlanName(appointment)
    const servicePlanAmount = getAppointmentPlanAmount(appointment)
    const servicePlanAmountFormatted = `$${formatDollarAmount( getAppointmentPlanAmount(appointment) )}`
    const addOns = getAppointmentAddOns(appointment)
    const addOnsTotalAmount = getAddOnsTotalPrice(appointment)
    const addOnsTotalAmountFormatted = `$${formatDollarAmount( getAddOnsTotalPrice(appointment) )}` 
    const addressFormatted = getAppointmentAddressFormatted(appointment)
    const addressStreet = getAppointmentAddressStreet(appointment)
    const addressCity = getAppointmentAddressCity(appointment)
    const addressZip = getAppointmentAddressZip(appointment)
    const addressState = getAppointmentAddressState(appointment)

    const vendorId = getAppointmentVendorId(appointment)
    const vendorName = getAppointmentVendorName(appointment)
    const vendorEmail = getAppointmentVendorEmail(appointment)
    const addressNotes = getAppointmentAddressNotes(appointment)

    return {
        _id,
        dateDayOfWeek,
        dateDisplay,
        dateDisplayFull,
        dateDisplayFormatted,
        customerId,
        customerName,
        customerEmail,
        serviceName,
        servicePlanAmount,
        servicePlanAmountFormatted,
        servicePlanName,
        addOns,
        addOnsTotalAmount,
        addOnsTotalAmountFormatted,
        addressFormatted,
        addressStreet,
        addressCity,
        addressZip,
        addressState,
        vendorId,
        vendorName,
        vendorEmail,
        serviceCategory,
        addressNotes
    }
}