const { VUE_APP_MODE } = process.env
const StoreServicesModule = () => import('@/store/modules/shared/services')
const StoreCustomersModule = () => import('@/store/modules/shared/customers')
const StoreCartModule = () => import('@/store/modules/customer/cart')
const StoreOrdersModule = () => import('@/store/modules/shared/orders')
const StoreAppointmentsModule = () => import('@/store/modules/shared/appointments')
// const StoreMediaModule = () => import('@/store/modules/shared/media')
const StoreOrganizationModule = () => import('@/store/modules/shared/organization')
const StoreVendorsModule = () => import('@/store/modules/shared/vendors')
const StoreAffiliatesModule = () => import('@/store/modules/shared/affiliates')
// const StoreSearchModule = () => import('@/store/modules/shared/search')
// const NotificationModule = () => import('@/store/modules/shared/notifications')
// const StoreModalModule = () => import('@/store/modules/shared/modal')
// const StoreListModule = () => import('@/store/modules/shared/list_view_module')
const StoreCommunicationModule = () => import('@/store/modules/shared/communication')
// const StoreTicketsModule = () => import('@/store/modules/customer/ticket')
// const AddOnOptionsModule = () => import('@/store/modules/shared/addOnOptions')


import StoreSearch from '@/store/modules/shared/search'
import StoreMedia from '@/store/modules/shared/media'
import StoreNotification from '@/store/modules/shared/notifications'
import StoreTicket from '@/store/modules/customer/ticket'
import storeModal from '@/store/modules/shared/modal'
import StoreListViewModel from '@/store/modules/shared/list_view_module'
import StoreAddOnOptions from '@/store/modules/shared/addOnOptions'
import StoreChat from '@/store/modules/shared/chat'

import store from "@/store/store";

export async function resetAllUserStores () { // eslint-disable-line no-unused-vars
  if ((store && store.state && store.state['user'])) {
    store.commit('resetUserStoreToDefaults', { root: true })
  }

  if ((store && store.state && store.state['auth'])) {
    store.commit('resetAuthStoreToDefaults', { root: true })
  }
  
}

export async function setUpUserStores (role) { // eslint-disable-line no-unused-vars
    // const StoreSearch = await StoreSearchModule()
    // const StoreMedia = await StoreMediaModule()
    // const StoreNotification = await NotificationModule()
    // const StoreTicket = await StoreTicketsModule()
    // const storeModal = await StoreModalModule();
    // const StoreListViewModel = await StoreListModule();
    // const StoreAddOnOptions = await AddOnOptionsModule()

    /**
     * stores that are used for ALL user types here
     **/
    if (!(store && store.state && store.state['media'])) {
      store.registerModule("media", StoreMedia);
    }
    if (!(store && store.state && store.state['notifications'])) {
      store.registerModule("notifications", StoreNotification);
    }
    if(VUE_APP_MODE !== 'native'){
      if (!(store && store.state && store.state['modal'])) {
        store.registerModule("modal", storeModal);
      }
      if (!(store && store.state && store.state['list_view_module'])) {
        store.registerModule("list_view_module", StoreListViewModel);
      }
    }else{
      if (!(store && store.state && store.state['chat'])) {
        store.registerModule("chat", StoreChat);
      }
    }


    switch(role){
      /**
       * stores for super admin only
       **/
      case 'super':{
        const StoreAppointmentsAdmin = await StoreAppointmentsModule()
        const StoreCustomersAdmin = await StoreCustomersModule()
        const StoreAffiliatesAdmin = await StoreAffiliatesModule()
        const StoreServices = await StoreServicesModule();
        const StoreCommunication = await StoreCommunicationModule()

        if (!(store && store.state && store.state['customers'])) {
          store.registerModule("customers", StoreCustomersAdmin.default);
        }
        if(VUE_APP_MODE !== 'native'){
          const StoreAdminModule = () => import('@/store/modules/admin/admin')
          const StoreAdmin = await StoreAdminModule()
          
          if (!(store && store.state && store.state['admin'])) {
            store.registerModule("admin", StoreAdmin.default);
          }
        }

        if (!(store && store.state && store.state['affiliates'])) {
          store.registerModule("affiliates", StoreAffiliatesAdmin.default);
        }
        if (!(store && store.state && store.state['appointments'])) {
          store.registerModule("appointments", StoreAppointmentsAdmin.default);
        }
        if (!(store && store.state && store.state['search'])) {
          store.registerModule("search", StoreSearch);
        }
        if (!(store && store.state && store.state['ticket'])) {
          store.registerModule("ticket", StoreTicket);
        }
        if (!(store && store.state && store.state['services'])) {
          store.registerModule("services", StoreServices.default);
        }
        if (!(store && store.state && store.state['communication'])) {
          store.registerModule("communication", StoreCommunication.default);
        }
      break;
      }
      /**
       * stores for vendors only
       **/
      case 'vendor': {
        const StoreVendors = await StoreVendorsModule()
        const StoreAppointmentsVendor = await StoreAppointmentsModule()
        const StoreServices = await StoreServicesModule()
        const StoreOrders = await StoreOrdersModule()

        if (!(store && store.state && store.state['vendors'])) {
          store.registerModule("vendors", StoreVendors.default);
        }
        if (!(store && store.state && store.state['appointments'])) {
          store.registerModule("appointments", StoreAppointmentsVendor.default);
        }
        if (!(store && store.state && store.state['ticket'])) {
          store.registerModule("ticket", StoreTicket);
        }
        if (!(store && store.state && store.state['services'])) {
          store.registerModule("services", StoreServices.default);
        }
        if (!(store && store.state && store.state['addOnOptions'])) {
          store.registerModule("addOnOptions", StoreAddOnOptions);
        }
        if (!(store && store.state && store.state['orders'])) {
          store.registerModule("orders", StoreOrders.default);
        }
        if (!(store && store.state && store.state['search'])) {
          store.registerModule("search", StoreSearch);
        }
      break;
      }
      /**
       * stores for customers only
       **/
      case 'customer':{
        const [
          StoreCustomers,
          StoreCart,
          StoreServices,
          StoreOrders,
          StoreAppointmentsCustomer,
          StoreAffiliates,
          StoreCommunication
        ] = await Promise.all([
          StoreCustomersModule(),
          StoreCartModule(),
          StoreServicesModule(),
          StoreOrdersModule(),
          StoreAppointmentsModule(),
          StoreAffiliatesModule(),
          StoreCommunicationModule()
        ])
        // const StoreCustomers = await StoreCustomersModule()
        // const StoreCart = await StoreCartModule()
        // const StoreServices = await StoreServicesModule()
        // const StoreOrders = await StoreOrdersModule()
        // const StoreAppointmentsCustomer = await StoreAppointmentsModule()

          if (!(store.state['customers'])) {
            store.registerModule("customers", StoreCustomers.default);
          }
          if (!(store.state['services'])) {
            store.registerModule("services", StoreServices.default);
          }
          if (!(store.state['cart'])) {
            store.registerModule("cart", StoreCart.default);
          }
          if (!(store.state['ticket'])) {
            store.registerModule("ticket", StoreTicket);
          }
          if (!(store.state['orders'])) {
            store.registerModule("orders", StoreOrders.default);
          }
          if (!(store.state['appointments'])) {
            store.registerModule("appointments", StoreAppointmentsCustomer.default);
          }
          if (!(store.state['search'])) {
            store.registerModule("search", StoreSearch);
          }
          if (!(store.state['addOnOptions'])) {
            store.registerModule("addOnOptions", StoreAddOnOptions);
          }
          if (!(store && store.state && store.state['affiliates'])) {
            store.registerModule("affiliates", StoreAffiliates.default);
          }
          if (!(store && store.state && store.state['communication'])) {
            store.registerModule("communication", StoreCommunication.default);
          }
        break;
      }

      /**
       * stores for affiliates only
       **/
      case 'affiliate': {
        const StoreAffiliates = await StoreAffiliatesModule()
        const StoreCommunicationAffiliate = await StoreCommunicationModule()

        if (!(store && store.state && store.state['affiliates'])) {
          store.registerModule("affiliates", StoreAffiliates.default);
        }
        if (!(store && store.state && store.state['search'])) {
          store.registerModule("search", StoreSearch);
        }
        if (!(store && store.state && store.state['communication'])) {
          store.registerModule("communication", StoreCommunicationAffiliate.default);
        }
      break;
      }
      /**
       * stores for organizations only
       **/
      case 'organization':{ 
        const StoreOrganization = await StoreOrganizationModule()
        const StoreAppointmentsOrganization= await StoreAppointmentsModule()
        const StoreServices = await StoreServicesModule()
        const StoreOrders = await StoreOrdersModule()

        if (!(store && store.state && store.state['organizations'])) {
          store.registerModule("organizations", StoreOrganization.default);
        }
        if (!(store && store.state && store.state['appointments'])) {
          store.registerModule("appointments", StoreAppointmentsOrganization.default);
        }
        if (!(store && store.state && store.state['search'])) {
          store.registerModule("search", StoreSearch);
        }
        if (!(store && store.state && store.state['ticket'])) {
          store.registerModule("ticket", StoreTicket);
        }
        if (!(store && store.state && store.state['services'])) {
          store.registerModule("services", StoreServices.default);
        }
        if (!(store && store.state && store.state['addOnOptions'])) {
          store.registerModule("addOnOptions", StoreAddOnOptions);
        }
        if (!(store && store.state && store.state['orders'])) {
          store.registerModule("orders", StoreOrders.default);
        }
      break;
      }

    }
  }

export function removeUserStores() { // eslint-disable-line no-unused-vars
    if ((store && store.state && store.state['cart'])) {
      store.unregisterModule("cart");
    }
    if ((store && store.state && store.state['appointments'])) {
      store.unregisterModule("appointments");
    }
    if ((store && store.state && store.state['admin'])) {
      store.unregisterModule("admin");
    }
    if ((store && store.state && store.state['customers'])) {
      store.unregisterModule("customers");
    }
    if ((store && store.state && store.state['services'])) {
      store.unregisterModule("services");
    }
    if ((store && store.state && store.state['orders'])) {
      store.unregisterModule("orders");
    }
    if ((store && store.state && store.state['vendors'])) {
      store.unregisterModule("vendors");
    }
    if ((store && store.state && store.state['affiliates'])) {
      store.unregisterModule("affiliates");
    }
    if ((store && store.state && store.state['media'])) {
      store.unregisterModule("media");
    }
    if ((store && store.state && store.state['organizations'])) {
      store.unregisterModule("organizations");
    }
    if ((store && store.state && store.state['search'])) {
      store.unregisterModule("search");
    }
    if ((store && store.state && store.state['notifications'])) {
      store.unregisterModule("notifications");
    }
    if ((store && store.state && store.state['ticket'])) {
      store.unregisterModule("ticket");
    }
    if ((store && store.state && store.state['list_view_module'])) {
      store.unregisterModule("list_view_module");
    }
    if ((store && store.state && store.state['addOnOptions'])) {
      store.unregisterModule("addOnOptions");
    }
    if (!(store && store.state && store.state['modal'])) {
      store.unregisterModule("modal");
    }
    if (!(store && store.state && store.state['communication'])) {
      store.unregisterModule("communication");
    }
    if ((store && store.state && store.state['chat'])) {
      store.unregisterModule("chat");
    }
  }