import store from "@/store/store";

// Guards the onboarding screen(s).
// Redirect the user to dashboard if onboarding is already complete and they try to access the page
export const affiliateBeforeResolveOnboarding = async (_toRoute, _fromRoute, next) => {
  const { meta = {}}  = store.getters["getUserActiveAccount"];
  const { initialSetup = false, skipOnboarding = false } = meta;
  if(!initialSetup && !skipOnboarding){
    // store.commit(APP_STATE_WEB_CONTENT_WIDTH, "960px")
    return next()
  }else {
    return next('/affiliate')
  }
};
