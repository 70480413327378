
import {get} from "lodash-es"
import { i18nFormatDate } from "@/lib/i18nFormatDate";

export const formatApprovalServiceDisplayData = (approvalData) => {
    const homeSize = get(approvalData,'data.homeSize','');
    switch(approvalData.type){
      case "home_size":{
        const subscriptionName = get(approvalData,'data.subscriptionData.service_data.name','');
        return {
          name: `Home Size change request.`,
          description: `Approving this changes home size to ${homeSize}`,
          descriptionDetailed: `We would like change the home size of the address attached to your subscription ${subscriptionName} to ${homeSize}`,
          note: approvalData.note,
        }
      }
      case "start_time":{
        const startTime = get(approvalData,'data.startTime','');
        const startTimeFormatted = startTime? i18nFormatDate(new Date(startTime), "MMMM d, y pp"): '';
        const currentStartTime = get(approvalData,'data.appointmentData.start_time','');
        const currentAppointmentTimeFormatted = currentStartTime? i18nFormatDate(new Date(currentStartTime), "MMMM d, y pp"): '';
        return {
          name: `Start time change request.`,
          description: `Approving this changes start time to ${startTimeFormatted}`,
          descriptionDetailed: `We would like to change the start time of your appointment scheduled for ${currentAppointmentTimeFormatted} to ${startTimeFormatted}`,
          note: approvalData.note,
        }
      }
      
      default: 
        return {
          name: "",
          description: "",
        }
      }
  }