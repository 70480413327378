// import $socket from '../../../socket-instance';
// import auth from '../../../auth'

import {
  APPOINTMENTS_GET_ONE,
} from "@/store/action-types";

const defaultState = () => ({
  connected: false,
  authenticated: false
})

const state = defaultState()

const mutations = {
  SOCKET_CONNECT(state) {
    console.log('socketio connect~~~~~~~')
    state.connected = true
  },
  SOCKET_DISCONNECT(state) {
    console.log('socketio disconnect~~~~~~~')
    state.connected = false
    state.authenticated = false
  },
  SOCKET_AUTHENTICATED(state){
    console.log('socketio authenticate~~~~~~~')
    state.authenticated = true
  },
}

const actions = {
    async socket_ioResourceUpdate({ dispatch }, payload) {
      const { reason, resourceType, resourceId } = payload
        console.log("socket_ioResourceUpdate", payload);
        switch (resourceType) {
          case "appointment":
            if (reason === "updated") {
                console.log("yoooooooooooooo")
                await dispatch(`appointments/${APPOINTMENTS_GET_ONE}`, resourceId)
            //   store.dispatch("auth/init");
            }
            break;
        }
      }
}
// // getters
const getters = {
  socketConnected: state => state.connected
}

export default {
  state,
  getters,
  actions,
  mutations
}