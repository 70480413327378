export const affiliate = {
    topContributors: "Mayores contribuyentes",
    manageIDs: "Administrar ID de afiliados",
    noContributors: "No hay contribuyentes ¡Regístralos!",
    noAffiliateIDs: "No hay identificación de afiliado.",
    affiliateID: "ID de afiliado | ID de afiliados",
    affiliateLink: "Su enlace de afiliado es",
    marketingScreen: "Pantalla de marketing",
    contributor: "contribuyente | contribuyentes",
    contributions: "contribución | contribuciones",
    totalLessThanHundred: "El total debe ser menos del 100%",
    selectAffiliate: "Por favor seleccione un afiliado",
    contributionsBeingMadeTo: "Contribuciones hechas a",
    fundraising: "Recaudación de fondos",
    vendorsSearch: "Búsqueda de proveedores",
    contributors: "Contribuyentes",
    aboutMe: "escribe algo sobre ti",
    makePublic: "Hacer mi cuenta pública"
}