import Vue from 'vue'
import Vuex from 'vuex'

import StoreAuth from './modules/StoreAuth'
import StoreSocketIO from './modules/StoreSocketIO'
import StoreUser from './modules/StoreUser'
import StoreAppState from './modules/StoreAppState'
import StoreInvoice from './modules/StoreInvoice'

Vue.use(Vuex)

/*
 * Other stores are dynamically registered via @/plugins/pluginAuth.js
 */
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth: StoreAuth,
    user: StoreUser,
    app: StoreAppState,
    invoice: StoreInvoice,
    socketIO: StoreSocketIO
  },
})
