
<template>
    <div class="border-t pt-4 mt-2">
        <div class="font-medium">{{addOnDisplayData.name}}</div>
        <div class="text-sm">{{addOnDisplayData.description}}</div>
    </div>
</template>

<script>
import { formatApprovalServiceDisplayData } from "@/helpers/approvals"

export default {
    props: {
        slotData: {
            type: Object,
            default(){
                return {}
            }
        },
    },
    computed: {
        addOnDisplayData() {
        const { slotData } = this;
        return formatApprovalServiceDisplayData(slotData)
        },
    }
}
</script>