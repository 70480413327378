import store from '@/store/store'

const redirectToLogin = (next, redirectFrom) => next({ path: '/login', query: { redirectFrom } })
const redirectToError = (next) => next({ path: '/not-authorized' })
const isRouteAuthenticationRequired = (toRoute) =>  ({
  required: toRoute.matched.some(route => route.meta.requiresAuth),
  allowed: toRoute.meta.allowedRoles
})

export const canAccessRoute = async (
  toRoute,
  fromRoute,
  next
) => {
  const redirectFrom = ['/', '/register', '/not-authorized'].includes(fromRoute.fullPath) ? undefined : fromRoute.fullPath

  // Check whether the route requires authentication. If not, continue.
  const {required, allowed} = isRouteAuthenticationRequired(toRoute)
  if (!required) {
    return next()
  }

  // Route requires authentication. Check to make sure they are logged in.
  const isLoggedIn = store.getters['isUserLoggedIn']

  if (isLoggedIn) {
    try {
      // User is logged in. Check to see if the user's role is allowed
      // to access the requested route. If not, redirect them to error page.
      const role = store.getters['getUserRole']
      const isAllowed = allowed.includes(role)

      if (!isAllowed) {
        return redirectToError(next)
      }

      // User is allowed to access the requested route, continue.
      return next()
    }catch(e){
      // Possibly there was an issue with token verification from server
      return redirectToLogin(next, redirectFrom)
    }
  }

  return redirectToLogin(next, redirectFrom)
}
