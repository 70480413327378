import { pluralizeText } from "../helpers";

import {
  startOfDay,
  parseISO,
  isSameDay,
  format,
  addMinutes,
  differenceInCalendarDays,
} from "date-fns/esm";

import { get, isNumber } from "lodash-es"
import { allDayTimesWithSep } from "../dropdownItems";

import { getServicePriceByPlanId, getPlanFromService } from "../order";

import i18n from "@/plugins/i18n";

import { i18nFormatDate } from "@/lib/i18nFormatDate";

export const getStatusTagType = (status) => {
  switch (status) {
    case "active":
      return "success";
    case "complete":
      return "info";
    case "canceled":
      return "info";
    case "danger":
      return "warning";
    default:
      return "info";
  }
};

export const getAppointmentStatusColor = (status) => {
  switch (status) {
    case "complete":
      return "bg-green-800";
    case "canceled":
      return "bg-red-600";
    case "danger":
      return "bg-yellow-600";
    case "exception":
      return "bg-yellow-600";
    case "active":
      return "bg-green-300";
    default:
      return "bg-gray-500";
  }
};

export const getVendorColorFromAppointment = (appointment) => {
  if (appointment.vendor_data && appointment.vendor_data.schedule_color) {
    return appointment.vendor_data.schedule_color;
  }
  return "#333";
};

export const getDurationNiceName = (duration) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const hourText = pluralizeText(i18n.t("timeOfDay.hour"), hours);
  const minuteText = pluralizeText(i18n.t("timeOfDay.minute"), minutes);
  //
  if (hours <= 0) {
    return `${minutes} ${minuteText}`;
  } else if (hours > 0 && minutes <= 0) {
    return `${hours} ${hourText}`;
  } else {
    return `${hours} ${hourText} ${minutes} ${minuteText}`;
  }
};

export const buildTimeSlotDuration = (doc) => {
  let startTime = format(parseISO(doc.start_time), "HH:mm");
  let endTime = format(
    addMinutes(parseISO(doc.start_time), doc.duration),
    "HH:mm"
  );
  let times = allDayTimesWithSep;
  times = times.filter((el) => el >= startTime && el <= endTime);
  return times;
};

export const getTimeFromArrayPos = (pos) => {
  const format1 = allDayTimesWithSep[pos].substr(0, 2);
  const format2 = allDayTimesWithSep[pos].substr(2, 4);
  const time = `${format1}${format2}`;
  return time;
};

export const parseDayAppointmentsWithSlots = (appointments, opt) => {
  const options = Object.assign(
    {
      day: new Date(),
      dayStart: 31,
      dayEnd: 81,
      timeSlot: 15,
    },
    opt
  );

  if (
    !appointments
      .map((a) => a.start_time)
      .every((el, index, arr) => isSameDay(parseISO(arr[0]), parseISO(el)))
  ) {
    throw new Error("Appointments passed in are not all the same date");
  }

  const numSlots = 1440 / options.timeSlot;
  const timesOut = {};
  for (const [i] of new Array(numSlots).entries()) {
    if (i <= options.dayStart || i >= options.dayEnd) continue;
    const thisTime = getTimeFromArrayPos(i);
    timesOut[thisTime] = appointments.filter((s) => {
      const times = buildTimeSlotDuration(s);
      return times.includes(thisTime);
    });
  }

  return timesOut;
};

export const pastAppointment = (start_time) => {
  return (
    differenceInCalendarDays(
      startOfDay(new Date()),
      startOfDay(new Date(start_time))
    ) >= 1
  );
};

export const getAppointmentServiceName = (appointment) => {
  if (!appointment) return "";
  if(appointment.service_data){
    const { service_data = {} } = appointment;
    const { name = "" } = service_data;
    return name;
  }
  return ""
};


export const getAppointmentCustomerId = (appointment) => {
  if (!appointment) return "";
  const { customer_data = {} } = appointment;
  const { _id = "" } = customer_data;
  return _id;
};

export const getAppointmentCustomerName = (appointment) => {
  if (!appointment) return "";
  const { customer_data = {} } = appointment;
  const { first_name = "", last_name = "" } = customer_data;
  return `${first_name} ${last_name}`;
};


export const getAppointmentCustomerEmail = (appointment) => {
  if (!appointment) return "";
  const { customer_data = {} } = appointment;
  const { primary_email = "" } = customer_data;
  return primary_email;
};



export const getAppointmentVendorName = (appointment) => {
  if (!appointment) return "";
  if(appointment.vendor_data){
    const { vendor_data = {} } = appointment;
    const { first_name = "", last_name = "" } = vendor_data;
    return `${first_name} ${last_name}`;
  }
  return ""
};

export const getAppointmentVendorEmail = (appointment) => {
  if (!appointment) return "";
  if(appointment.vendor_data){
    const { vendor_data = {} } = appointment;
    const { primary_email = "" } = vendor_data;
    return `${primary_email}`;
  }
  return ""
};

export const getAppointmentVendorId = (appointment) => {
  if (!appointment) return "";
  const { vendor_data = {} } = appointment;
  const { _id = "" } = vendor_data;
  return _id;
};


export const getAppointmentDateDay = (appointment) => {
  if (!appointment) return "";
  const { start_time } = appointment;
  try {
    return !start_time
      ? ""
      : i18nFormatDate(new Date(start_time), "eeee", i18n.locale);
  } catch (error) {
    return start_time;
  }
};
export const getAppointmentDateDisplay = (appointment) => {
  if (!appointment) return "";
  const { start_time } = appointment;
  try {
    return !start_time
      ? ""
      : i18nFormatDate(new Date(start_time), "PPP", i18n.locale);
  } catch (error) {
    return start_time;
  }
};

export const getAppointmentDateDisplayFull = (appointment) => {
  if (!appointment) return "";
  const { start_time } = appointment;
  try {
    return !start_time
      ? ""
      : i18nFormatDate(new Date(start_time), "PPPP", i18n.locale);
  } catch (error) {
    return start_time;
  }
};

export const getAppointmentDateFormat = (appointment) => {
  if (!appointment) return "";
  const { start_time } = appointment;
  try {
    return !start_time
      ? ""
      : i18nFormatDate(new Date(start_time),  "M/dd/yyyy", i18n.locale);
  } catch (error) {
    return start_time;
  }
}

export const getAppointmentAddOns = (appointment) => {
  if (!appointment) return [];
  const { appointmentAddOnData = [], subscriptionAddOnData = [] } = appointment;
  return [...appointmentAddOnData, ...subscriptionAddOnData];
};

export const getAppointmentPlanAmount = (appointment) => {
  if (!appointment) return "";
  if(appointment.service_data && appointment.order_data){
    const { service_data = {}, order_data = {} } = appointment;
    const { plan_id = "" } = order_data;
    return getServicePriceByPlanId(plan_id, service_data);
  }
  return ""
};

export const getAppointmentPlanName = (appointment) => {
  if (!appointment) return "";
  if(appointment.service_data && appointment.order_data){
    const { order_data = {}, service_data = {} } = appointment;
    const { plan_id = "" } = order_data;
    const plan = getPlanFromService(plan_id, service_data)
    return plan && plan.label ? plan.label : ""
  }
  return ""
};

export const getAddOnPlanAmount = (addOn) => {
  if (!addOn) return 0;
  if(addOn.custom && addOn.custom.value){
    return addOn.custom.value;
  }
  if(addOn.service_data && addOn.plan_id){
    const { service_data = {}, plan_id = "" } = addOn;
    return getServicePriceByPlanId(plan_id, service_data);
  }
  return 0
};

export const getAddOnsTotalPrice = (appointment) => {
  const addons = getAppointmentAddOns(appointment)
  if (!addons || !addons.length) return 0;

  let value = 0;
  for (const addon of addons) {
    const price = getAddOnPlanAmount(addon)
    if(isNumber(price)){
      value = value + price
    }
  }
  return isNumber(value) ? value : 0
};


export const getAppointmentAddressFormatted = (appointment) => {
  if (!appointment) return "";
  return get(appointment, "address_data.address_formatted", "")
};

export const getAppointmentAddressStreet = (appointment) => {
  if (!appointment) return "";
  const streetNumber = get(appointment, "address_data.street_number", "")
  const streetName = get(appointment, "address_data.street", "")
  return `${streetNumber} ${streetName}`
};

export const getAppointmentAddressCity = (appointment) => {
  if (!appointment) return "";
  return get(appointment, "address_data.city", "")
};

export const getAppointmentAddressZip = (appointment) => {
  if (!appointment) return "";
  return get(appointment, "address_data.postal_code", "")
};

export const getAppointmentAddressState = (appointment) => {
  if (!appointment) return "";
  return get(appointment, "address_data.state", "")
};

export const getAppointmentAddressNotes = (appointment) => {
  if (!appointment) return "";
  if(appointment.address_data){
    const { address_data = {} } = appointment;
    const { address_meta = {} } = address_data;
    const { additional_details = "" } = address_meta;
    return additional_details;
  }
  return ""
};

export const getAppointmentServiceCategoryName = (appointment) => {
  if (!appointment) return "";
  if(appointment.service_data && appointment.service_data.category){
    const { service_data = {} } = appointment;
    const { category = {} } = service_data;
    const { name = "" } = category;
    return name;
  }
  return ""
};

export const getAppointmentOpenTickets = (appointment) => {
  if (!appointment) return [];
  const { tickets_data = [] } = appointment;
  return tickets_data.filter(ticket => ticket.status === 'open');
};
