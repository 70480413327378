<template>
  <CustomDropdownRoot
    :dropDownItems="dropDownItems"
    :selectedValue="selectedValue"
    :preventPopover="preventPopover"
    @onClickPlaceholder="$emit('onClickPlaceholder', $event)"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @customAction="$emit('customAction', $event)"
  >
    <template v-slot:labelHasValue="{ selected }">
      <div class="flex items-center justify-start">
        <div class="mr-4 relative overflow-hidden" style="width:38px;height:27px"><img
          class="top-0 absolute max-w-none"
          :style="getCardTypeStyle(selected.brand)"
          src="@/assets/web/images/creditcard-icons.svg"
        /></div>
       
        <div class="flex justify-between items-center ">
           <span class="text-gray-900"
          >**** **** **** <span v-if="selected">{{ selected.label }}</span></span
        >
          <div class="text-xs text-gray-600 ml-3">Expires: {{ selected.data.exp_month }}/{{ selected.data.exp_year }}</div>
        </div>
      </div>

    </template>
    <template v-slot:labelNoValue>Add Payment Method</template>
    <template v-slot:innerDropdownItem="{ item }">
      <div class="flex justify-start items-center">
        <div class="mr-4 relative overflow-hidden" style="width:38px;height:27px"><img
          class="top-0 left-0 absolute max-w-none"
          :style="getCardTypeStyle(item.data.brand)"
          src="@/assets/web/images/creditcard-icons.svg"
        /></div>
        <div class="flex justify-between items-center flex-grow">
          <div>**** **** **** {{ item.label }}</div>
          <div class="text-xs text-gray-600 ml-3">Expires: {{ item.data.exp_month }}/{{ item.data.exp_year }}</div>
        </div>

      </div>
    </template>

    <template v-slot:innerContent="{ closePopover, emitCustomAction }">
      <div
        class="cursor-pointer py-4 px-4 hover:bg-gray-200 flex justify-start items-center text-gray-600"
        @click="emitCustomAction({ key: 'addNewCard' }); closePopover()"
      >
        <font-awesome-icon icon="plus" class="mr-3"></font-awesome-icon>
        <span class="text-md">Add New</span>
      </div>
    </template>
  </CustomDropdownRoot>
</template>
      
<script>
import { get } from "lodash-es"
import CustomDropdownRoot from "./CustomDropdownRoot";
export default {
  name: "customDropdownPayment",
  components: { CustomDropdownRoot },
  props: {
    selectedValue: {
      type: [Array, Object, String, Number, Date],
      default() {
        return null;
      },
    },
    dropDownItems: {
      type: [Array],
      default() {
        return null;
      },
    },
    preventPopover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getCardTypeStyle(brand){
      const brandMap = {
        Visa: 'left:0;width:128px;',
        Mastercard: 'left:-44px;width:128px;',
        AmericanExpress: 'left:-90px;width:128px;'
      }
      return get(brandMap, brand, brandMap['Visa'])
    }
  }
};
</script>
