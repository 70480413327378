<template>
  <div
    @mouseover="isMouseOver = true"
    @mouseleave="isMouseOver = false"
    @mousedown="isMouseFocus = true"
    @mouseup="isMouseFocus = false"
    @click="handleUpdate"
    :class="outerClass"
  >
    <!-- a mask to make this test not :selectable -->
    <div class="absolute top-0 left-0 w-full h-full z-10" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"></div>
    <!-- end mask -->
    <div :class="innerClass">
      <div class="w-4" v-if="showRadio">
        <RadioBlockFieldInnerButton
          :hover="isMouseOver"
          :checked="active"
          :focus="isMouseFocus"
          :disabled="disabled"
          :showAsCheckbox="showAsCheckbox"
        />
      </div>
    <div>
    </div>
     <slot name="labelHasValue">
       <span :class="labelClass"
          >{{ label }}</span
        >
     </slot>
    </div>
  </div>
</template>

<script>

import RadioBlockFieldInnerButton from "./RadioBlockFieldInnerButton";

export default {
  components: {
    RadioBlockFieldInnerButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayDirection: {
      type: String,
      default: "vertical"
    },
    showRadio: {
      type: Boolean,
      required: false,
      default: true
    },
    showAsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    reverseInner: {
      type: Boolean,
      required: false,
      default: false
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isMouseOver: false,
      isMouseFocus: false
    };
  },
  methods: {
    handleUpdate() {
      if(!this.disabled){
        const { value } = this.$props;
        this.$emit("input", value);
        this.$emit("change", value);
      }
    }
  },
  computed: {
    outerClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection
      } = this;

      const baseClasses = [
        "relative",
        "border",
        "border-gray-400",
        "flex"
      ];
      if(this.rounded) {
        baseClasses.push("rounded-md");
      }

      const hoverClass = ["border-green-900"];
      const focusClass = ["border-gray-300", "shadow-sm"];
      const selectedClass = [
        "bg-green-900",
        "border-green-900",
        "text-white",
        "scale-105"
      ];

      const isStyleVertical = ["py-4", "px-6"];
      const isStyleHorizontal = ["py-2", "px-4", "items-center"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    innerClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        disabled,
        displayDirection,
        reverseInner
      } = this;

      const baseClasses = ["w-full", "flex"];

      const hoverClass = [];
      const focusClass = [];
      const selectedClass = [];

      const isStyleVertical = [`${reverseInner ? "flex-col-reverse" : "flex-col"}`, "justify-center items-center"];
      const isStyleHorizontal = [`${reverseInner ? "flex-row-reverse" : "flex-row"}`, "justify-start items-center"];
      const disabledClass = ["opacity-30", "bg-opacity-50"];

      return Object.assign(
        {},
        Object.fromEntries(disabledClass.map((i) => [i, disabled])),
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    labelClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection
      } = this;

      const baseClasses = [
        "select-none",
        "whitespace-nowrap",
        "font-medium"
      ];

      const hoverClass = [];
      const focusClass = [];
      const selectedClass = [];

      const isStyleVertical = ["mt-3"];
      const isStyleHorizontal = ["ml-3"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    }
  }
};
</script>
