<template web>
<div :class="pageWrapperClass" id="app-id">
  <transition name="component-fade" mode="out-in">
  <template v-if="!setup"><PublicScreenNotReady>Loading</PublicScreenNotReady></template>
  <template v-else-if="!loggedIn"><router-view  name="public"/></template>
  <template v-else>

    <div>
      <component :is="getLayoutComponent" :class="`user-${userRole}`" :maxWidthContent="getWebContentWidth" :showHeaderLogoAsLink="routeMeta.showHeaderLogoAsLink" :isLoading="false || asyncComponentLoading" :headerRightClasses="routeMeta.headerRightClasses" :headerRightInnerClasses="routeMeta.headerRightInnerClasses">
        <template v-slot:header>
          <router-view name="header" />
          
          <AppMenu v-if="!routeMeta.hideNavigation && userStoreIsReady && loggedIn && userRole !== 'none' && !routePath.includes('alert')" :role="userRole" />
          
          <a v-else-if="currentPageName === 'CustomerOnboarding'" @click="showModalShareLink" class="hidden md:inline  ml-12 mr-0 md:mr-16 text-xs text-gray-600 font-medium cursor-pointer highlight-link bg-white mt-4 sm:mt-0">What is Wemow?</a>

          <router-link v-else
            to="/logout"
            class="text-gray-500 text-sm cursor-pointer ml-12"
          > 
            <span>{{ $t("menu.logout") }}</span>
          </router-link>


        </template>
        <template v-slot:body>
          <router-view />
        </template>

      <template v-slot:after>
            <router-view name="footer" />
      </template>
      </component>
    </div>
  </template>
    </transition>
</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { throttle, get } from "lodash-es";
import { getBrowserLanguage, loadingTimeout, setupWemowWebChat } from "@/helpers/helpers"
import { APP_SET_WINDOW } from "@/store/mutation-types";

import PublicViewWrap from "@/components/public/views/PublicViewWrap"
import PublicViewWrapFullScreen from "@/components/public/views/PublicViewWrapFullScreen"
import PublicScreenNotReady from "@/components/public/views/PublicScreenNotReady"
import PublicViewLoaderBlock from "@/components/public/views/PublicViewLoaderBlock"
import AppMenu from "@/components/global/app-menu-horizontal/AppMenu.vue";
import RegisterWhatIsWemowModal from "@/components/public/register/modules/RegisterWhatIsWemowModal";
import { SocketIOMixin } from "@/mixins/web/socketIOMixin"
import {
  CUSTOMER_SAVE_INFO,
  ORGANIZATION_SAVE_BASIC,
  VENDOR_SAVE_UPDATE_PROFILE,
  AFFILIATE_SAVE_UPDATE_PROFILE
} from '@/store/action-types'

export default {
  name: "app",
  mixins: [SocketIOMixin],
  components: {
    AppMenu,
    PublicViewWrap,
    PublicViewLoaderBlock,
    PublicScreenNotReady,
    PublicViewWrapFullScreen,
    RegisterWhatIsWemowModal

  },
  watch: {
    currentPageName(name){
      if(name && get(this.$route, 'meta.requiresAuth')) {
          this.$store.dispatch("SET_SCREEN_NAME",name)
      }
    },
    loggedIn(isLoggedIn){
      if(!isLoggedIn){
        this.$socket.client.close();
        this.$router.replace('/').catch(()=>{})
      }
      else{
        this.$socket.client.open();
        if(this.$store.state.route.from){
          let redirectTo = '/'
          if(this.$route.query['redirectTo']){
           redirectTo = this.$route.query['redirectTo']
          }
          this.$router.replace(redirectTo).catch(()=> {})
        }
      }
    },
    getUserLocale: {
      immediate: true,
      handler(){
        this.$i18n.locale = this.getUserLocale || getBrowserLanguage() || 'en'
      }
    },
    userStoreIsReady: {
      immediate: true,
      handler(v){
        if(v && this.changeLanguage){
          switch(this.userRole){
            case 'customer': {
              const updatedData = {
                _id: this.activeAccount._id,
                settings: {
                  language_preference: getBrowserLanguage()
                }
              }
              this.$store.dispatch(`customers/${CUSTOMER_SAVE_INFO}`, updatedData)
              break;
            }
            case 'organization': {
              const orgData = {
                organization_id: this.activeAccount._id,
                data: {
                  settings: {
                    language_preference: getBrowserLanguage()
                  }
                }
              }
              this.$store.dispatch(`organizations/${ORGANIZATION_SAVE_BASIC}`, orgData)
              break;
            }
            case 'affiliate': {
              const data = {
                affiliateID: this.activeAccount._id,
                data: {
                    settings: {
                      language_preference: getBrowserLanguage()
                    }
                  }
              }
              this.$store.dispatch(`affiliates/${AFFILIATE_SAVE_UPDATE_PROFILE}`, data)
              break;
            }
            case 'vendor': {
              const vendorData = {
                vendorID: this.activeAccount._id,
                data: {
                  settings: {
                    language_preference: getBrowserLanguage()
                  }
                }
              }
              this.$store.dispatch(`vendors/${VENDOR_SAVE_UPDATE_PROFILE}`, vendorData)
              break;
            }
          }
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      error: false,
      setup: false,
      showNotificationPanel: false,
    };
  },
  created() {
    const debounced = throttle(this.appHandleResize, 1000);
    window.addEventListener("resize", debounced);
  },
  mounted(){
    this.appHandleResize();
    loadingTimeout(1100).then(()=> {
      this.setup = true;
    })
    .finally(() => setupWemowWebChat(this.$wemowActiveUserType()))
  },
  destroyed() {
    const debounced = throttle(this.appHandleResize, 1000);
    window.removeEventListener("resize", debounced);
  },
  methods: {
    appHandleResize() {
      this.$store.commit(APP_SET_WINDOW, window);
    },
    showModalShareLink() {
      this.$modal.show(
        RegisterWhatIsWemowModal,
        {},
        {
          name: "something",
          maxHeight: "80%",
          width: "720px",
          height:"400px",
          minHeight: `480px`,
          clickToClose: true,
          adaptive: true,
          // classes: "p-12",
        },
      );
    },
  },
  computed: {
    ...mapState({
      asyncComponentLoading: state => state.app.asyncComponentLoading
    }),
    currentPageName() {
      return this.$route.name
    },
    loggedIn: function() {
      return this.$store.getters.isUserLoggedIn;
    },
    userRole: function() {
      return this.$store.getters.getUserRole;
    },
    getUserLocale: function(){
      let locale = this.$store.getters.getUserLocale
      return locale
    },
    changeLanguage: function(){
      if(this.getUserLocale === 'default'){
        return true
			}
      return false
    },
    activeAccount: function() {
      return this.$store.getters.getUserActiveAccount;
    },
    loadedNotifications: function() {
      const notiStore = this.$store.state["notifications"];
      return notiStore;
    },
    ...mapGetters({
      showMenu: "isMenuOpen",
      getScreenSize: "getScreenSize",
      getWebContentWidth: "getWebContentWidth"
    }),
    disableMenu: function(){
      const { hideNavigation } =  this.$store.state.route.meta
      return hideNavigation
    },
    userStoreIsReady() {
      return this.$store.getters.getStoreIsReady;
    },
    routePath() {
      return get(this.$route, "path", "");
    },
    routeMeta(){
      const meta = get(this.$route, "meta", {});
      const defaults = {hideNavigation: false, requiresAuth: false, allowedRoles: [], showFooter: false, showHeaderLogoAsLink: true, maxWidthContent: '960px', layoutTemplate: 'default', }
      return Object.assign({}, defaults,  meta )

    },
    pageWrapperClass(){
      const { userRole, getUserLocale, loggedIn, routePath } = this;
      return `role-${userRole} is-logged-${loggedIn ? 'in' : 'out'} language-${getUserLocale} page${routePath}`.replace(/\//ig, '-')
    },
    getLayoutComponent(){
      const { layoutTemplate } = this.routeMeta;
      switch (layoutTemplate) {
        case 'fullScreen':
          return PublicViewWrapFullScreen;
        case 'loading-block': 
          return PublicViewLoaderBlock
        case 'loading': 
        return PublicScreenNotReady;
        default:
          return PublicViewWrap;
      }
    }
  },
};
</script>

<style scoped>

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .16s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
