var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.resource
        ? [
            _c("div", { staticClass: "flex items-center" }, [
              _c(
                "span",
                {
                  staticClass: "text-xl font-bold cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.copyText(_vm.appointment.serviceName)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.appointment.serviceName))]
              ),
              _c(
                "span",
                {
                  staticClass: "text-xs ml-6 cursor-pointer",
                  on: { click: _vm.goTo },
                },
                [_vm._v("View")]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "mb-2 text-xs",
                on: {
                  click: function ($event) {
                    return _vm.copyText(_vm.appointment.addressFormatted)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.appointment.addressFormatted) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "mb-0.5",
                on: {
                  click: function ($event) {
                    return _vm.copyText(_vm.appointment.dateDisplayFull)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.appointment.dateDisplayFull) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "mb-0.5",
                on: {
                  click: function ($event) {
                    return _vm.copyText(_vm.appointment.vendorName)
                  },
                },
              },
              [_vm._v(" Vendor: " + _vm._s(_vm.appointment.vendorName) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "mb-0.5",
                on: {
                  click: function ($event) {
                    return _vm.copyText(_vm.appointment.customerName)
                  },
                },
              },
              [
                _vm._v(
                  " Customer: " + _vm._s(_vm.appointment.customerName) + " "
                ),
              ]
            ),
          ]
        : _c("p", [_vm._v("No Details")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }