import axios from '../lib/axios'
import auth from '../auth'

const {
  VUE_APP_API_SERVER: DB_URL,
} = process.env;

export function organizationGetAllSearch(query) {
  return axios.get(DB_URL + 'search/organizations', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((organizations) => Promise.resolve(organizations))
    .catch((error) => Promise.reject(error))
}

export function vendorGetAllSearch(query) {
  return axios.get(DB_URL + 'search/vendors', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((vendors) => Promise.resolve(vendors))
    .catch((error) => Promise.reject(error))
}

export function vendorInLocationSearch(query) {
  return axios.get(DB_URL + 'vendors/location-search', {
    headers: auth.getAuthHeader(),
    params: query
  })
  .then((vendors) => Promise.resolve(vendors))
  .catch((error) => Promise.reject(error))
}

export function customerGetAllSearch(query) {
  return axios.get(DB_URL + 'search/customers', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((customers) => Promise.resolve(customers))
    .catch((error) => Promise.reject(error))
}

export function userGetAllSearch(query) {
  return axios.get(DB_URL + 'search/users', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((users) => Promise.resolve(users))
    .catch((error) => Promise.reject(error))
}

export function affiliateGetAllSearch(query) {
  return axios.get(DB_URL + 'search/affiliates', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((affiliates) => Promise.resolve(affiliates))
    .catch((error) => Promise.reject(error))
}

export function affiliateIdGetAllSearch(query) {
  return axios.get(DB_URL + 'search/affiliates_id', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((affiliates) => Promise.resolve(affiliates))
    .catch((error) => Promise.reject(error))
}

export function searchServices(query) {
  return axios.get(DB_URL + 'search/services', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((services) => Promise.resolve(services))
    .catch((error) => Promise.reject(error))
}
export function searchZipcodes(query) {
  return axios.get(DB_URL + 'search/zipcodes', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((services) => Promise.resolve(services))
    .catch((error) => Promise.reject(error))
}

export function searchAccounts(query) {
  return axios.get(DB_URL + 'accounts/search', {
      headers: auth.getAuthHeader(),
      params: query
    })
    .then((accounts) => Promise.resolve(accounts))
    .catch((error) => Promise.reject(error))
}
