var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicViewWrap", {
    attrs: {
      maxWidthContent: ["affiliate", "organization"].includes(
        _vm.registrationType
      )
        ? "1040px"
        : "500px",
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                !_vm.success
                  ? _c(
                      "div",
                      [
                        _vm.error
                          ? _c("el-alert", {
                              attrs: { title: _vm.error, type: "error" },
                            })
                          : _vm._e(),
                        _vm.registrationType === "affiliate"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center uppercase text-green-900 text-sm font-medium mb-2",
                                },
                                [
                                  _vm._v(
                                    "Become an Affiliate - Join the Wemow Gives Back Program"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xl md:text-2xl font-medium text-gray-800 mb-2 text-center max-w-xl mx-auto",
                                },
                                [
                                  _vm._v(
                                    "We partner with individuals like you to make an impact in the communities we serve. "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex mt-6 md:mt-16 flex-col-reverse md:flex-row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full md:w-1/2 pr-0 md:pr-8 mt-12 md:mt-0",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { margin: "0 auto 24px" },
                                        attrs: {
                                          src: require("@/assets/web/images/fence-image.png"),
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 text-lg font-medium text-center",
                                        },
                                        [
                                          _vm._v(
                                            "Get the word out and start getting paid!"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-8 text-gray-700 text-center",
                                        },
                                        [
                                          _vm._v(
                                            "Refer your friends, family - or whoever - to the most convenient lawn care company around."
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "Unlimited Growth Potential"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Make money on every service completed. "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "All the Tools to Help You Grow"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Get social, start emailing and more."
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "A Lawn Service You Can Feel Good About"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Help your community get more out of the lawn services they're already paying for."
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-full md:w-1/2 px-8" },
                                    [
                                      _c("RegisterFields", {
                                        attrs: {
                                          accountConfirmationMethod:
                                            _vm.account_confirmation_method,
                                          loading: _vm.loading,
                                          registrationType:
                                            _vm.registrationType,
                                        },
                                        on: {
                                          registerAction: _vm.doRegisterAction,
                                        },
                                      }),
                                      _c("SignInWith", {
                                        attrs: {
                                          orPosition: "top",
                                          signInOrSignUp: "Up",
                                        },
                                        on: {
                                          signIn: _vm.doAltRegisterAction,
                                          signInError: function ($event) {
                                            _vm.error = _vm.$t(
                                              "errorMessages.errorLoggingIn"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm.registrationType === "organization"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center uppercase text-green-900 text-sm font-medium mb-2",
                                },
                                [
                                  _vm._v(
                                    "Become an Provider - Join the Wemow Gives Back Program"
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xl md:text-2xl font-medium text-gray-800 mb-2 text-center max-w-xl mx-auto",
                                },
                                [
                                  _vm._v(
                                    "We partner with individuals like you to make an impact in the communities we serve. "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex mt-6 md:mt-16 flex-col-reverse md:flex-row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full md:w-1/2 pr-0 md:pr-8 mt-12 md:mt-0",
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { margin: "0 auto 24px" },
                                        attrs: {
                                          src: require("@/assets/web/images/fence-image.png"),
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 text-lg font-medium text-center",
                                        },
                                        [
                                          _vm._v(
                                            "Get the word out and start getting paid!"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-8 text-gray-700 text-center",
                                        },
                                        [
                                          _vm._v(
                                            "Refer your friends, family - or whoever - to the most convenient lawn care company around."
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "Unlimited Growth Potential"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Make money on every service completed. "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "All the Tools to Help You Grow"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Get social, start emailing and more."
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-7 flex pl-6" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "text-3xl -mt-3 -mr-1 text-green-900",
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "font-medium" },
                                              [
                                                _vm._v(
                                                  "A Lawn Service You Can Feel Good About"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-sm text-gray-700",
                                              },
                                              [
                                                _vm._v(
                                                  "Help your community get more out of the lawn services they're already paying for."
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "w-full md:w-1/2 px-8" },
                                    [
                                      _c("RegisterFields", {
                                        attrs: {
                                          accountConfirmationMethod:
                                            _vm.account_confirmation_method,
                                          loading: _vm.loading,
                                          registrationType:
                                            _vm.registrationType,
                                        },
                                        on: {
                                          registerAction: _vm.doRegisterAction,
                                        },
                                      }),
                                      _c("SignInWith", {
                                        attrs: {
                                          orPosition: "top",
                                          signInOrSignUp: "Up",
                                        },
                                        on: {
                                          signIn: _vm.doAltRegisterAction,
                                          signInError: function ($event) {
                                            _vm.error = _vm.$t(
                                              "errorMessages.errorLoggingIn"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _c(
                              "div",
                              [
                                _vm.error
                                  ? _c("el-alert", {
                                      attrs: {
                                        title: _vm.error,
                                        type: "error",
                                      },
                                    })
                                  : _vm._e(),
                                _c("SignInWith", {
                                  attrs: {
                                    orPosition: "bottom",
                                    signInOrSignUp: "Up",
                                  },
                                  on: {
                                    signIn: _vm.doAltRegisterAction,
                                    signInError: function ($event) {
                                      _vm.error = _vm.$t(
                                        "errorMessages.errorLoggingIn"
                                      )
                                    },
                                  },
                                }),
                                _c("RegisterFields", {
                                  attrs: {
                                    accountConfirmationMethod:
                                      _vm.account_confirmation_method,
                                    loading: _vm.loading,
                                    registrationType: _vm.registrationType,
                                  },
                                  on: { registerAction: _vm.doRegisterAction },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "after",
        fn: function () {
          return [
            _c("RegisterFooter", {
              staticClass: "mt-12",
              attrs: { registrationType: _vm.registrationType },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }