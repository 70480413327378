import i18n from "@/plugins/i18n";

export const MenuItemsOrganization = [{
    text: i18n.t('menu.manageTeam'),
    to: '/organization/vendors',
    id: 'MenuItemOrganizationVendors'
},{
    to: "/organization/appointments",
    text: i18n.t('menu.workSchedule'),
    id: 'MenuItemOrganizationAppointments'
},{
    to: "/organization/help-desk",
    text: i18n.t('menu.myTickets'),
    id: 'MenuItemOrganizationHelp',
    indicator: true
},
{
    text: "Invoices",
    to: '/organization/list-invoices',
    id: 'MenuItemOrganizationInvoices'
}]

export const AccountMenuOrganization = [{
    text: i18n.t('menu.mySettings'),
    to: '/organization/profile',
    id: 'MenuItemOrganizationSettings'
},{
    text: 'Transfers',
    to: '/organization/view-transfers',
    id: 'MenuItemOrganizationPayments'
},
{
    text: 'Payouts',
    to: '/organization/view-payouts',
    id: 'MenuItemOrganizationPayouts'
}]