import {
  vendorBeforeResolve,
  vendorBeforeResolveOnboarding
} from "../../middleware";

export const vendorRoutes = [
    /*
     * Vendor Routes
     */
    {
      path: "/vendor/onboarding",
      name: "VendorOnboarding",
      component: () =>
        import(
          /* webpackChunkName: "vendor-onboarding" */ `../../../../components/vendor/onboarding/VendorSetup.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], hideNavigation: true, showHeaderLogoAsLink: false, beforeResolve: vendorBeforeResolveOnboarding  }
    },
    {
      path: "/vendor",
      name: "VendorDashboard",
      component: () =>
        import(
          /* webpackChunkName: "vendor-dashboard" */ `../../../../components/vendor/dashboard/VendorDashboard.vue`
        ),
      
      meta: { requiresAuth: true, allowedRoles: ["vendor"], showHeaderLogoAsLink: false, beforeResolve: vendorBeforeResolve }
    },
    {
      path: "/vendor/profile",
      name: "VendorProfile",
      component: () =>
        import(
          /* webpackChunkName: "vendor-profile" */ `../../../../components/vendor/profile/VendorProfile.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve }
    },
    {
      path: "/vendor/availability",
      name: "VendorAvailability",
      component: () =>
        import(
          /* webpackChunkName: "vendor-availability" */ `../../../../components/vendor/availability/VendorAvailability.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve }
    },
    {
      path: "/vendor/appointments",
      name: "VendorAppointmentArchive",
      component: () =>
        import(
          /* webpackChunkName: "vendor-appointments-archive" */ `../../../../components/shared/AppointmentsDisplay/AppointmentsDisplay.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve },
      props: {
        roleType: "vendor",
        defaultView: 'day',
        linkPath: "vendor/appointment",
        allowCustomerFilter: true,
      }
    },
    {
      path: "/vendor/appointment/:id",
      name: "VendorAppointmentSingle",
      component: () =>
        import(
          /* webpackChunkName: "vendor-appointment-single" */ `../../../../components/v2/shared/appointments/SingleView.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve },
      props: {
        userType: "vendor"
      }
    },
    {
      path: "/vendor/help-desk",
      name: "VendorHelpdeskDashboard",
      component: () =>
        import(
          /* webpackChunkName: "vendor-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketDashboard.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve  },
    },
    {
      path: "/vendor/help-desk/history",
      name: "VendorHelpdeskTicketHistory",
      component: () =>
        import(
          /* webpackChunkName: "vendor-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketHistory.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve  },
    },
    {
      path: "/vendor/help-desk/:id",
      name: "VendorrHelpDeskViewSingle",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-single" */ `../../../../components/vendor/tickets/TicketsViewSingle.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve },
      props: true
    },
    {
      path: "/vendor/list-invoices",
      name: "VendorListInvoices",
      component: () =>
        import(
          /* webpackChunkName: "vendor-invoices" */ `../../../../components/vendor/invoice/InvoicesList.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve }
    },
    {
      path: "/vendor/view-invoice/:id",
      name: "VendorViewInvoice",
      component: () =>
        import(
          /* webpackChunkName: "vendor-view-invoice" */ `../../../../components/vendor/invoice/ViewInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve }
    },
    {
      path: "/vendor/edit-invoice/:id",
      name: "VendorEditInvoice",
      component: () =>
        import(
          /* webpackChunkName: "vendor-edit-invoice" */ `../../../../components/shared/Invoice/EditInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["vendor"], beforeResolve: vendorBeforeResolve, props: true }
    }
  ];