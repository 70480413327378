<template>
  <div class="h-full w-full flex justify-between items-start flex-col px-4 ">
    <div class="x-bg-red-400 w-full h-full flex  justify-center">
      <div
        class="w-full px-4 x-bg-red-500 grid grid-flow-row-dense grid-cols-4"
      >
        <div
          v-if="icon"
          class="col-span-1 h-24 mr-8 bg-red-600 flex items-center justify-center"
        >
        {{icon}}
        </div>
        <div :class="icon ? 'col-span-3' : 'col-span-4'">
          <div v-if="title" class="text-2xl font-medium mt-4">{{title}}</div>
          <div v-if="description" class="mt-2 text-sm">{{description}}</div>
          <component v-if="hasInnerData" :is="hasInnerData" :slotData="slotData" @confirm="onClickConfirm" @cancel="onClickCancel" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import{ get } from "lodash-es";
import TemplateCustomerCredit from "./InnerDataTemplates/CustomerCredit"
import TemplateAffiliateShareLink from "../GlobalDialog/InnerDataTemplates/AffiliateShareLink";

export default {
  props: {
    title: {
      type: String,
      default: "",
    }, 
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "icon",
    },
    slotTemplate: {
      type: String,
      default: "none",
    },
    slotData: {
        type: Object,
        default(){
            return {}
        }
    },
    primaryButtonText: {
      type: String,
      default: "Confirm",
    },
    secondaryButtonText: {
      type: String,
      default: "Cancel",
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
      async onClickConfirm(data){
          if(typeof this.onConfirm === 'function'){
              await this.onConfirm(data)
          }
          this.$emit('close')
      },
      async onClickCancel(data){
          if(typeof this.onCancel === 'function'){
              await this.onCancel(data)
          }
          this.$emit('close')
      },
  },
  computed: {
      hasInnerData(){
          const { slotTemplate } = this;
          const innerDataMap = {
              none: "",
              customerCredit: TemplateCustomerCredit,
              affiliateShareLink: TemplateAffiliateShareLink
          }
          return get(innerDataMap, slotTemplate, get(innerDataMap, 'none'))
      }
  }
};
</script>
