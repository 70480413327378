export const services = {
    useDefaultAddress: "Usar dirección predeterminada",
    manageAddresses: "Administrar direcciones",
    useDefaultPaymentMethod: "Usar método de pago predeterminado",
    service: "servicio | servicios",
    useRandomVendor: "Usar vendedor aleatorio",
    nextAvailableTime: "Próximo tiempo disponible",
    nextAvailableSlot: "El siguiente espacio disponible es",
    orderService: "Servicio de pedidos",
    subscriptionFrequency: "Frecuencia de suscripción",
    selectServiceFrequency: "Seleccione con qué frecuencia le gustaría recibir servicio.",
    serviceRepeat: "El servicio se repetirá.",
    selectSubscriptionTime: "Seleccione la hora a la que desea que comience su suscripción.",
    subscriptionStartDate: "Su suscripción comenzará en esta fecha.",
    serviceAddress: "Dirección de Servicio",
    preferredAddress: "¿En qué dirección le gustaría que se prestara el servicio?",
    selectPaymentForSubscription: "Seleccione un método de pago para la suscripción.",
    paymentSourceForSubscription: "La fuente de pago a continuación se le cobrará por la suscripción.",
    startSubscription: "¡Comience la suscripción ahora!",
    completeOrder: "Orden completa",
    noItems: "¡No hay artículos!",
    pricePerVisit: "{precio} ses su precio por visita en función del tamaño de su hogar",
    featuredServices: "Servicios destacados",
    priceStartingAt: "A partir de: {precio}",
    servicePreferences: "Preferencias de servicio",
    setupServiceTimes: "Necesitas establecer tu día preferido",
    serviceFrequency: "Frecuencia de servicio",
    basicServices: "Servicios básicos de jardinería",
    exploreServices: "Explore nuestros servicios de jardinería",
    noService: "No hay servicio disponible",
    selectedFilters: "para filtros seleccionados",
    refreshServices: "Servicios de refresco",
    serviceDay: "Día de servicio",
    serviceTime: "Tiempo de servicio",
    servicePlan: "Plan de servicio",
    lawn: "Césped",
    trees: "Arboles",
    pools: "Quinielas",
    sprinkler: "Aspersor",
    viewServices: "Ver servicios",
    noPriceForHomeSize: 'No hay precio disponible para el tamaño de la casa',
    selectedPlan: "Plan seleccionado",
    noAddOnServices: "Sin servicios complementarios",
    plans: "Planes",
    addSubscription: "Agregar suscripción",
    selectFrequency: "Seleccionar frecuencia"
}