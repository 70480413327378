import { get, omit, isEqual } from "lodash-es";
import { formatDollarAmount } from "@/helpers/helpers"

export const getDefaultCustomerAddress = (activeAccount) => {
  const { address: addresses = [] } = activeAccount;
  // No address
  if (addresses.length === 0) return;
  // Only has one address
  if (addresses.length === 1) return addresses[0];
  // find default address
  const defaultAddress = addresses.find((a) => a.is_default);
  if (defaultAddress) return defaultAddress;
  // no default, just return an address
  return addresses[0];
};

export const getDefaultCustomerHomeSize = (activeAccount) => {
  const address = getDefaultCustomerAddress(activeAccount);
  if(!address || !address.address_meta) return "";
  const { home_size = "" } = address.address_meta;
  return home_size;
};


export const getPlanFromService = (plan_id, service) => {
  if(plan_id && service && service.price){
    const plan = service.price.find(
      (result) => result.plan_id === plan_id
    );
    return plan;
  }
  return ""
};

export const getPlanFromServiceByHomeSize = (home_size, service) => {
  if(home_size && service && Object.keys(service).length && service.price){
    const plan = service.price.find(
        result => result.preference_match_size === home_size
    );
    return plan
  }
  return ""
};

export const getPlanFromServiceByRuleQuery = (service, ruleQuery) => {
  if(!service) return null
  const { price = [] } = service;
  const exactMatch = price.find((priceRule) => doesPriceMatchRuleQuery(priceRule, ruleQuery));
  
  if (exactMatch) {
    return exactMatch;
  }

  // try to get best match ?
  return null;
};

export const doesPriceMatchRuleQuery = (price, ruleQuery) => {
  const { priceAutoSelectRules = [] } = price;
  if(!priceAutoSelectRules) return false
  const exactMatch =  priceAutoSelectRules.find((r) => isEqual(omit(r, ['_id']), ruleQuery));
  return !!exactMatch;
};

export const doesPriceMatchRuleQueryPartial = (price, ruleQuery) => {
  const { priceAutoSelectRules = [] } = price;
  if(!priceAutoSelectRules) return false

  for (const ruleKey of Object.keys(ruleQuery)) {
    const partialMatch =  priceAutoSelectRules.find((r) => get(r, ruleKey) === get(ruleQuery, ruleKey))
    if(partialMatch){
      return true;
    }
  }
  return false
};


export const getServicePlanMatrixByHomeSizeForFrequency = (service, homeSize, selectedPlanId) => {
  const { price = [] } = service;
  const matches = []
  for (const iterator of price) {
    const { priceAutoSelectRules = [] } = iterator;
    if(priceAutoSelectRules){
      for (const rule of priceAutoSelectRules) {
        if(rule.home_size === homeSize){
          matches.push({
            rule, 
            plan: iterator
          })
        }
      }
    }
  }

  const sorted =  matches.sort((a, b) => a.plan.value - b.plan.value)

  const selectedIndex = sorted.findIndex(p => p.plan.plan_id === selectedPlanId)
  return sorted.map((item, index) => {
      const currentPlan = get(item, `plan`)
      const curPrice = get(currentPlan, `value`)

      item.betterPlanMessages = []
      item.otherPlanMessages = []
      item.amountDecreaseVsSelected = 0;
      item.amountIncreaseVsSelected = 0;
      item.bestPlan = index === 0
      item.isSelected = selectedPlanId && selectedPlanId === currentPlan.plan_id;

      for (let i = index; i >= 0; i--) {

        const previousPlan = get(sorted, `[${index - 1}].plan`)
        const previousRule = get(sorted, `[${index - 1}].rule`)
        const previousPrice = get(previousPlan, `value`)
        const previousPlanName = get(previousRule, `frequency`)
  
        const savings = curPrice - previousPrice;
        if(curPrice && previousPrice && previousPlanName && savings){
          const previousPlanNameOutput = `${previousPlanName}ly`
          const savingsOutput = `$${formatDollarAmount(savings)}`
          item.betterPlanMessages.push(`Switch to ${previousPlanNameOutput} and save ${savingsOutput}`)
        }
  
        if(selectedPlanId && selectedIndex > -1) {
          const selectedPlan = get(sorted, `[${selectedIndex}].plan`)
          const selectedPrice = get(selectedPlan, `value`)
            if(curPrice && selectedPrice){
              if(curPrice > selectedPrice ){
                const amountIncreaseVsSelected = curPrice - selectedPrice;
                item.amountIncreaseVsSelected = `$${formatDollarAmount(amountIncreaseVsSelected)}`
              }
              if(curPrice < selectedPrice){
                const amountDecreaseVsSelected = selectedPrice - curPrice;
                item.amountDecreaseVsSelected = `$${formatDollarAmount(amountDecreaseVsSelected)}`
              }
            }
        }
      }
      return item
  })
}

export const getServicePlanMatrixByHomeSizeForFrequencyMessage = (service, homeSize, selectedPlanId) => {
  if(!homeSize || !selectedPlanId) return ""

  const matrix = getServicePlanMatrixByHomeSizeForFrequency(service, homeSize)
  const selectedIndex = matrix.findIndex(p => p.plan.plan_id === selectedPlanId)
  const messages = []

  if(selectedIndex > -1){
    if(selectedIndex === 0){
      return messages
    }

    for (let index = selectedIndex; index > 0; index--) {
      const currentPlan = get(matrix, `[${selectedIndex}].plan`)
      const nextPlan = get(matrix, `[${index - 1}].plan`)
      const nextRule = get(matrix, `[${index - 1}].rule`)

      const curPrice = get(currentPlan, `value`)
      const nextPrice = get(nextPlan, `value`)
      const nextPlanName = get(nextRule, `frequency`)

      const savings = curPrice - nextPrice;
      if(!curPrice || !nextPrice || !nextPlanName || !savings) return ""

      const nextPlanNameOutput = `${nextPlanName}ly`
      const savingsOutput = `$${formatDollarAmount(savings)}`

      messages.push(`Switch to ${nextPlanNameOutput} and save ${savingsOutput}`)
    }
  }
  return messages
}


export const getPlanFromPlanId = (plan_id, services) => {
  const plan = services.filter(s => getPlanFromService(plan_id, s)).filter(s => s)
    return plan
};

export const getServiceByPlanId = (plan_id, services) => {
  if(plan_id && services){
    for (const service of services) {
      const plan = service.price.find(
        (result) => result.plan_id === plan_id
      );
      if(plan){
        return service;
      }
    }
  }
};


export const getServicePriceByPlanId = (plan_id, service) => {
  const plan = getPlanFromService(plan_id, service)
  if(!plan) return 0
  const { value = 0 } = plan;
  return value;
};
