export const getSizeClasses = (size) => {
    switch (size) {
      case "full":
        return [
          "w-screen",
          "h-screen",
          "top-0",
          "left-0",
          "px-4",
          "pt-5",
          "pb-4",
          "sm:p-6"
        ];
      case "h-100":
        // small, etc
        return [
          "max-w-[90%]",
            "sm:max-w-2xl",
            "sm:w-full",
            "h-1/2",
            "top-0",
            "sm:top-1/4",
            "px-4",
            "pt-5",
            "pb-4",
            "sm:p-6"
            
        ];
      default:
        // small, etc
        return [
          "max-w-[90%]",
            "sm:max-w-2xl",
            "sm:w-full",
            "max-h-80",
            "top-0",
            "sm:top-1/4",
            "px-4",
            "pt-5",
            "pb-4",
            "sm:p-6"
            
        ];
    }
  }

export const boxClasses = (size, position, animatedClass, allowYScrollOnOverflow) => {
    const sizeClasses  = getSizeClasses(size);
    const boxClasses = [
      "z-50",
      "bg-white",
      "text-left",
      "shadow-xl",
      "transform",
      "transition-all",
      "select-none",
      "flex",
      "justify-start",
      "items-center",
      "flex-col"
    ];

    if (position === "top") {
      boxClasses.push("rounded-b-lg rounded-t-none");
    } else {
      boxClasses.push("rounded-lg");
    }

    if (allowYScrollOnOverflow) {
      boxClasses.push("overflow-y-auto");
    }

    return [...sizeClasses, ...boxClasses, animatedClass];
  }

  export const boxHeight = (screenSize) => {
    if(['sm'].includes(screenSize)){
        return "100%"
    }
    return "auto"
  }