<template>
  <div class="flex relative">
    <AppMenuButton v-if="['sm'].includes(screenSize)" style="margin-right:8px;" />
    <NotificationMenuButton v-if="userRole && userRole !== 'none' && userRole !== 'super'" />
  </div>
</template>

<script>
import {  mapGetters } from "vuex";

export default {
  components: {
    AppMenuButton: () => import("@/components/global/app-menu/AppMenuButton.vue"),
    NotificationMenuButton: () => import("@/components/global/notification-menu/NotificationMenuButton.vue")
  },
  computed: {
    ...mapGetters({
      showMenu: 'isMenuOpen',
      screenSize: 'getScreenSize'
    }),
    userRole: function() {
      return this.$store.getters.getUserRole;
    },
  }
}
</script>
