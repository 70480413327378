import {
  adminBeforeResolve,
} from "../../middleware";

export const adminRoutes = [
  /*
   * Admin Routes
   */
  {
    path: "/admin/customer/:customer_id",
    name: "AdminCustomerSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-single" */ `../../../../components/admin/customers/single/CustomerDashboard.vue`
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: ["super"],
      beforeResolve: adminBeforeResolve
    }
    ,
    props: true,
    redirect: "/admin/customer/:customer_id/profile",
    children: [
      {
        path: "profile",
        name: "AdminCustomerSingleProfile",
        component: () =>
          import("../../../../components/admin/customers/single/StepBasic.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "address",
        name: "AdminCustomerSingleAddress",
        component: () =>
          import("../../../../components/admin/customers/single/StepAddress.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "check-list",
        name: "AdminCustomerSingleChecklist",
        component: () =>
          import("../../../../components/admin/customers/single/StepCheckList.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "notes",
        name: "AdminCustomerSingleNotes",
        component: () =>
          import("../../../../components/admin/customers/single/StepNotes.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "billing",
        name: "AdminCustomerSingleBilling",
        component: () =>
          import("../../../../components/admin/customers/single/StepBilling.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "credit",
        name: "AdminCustomerCredit",
        component: () =>
          import(
                /* webpackChunkName: "customer-credit" */ `../../../../components/admin/customers/creditList/CustomerCreditList.vue`
          ),
        meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
      },
      {
        path: "access",
        name: "AdminCustomerSingleAccess",
        component: () =>
          import("../../../../components/admin/customers/single/StepAccess.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "delete",
        name: "AdminCustomerSingleDelete",
        component: () =>
          import("../../../../components/admin/customers/single/StepSettings.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "contributions",
        name: "AdminCustomerSingleContributions",
        component: () =>
          import("../../../../components/admin/customers/single/StepContributions.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "preferences",
        name: "AdminCustomerSinglePreferences",
        component: () =>
          import("../../../../components/admin/customers/single/StepPreferences.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "appointments",
        name: "AdminCustomerSingleAppointments",
        component: () =>
          import("../../../../components/admin/customers/single/StepAppointments.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "tickets",
        name: "AdminCustomerSingleTickets",
        component: () =>
          import("../../../../components/admin/customers/single/StepTickets.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "subscriptions",
        name: "AdminCustomerSingleSubscriptions",
        component: () =>
          import("../../../../components/admin/customers/single/StepSubscriptions.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "communications",
        name: "AdminCustomerSingleCommunications",
        component: () =>
          import("../../../../components/admin/customers/single/StepCommunications.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "reviews",
        name: "AdminCustomerSingleReviews",
        component: () =>
          import("../../../../components/admin/customers/single/StepReviews.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "activities",
        name: "AccountCustomerSingleActivitiesList",
        component: () => import(
          /* webpackChunkName: "account-activity" */ `../../../../components/admin/activity/v2/ActivitiesListView.vue`
      ),
        meta: { requiresAuth: true, allowedRoles: ["super"] },
        props: true
      }
    ]
  },
  {
    path: "/admin/customers",
    name: "AdminCustomerArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-archive" */ `../../../../components/admin/customers/CustomerArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/emailing",
    name: "AdminCustomerEmailing",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-archive" */ `../../../../components/admin/emailing/Emailing.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] }
  },
  {
    path: "/admin/sms",
    name: "AdminSMSActivity",
    component: () =>
      import(
          /* webpackChunkName: "admin-sms-activity" */ `../../../../components/admin/activity/SmsActivity.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] }
  },
  {
    path: "/admin/sms/:activity_id",
    name: "AdminSMSSingleActivity",
    component: () =>
      import(
          /* webpackChunkName: "admin-sms-activity" */ `../../../../components/admin/activity/SmsSingleActivity.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] }
  },
  {
    path: "/admin/sms/convo/:account_id",
    name: "AdminSMSConversations",
    component: () =>
      import(
          /* webpackChunkName: "admin-sms-activity" */ `../../../../components/admin/activity/SmsConversations.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] }
  },
  {
    path: "/admin/customers/new",
    name: "AdminCustomerAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-add-new" */ `../../../../components/admin/customers/CustomerAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/subscriptions/:id",
    name: "AdminCustomerSingleSubscription",
    component: () =>
      import(
                  /* webpackChunkName: "admin-customer-subscription-single" */ `../../../../components/v2/shared/subscriptions/SingleView.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/add/subscription/:customer_id",
    name: "AdminCustomerAddSubscription",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-single" */ `../../../../components/admin/customers/single/AddSubscription.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true
  },
  {
    path: "/admin/users",
    name: "AdminUsersListView",
    component: () =>
      import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/admin/users/UsersList.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/create-invoice",
    name: "AdminCustomBilling",
    component: () =>
      import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/shared/Invoice/CreateInvoice.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/user/:id",
    name: "AdminUserSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-user-single" */ `../../../../components/admin/users/UserSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/users/new",
    name: "AdminUserAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-user-add-new" */ `../../../../components/admin/users/UserAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/vendors",
    name: "AdminVendorArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-vendor-archive" */ `../../../../components/admin/vendors/VendorArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/accounts",
    name: "AdminAccountsSearch",
    component: () =>
      import(
          /* webpackChunkName: "admin-accounts-search" */ `../../../../components/shared/AccountSearch/AccountSearch.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/vendor/:vendor_id",
    name: "AdminVendorSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-vendor-single" */ `../../../../components/admin/vendors/VendorSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true,
    redirect: "/admin/vendor/:vendor_id/profile",
    children: [
      {
        path: "profile",
        name: "AdminVendorSingleProfile",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditBasic.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "address",
        name: "AdminVendorSingleAddress",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditAddress.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "availability",
        name: "AdminVendorSingleAvailability",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditSchedule.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "access",
        name: "AdminVendorSingleAccess",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditAccess.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "appointments",
        name: "AdminVendorSingleAppointments",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorAppointments.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "subscriptions",
        name: "AdminVendorSingleSubscriptions",
        component: () =>
          import("../../../../components/admin/vendors/single/subscriptions/VendorSubscriptions.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "service-area",
        name: "AdminVendorSingleServiceAreas",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditServiceArea.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "tickets",
        name: "AdminVendorSingleTickets",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorTickets.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "delete",
        name: "AdminVendorSingleDelete",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorEditDelete.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "communications",
        name: "AdminVendorSingleCommunications",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorCommunications.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "survey",
        name: "AdminVendorSingleSurvey",
        component: () =>
          import("../../../../components/admin/vendors/single/VendorSurvey.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "activities",
        name: "AccountActivitiesList",
        component: () =>
          import(
             /* webpackChunkName: "account-activity" */ `../../../../components/admin/activity/v2/ActivitiesListView.vue`
          ),
        meta: { requiresAuth: true, allowedRoles: ["super"] },
        props: true
      }
    ]
  },
  {
    path: "/admin/vendors/new",
    name: "AdminVendorAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-vendor-add-new" */ `../../../../components/admin/vendors/VendorAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true
  },

  {
    path: "/admin/affiliates",
    name: "AdminAffiliateArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-affiliate-archive" */ `../../../../components/admin/affiliates/AffiliateArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/affiliate/:affiliate_id",
    name: "AdminAffiliateSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-affiliate-single" */ `../../../../components/admin/affiliates/AffiliateSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true,
    redirect: "/admin/affiliate/:affiliate_id/profile",
    children: [
      {
        path: "profile",
        name: "AdminAffiliateSingleProfile",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateEditBasic.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "ids",
        name: "AdminAffiliateSingleAffiliateIds",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateEditID.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "access",
        name: "AdminAffiliateSingleAccess",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateEditAccess.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "bank",
        name: "AdminAffiliateSingleBank",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateBankAccountDetails.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "communications",
        name: "AdminAffiliateSingleCommunications",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateCommunications.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "contributions",
        name: "AdminAffiliateSingleContributions",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateContributions.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "delete",
        name: "AdminAffiliateSingleDelete",
        component: () =>
          import("../../../../components/admin/affiliates/single/AffiliateEditDelete.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "activities",
        name: "AdminAffiliateActivitiesList",
        component: () =>
          import(
               /* webpackChunkName: "account-activity" */ `../../../../components/admin/activity/v2/ActivitiesListView.vue`
          ),
        meta: { requiresAuth: true, allowedRoles: ["super"] },
        props: true
      }
    ]
  },
  {
    path: "/admin/affiliates/new",
    name: "AdminAffiliateAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-affiliate-add-new" */ `../../../../components/admin/affiliates/AffiliateAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },

  {
    path: "/admin/services",
    name: "AdminServicesArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-customer-add-new" */ `../../../../components/admin/services/ServicesArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/services/:id",
    name: "AdminServicesSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-services-single" */ `../../../../components/admin/services/ServicesSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/service/new",
    name: "AdminServicesAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-services-add-new" */ `../../../../components/admin/services/ServicesAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },

  {
    path: "/admin/coupons",
    name: "AdminCouponsArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-coupon-archive" */ `../../../../components/admin/coupons/CouponsArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/coupons/new",
    name: "AdminCouponsAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-coupons-add-new" */ `../../../../components/admin/coupons/CouponsAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/coupons/:id",
    name: "AdminCouponsSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-coupons-single" */ `../../../../components/admin/coupons/CouponsSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: () =>
      import(
          /* webpackChunkName: "admin-dashboard" */ `../../../../components/admin/dashboard/AdminDashboard.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve, layoutTemplate: 'fullScreen' }
  },
  {
    path: "/admin/appointment/:id",
    name: "AdminAppointmentOneAvailability",
    component: () =>
      import(
          /* webpackChunkName: "admin-appointment-single" */ `../../../../components/v2/shared/appointments/SingleView.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: {
      userType: "super"
    }
  },
  {
    path: "/admin/organizations",
    name: "AdminOrganizationArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-organization-archive" */ `../../../../components/admin/organizations/OrganizationArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/organization/new",
    name: "AdminOrganizationAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-organization-add-new" */ `../../../../components/admin/organizations/OrganizationAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/organization/:organization_id",
    name: "AdminOrganizationSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-organization-single" */ `../../../../components/admin/organizations/OrganizationSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true,
    redirect: "/admin/organization/:organization_id/profile",
    children: [
      {
        path: "profile",
        name: "AdminOrganizationSingleProfile",
        component: () =>
          import("../../../../components/admin/organizations/single/StepBasic.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "insurance",
        name: "AdminOrganizationSingleInsurance",
        component: () =>
          import("../../../../components/admin/organizations/single/StepInsurance.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "address",
        name: "AdminOrganizationSingleAddress",
        component: () =>
          import("../../../../components/admin/organizations/single/StepAddress.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "access",
        name: "AdminOrganizationSingleAccess",
        component: () =>
          import("../../../../components/admin/organizations/single/StepAccess.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "payouts",
        name: "AdminOrganizationSinglePayouts",
        component: () =>
          import("../../../../components/admin/organizations/single/StepPayout.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "bank",
        name: "AdminOrganizationSingleBank",
        component: () =>
          import("../../../../components/admin/organizations/single/StepBankAccountDetails.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "vendors",
        name: "AdminOrganizationSingleVendors",
        component: () =>
          import("../../../../components/admin/organizations/single/StepVendors.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "appointments",
        name: "AdminOrganizationSingleAppointments",
        component: () =>
          import("../../../../components/admin/organizations/single/StepAppointments.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "service-area",
        name: "AdminOrganizationSingleServiceAreas",
        component: () =>
          import("../../../../components/admin/organizations/single/StepServiceAreas.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "contributions",
        name: "AdminOrganizationSingleContributions",
        component: () =>
          import("../../../../components/admin/organizations/single/StepContributions.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "tickets",
        name: "AdminOrganizationSingleTickets",
        component: () =>
          import("../../../../components/admin/organizations/single/StepTickets.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "delete",
        name: "AdminOrganizationSingleDelete",
        component: () =>
          import("../../../../components/admin/organizations/single/StepDelete.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "communications",
        name: "AdminOrganizationSingleCommunications",
        component: () =>
          import("../../../../components/admin/organizations/single/StepCommunications.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "survey",
        name: "AdminOrganizationSingleSurvey",
        component: () =>
          import("../../../../components/admin/organizations/single/StepSurvey.vue"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["super"]
        },
        props: true
      },
      {
        path: "activities",
        name: "AdminOrganizationSingleActivitiesList",
        component: () =>
          import(
               /* webpackChunkName: "account-activity" */ `../../../../components/admin/activity/v2/ActivitiesListView.vue`
          ),
        meta: { requiresAuth: true, allowedRoles: ["super"] },
        props: true
      }
    ]
  },
  {
    path: "/admin/help-desk",
    name: "AdminHelpdeskDashboard",
    component: () =>
      import(
          /* webpackChunkName: "admin-help-helpdesk-dashboard" */ `../../../../components/admin/helpdesk/HelpDeskDashboard.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/help-desk/history",
    name: "AdminHelpdeskHistory",
    component: () =>
      import(
          /* webpackChunkName: "admin-help-helpdesk-dashboard" */ `../../../../components/admin/helpdesk/HelpDeskFullHistory.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/help-desk/history",
    name: "AdminHelpdeskFullHistory",
    component: () =>
      import(
          /* webpackChunkName: "admin-help-helpdesk-dashboard" */ `../../../../components/shared/Tickets/TicketHistory.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/help-desk/ticket/:id",
    name: "AdminHelpdeskSingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-helpdesk-single" */ `../../../../components/admin/helpdesk/TicketViewSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true
  },
  {
    path: "/admin/help-desk/new",
    name: "AdminHelpDeskAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-helpdesk-add-new" */ `../../../../components/shared/Tickets/TicketAddNewRedirect.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true
  },
  {
    path: "/admin/help-desk/ticket/:id/draft",
    name: "AdminHelpDeskDraft",
    component: () =>
      import(
          /* webpackChunkName: "admin-helpdesk-add-new" */ `../../../../components/admin/helpdesk/TicketDraft.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve },
    props: true
  },
  {
    path: "/admin/subscription/services/:customer_id",
    name: "AdminSubscriptionServices",
    component: () =>
      import(
          /* webpackChunkName: "customer-services-archive" */ `../../../../components/admin/customers/services/ServicesViewAll.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/subscription/services/:id/:customer_id",
    name: "AdminSubscriptionServicesViewOne",
    component: () =>
      import(
          /* webpackChunkName: "customer-services-single" */ `../../../../components/admin/customers/services/ServicesViewOne.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    //:upcoming_invoice_id
    path: "/admin/billing/invoice/upcoming/:customer_id",
    name: "AdminUpcomingInvoice",
    component: () =>
      import(
            /* webpackChunkName: "customer-upcoming-invoice" */ `../../../../components/admin/customers/CustomerViewUpcomingInvoice.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  
  {
    path: "/admin/billing/past/invoice/:invoice_id/:customer_id",
    name: "AdminCustomerPastInvoice",
    component: () =>
      import(
          /* webpackChunkName: "invoice" */ `../../../../components/admin/customers/CustomerPastInvoice.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/lists",
    name: "AdminLists",
    component: () =>
      import(
          /* webpackChunkName: "admin-lists-archive" */ `../../../../components/admin/lists/ListsArchive.vue`
          ),
          meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/service-categories",
    name: "AdminServiceCategories",
    component: () =>
      import(
          /* webpackChunkName: "admin-lists-archive" */ `../../../../components/admin/serviceCategories/ServicesArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/new/list",
    name: "AdminListNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-list-add-new" */ `../../../../components/admin/lists/ListsAddNew.vue`
          ),
          meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/service-category/new",
    name: "AdminServiceCategoriesAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-service-category-add-new" */ `../../../../components/admin/serviceCategories/ServicesAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/list/:list_id",
    name: "AdminListEdit",
    component: () =>
      import(
          /* webpackChunkName: "admin-list-add-new" */ `../../../../components/admin/lists/ListEdit.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/communications",
    name: "AdminCommunications",
    component: () =>
      import(
          /* webpackChunkName: "admin-communications-archive" */ `../../../../components/admin/communications/CommunicationsArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/communication/new",
    name: "AdminNewCommunication",
    component: () =>
      import(
          /* webpackChunkName: "admin-communication-new" */ `../../../../components/admin/communications/CommunicationAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/communication/:communication_id",
    name: "AdminCommunicationEdit",
    component: () =>
      import(
          /* webpackChunkName: "admin-communication-edit" */ `../../../../components/admin/communications/CommunicationEdit.vue`

          ),
          meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/communication/dispatched/:communication_id",
    name: "AdminCommunicationDispatched",
    component: () =>
      import(
          /* webpackChunkName: "admin-communication-dispatched" */ `../../../../components/admin/communications/CommunicationDispatched.vue`
          ),
    meta: { requiresAuth: true, allowedRoles: ["super"] }
  },
  {
    path: "/admin/service-category/:id",
    name: "AdminServiceCategorySingle",
    component: () =>
      import(
          /* webpackChunkName: "admin-service-category-single" */ `../../../../components/admin/serviceCategories/ServicesSingle.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/zipcodes/new",
    name: "AdminZipcodesAddNew",
    component: () =>
      import(
          /* webpackChunkName: "admin-service-category-single" */ `../../../../components/admin/zipcodes/ZipcodesAddNew.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/zipcodes",
    name: "AdminZipcodesArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-service-category-single" */ `../../../../components/admin/zipcodes/ZipcodesArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/reports",
    name: "AdminRecentReports",
    component: () =>
      import(
          /* webpackChunkName: "admin-report-recent-reports" */ `../../../../components/admin/reports/RecentReports.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/reports/:reportId",
    name: "AdminReports",
    component: () =>
      import(
          /* webpackChunkName: "admin-report-reports-listView" */ `../../../../components/admin/reports/ReportListView.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  }, 
  {
    path: "/admin/vendors-search",
    name: "Admin Vendors Search",
    component: () =>
      import(/* webpackChunkName: "affiliate-fundraising" */ `../../../../components/affiliate/search/VendorsSearch.vue`),
    meta: {
      requiresAuth: true,
      allowedRoles: ["super"],
      beforeResolve: adminBeforeResolve
    }
  },
  {
    path: "/admin/reviews",
    name: "AdminReviewsArchive",
    component: () =>
      import(
          /* webpackChunkName: "admin-reviews" */ `../../../../components/admin/reviews/ReviewsArchive.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], beforeResolve: adminBeforeResolve }
  },
  {
    path: "/admin/activities",
    name: "AdminAccountActivitiesList",
    component: () =>
      import(
          /* webpackChunkName: "account-activity" */ `../../../../components/admin/activity/v2/ActivitiesListView.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] },
    props: true
  },
  {
    path: "/admin/move-vendor-appointments",
    name: "AdminMoveVendorAppointments",
    component: () =>
      import(
          /* webpackChunkName: "move-vendor-appointments" */ `../../../../components/admin/vendors/VendorMoveSubscriptions.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] },
    props: true
  },
  {
    path: "/admin/connect-inbox",
    name: "AdminConnectInbox",
    component: () =>
      import(
          /* webpackChunkName: "move-vendor-appointments" */ `../../../../components/shared/Communication/Inbox/InboxView.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"], layoutTemplate: 'fullScreen' },
    props: true
  },
  {
    path: "/admin/appointment-images",
    name: "AdminAppointmentImages",
    component: () =>
      import(
          /* webpackChunkName: "appointment-images" */ `../../../../components/admin/appointments/AppointmentImages.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] },
    props: true
  },
  {
    path: "/admin/ccp-redirect",
    name: "AdminCcpRedirect",
    component: () =>
      import(
          /* webpackChunkName: "appointment-images" */ `../../../../components/admin/ccp/CcpRedirect.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["super"] },
    props: true
  },
];