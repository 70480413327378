var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex items-center justify-end bg-white z-50 pr-4" },
      [
        _c("section", { staticClass: "loading-menu-items" }, [
          _c("article", { staticClass: "background" }, [
            _c("div", { staticClass: "item-1" }),
            _c("div", { staticClass: "item-2" }),
            _c("div", { staticClass: "item-3" }),
            _c("div", { staticClass: "item-4" }),
          ]),
        ]),
        _c("section", { staticClass: "loading-menu-profile" }, [
          _c("article", { staticClass: "background" }, [
            _c("div", { staticClass: "user-sep" }),
            _c("div", { staticClass: "row-1" }),
            _c("div", { staticClass: "row-2" }),
            _c("div", { staticClass: "row-3" }),
            _c("div", { staticClass: "row-4" }),
            _c("div", { staticClass: "row-5" }),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }