var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      attrs: {
        placement: "bottom",
        width: _vm.getPopoverWidth,
        trigger: "manual",
        "popper-class": "p-0",
      },
      model: {
        value: _vm.showPopover,
        callback: function ($$v) {
          _vm.showPopover = $$v
        },
        expression: "showPopover",
      },
    },
    [
      _c(
        "div",
        {
          ref: "referenceContainer",
          staticClass:
            "border border-gray-300  bg-gray-200 f py-4 pr-2 pl-4 flex items-center justify-between outline-none cursor-pointer",
          class: {
            "border-green-600": _vm.showPopover || _vm.isFocusSelect,
            "hover:border-green-600": _vm.showPopover,
            "hover:border-gray-400": !_vm.isFocusSelect && !_vm.showPopover,
            "opacity-50": _vm.disabled,
            "cursor-not-allowed": _vm.disabled,
          },
          attrs: { slot: "reference" },
          on: {
            click: _vm.onClickPlaceholder,
            mousedown: function ($event) {
              _vm.isFocusSelect = true
            },
            mouseup: function ($event) {
              _vm.isFocusSelect = false
            },
            mouseout: function ($event) {
              _vm.isFocusSelect = false
            },
          },
          slot: "reference",
        },
        [
          _vm.selectedValue
            ? _c(
                "div",
                [
                  _vm._t("labelHasValue", null, {
                    selected: _vm.dropDownItems.length
                      ? _vm.dropDownItems.find(function (i) {
                          return i.value === _vm.selectedValue
                        })
                      : _vm.selectedValue,
                  }),
                ],
                2
              )
            : _c(
                "div",
                [
                  _vm._t("labelNoValue", function () {
                    return [_vm._v("Select")]
                  }),
                ],
                2
              ),
          !_vm.selectedValue &&
          _vm.dropDownItems &&
          _vm.dropDownItems.length === 0
            ? _c("i", {
                staticClass: "text-gray-500 el-icon-plus el-icon--plus",
              })
            : _c("i", {
                staticClass:
                  "text-gray-600 el-icon-arrow-down el-icon--right transition-all duration-200 transform rotate-0",
                class: {
                  "-rotate-180": _vm.showPopover,
                },
              }),
        ]
      ),
      _vm.dropDownItems
        ? _c(
            "div",
            { staticClass: "max-h-96 overflow-auto" },
            _vm._l(_vm.dropDownItems, function (item, index) {
              return _c(
                "div",
                {
                  key: "item-" + index,
                  class: {
                    "py-5": true,
                    "px-4": true,
                    "border-b": index < _vm.dropDownItems.length - 1,
                    "hover:bg-gray-100 cursor-pointer": !_vm.isItemSelected(
                      item.value
                    ),
                    "opacity-60 cursor-not-allowed": _vm.isItemSelected(
                      item.value
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onClickDropdownItem(item)
                    },
                  },
                },
                [_vm._t("innerDropdownItem", null, { item: item })],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._t("innerContent", null, {
        closePopover: _vm.close,
        emitCustomAction: _vm.onEmitCustomAction,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }