<template>
  <div class="h-full w-full flex flex-col items-center justify-center">
    <div v-if="imageLink" class="flex justify-center mt-7 mb-6">
      <img :src="require(`@/assets/web/images/${imageLink}`)" class="" />
    </div>
    <div v-if="fullPath" class="flex justify-center mt-7 mb-6">
      <img :src="fullPath" class="" />
    </div>
    <div v-if="messageText" class="pt-2 flex justify-center font-medium text-xl">
      {{ messageText }}
    </div>
    <div v-if="descriptionText" class="text-xs flex justify-center">
      {{ descriptionText }}
    </div>
    <div v-if="showButton" class="pt-4 flex justify-center">
        <WemowButton
          :text="buttonText"
          :disabled="false"
          :block="false"
          @mousedown.native="buttonAction"
        />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageLink: {
      type: String,
      default: "",
    },
    fullPath: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonAction: {
      type: Function,
      default: () => {},
    },
    messageText: {
      type: String,
      default: "",
    },
    descriptionText: {
      type: String,
      default: "",
    },
  },
};
</script>