var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-screen flex items-center flex-col justify-center" },
    [
      _c("div", { staticClass: "mt-4" }, [
        _c("img", {
          staticClass: "pulse mb-3 mx-auto",
          staticStyle: { "max-width": "200px" },
          attrs: { src: require("@/assets/images/wemow-logo-primary.png") },
        }),
        _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "h-12",
          attrs: { "element-loading-background": "rgba(247, 250, 252, 0.75)" },
        }),
      ]),
      _vm.loadingText
        ? _c(
            "div",
            { staticClass: "text-sm  font-semibold text-gray-700 text-center" },
            [_vm._v(_vm._s(_vm.loadingText))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }