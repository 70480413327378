import i18n from "@/plugins/i18n";

export const MenuItemsAffiliate = [{
    to: "/affiliate/dashboard",
    text: 'Dashboard',
    id: 'MenuItemAffiliateDashboard'
},
{
    to: "/affiliate/promote",
    text: "Promote",
    id: 'MenuItemAffiliatePromote'
},
{
    to: "/affiliate/reports",
    text: "Reports",
    id: 'MenuItemAffiliateReports'
}
]

export const AccountMenuAffiliate = [{
    text: i18n.t('menu.mySettings'),
    to: '/affiliate/profile',
    id: 'MenuItemAffiliateSettings'
}]