export const maybeParseJSON = ( objOrMaybeJSON ) => {
    // eslint-disable-next-line
    if(!objOrMaybeJSON) return {} 
    if(typeof objOrMaybeJSON === 'string'){
      try {
        const result = JSON.parse(objOrMaybeJSON)
        return result 
      } 
      // eslint-disable-next-line
      catch (error) {}
    }
    if(typeof objOrMaybeJSON === 'object'){
      return objOrMaybeJSON 
    }
    return objOrMaybeJSON 
  }