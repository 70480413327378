import axios from "../lib/axios";
import auth from "../auth";
const {VUE_APP_AWS_CONNECT_URL, VUE_APP_CONNECT_API} = process.env

export async function connectParticipant(data) {
  return axios
    .post(VUE_APP_AWS_CONNECT_URL + "participant/connection", data, {
      headers: auth.getAWSAuthHeader('participant'),
    })
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function disconnectParticipant() {
  return axios
    .post(VUE_APP_AWS_CONNECT_URL + "participant/disconnect", {
      ClientToken: "",
    }, {
      headers: auth.getAWSAuthHeader('connection'),
    })
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function sendMessage(message) {
  return axios
    .post(
      VUE_APP_AWS_CONNECT_URL + "participant/message",
      {
        ClientToken: "",
        Content: message,
        ContentType: "text/plain",
      },
      {
        headers: auth.getAWSAuthHeader('connection'),
      }
    )
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function sendEvent(contentType) {
  return axios
    .post(VUE_APP_AWS_CONNECT_URL + "participant/event", {
      ClientToken: "",
      ContentType: contentType
    }, {
      headers: auth.getAWSAuthHeader('connection'),
    })
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function getChatTranscript(data) {
  return axios
    .get(VUE_APP_AWS_CONNECT_URL + "participant/transcript", data, {
      headers: auth.getAWSAuthHeader('connection'),
    })
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function startChatToken() {
  return axios
    .post(VUE_APP_CONNECT_API + "connect/start-chat-user", {}, {
      headers: auth.getAuthHeader()
    })
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}

export async function getChatStatus(data) {
  return axios
    .post(VUE_APP_CONNECT_API + "chat/status", data)
    .then((info) => Promise.resolve(info))
    .catch((error) => Promise.reject(error));
}
