
import {
  GET_NOTIFICATION,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_DISMISSED,
  MARK_NOTIFICATION_AS_UNDISMISSED,
  GET_ALERTS,
  ORGANIZATION_GET_NEW_SUBSCRIPTIONS
} from '@/store/action-types'

import {
  SET_NOTIFICATIONS,
  SET_ACTIVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS_STORE,
  SET_ALERTS_COUNT,
  SET_ALERT_NOTIFICATIONS,
  DECREMENT_ALERTS_COUNT,
  SET_NEW_SUBSCRIPTIONS
}from '@/store/mutation-types'

import {
  getNotifications,
  getNotification,
  markNotificationAsRead,
  markNotificationAsDismissed,
  markNotificationAsUndismissed,
  getNotificationAlerts,
  getNewSubscriptions
}from '@/services/ServiceDatabaseAPI'
import { subDays } from 'date-fns';

import { getErrorMessage } from "@/helpers/helpers"
const getDefaultState = () => {
  return {
    activeNotification: {},
    all: [],
    alertsCount: 0,
    alertNotifications: [],
    newOrgSubscriptions: []
  }
}

const state = getDefaultState();

const mutations = {
  [CLEAR_NOTIFICATIONS_STORE](state) {
    state = Object.assign(state, getDefaultState())
    return state
  },
  [SET_NOTIFICATIONS]: (state, notifications) => {
    const addNotification = notification => {
      const existsIndex = state.all.findIndex(c => c._id === notification._id)
      if (existsIndex > -1) {
        state.all.splice(existsIndex, 1, notification)
      } else {
        state.all.push(notification)
      }
    }
    if (Array.isArray(notifications)) {
      for (let i = 0; i < notifications.length; i++) {
        addNotification(notifications[i])
      }
    } else {
      addNotification(notifications)
    }
    state.all = state.all.sort((a,b) => a.created - b.created)
  },
  [SET_ACTIVE_NOTIFICATION]: (state, notification_id) => {
    const notif = state.all.find(n => n._id === notification_id) || {}
    state.activeNotification = notif
  },
  [SET_ALERTS_COUNT](state, notifications) {
    state.alertsCount = notifications ? notifications.filter((n) => n.type == 'alert' && n.dismissed == false).length : 0
  },
  [DECREMENT_ALERTS_COUNT](state) {
    state.alertsCount = --state.alertsCount
  },
  [SET_ALERT_NOTIFICATIONS]: (state, notifications) => {
    const notifs = notifications ? notifications.filter(n => n.type == 'alert' && n.dismissed == false) : []

    state.alertNotifications = notifs
  },
  [SET_NEW_SUBSCRIPTIONS](state, subscriptions = []) {
    state.newOrgSubscriptions = subscriptions
  }
};

const actions = {
  async [GET_NOTIFICATIONS](
    { commit },
  ) {
    try {
      const notifications = await getNotifications();

      if (notifications.status > 204 || !notifications.data) {
        throw new Error("Error receiving affiliate notifications");
      }

      const notificationsData = notifications.data
      commit(SET_NOTIFICATIONS, notificationsData)
      commit(SET_ALERTS_COUNT, notificationsData)
      commit(SET_ALERT_NOTIFICATIONS, notificationsData)
      return notificationsData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [GET_NOTIFICATION](
    { commit },
    { notification_id }
  ) {
    try {
      const notifications = await getNotification({notification_id});

      if (notifications.status > 204 || !notifications.data) {
        throw new Error("Error receiving affiliate notifications");
      }

      const notificationsData = notifications.data
      console.log("note", notificationsData)
      commit(SET_NOTIFICATIONS, notificationsData)
      return notificationsData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [MARK_NOTIFICATION_AS_READ](
    { commit },
    { notification_id }
  ) {
    try {
      const markedAsRead = await markNotificationAsRead(notification_id);

      if (markedAsRead.status > 204 || !markedAsRead.data) {
        throw new Error("Error receiving affiliate notifications");
      }

      const markedAsReadData = markedAsRead.data
      commit(SET_NOTIFICATIONS, markedAsReadData)

      return markedAsReadData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [MARK_NOTIFICATION_AS_DISMISSED](
    { commit },
    { notification_id }
  ) {
    try {
      const markedAsDismissed = await markNotificationAsDismissed(notification_id);

      if (markedAsDismissed.status > 204 || !markedAsDismissed.data) {
        throw new Error("Error receiving affiliate notifications");
      }

      const markedAsDismissedData = markedAsDismissed.data
      commit(SET_NOTIFICATIONS, markedAsDismissedData)

      return markedAsDismissedData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [MARK_NOTIFICATION_AS_UNDISMISSED](
    { commit },
    { notification_id }
  ) {
    try {
      const markedAsDismissed = await markNotificationAsUndismissed(notification_id);

      if (markedAsDismissed.status > 204 || !markedAsDismissed.data) {
        throw new Error("Error receiving affiliate notifications");
      }

      const markedAsDismissedData = markedAsDismissed.data
      commit(SET_NOTIFICATIONS, markedAsDismissedData)

      return markedAsDismissedData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [GET_ALERTS](
    { commit },
    query = {}
  ) {
    try {
      const alerts = await getNotificationAlerts(query);

      if (alerts.status > 204 || !alerts.data) {
        throw new Error("Error receiving alerts");
      }

      const alertsData = alerts.data
      commit(SET_ALERTS_COUNT, alertsData)
      commit(SET_ALERT_NOTIFICATIONS, alertsData)
      return alertsData;
    } catch (e) {
      console.log("error", e)
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
  async [ORGANIZATION_GET_NEW_SUBSCRIPTIONS]({ commit }, { dateFrom = subDays(new Date, 30), dateTo = new Date() }) {
    try {
      let response = await getNewSubscriptions({ dateFrom, dateTo });
      commit(SET_NEW_SUBSCRIPTIONS, response.data)
      return response.data;
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      throw new Error(errorMessage);
    }
  },
}

const getters = {
  getAllMessages : state => [...state.all].sort((a,b) => b.created - a.created),
  getUnreadMessages : state => [...state.all].filter(n => !n.has_read).sort((a,b) => b.created - a.created),
  getReadMessages : state => [...state.all].filter(n => n.has_read).sort((a,b) => b.created - a.created),
  getDismissedMessages : state => [...state.all].filter(n => n.dismissed).sort((a,b) => b.created - a.created),
  getUndismissedMessages : state => [...state.all].filter(n => !n.dismissed).sort((a,b) => b.created - a.created),
  getActiveNotification: state => state.activeNotification,
  hasActiveNotification: state => state.activeNotification._id ? true : false,
  hasAlertNotification: (state) => state.alertsCount > 0 ? true : false,
  getAlertNotification: state => state.alertNotifications,
  getNewSubscriptions: state => state.newOrgSubscriptions,
};

export default {
namespaced: true,
state,
mutations,
actions,
getters
}
