// @ts-check

export const formLabels = {
    firstName: "primer nombre",
    lastName: "apellido",
    email: "correo electrónico | correos electrónicos",
    amount: "cantidad",
    phone: "telefono | Los telefonos",
    address: "dirección | direcciones",
    username: "Nombre de usuario",
    password: "Contraseña",
    phoneNumber: "Número de teléfono | Números de teléfono",
    emailAddress: "Dirección de correo electrónico",
    status: "Estado",
    subject: "tema",
    description: "Descripción",
    contact: "contacto | contactos",
    serviceAreas: "áreas de servicio",
    companyName: "Nombre de Empresa",
    qualifications: "Calificaciones",
    resetPassword: "Restablecer la contraseña",
    jobTitle: "Título profesional",
    recipientEmail: "receptor de correo electronico",
    sourceType: "tipo de fuente",
    broadCastMessage: "Mensaje de Emision",
    typeReply: "Escriba respuesta ...",
    priority: "Prioridad",
    type: "Tipo",
    uploadImages: "Subir imágenes",
    frequency: "Frecuencia",
    recurringLength: "Duracion Recurrente",
    selectService: "Seleccionar Servicio",
    selectPlan: "Seleccionar Plan",
    date: "Fecha",
    selectDateAndTime: "Seleccione fecha y hora",
    select: "Seleccione",
    selectServiceAreas: "Seleccionar áreas de servicio",
    defaultAddress: "Es la dirección predeterminada",
    homeSize: "Tamaño de casa",
    zipCode: "Código postal",
    city: "Ciudad",
    state: "Estado",
    streetAddress: "Dirección",
    verifyPhoneNumber: "Verificar número de teléfono",
    phoneVerified: "Teléfono verificado",
    phoneUnverified: "Teléfono no verificado",
    phoneNumberType: "Tipo (celular, oficina, hogar, etc.)",
    startDate: "Fecha de inicio",
    endDate: "Fecha final",
    confirmPassword: "Confirmar contraseña",
    newPassword: "Nueva contraseña",
    category: "categoría",
    grid: "cuadrícula",
    table: "mesa",
    selectDay: "Seleccione dia",
    selectTimeOfDay: "Seleccionar hora del día",
    selectedAddress: "Su dirección seleccionada",
    images: "Imágenes",
    expiryDate: "Fecha de caducidad",
    frequencyUpdate: "Actualización de frecuencia",
    houseSize: "Tamaño de la casa",
    addressInformation: "Datos del Domicilio",
    small: "Pequeño",
    medium: "Medio",
    large: "Grande",
    enterFirstName: "Ingrese el nombre",
    enterLastName: "Introduzca el apellido",
    enterPhoneNumber: "introduzca el número de teléfono",
    enterCode: "Introduzca el código",
    quantity: "Cantidad",
    itemDescription:"Descripción del Artículo",
    enterStreetAddress: "Ingrese la dirección de la calle",
    enterState: "Ingresar estado",
    enterCity: "Ingrese el nombre de la ciudad",
    enterZipCode: "Ingresa tu código postal",
    selectCustomer: "Seleccionar cliente",
    selectVendor: "Seleccionar proveedor",
    addCustomService: "Agregar servicio personalizado",
    enableBiometric: "Habilitar la autenticación biométrica",
    oldPassword: "Contraseña anterior",
    checklist:"Lista de Verificación",
    appointmentOptional: "Cita - opcional",
    requirePasswordResetOnLogin:"Requerir restablecimiento de contraseña al iniciar sesión",
    emailPasswordToUser: "Contraseña de correo electrónico a la usuaria",
    selectCustomTemplate: "Seleccionar plantilla de comunicación personalizada",
    displayName: "Nombre para mostrar",
    changePaymentMethod: "Cambiar método de pago",
    rain: "Lluvia"
}