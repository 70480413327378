<template>
  <div :class="outerClass">
    <div :class="innerClass">
      <svg
        v-if="showAsCheckbox"
        v-show="checked"
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 text-white"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showAsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    outerClass() {
      const baseClasses = [
        "flex",
        "items-center",
        "justify-center",
        "w-4",
        "h-4",
        "bg-gray-200",
        "border",
        "border-gray-400"
      ];

      if (!this.showAsCheckbox) {
        baseClasses.push("rounded-full");
      }
      return Object.fromEntries(baseClasses.map((i) => [i, true]));
    },
    innerClass() {
      const { hover, checked, focus } = this;
      const baseClasses = [
        "transition-all",
        "flex",
        "items-center",
        "justify-center",
        "w-full",
        "h-full",
        "border-white",
        "border-1"
      ];

      if (!this.showAsCheckbox) {
        baseClasses.push("rounded-full");
        baseClasses.push("border-2");
      }

      const activeClass = ["opacity-100", "scale-100"];
      const inActiveClass = ["opacity-0", "scale-0"];

      const hoverClass = ["border-gray-100", "bg-green-300"];
      const focusClass = ["border-gray-300", "bg-green-300"];
      const selectedClass = ["bg-green-900"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(
          activeClass.map((i) => [i, hover || checked])
        ),
        Object.fromEntries(
          inActiveClass.map((i) => [i, !hover && !checked])
        ),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, checked])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
      );
    }
  }
};
</script>
