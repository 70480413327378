import store from "@/store/store";
import {
  VENDOR_INIT_DASHBOARD
} from '@/store/action-types'
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";

function redirectIfNotOnboarded(next) {
  const { meta = {} } = store.getters["getUserActiveAccount"];
  const { initialSetup = "" } = meta;
  
  // if (!initialSetup || !agreedToTAndC) {
  if (!initialSetup) {
    store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    return next("/vendor/onboarding");
  }
}

function redirectIfAlert(next) {
  const hasAlert = store.getters["notifications/hasAlertNotification"];
  if(hasAlert){
    store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    next("/alert");
    return true
  }
  return false
}

function checkIfDashboardLoaded(next) {
  const isDashboardInit = store.getters["vendors/getLoadedDashboard"]
  if(!isDashboardInit){
    const activeAccount = store.getters["getUserActiveAccount"];
    store.dispatch(`vendors/${VENDOR_INIT_DASHBOARD}`, activeAccount._id)
    return next();
  }
}

export const vendorBeforeResolve = async (_toRoute, fromRoute, next) => {
  if (
    redirectIfNotOnboarded(next) ||
    redirectIfAlert(next)
  ){
    return
  }
  store.commit(APP_STATE_WEB_CONTENT_WIDTH)
  checkIfDashboardLoaded(next);
  return next();
};
