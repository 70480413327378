import store from "@/store/store";
import {
    ADMIN_INIT_DASHBOARD
} from '@/store/admin-action-types'
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";


function checkIfDashboardLoaded(next) {
  const isDashboardInit = store.getters["admin/getLoadedDashboard"]
  if(!isDashboardInit){
    const activeAccount = store.getters["getUserActiveAccount"];
    store.dispatch(`admin/${ADMIN_INIT_DASHBOARD}`, activeAccount._id, {root: true})
    return next();
  }
}

export const adminBeforeResolve = async (_toRoute, fromRoute, next) => {
  store.commit(APP_STATE_WEB_CONTENT_WIDTH)
  checkIfDashboardLoaded(next);
  return next();
};
