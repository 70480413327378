<template>
    <el-container>
        <el-row>
            <h1>Home Page</h1>
            <p>Hello. <router-link to="login">Click here to login</router-link> or <router-link to="register">Click here to register</router-link>.</p>
        </el-row>
    </el-container>
</template>

<script>

export default {
  components: {
  },
  created(){
    console.log('app home created')
  },
  data: function() {
    return {
      editing: {},
    }
  }
}
</script>

<style>

</style>
