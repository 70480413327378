var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: " h-full w-full divide-y overflow-auto " },
    [
      _c("div", { staticClass: "pt-4" }),
      _c(
        "div",
        { staticClass: "py-4 flex flex-row justify-evenly w-30 border-solid " },
        [
          _c(
            "div",
            {
              staticClass:
                "w-3/6 flex flex-col border-r items-center flex-grow",
            },
            [
              _c("h3", { staticClass: "text-gray-600" }, [
                _vm._v("Starting Balance"),
              ]),
              _c("span", { staticClass: "text-2xl" }, [
                _vm._v(_vm._s(_vm.formattedStartingBalance) + " "),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "w-3/6 flex flex-col items-center flex-grow" },
            [
              _c("h3", { staticClass: "text-gray-600" }, [
                _vm._v("New Balance"),
              ]),
              _c("span", { staticClass: "text-2xl text-green-700" }, [
                _vm._v(" " + _vm._s(_vm.formattedNewBalance)),
              ]),
              _vm.credit.amount
                ? _c(
                    "span",
                    { staticClass: "text-center text-gray-600 text-xs p-2" },
                    [_vm._v(_vm._s(_vm.amountDescription))]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "el-form",
        {
          staticClass: "py-2 flex justify-center flex-col",
          attrs: { "label-position": "top" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "w-full sm:w-60",
              attrs: { label: "Adjustment Type" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.adjustmentType,
                    callback: function ($$v) {
                      _vm.adjustmentType = $$v
                    },
                    expression: "adjustmentType",
                  },
                },
                _vm._l(_vm.adjustmentTypeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "w-full sm:w-60", attrs: { label: "Amount" } },
            [
              _c(
                "text-field",
                {
                  ref: "field_amount",
                  attrs: {
                    value: _vm.formattedValue,
                    placeholder: "Amount",
                    required: false,
                    type: "text",
                    validations: [],
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setAmount($event)
                    },
                    change: function ($event) {
                      return _vm.setAmount($event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-black text-xl",
                      attrs: { slot: "prepend" },
                      slot: "prepend",
                    },
                    [_vm._v("$")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "w-full", attrs: { label: "Add internal note" } },
            [
              _c("text-field", {
                ref: "field_description",
                attrs: {
                  value: _vm.credit.description,
                  type: "textarea",
                  placeholder: "",
                  rows: 5,
                  validations: [
                    {
                      method: "maxLength",
                      value: 500,
                      error: "Maximum 500 characters",
                    },
                  ],
                },
                on: {
                  input: function ($event) {
                    _vm.credit.description = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("p", { staticClass: "text-xs  border-solid  text-gray-600" }, [
            _vm._v(
              "This space can be used to provide additional information about your adjustment.This is an internal note and will not be visible to your customer"
            ),
          ]),
          _c("WemowButton", {
            staticClass: "py-4 mt-4 mb-8",
            attrs: {
              text: "Apply to customer balance",
              disabled: !this.credit.amount,
              block: false,
            },
            nativeOn: {
              mousedown: function ($event) {
                return _vm.creditCustomer.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }