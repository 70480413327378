import {
  organizationBeforeResolve,
  organizationBeforeResolveOnboarding,
  organizationBeforeResolveAwaitingApproval
} from "../../middleware";

export const organizationRoutes = [
    /*
     * Organization Routes
     */
    {
      path: "/organization",
      name: "OrganizationDashboard",
      component: () =>
        import(
          /* webpackChunkName: "organization-dashboard" */ `../../../../components/organization/dashboard/OrganizationDashboard.vue`
        ),
      
      meta: { requiresAuth: true, allowedRoles: ["organization"], showHeaderLogoAsLink: false, beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/onboarding",
      name: "OrganizationOnboarding",
      component: () =>
        import(
          /* webpackChunkName: "organization-onboarding" */ `../../../../components/organization/setup/OrganizationSetup.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], hideNavigation: true, showHeaderLogoAsLink: false, beforeResolve: organizationBeforeResolveOnboarding, headerRightInnerClasses: "md:items-center justify-end"  }
    },
    {
      path: "/organization/pending-approval",
      name: "OrganizationPendingApproval",
      component: () =>
        import(
          /* webpackChunkName: "organization-pending-approval" */ `../../../../components/organization/other/PendingApproval.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], hideNavigation: true, showHeaderLogoAsLink: false, beforeResolve: organizationBeforeResolveAwaitingApproval, headerRightInnerClasses: "md:items-center justify-end"   }
    },
    {
      path: "/organization/profile",
      name: "OrganizationProfile",
      component: () =>
        import(
          /* webpackChunkName: "organization-profile" */ `../../../../components/organization/profile/OrganizationProfile.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/vendor/new",
      name: "OrganizationVendorAddNew",
      component: () =>
        import(
          /* webpackChunkName: "organization-vendor-add-new" */ `../../../../components/organization/vendors/VendorAddNew.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/vendors",
      name: "OrganizationVendors",
      component: () =>
        import(
          /* webpackChunkName: "organization-vendors-archive" */ `../../../../components/organization/vendors/VendorsArchive.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/vendor/:id",
      name: "OrganizationVendorSingle",
      component: () =>
        import(
          /* webpackChunkName: "organization-vendors-single" */ `../../../../components/organization/vendors/VendorSingle.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/appointments",
      name: "OrganizationAppointmentsDashboard",
      component: () =>
        import(
          /* webpackChunkName: "organization-appointments-archive" */ `../../../../components/shared/AppointmentsDisplay/AppointmentsDisplay.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve },
      props: {
        roleType: "organization",
        defaultView: 'day',
        linkPath: "organization/appointment",
        allowCustomerFilter: true,
        allowVendorFilter: true
      }
    },
    {
      path: "/organization/appointment/:id",
      name: "OrganizationAppointmentSingle",
      component: () =>
        import(
          /* webpackChunkName: "organization-appointment-single" */ `../../../../components/v2/shared/appointments/SingleView.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve },
      props: {
        userType: "organization"
      }
    },
    {
      path: "/organization/payouts",
      name: "OrganizationPayout",
      component: () =>
        import(
          /* webpackChunkName: "organization-payouts" */ `../../../../components/organization/payout/Payout.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
  {
    path: "/organization/payment-transfers",
    name: "OrganizationPaymentTransfers",
    component: () =>
      import(
          /* webpackChunkName: "organization-payment-transfers" */ `../../../../components/organization/transactions/OrganizationTransactionTransfers.vue`
      ),
    meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
  },
    {
      path: "/organization/help-desk",
      name: "OrganizationHelpdeskDashboard",
      component: () =>
        import(
          /* webpackChunkName: "vendor-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketDashboard.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/help-desk/history",
      name: "OrganizationHelpdeskHistory",
      component: () =>
        import(
          /* webpackChunkName: "vendor-helpdesk-archive" */ `../../../../components/shared/Tickets/TicketHistory.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/help-desk/:id",
      name: "OrganizationHelpDeskViewSingle",
      component: () =>
        import(
          /* webpackChunkName: "customer-helpdesk-single" */ `../../../../components/organization/tickets/TicketsViewSingle.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve },
      props: true
    },
    {
      path: "/organization/create-invoice",
      name: "OrganizationCreateInvoice",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/shared/Invoice/CreateInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/edit-invoice/:id",
      name: "OrganizationEditInvoice",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/shared/Invoice/EditInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve, props: true }
    },
    {
      path: "/organization/list-invoices",
      name: "OrganizationListInvoices",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/organization/invoice/InvoicesList.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/view-invoice/:id",
      name: "OrganizationViewInvoice",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/organization/invoice/ViewInvoice.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/view-transfers",
      name: "OrganizationViewTransfers",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/organization/transactions/OrganizationTransactionTransfers.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },
    {
      path: "/organization/view-payouts",
      name: "OrganizationViewPayouts",
      component: () =>
        import(
          /* webpackChunkName: "admin-user-archive" */ `../../../../components/organization/transactions/OrganizationTransactionPayouts.vue`
        ),
      meta: { requiresAuth: true, allowedRoles: ["organization"], beforeResolve: organizationBeforeResolve }
    },

  ];