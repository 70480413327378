import Vue from "vue";
import Router from "vue-router";
import { routes } from "./routes";
import store from "@/store/store";
import { APP_SET_ASYNC_COMPONENT_LOADING } from "@/store/mutation-types";
import { saveQuoteToLocalStorage , saveDefaultsToLocalStorage} from "@/helpers/quote";

import {
  getCurrentUser,
  canAccessRoute,
  routerBeforeResolve
} from './middleware'

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

/* eslint-disable no-new */
const router = new Router({
  routes: process.env.VUE_APP_PLATFORM === "web"
    ? routes
    : [],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  try {
    const [matchedToRoute] = to.matched;
    if (matchedToRoute?.components && typeof matchedToRoute?.components.default === 'function') {
      if(from.name !== matchedToRoute.name){
        store.commit(APP_SET_ASYNC_COMPONENT_LOADING, true)
      }
    }

    for (const param of Object.keys(to.query)) {
      if(param.substring(0, 16) === 'wemow_quote_set_'){
        const [,key] = param.split('wemow_quote_set_')
        saveQuoteToLocalStorage({[key]: to.query[param] });
      }
      if(param.substring(0, 18) === 'wemow_default_set_'){
        const [,key] = param.split('wemow_default_set_')
        saveDefaultsToLocalStorage({[key]: to.query[param] });
      }
    }
  } finally {
    next();
  }
});

router.beforeEach(getCurrentUser)
router.beforeEach(canAccessRoute)
router.beforeResolve(routerBeforeResolve)
router.beforeResolve((to, from, next) => {
  store.commit(APP_SET_ASYNC_COMPONENT_LOADING, false)
  next();
});

export default router;
