import axios from '../lib/axios'
import auth from '../auth'

const {
  VUE_APP_API_SERVER: DB_URL,
} = process.env;


export function fetchPresignedUploadUrl( { data, file }) {
  return axios.put(DB_URL + 'media/upload/getUrl', { data, file }, {
      headers: auth.getAuthHeader()
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}


export function fetchPresignedUploadUrlForAccount( { data, file }) {
  return axios.put(DB_URL + 'media/upload/getUrlForAccount', { data, file }, {
      headers: auth.getAuthHeader()
    })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}


export function uploadToPresignedUrl(url, payload, param) {
    return axios.post(url, payload, {
      onUploadProgress: function( progressEvent ) {
        if (progressEvent.total > 0) {
          progressEvent.percent = progressEvent.loaded / progressEvent.total * 100;
        }
        param.onProgress(progressEvent);
      }
    })
      .then((user) => Promise.resolve(user))
      .catch((error) => Promise.reject(error))
  }

export function getPresignedFileUrl({key}){
  return axios.get(DB_URL + 'media/file',  {
    headers: auth.getAuthHeader(),
    params: { key }
  })
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}

export function deleteMediaFile({key, ticket_id}){
  return axios.put(DB_URL + 'media/file/delete', {key, ticket_id},  {
    headers: auth.getAuthHeader(),
  })
  .then((user) => Promise.resolve(user))
  .catch((error) => Promise.reject(error))
}