<template>
    <CustomDropdownRoot
      :dropDownItems="dropDownItems"
      :selectedValue="selectedValue"
      :preventPopover="preventPopover"
      @onClickPlaceholder="$emit('onClickPlaceholder', $event)"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @customAction="$emit('customAction', $event)"
    >
      <template v-slot:labelHasValue="{ selected }">
        <span class="text-gray-900 text-lg"
          ><span v-if="selected">{{ selected.label }}</span></span
        >
      </template>
      <template v-slot:labelNoValue><span class="text-lg">Select Add-on Service</span></template>
      <template v-slot:innerDropdownItem="{ item }">
        <div class="flex justify-between items-center">
          <span class="text-lg">{{ item.label }}</span>
          <span class="text-xs">{{ item.sub }}</span>
        </div>
      </template>

    </CustomDropdownRoot>
</template>

<script>
import CustomDropdownRoot from "./CustomDropdownRoot"
export default {
  name: "customDropdownAddOns",
  components: { CustomDropdownRoot },
  props: {
      selectedValue: {
          type: [Array, Object, String, Number, Date],
          default(){
              return null
          }
      },
      dropDownItems: {
          type: [Array],
          default(){
              return null
          }
      },
      preventPopover: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      loading: false,
    };
  },


  methods: {


  }
};
</script>