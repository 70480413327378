var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-full w-full flex justify-between items-start flex-col px-4 ",
    },
    [
      _c(
        "div",
        { staticClass: "x-bg-red-400 w-full h-full flex  justify-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full px-4 x-bg-red-500 grid grid-flow-row-dense grid-cols-4",
            },
            [
              _vm.icon
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-span-1 h-24 mr-8 bg-red-600 flex items-center justify-center",
                    },
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { class: _vm.icon ? "col-span-3" : "col-span-4" },
                [
                  _vm.title
                    ? _c("div", { staticClass: "text-2xl font-medium mt-4" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm.description
                    ? _c("div", { staticClass: "mt-2 text-sm" }, [
                        _vm._v(_vm._s(_vm.description)),
                      ])
                    : _vm._e(),
                  _vm.hasInnerData
                    ? _c(_vm.hasInnerData, {
                        tag: "component",
                        attrs: { slotData: _vm.slotData },
                        on: {
                          confirm: _vm.onClickConfirm,
                          cancel: _vm.onClickCancel,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }