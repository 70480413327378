import { setCookie, eraseCookie, getCookie } from "@/helpers/helpers";


export default {
  mounted() {
    this.setAffiliateIdCookie()
  },
  methods: {
    setAffiliateIdCookie() {
      const { aff } = this.$route.query;
      if (aff && aff.length > 0) {
        setCookie("affiliate_id", aff, 365);
      }
    },
    removeAffiliateIdCookie() {
      const cookieValue = getCookie("affiliate_id")
      if (cookieValue && cookieValue.length > 0) {
        eraseCookie("affiliate_id");
      }
    },
    getAffiliateIdCookie() {
      return getCookie("affiliate_id");
    }
  }
}