import axios from 'axios';
const { VUE_APP_MODE, VUE_APP_PLATFORM} = process.env
import packageJson from '../../package.json'
const { version } = packageJson

const login = '/login';
const instance = axios.create(
	{
		// baseURL: `/your/host/api/v1`, might need to enable in future
	}
);

// response parse
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response } = error;
		if (!response) Promise.reject(error);
		try {
			const { response } = error;
			const { status, data } = response;
			switch (status) {
				case 401:
					if (window) {
						if (
							data &&
							data.message === 'User token authentication failed' &&
							window.location.pathname !== login
						) {
							return (window.location.href = login);
						}
					}
					return Promise.reject(error);
				default:
					return Promise.reject(error);
			}
		} catch (e) {
			return Promise.reject(error);
		}
	}
);


instance.interceptors.request.use(function (config) {
	const platform = VUE_APP_MODE === 'native'? 'app': VUE_APP_MODE
	if(config && config.headers) {
		config.headers['x-wemow-platform'] = platform
		config.headers['x-wemow-app-version'] = version
		config.headers['x-wemow-device-type'] = VUE_APP_PLATFORM === 'web' ? 'browser' : VUE_APP_PLATFORM
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});
export default instance;
