export const errorMessages = {
    errorSaving: "Error al guardar {collection}",
    errorRemoving: "Error al eliminar {collection}",
    errorUpdating: "Error al actualizar {collection}",
    errorGetting: "Error al obtener {collection}",
    errorReceiving: "Error al recibir {collection}",
    errorSending: "Error al enviar {collection}",
    errorAdding: "Error al agregar {collection}",
    errorDeleting: "Error al eliminar {collection}",
    errorApproving: "Error al aprobar {collection}",
    errorCancelling: "Error al cancelar {collection}",
    errorRetrieving: "Error al recuperar {collection}",
    errorValidating: "Error al validar",
    minimumCharacters: "Mínimo {num} caracteres!",
    maximumCharacters: "Máximo {num} caracteres!",
    missingFields: "Faltan campos obligatorios",
    allFieldsRequired: "Todos los campos son obligatorios",
    resolveAddressErrors: "Resolver problema con la dirección",
    invalidText: "{text} no válido",
    addRecipient: "Agregue al menos un recibidor",
    enterFields: "Por favor ingrese {text}",
    helpDeskAccountIssue: "Hay un problema con su cuenta del servicio de asistencia. Por favor, póngase en contacto con el soporte.",
    ticketNotCreated: "No se pudo crear el boleto. Intenta nuevamente más tarde.",
    errorUploadingImage: "Error al subir la imagen",
    error: "Error",
    notAvailable: "No disponible",
    appointmentTimeNotFound: "No se puede encontrar la hora de la cita",
    maxVendorsReached: "Se ha alcanzado el número máximo de vendedores para su organización. No puede agregar más proveedores.",
    vendorNotPartOfOrg: "Proveedor no encontrado o no es parte de esta organización",
    notInServiceArea: "no esta en el área de servicio",
    timeOffError: "Error al enviar la solicitud de tiempo libre del proveedor",
    loginError: "Error de inicio de sesión",
    signupError: "Error de registro",
    defaultAddressError: "Error: su dirección seleccionada o predeterminada no se encuentra en nuestras áreas de servicio. No podemos prestar nuestros servicios allí en este momento",
    zipCodeError: "no es válido para el parámetro: codigo_postal",
    addressNotInServiceArea: "Su dirección predeterminada no está en el área de servicio.",
    confirmationCodeFailed: "Error de reenvío del código de confirmación del teléfono:",
    codeVerificationFailed: "La verificación del código falló:",
    profileImageChange: "Cambio de imagen de perfil falló",
    profileImageRemoved: "Error al eliminar la imagen de perfil",
    uploadType: "¡La imagen de perfil debe estar en formato {types}!",
    uploadSize: "El tamaño del archivo no puede exceder {size} {fileSizeLabel}!",
    invalidEmailEntered: "Correo electrónico no válido ingresado",
    enterVendorFirstName: "Por favor ingrese el nombre del vendedor",
    enterVendorLastName: "Por favor ingrese el apellido del vendedor",
    enterVendorEmail: "Por favor ingrese el correo electrónico del proveedor",
    enterVendorPassword: "Por favor, introduzca la contraseña del proveedor",
    enterVendorPhoneNumber: "Por favor ingrese el número de teléfono del proveedor",
    enterName: "Por favor ingrese el nombre",
    enterPrice: "Ingrese el precio",
    enterSubject: "Por favor ingrese el asunto",
    enterMessage: "Por favor ingrese el mensaje",
    resetTokenExpired: "Tu token ha caducado. Intente restablecer su contraseña nuevamente para recibir un nuevo enlace.",
    confirmAccountTokenExpired: "Su token de confirmación ha caducado. intente iniciar sesión para solicitar un nuevo enlace de confirmación.",
    invalidTicketId:"La identificación del boleto recibido no es válida o no existe.",
    invalidAppointmentInstanceId: "La referencia de cita recibida no es válida o no existe.",
    passwordStrengthError: "Ingrese una contraseña más fuerte",
    passwordsDoNotMatch: "Las contraseñas no coinciden",
    errorChangingPrice: "No hay precio disponible para la frecuencia seleccionada",
    noMapCompatibleApp: "No tiene ninguna aplicación compatible para ver la dirección visualmente.",
    errorShowingAddressOnMap: "Se produjo un error al mostrar la dirección de la cita en el mapa. Si el problema persiste, llame al servicio de asistencia.",
    invalidPhoneNumber: "Formato de número de teléfono no válido.",
    notAllowed: "No tienes permiso para ver esta ruta.",
    noCustomerCard: "El cliente no tiene tarjeta adjunta",
    notValidEmail: "Este no es un correo electrónico válido",
    emailExists: "El correo electrónico ya existe",
    noSpacesReferralId: "El ID de referencia no debe contener espacios.",
    codeIsRequired:"Se requiere código.",
    timeNotInRange: "El tiempo debe estar en el rango de 8AM A 8PM.",
    errorLoggingIn: "Hubo un error al iniciar sesión",
    errorRestoring: "Hubo un error al restaurar la suscripción",
    errorSuspending: "Hubo un error al restaurar la suscripción",
    errorNoteRequired: "Se requiere nota!"
}