
import { boxClasses } from "@/helpers/modals";
import WemowDialog from "@/components/global/GlobalDialog/Dialog.vue";

const pluginWemowDialog = {
  install(Vue) {
    Vue.prototype.$wemowDialog = function ({
      title = "",
      description = "",
      primaryButtonText,
      secondaryButtonText,
      icon = "",
      slotTemplate = "none",
      slotData = {},
      onConfirm = () => {},
      onCancel = () => {},
      beforeClose = () => {},
    }) {
      if(!title) return;

      this.$modal.show(
        WemowDialog,
        {
          icon,
          title,
          description,
          onConfirm,
          onCancel,
          slotTemplate,
          slotData,
          primaryButtonText,
          secondaryButtonText
        },
        {
          name: "WemowDialogModal", // needs this to close
          height: "100%",
          width: "100%",
          adaptive: true,
          classes: boxClasses("not-full", "top", "", true),
          styles: {
            position: "static",
          },
        },
        {
          "before-close": beforeClose,
        }
      );
    };
  },
};

export default pluginWemowDialog;
