// @ts-check
import { formLabels } from "./formLabels";
import { buttons } from "./buttons"
import { menu } from "./menu"
import { registration } from "./registration"
import { services } from "./services"
import { payment } from "./payment"
import { information } from "./information"
import { accountTypes } from "./accountTypes"
import { tickets } from "./tickets"
import { appointments } from "./appointments"
import { affiliate } from "./affiliate"
import { errorMessages } from "./errorMessages"
import { confirmMessages } from "./confirmMessages"
import { successMessages } from "./successMessages"
import { timeOfDay } from "./timeOfDay"
import { miscStrings } from "./misc"
import { languages } from "./languages"
import { timezones } from "./timezones"

export const es = {
    ...miscStrings,
    ...{ formLabels },
    ...{ buttons },
    ...{ menu },
    ...{ timeOfDay },
    ...{ registration },
    ...{ services },
    ...{ payment },
    ...{ information },
    ...{ accountTypes },
    ...{ tickets },
    ...{ appointments },
    ...{ affiliate },
    ...{ errorMessages },
    ...{ confirmMessages},
    ...{ successMessages },
    ...{ languages },
    ...{ timezones }
}