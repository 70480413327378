<template>
  <div class="flex items-center justify-end bg-white z-50 pr-4">
    <section class="loading-menu-items">
      <article class="background">
        <div class="item-1"></div>
        <div class="item-2"></div>
        <div class="item-3"></div>
        <div class="item-4"></div>
      </article>
    </section>
    <section class="loading-menu-profile">
      <article class="background">
        <div class="user-sep"></div>
        <div class="row-1"></div>
        <div class="row-2"></div>
        <div class="row-3"></div>
        <div class="row-4"></div>
        <div class="row-5"></div>
      </article>
    </section>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>


.background {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
}

.background div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
}

.loading-menu-profile .background {
    background-size: 800px 40px;
    height: 40px;
}

.loading-menu-profile {
  width:200px;
  max-width: 476px;
  height: 40px;
}


.loading-menu-items {
  width:400px;
  width:33vw;
  max-width: 380px;
  height: 8px;
}

.loading-menu-items .background {
    background-size: 800px 8px;
    height: 8px;
}

.loading-menu-items .background div {
  left:auto;
  top:0;
  height:8px;
  width:28px;
}


div.item-1 {
    right: 93px;
}

div.item-2 {
    right: 181px;
}

div.item-3 {
    right: 284px;
}

/* div.item-4 {
    right: 376px;
} */


div.user-sep {
    height: 40px;
    right: 40px;
    left: auto;
    top: 0;
    width: 8px;
}

div.row-1 {
    height: 8px;
    right: 48px;
    top: 0;
}
div.row-2 {
    right: 136px;
    top: 8px;
}
div.row-3 {
    height: 12px;
    right: 48px;
    top: 14px;
}
div.row-4 {
    right: 100px;
    top: 26px;
}
div.row-5 {
    height: 10px;
    right: 48px;
    top: 32px;
}


@keyframes placeHolderShimmer{
  
0% {
    background-position: -468px 0;
}
100% {
    background-position: 468px 0;
}
}
</style>