export const menu = {
  logout: "Cerrar sesión",
  mySettings: "Mi configuración",
  fundraisingStats: "Estadísticas de Recaudación de Fondos",
  marketingScreen: "Pantalla de Marketing",
  overview: "Resumen",
  transfers: "Traslados",
  manageTeam: "Administrar Equipo",
  myTickets: "Mis boletos",
  workSchedule: "Horario de Trabajo",
  home: "Hogar",
  help: "Ayuda",
  dashboard: "Tablero",
  helloAdmin: "Hola Administrador",
  manageServiceAreas: "Administrar áreas de servicio",
  activitiesTomorrow: "Actividades - Mañana",
  activitiesToday: "Actividades - Hoy",
  activitiesForYesterday: "Actividades - ayer",
  referralIDs: "ID de referencia",
  reports: "Informes",
  approvals: "Aprobaciones",
}