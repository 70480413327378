import { SET_LIST_VIEW_MODULE_STATE, SET_ACTIVE_TABLE_COLUMNS,  RESET_LIST_VIEW_MODULE_STORE } from "@/store/mutation-types";
import {has, set} from "lodash-es";

export const defaultState = () => {
  return {
      tableRecordsPerPageCount: 0,
      currentPage: 1,
      defaultPageSize: 10,
      totalTableRecords: 0,
      tableData: [],
      tableRowData: {},
      tableActions: [],
      actionLinks: [],
      paginationPageSizes: [],
      paginationLinks: {
          pages: []
      },
      showAdvancedTableFilter: false,
      showSimpleTableFilter: false,
      tableColumnRules: {}, //table columns data
      tableColumns: [],
      query:{},
      filterQueryOperations: [],
      filterQuery: {},
      activeFilterQueries: [], // used by active fields in advanced filter
      selectedTableColumns: [],
      advanceFilterFields: [],
      simpleFilterFields: [],
      listViewStoreReady: false
  }
}

export const state = defaultState()

export const mutations = {
    [SET_LIST_VIEW_MODULE_STATE](state,{ key, value }) {
        if (has(state, key)) {
          set(state, key, value)
        }
    },
    [SET_ACTIVE_TABLE_COLUMNS](state, headers) {
        state.selectedTableColumns = headers
    },
    [RESET_LIST_VIEW_MODULE_STORE](state) {
        state.currentPage = 1;
        state.tableData = []
        state.activeFilterQueries = []
        state = { ...defaultState() }
    }
};

export function getColumnsVisibleByDefault(tableColumnRules) {
  return Object.keys(tableColumnRules).filter(field => tableColumnRules[field].visibleByDefault)
}

export const getters = {
  getTableColumns: (state) => {
    if (state.tableColumns.length > 0) {
      return state.tableColumns
    } else {
      return getColumnsVisibleByDefault(state.tableColumnRules)
    }
  },
  getActiveTableColumns: (state) => {
    if (state.selectedTableColumns.length > 0) {
      return state.selectedTableColumns
    } else {
      return getColumnsVisibleByDefault(state.tableColumnRules)
    }
  },
  currentPage: (state) => state.currentPage,
  getQuery: (state) => state.query,
  paginationPageSizes: (state) => state.paginationPageSizes,
  defaultPageSize: (state) => state.defaultPageSize,
}



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {}
};
