var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.pageWrapperClass, attrs: { id: "app-id" } },
    [
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [
          !_vm.setup
            ? [_c("PublicScreenNotReady", [_vm._v("Loading")])]
            : !_vm.loggedIn
            ? [_c("router-view", { attrs: { name: "public" } })]
            : [
                _c(
                  "div",
                  [
                    _c(_vm.getLayoutComponent, {
                      tag: "component",
                      class: "user-" + _vm.userRole,
                      attrs: {
                        maxWidthContent: _vm.getWebContentWidth,
                        showHeaderLogoAsLink:
                          _vm.routeMeta.showHeaderLogoAsLink,
                        isLoading: false || _vm.asyncComponentLoading,
                        headerRightClasses: _vm.routeMeta.headerRightClasses,
                        headerRightInnerClasses:
                          _vm.routeMeta.headerRightInnerClasses,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("router-view", { attrs: { name: "header" } }),
                              !_vm.routeMeta.hideNavigation &&
                              _vm.userStoreIsReady &&
                              _vm.loggedIn &&
                              _vm.userRole !== "none" &&
                              !_vm.routePath.includes("alert")
                                ? _c("AppMenu", {
                                    attrs: { role: _vm.userRole },
                                  })
                                : _vm.currentPageName === "CustomerOnboarding"
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "hidden md:inline  ml-12 mr-0 md:mr-16 text-xs text-gray-600 font-medium cursor-pointer highlight-link bg-white mt-4 sm:mt-0",
                                      on: { click: _vm.showModalShareLink },
                                    },
                                    [_vm._v("What is Wemow?")]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "text-gray-500 text-sm cursor-pointer ml-12",
                                      attrs: { to: "/logout" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("menu.logout"))),
                                      ]),
                                    ]
                                  ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "body",
                          fn: function () {
                            return [_c("router-view")]
                          },
                          proxy: true,
                        },
                        {
                          key: "after",
                          fn: function () {
                            return [
                              _c("router-view", { attrs: { name: "footer" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }