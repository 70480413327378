import {
    SET_ADD_ON_OPTIONS
  } from "@/store/mutation-types";
  
  const state = {
    addOnOptions: [],
  }
  
  const mutations = {
    [SET_ADD_ON_OPTIONS]: (state, addOns) => {
      state.addOnOptions = addOns;
    },
  };
  
  const actions = {}
  
  const getters = {
    getAddOnOptions: state => state.addOnOptions
  }
  
  export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
  }