var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { hasError: _vm.$v.$error, isFocus: _vm.isFocused },
    },
    [
      _c(
        "div",
        { staticClass: "form-field", class: "form-field-" + _vm.type },
        [
          _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
          _vm.type === "phone"
            ? _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "###-###-####",
                    expression: "'###-###-####'",
                  },
                ],
                ref: "textField",
                attrs: {
                  id: _vm.id,
                  "prefix-icon": _vm.prefixIcon,
                  type: _vm.type,
                  placeholder: _vm.placeholder,
                  "auto-complete": "off",
                  masked: true,
                  readonly: _vm.readonly,
                  disabled: _vm.disabled,
                  rows: _vm.type == "textarea" ? _vm.rows : "",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.$touch()
                  },
                  change: _vm.emitChange,
                },
                model: {
                  value: _vm.sample_text,
                  callback: function ($$v) {
                    _vm.sample_text = $$v
                  },
                  expression: "sample_text",
                },
              })
            : _vm.type === "password"
            ? _c(
                "el-input",
                {
                  ref: "textField",
                  attrs: {
                    "prefix-icon": _vm.prefixIcon,
                    type: _vm.showPassword ? "text" : "password",
                    placeholder: _vm.placeholder,
                    "auto-complete": "off",
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.$touch()
                    },
                    change: _vm.emitChange,
                    focus: _vm.focusField,
                    blur: _vm.blurField,
                  },
                  model: {
                    value: _vm.sample_text,
                    callback: function ($$v) {
                      _vm.sample_text = $$v
                    },
                    expression: "sample_text",
                  },
                },
                [
                  _vm.showPassword
                    ? _c("font-awesome-icon", {
                        staticClass: "mr-8 cursor-pointer el-input__icon",
                        attrs: { slot: "suffix", icon: "eye-slash" },
                        on: {
                          click: function ($event) {
                            _vm.showPassword = false
                          },
                        },
                        slot: "suffix",
                      })
                    : _c("font-awesome-icon", {
                        staticClass: "mr-8 cursor-pointer el-input__icon",
                        attrs: { slot: "suffix", icon: "eye" },
                        on: {
                          click: function ($event) {
                            _vm.showPassword = true
                          },
                        },
                        slot: "suffix",
                      }),
                ],
                1
              )
            : _vm.type === "taxId"
            ? _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.getTaxIdMaskingFormat,
                    expression: "getTaxIdMaskingFormat",
                  },
                ],
                ref: "textField",
                attrs: {
                  "prefix-icon": _vm.prefixIcon,
                  type: "text",
                  placeholder: _vm.placeholder,
                  "auto-complete": "off",
                  masked: true,
                  readonly: _vm.readonly,
                  disabled: _vm.disabled,
                  rows: _vm.type == "textarea" ? _vm.rows : "",
                  autofocus: _vm.focusOnMount,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.$touch()
                  },
                  change: _vm.emitChange,
                  focus: _vm.focusField,
                  blur: _vm.blurField,
                },
                model: {
                  value: _vm.sample_text,
                  callback: function ($$v) {
                    _vm.sample_text = $$v
                  },
                  expression: "sample_text",
                },
              })
            : _c(
                "el-input",
                {
                  ref: "textField",
                  attrs: {
                    id: _vm.id,
                    "prefix-icon": _vm.prefixIcon,
                    type: _vm.type,
                    placeholder: _vm.placeholder,
                    "auto-complete": "off",
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                    maxlength: _vm.maxLengthValidation.value,
                    "show-word-limit": _vm.maxLengthValidation.showWordLimit,
                    rows: _vm.type == "textarea" ? _vm.rows : "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.$touch()
                    },
                    change: _vm.emitChange,
                    focus: _vm.focusField,
                    blur: _vm.blurField,
                  },
                  model: {
                    value: _vm.sample_text,
                    callback: function ($$v) {
                      _vm.sample_text = $$v
                    },
                    expression: "sample_text",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._t("prepend")], 2)],
                2
              ),
          _c(
            "el-tooltip",
            {
              attrs: {
                tabindex: 9999,
                disabled:
                  _vm.$v.sample_text.required &&
                  _vm.$v.sample_text.required === true,
                content: "Required",
                placement: "top",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "icon",
                  class:
                    "icon-" +
                    _vm.displayIcon +
                    " " +
                    "" +
                    (_vm.loading ? "fa-spin" : ""),
                  attrs: { tabindex: 9999 },
                },
                [_c("font-awesome-icon", { attrs: { icon: _vm.displayIcon } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.displayErrorMessage &&
      _vm.displayErrorMessage.length &&
      _vm.type === "email" &&
      !_vm.isFocused
        ? _c(
            "div",
            { staticClass: "error-message" },
            _vm._l(_vm.displayErrorMessage, function (err, index) {
              return _c("span", { key: index }, [_vm._v(_vm._s(err))])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }