import {
  SEARCH_USER_QUERY,
  SEARCH_VENDOR_QUERY,
  SEARCH_ORGANIZATION_QUERY,
  SEARCH_CUSTOMER_QUERY,
  SEARCH_AFFILIATE_QUERY,
  SEARCH_AFFILIATE_ID_QUERY,
  SEARCH_SERVICES,
  SEARCH_VENDORS_IN_LOCATION,
  SEARCH_ZIPCODES,
  SEARCH_ACCOUNTS
} from '@/store/action-types'

import {
  userGetAllSearch,
  vendorGetAllSearch,
  customerGetAllSearch,
  organizationGetAllSearch,
  affiliateGetAllSearch,
  affiliateIdGetAllSearch,
  searchServices,
  vendorInLocationSearch,
  searchZipcodes,
  searchAccounts
}from '@/services/ServiceSearchDatabaseAPI'

import {
  SET_SELECTED_SEARCH_VALUE
} from "@/store/mutation-types";

const state = {
  selectedSearch: {
    customers: {},
    vendors: {},
    services: [],
    organizations: {},
    users: {},
    affiliates: {},
    note: ""
  },
}

const mutations = {
  [SET_SELECTED_SEARCH_VALUE]: (state, selected) => {
    state.selectedSearch = selected;
  },
};

const actions = {
  async [SEARCH_USER_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_users = await userGetAllSearch(query);
      if (load_users.status > 204 || !load_users.data) {
        throw new Error("Error loading users");
      }

      const users = load_users.data;

      return users;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_VENDOR_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_vendors = await vendorGetAllSearch(query);
      if (load_vendors.status > 204 || !load_vendors.data) {
        throw new Error("Error loading vendors");
      }

      const vendors = load_vendors.data;

      return vendors;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_ORGANIZATION_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_organizations = await organizationGetAllSearch(query);
      if (load_organizations.status > 204 || !load_organizations.data) {
        throw new Error("Error loading organizations");
      }

      const organizations = load_organizations.data;

      return organizations;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_CUSTOMER_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_customers = await customerGetAllSearch(query);
      if (load_customers.status > 204 || !load_customers.data) {
        throw new Error("Error loading customers");
      }

      const customers = load_customers.data;

      return customers;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_AFFILIATE_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_affiliates = await affiliateGetAllSearch(query);
      if (load_affiliates.status > 204 || !load_affiliates.data) {
        throw new Error("Error loading affiliates");
      }

      const affiliates = load_affiliates.data;

      return affiliates;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  ///search/services
  async [SEARCH_AFFILIATE_ID_QUERY]({}, query) {
    try {
      if(!query || (_.has(query, 'search') && query.search.split('').length < 3)){
        return []
      }
      const load_affiliates = await affiliateIdGetAllSearch(query);
      if (load_affiliates.status > 204 || !load_affiliates.data) {
        throw new Error("Error loading affiliates");
      }

      const affiliates = load_affiliates.data;

      return affiliates;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_SERVICES]({}, query) {
    try {
      const response = await searchServices(query);
      if (response.status > 204 || !response.data) {
        throw new Error("Error loading affiliates");
      }

      return response.data;

    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_VENDORS_IN_LOCATION]({}, query) {
    try {
      if (Object.keys(query).length === 0 ) {
        return []
      }
      const vendors = await vendorInLocationSearch(query);
      if (vendors.status > 204 || !vendors.data) {
        throw new Error("There was a problem loading results");
      }

      const affiliates = vendors.data;

      return affiliates;
    } catch (e) {
      console.log(e)
      throw new Error("There was a problem loading results");
    }
  },
  async [SEARCH_ZIPCODES]({}, query) {
    try {
      const response = await searchZipcodes(query);
      if (response.status > 204 || !response.data) {
        throw new Error("Error loading affiliates");
      }

      return response.data;

    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  },
  async [SEARCH_ACCOUNTS]({}, query) {
    try {
      const response = await searchAccounts(query);
      if (response.status > 204 || !response.data) {
        throw new Error("Error loading accounts");
      }

      return response.data;
    } catch (e) {
      throw new Error('There was a problem loading results');
    }
  }
}

const getters = {
  getSelectedSearch: state => state.selectedSearch
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}