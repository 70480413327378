export const ADMIN_LOAD_USER_DATA_BY_ID = `ADMIN_LOAD_USER_DATA_BY_ID`
export const ADMIN_USER_CREATE_NEW = `ADMIN_USER_CREATE_NEW`
export const ADMIN_USER_DELETE_ONE = `ADMIN_USER_DELETE_ONE`
export const ADMIN_USERS_GET_ALL_QUERY = `ADMIN_USERS_GET_ALL_QUERY`
export const ADMIN_USER_ASSIGN_ACCOUNT = `ADMIN_USER_ASSIGN_ACCOUNT`
export const ADMIN_USER_UPDATE_SETTING = `ADMIN_USER_UPDATE_SETTING`
export const ADMIN_MEDIA_GET_WITH_QUERY = `ADMIN_MEDIA_GET_WITH_QUERY`
export const ADMIN_REMOVE_UPLOADED_MEDIA = `ADMIN_REMOVE_UPLOADED_MEDIA`
export const ADMIN_LOAD_MEDIA_DATA = `ADMIN_LOAD_MEDIA_DATA`

export const SERVICES_GET_ONE = `SERVICES_GET_ONE`
export const SERVICES_GET_ALL = `SERVICES_GET_ALL`
export const SERVICES_GET_ALL_QUERY = `SERVICES_GET_ALL_QUERY`
export const SERVICES_CREATE_NEW = `SERVICES_CREATE_NEW`
export const SERVICES_SAVE_UPDATE = `SERVICES_SAVE_UPDATE`
export const SERVICES_DELETE_ONE = `SERVICES_DELETE_ONE`
export const SERVICES_SAVE_PRICING_PLAN = `SERVICES_SAVE_PRICING_PLAN`
export const SERVICES_DELETE_PRICING_PLAN = `SERVICES_DELETE_PRICING_PLAN`
export const SERVICES_UPDATE_PRICING_PLAN = `SERVICES_UPDATE_PRICING_PLAN`
export const SERVICES_SYNCHRONIZE_ALL = `SERVICES_SYNCHRONIZE_ALL`
export const SERVICE_CATEGORIES_GET_ALL_QUERY = `SERVICE_CATEGORIES_GET_ALL_QUERY`;
export const SERVICE_CATEGORIES_GET_ONE = `SERVICE_CATEGORIES_GET_ONE`;
export const SERVICE_CATEGORIES_CREATE_NEW = `SERVICE_CATEGORIES_CREATE_NEW`;
export const SERVICE_CATEGORIES_SAVE_UPDATE = `SERVICE_CATEGORIES_SAVE_UPDATE`;
export const SERVICE_CATEGORIES_DELETE_ONE = `SERVICE_CATEGORIES_DELETE_ONE`;

export const COUPONS_GET_ONE = `COUPONS_GET_ONE`
export const COUPONS_GET_ALL_QUERY = `COUPONS_GET_ALL_QUERY`
export const COUPONS_CREATE_NEW = `COUPONS_CREATE_NEW`
export const COUPONS_SAVE_UPDATE = `COUPONS_SAVE_UPDATE`
export const COUPONS_DELETE_ONE = `COUPONS_DELETE_ONE`
export const COUPONS_SYNCHRONIZE_ALL = `COUPONS_SYNCHRONIZE_ALL`

export const CUSTOMER_GET_ALL_QUERY = `CUSTOMER_GET_ALL_QUERY`
export const CUSTOMER_FETCH_AND_EDIT = `CUSTOMER_FETCH_AND_EDIT`
export const CUSTOMER_SAVE_BASIC = `CUSTOMER_SAVE_BASIC`
export const CUSTOMER_SAVE_SETUP = `CUSTOMER_SAVE_SETUP`
export const CUSTOMER_CREATE_NEW = `CUSTOMER_CREATE_NEW`
export const CUSTOMER_SAVE_NOTE = `CUSTOMER_SAVE_NOTE`
export const CUSTOMER_DELETE_NOTE = `CUSTOMER_DELETE_NOTE`
export const CUSTOMER_DELETE = `CUSTOMER_DELETE`
export const CUSTOMER_GET_ONE = `CUSTOMER_GET_ONE`
export const CUSTOMER_SAVE_PHONE = `CUSTOMER_SAVE_PHONE`
export const CUSTOMER_DELETE_PHONE = `CUSTOMER_DELETE_PHONE`

export const CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS = `CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS`
export const CUSTOMER_GET_ALL_AFFILIATES = `CUSTOMER_GET_ALL_AFFILIATES`
export const CUSTOMER_ATTACH_AFFILIATE = `CUSTOMER_ATTACH_AFFILIATE`
export const CUSTOMER_REMOVE_AFFILIATE = `CUSTOMER_REMOVE_AFFILIATE`
export const CUSTOMER_UPDATE_AFFILIATE = `CUSTOMER_UPDATE_AFFILIATE`
export const CUSTOMER_GET_PAYMENT_INFO = `CUSTOMER_GET_PAYMENT_INFO`
export const CUSTOMER_GET_PAYMENT_HISTORY = `CUSTOMER_GET_PAYMENT_HISTORY`
export const CUSTOMER_DEFAULT_PAYMENT_METHOD = `CUSTOMER_DEFAULT_PAYMENT_METHOD`
export const CUSTOMER_SAVE_PAYMENT_INFO = `CUSTOMER_SAVE_PAYMENT_INFO`
export const CUSTOMER_DELETE_PAYMENT_INFO = `CUSTOMER_DELETE_PAYMENT_INFO`
export const UPDATE_CUSTOMER_HOME_SIZE = `UPDATE_CUSTOMER_HOME_SIZE`
export const UPDATE_SUBSCRIPTION_PRICE = `UPDATE_SUBSCRIPTION_PRICE`
export const CUSTOMER_GET_NOTES = `CUSTOMER_GET_NOTES`

export const ADMIN_SAVE_NEW_APPOINTMENT = `ADMIN_SAVE_NEW_APPOINTMENT`
export const APPOINTMENTS_SCHEDULE_GET_RANGE = `APPOINTMENTS_SCHEDULE_GET_RANGE`
export const APPOINTMENTS_SCHEDULE_DELETE_ONE = `APPOINTMENTS_SCHEDULE_DELETE_ONE`
export const APPOINTMENTS_SCHEDULE_GET_AVAILABILITY = `APPOINTMENTS_SCHEDULE_GET_AVAILABILITY`
export const AMIN_GET_ONE_UPCOMING_APPOINTMENT = `AMIN_GET_ONE_UPCOMING_APPOINTMENT`
export const ADMIN_GET_UPCOMING_APPOINTMENTS = `ADMIN_GET_UPCOMING_APPOINTMENTS`
export const APPOINTMENTS_ASSIGN_ADD_ON = `APPOINTMENTS_ASSIGN_ADD_ON`
export const APPOINTMENTS_UPDATE_ADD_ONS = `APPOINTMENTS_UPDATE_ADD_ONS`
export const APPOINTMENTS_REMOVE_ADD_ON = `APPOINTMENTS_REMOVE_ADD_ON`
export const APPOINTMENT_PRE_AUTHORIZE_PAYMENT = `APPOINTMENT_PRE_AUTHORIZE_PAYMENT`
export const APPOINTMENT_CANCEL_PRE_AUTHORIZE_PAYMENT = `APPOINTMENT_CANCEL_PRE_AUTHORIZE_PAYMENT`
export const APPOINTMENT_CALCULATE_PRE_AUTHORIZE_AMOUNT = `APPOINTMENT_CALCULATE_PRE_AUTHORIZE_AMOUNT`

export const VENDOR_GET_ALL_QUERY = `VENDOR_GET_ALL_QUERY`
export const VENDOR_FETCH_AND_EDIT = `VENDOR_FETCH_AND_EDIT`
export const VENDOR_SAVE_UPDATE = `VENDOR_SAVE_BASIC`
export const VENDOR_CREATE_NEW = `VENDOR_CREATE_NEW`
export const VENDOR_DELETE_ONE = `VENDOR_DELETE_ONE`
export const VENDOR_GET_ONE = `VENDOR_GET_ONE`
export const VENDOR_SAVE_AVAILABILITY_SCHEDULE = `VENDOR_SAVE_AVAILABILITY_SCHEDULE`
export const VENDOR_REMOVE_OR_REJECT_AVAILABILITY_SCHEDULE = `VENDOR_REMOVE_OR_REJECT_AVAILABILITY_SCHEDULE`
export const VENDOR_AVAILABILITY_SCHEDULE_APPROVAL = `VENDOR_AVAILABILITY_SCHEDULE_APPROVAL`
export const VENDOR_UN_APPROVE_AVAILABILITY_SCHEDULE = `VENDOR_UN_APPROVE_AVAILABILITY_SCHEDULE`
export const VENDOR_SAVE_ADDRESS = `VENDOR_SAVE_ADDRESS`
export const VENDOR_DELETE_ADDRESS = `VENDOR_DELETE_ADDRESS`
export const VENDOR_SAVE_PHONE = `VENDOR_SAVE_PHONE`
export const VENDOR_DELETE_PHONE = `VENDOR_DELETE_PHONE`
export const VENDOR_MOVE_SUBSCRIPTIONS = `VENDOR_MOVE_SUBSCRIPTIONS`
export const VENDOR_CHANGE_STATUS = `VENDOR_CHANGE_STATUS`

export const AFFILIATE_GET_ALL_QUERY = `AFFILIATE_GET_ALL_QUERY`
export const AFFILIATE_FETCH_AND_EDIT = `AFFILIATE_FETCH_AND_EDIT`
export const AFFILIATE_SAVE_UPDATE = `AFFILIATE_SAVE_BASIC`
export const AFFILIATE_CREATE_NEW = `AFFILIATE_CREATE_NEW`
export const AFFILIATE_DELETE_ONE = `AFFILIATE_DELETE_ONE`
export const AFFILIATE_GET_ONE = `AFFILIATE_GET_ONE`
export const AFFILIATE_ADD_REFERRAL_ID = `AFFILIATE_ADD_REFERRAL_ID`
export const AFFILIATE_UPDATE_REFERRAL_ID = `AFFILIATE_UPDATE_REFERRAL_ID`
export const AFFILIATE_GET_ACCOUNT_DETAILS = `AFFILIATE_GET_ACCOUNT_DETAILS`;
export const AFFILIATE_SAVE_PHONE = `AFFILIATE_SAVE_PHONE`
export const AFFILIATE_DELETE_PHONE = `AFFILIATE_DELETE_PHONE`

export const ORGANIZATION_SAVE_PHONE = `ORGANIZATION_SAVE_PHONE`
export const ORGANIZATION_DELETE_PHONE = `ORGANIZATION_DELETE_PHONE`
export const ORGANIZATION_SAVE_CONTACT = `ORGANIZATION_SAVE_CONTACT`
export const ORGANIZATION_DELETE_CONTACT = `ORGANIZATION_DELETE_CONTACT`
export const ORGANIZATION_SAVE_ADDRESS = `ORGANIZATION_SAVE_ADDRESS`
export const ORGANIZATION_DELETE_ADDRESS = `ORGANIZATION_DELETE_ADDRESS`

export const ORGANIZATION_GET_ONE = `ORGANIZATION_GET_ONE`
export const ORGANIZATION_GET_ALL_QUERY = `ORGANIZATION_GET_ALL_QUERY`
export const ORGANIZATION_CREATE_NEW = `ORGANIZATION_CREATE_NEW`
export const ORGANIZATION_SAVE_UPDATE = `ORGANIZATION_SAVE_UPDATE`
export const ORGANIZATION_SAVE_PAYOUT = `ORGANIZATION_SAVE_PAYOUT`
export const ORGANIZATION_GET_ALL_VENDORS = `ORGANIZATION_GET_ALL_VENDORS`
export const ORGANIZATION_SET_VENDOR = `ORGANIZATION_SET_VENDOR`
export const ORGANIZATION_DELETE = `ORGANIZATION_DELETE`
export const ORGANIZATION_GET_ACCOUNT_DETAILS = `ORGANIZATION_GET_ACCOUNT_DETAILS`
export const ORGANIZATION_ATTACH_AFFILIATE = `ORGANIZATION_ATTACH_AFFILIATE`
export const ORGANIZATION_REMOVE_AFFILIATE = `ORGANIZATION_REMOVE_AFFILIATE`

export const SETTINGS_GET_ALL = `SETTINGS_GET_ALL`
export const GENERAL_SETTINGS_UPDATE = `GENERAL_SETTINGS_UPDATE`
export const SETTINGS_DELETE_ZIP_CODE = `SETTINGS_DELETE_ZIP_CODE`
export const ORGANIZATION_SAVE_BASIC = `ORGANIZATION_SAVE_BASIC`
export const ORGANIZATION_UPDATE_BUSINESS_TYPE = `ORGANIZATION_UPDATE_BUSINESS_TYPE`

export const ADMIN_GET_TICKETS = "ADMIN_GET_TICKETS"

export const ADMIN_SUBSCRIBE_EMAIL = "ADMIN_SUBSCRIBE_EMAIL"
export const ADMIN_GET_EMAIL_LIST = "ADMIN_GET_EMAIL_LIST"
export const ADMIN_DELETE_SUSCRIBED = "ADMIN_DELETE_SUSCRIBED"
export const LIST_GET_ONE = `LIST_GET_ONE`;
export const LIST_GET_ALL_QUERY = `LIST_GET_ALL_QUERY`;
export const LIST_CREATE_NEW = `LIST_CREATE_NEW`;
export const LIST_DELETE = `LIST_DELETE`;
export const LIST_SAVE_UPDATE = `LIST_SAVE_UPDATE`;

export const COMMUNICATION_GET_ONE = `COMMUNICATION_GET_ONE`;
export const COMMUNICATION_GET_ALL_QUERY = `COMMUNICATION_GET_ALL_QUERY`;
export const COMMUNICATION_CREATE_NEW = `COMMUNICATION_CREATE_NEW`;
export const COMMUNICATION_QUICK_REPLY = `COMMUNICATION_QUICK_REPLY`;
export const COMMUNICATION_DELETE = `COMMUNICATION_DELETE`;
export const COMMUNICATION_UPDATE = `COMMUNICATION_UPDATE`;
// Stream
export const COMMUNICATION_GET_USER_STREAM = `COMMUNICATION_GET_USER_STREAM`
export const SEND_SINGLE_COMMUNICATION = `SEND_SINGLE_COMMUNICATION`

export const ADMIN_UPDATE_GRAPH_REFRESH_INTERVAL = "ADMIN_UPDATE_GRAPH_REFRESH_INTERVAL"
export const ADMIN_GET_MONGO_CHART_IDS = "ADMIN_GET_MONGO_CHART_IDS"


export const GET_ACTIVE_PROVIDER_ZIPCODES = `GET_ACTIVE_PROVIDER_ZIPCODES`

export const ZIPCODE_ADD_NEW = "ZIPCODE_ADD_NEW"
export const ZIPCODE_GET_ALL_QUERY = "ZIPCODE_GET_ALL_QUERY"

export const ADMIN_SET_PROMO_CODE = `ADMIN_SET_PROMO_CODE`
export const ADMIN_UPDATE_PROMO_CODE = `ADMIN_UPDATE_PROMO_CODE`
export const ADMIN_GET_APPLICABLE_PROMO_CODE = `ADMIN_GET_APPLICABLE_PROMO_CODE`
export const REFUND_CHARGE = `REFUND_CHARGE`
export const LIST_CUSTOMER_CREDITS = `LIST_CUSTOMER_CREDITS`
export const ADMIN_INIT_DASHBOARD = `ADMIN_INIT_DASHBOARD`
export const REVIEWS_GET_ALL_QUERY = `REVIEWS_GET_ALL_QUERY`

export const ACTIVITY_GET_ONE = `ACTIVITY_GET_ONE`;
export const ACTIVITY_GET_ALL_QUERY = `ACTIVITY_GET_ALL_QUERY`;
export const ACTIVITY_GET_SUMMARY = `ACTIVITY_GET_SUMMARY`;
export const ACTIVITY_GET_ALL_SMS_QUERY = `ACTIVITY_GET_ALL_SMS_QUERY`;
export const ACTIVITY_GET_ONE_SMS_QUERY = `ACTIVITY_GET_ONE_SMS_QUERY`;
