<template>
  <PublicViewWrap :maxWidthContent="['affiliate', 'organization'].includes(registrationType) ? '1040px' :'500px'">
      <template v-slot:body>
        <div v-loading="loading">
          <div v-if="!success">
              <el-alert
                v-if="error"
                :title="error"
                type="error" />


            <div v-if="registrationType === 'affiliate'">
              <div class="text-center uppercase text-green-900 text-sm font-medium mb-2">Become an Affiliate - Join the Wemow Gives Back Program</div>
                          <div
              class="text-xl md:text-2xl font-medium text-gray-800 mb-2 text-center max-w-xl mx-auto"
            >We partner with individuals like you to make an impact in the communities we serve.
            </div>
<div class="flex mt-6 md:mt-16 flex-col-reverse md:flex-row">
              <div class="w-full md:w-1/2 pr-0 md:pr-8 mt-12 md:mt-0">

              <img src="@/assets/web/images/fence-image.png" style="margin:0 auto 24px" />


              <p class="mb-2 text-lg font-medium text-center">Get the word out and start getting paid!</p>
              <p class="mb-8 text-gray-700 text-center">Refer your friends, family - or whoever - to the most convenient lawn care company around.</p>



              <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">Unlimited Growth Potential</div>
                  <div class="text-sm text-gray-700">Make money on every service completed. </div>
                </div>
              </div>
               <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">All the Tools to Help You Grow</div>
                  <div class="text-sm text-gray-700">Get social, start emailing and more.</div>
                </div>
              </div>
              <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">A Lawn Service You Can Feel Good About</div>
                  <div class="text-sm text-gray-700">Help your community get more out of the lawn services they're already paying for.</div>
                </div>
              </div>



              </div>
              <div class="w-full md:w-1/2 px-8">
                  <RegisterFields
                    :accountConfirmationMethod="account_confirmation_method"
                    v-on:registerAction="doRegisterAction"
                    :loading="loading"
                    :registrationType="registrationType"
                  />
                  <SignInWith @signIn="doAltRegisterAction" @signInError="error =$t('errorMessages.errorLoggingIn')" orPosition="top" signInOrSignUp="Up" /> 
              </div>
              </div>

            </div>
            <div v-else-if="registrationType === 'organization'">
              <div class="text-center uppercase text-green-900 text-sm font-medium mb-2">Become an Provider - Join the Wemow Gives Back Program</div>
                          <div
              class="text-xl md:text-2xl font-medium text-gray-800 mb-2 text-center max-w-xl mx-auto"
            >We partner with individuals like you to make an impact in the communities we serve.
            </div>
<div class="flex mt-6 md:mt-16 flex-col-reverse md:flex-row">
              <div class="w-full md:w-1/2 pr-0 md:pr-8 mt-12 md:mt-0">

              <img src="@/assets/web/images/fence-image.png" style="margin:0 auto 24px" />


              <p class="mb-2 text-lg font-medium text-center">Get the word out and start getting paid!</p>
              <p class="mb-8 text-gray-700 text-center">Refer your friends, family - or whoever - to the most convenient lawn care company around.</p>



              <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">Unlimited Growth Potential</div>
                  <div class="text-sm text-gray-700">Make money on every service completed. </div>
                </div>
              </div>
               <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">All the Tools to Help You Grow</div>
                  <div class="text-sm text-gray-700">Get social, start emailing and more.</div>
                </div>
              </div>
              <div class="mb-7 flex pl-6">
                <div class="mt-2 mr-4 w-5 h-5 bg-gray-300 rounded-full flex justify-center items-center shadow-sm"><font-awesome-icon class="text-3xl -mt-3 -mr-1 text-green-900" icon="check"></font-awesome-icon></div>
                <div>
                  <div class="font-medium">A Lawn Service You Can Feel Good About</div>
                  <div class="text-sm text-gray-700">Help your community get more out of the lawn services they're already paying for.</div>
                </div>
              </div>



              </div>
              <div class="w-full md:w-1/2 px-8">
                  <RegisterFields
                    :accountConfirmationMethod="account_confirmation_method"
                    v-on:registerAction="doRegisterAction"
                    :loading="loading"
                    :registrationType="registrationType"
                  />
                  <SignInWith @signIn="doAltRegisterAction" @signInError="error =$t('errorMessages.errorLoggingIn')" orPosition="top" signInOrSignUp="Up" /> 
              </div>
              </div>

            </div>
            <div v-else>
                    <el-alert
                      v-if="error"
                      :title="error"
                      type="error">
                  </el-alert>
                  <SignInWith @signIn="doAltRegisterAction" @signInError="error =$t('errorMessages.errorLoggingIn')" orPosition="bottom" signInOrSignUp="Up" />
                  <RegisterFields
                    :accountConfirmationMethod="account_confirmation_method"
                    v-on:registerAction="doRegisterAction"
                    :loading="loading"
                    :registrationType="registrationType"
                  />


            </div>



          </div>

        </div>
      </template>
      <template v-slot:after>
              <RegisterFooter class="mt-12" :registrationType="registrationType" />
      </template>

  </PublicViewWrap>
</template>

<script>
import { mapActions } from "vuex";

import {
  AUTH_REGISTRATION_ACTION,
  AUTH_ALT_REGISTRATION_ACTION,
  AUTH_QUICK_REGISTRATION_ACTION
} from "@/store/action-types";
import { loadingTimeout   } from "@/helpers/helpers";
import CookieMixin from "@/mixins/Cookie"

export default {
  components: {
    PublicViewWrap: () => import("@/components/public/views/PublicViewWrap"),
    RegisterFields: () => import("./modules/RegisterFields"),
    RegisterSidebar: () => import("./modules/RegisterSidebar"),
    RegisterFooter: () => import("./modules/RegisterFooter"),
    SignInWith: () => import("../login/SignInWith2")
  },
  mixins: [CookieMixin],
  props: {
    // pass this in from router
    registrationType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      error: "",
      success: false,
      successMessage: "",
      account_confirmation_method: "email",
    };
  },
  methods: {
    ...mapActions({
      register: AUTH_REGISTRATION_ACTION,
      registerAlt: AUTH_ALT_REGISTRATION_ACTION,
      quickRegister: AUTH_QUICK_REGISTRATION_ACTION,
    }),
    async doRegisterAction(newUserObject) {
      try {
        this.loading = true;
        const { account_confirmation_method } = this;
        const newUser = Object.assign({}, newUserObject, {
          account_confirmation_method,
          platform:"web"
        });
        
        //computed property didn't seem to have been working as intended
        //sometimes we get a referral Id and other times we don't 
        //when cookie actually exists
        const affiliateReferrerCookie = this.getAffiliateIdCookie();
        const allowedAccountsToBeRefrredByAffiliate = ['customer', 'organization'];
        if(allowedAccountsToBeRefrredByAffiliate.includes(this.registrationType) && affiliateReferrerCookie && affiliateReferrerCookie.length > 0) {
          newUser.referred_by = affiliateReferrerCookie;
        }

        //   await Promise.all([loadingTimeout(500), this.register(newUser)]);
        //   if (account_confirmation_method === "sms") {
        //     const phone = this.registrationType === 'organization' ? newUser.contact.phone.number : newUser.phone.number
        //     this.$router.replace({
        //       path: "/register/verify/sms",
        //       query: { phoneNumber: phone },
        //     });
        //   } else {
        //     this.success = true;
        //     const newUserEmail = newUser.email ? newUser.email : newUser.contact.email;
        //     this.successMessage = this.$t('registration.emailSentTo', {text: newUserEmail});
        //   }

        await Promise.all([loadingTimeout(500), this.quickRegister(newUser)]);

        switch(this.registrationType){
          case 'affiliate':
            this.$router.replace("/affiliate/onboarding");
            break;
          case 'organization':
            this.$router.replace("/organization/onboarding");
            break;
        }

        this.$sendAnalyticsEvent('sign_up', {
          method: 'email',
          type: this.registrationType,
          version: 'default'
        });
        this.removeAffiliateIdCookie();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async doAltRegisterAction(data) {
      try {
        const { source_type = 'social' } = data;
        this.loading = true;
        const affiliateReferrerCookie = this.getAffiliateIdCookie();
        if(this.registrationType === "customer" && affiliateReferrerCookie && affiliateReferrerCookie.length > 0) {
          data.referred_by = affiliateReferrerCookie;
        }
        data.type = this.registrationType
        data.platform = "web"
        data.registerMethod = source_type
        const user = await this.registerAlt(data);
        this.removeAffiliateIdCookie();
        this.$sendAnalyticsEvent('sign_up', {
          method: `${data.source_type}`,
          type: this.registrationType,
          version: 'default'
        });
        if(user){
          this.$router.push({path:'/'})
        }
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    toggleAccountConfirmationMethod() {
      const newMethod =
        this.account_confirmation_method === "sms" ? "email" : "sms";
      this.account_confirmation_method = newMethod;
    }
  },
  computed: {
    accountConfirmationMethodText() {
      const smsText = this.$t('registration.smsConfirmationCode');
      const emailText = this.$t('registration.emailAccountConfirmation');
      return this.account_confirmation_method === "sms" ? smsText : emailText;
    },
    otherAccountConfirmationMethod() {
      return this.account_confirmation_method === "sms" ? "email" : "sms";
    },
    iconAccountConfirmationMethod() {
      return this.account_confirmation_method === "sms" ? "sms" : "envelope-open-text";
    }
  }
};
</script>

<style scoped>
.big-check {
  font-size: 60px;
  background: #03733d;
  color: #fff;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link-style {
  color: blue;
}

.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.fade-enter-active #register-sidebar {
  transition: all 0.3s ease;
}
.fade-leave-active #register-sidebar {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-sidebar,
.fade-leave-to #register-sidebar {
  transform: translateX(-500px);
  opacity: 0;
}

.fade-enter-active #register-area {
  transition: all 0.3s ease;
}
.fade-leave-active #register-area {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-area,
.fade-leave-to #register-area {
  transform: translateX(500px);
  opacity: 0;
}
</style>
