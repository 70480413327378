export const ADMIN_CLEAR_USER_STORE = `ADMIN_CLEAR_USER_STORE`
export const ADMIN_USERS_GET_ALL = `ADMIN_USERS_GET_ALL`
export const ADMIN_USER_DELETE = `ADMIN_USER_DELETE`
export const ADMIN_USERS_GET_TRASH = `ADMIN_USERS_GET_TRASH`
export const ADMIN_SET_USER_LINKS = `ADMIN_SET_USER_LINKS`

export const ADMIN_GALLERY_SET = `ADMIN_GALLERY_SET`
export const ADMIN_GALLERY_DELETE = `ADMIN_GALLERY_DELETE`
export const ADMIN_GALLERY_ADD = `ADMIN_GALLERY_ADD`

export const COUPONS_GET_ALL = `COUPONS_GET_ALL`
export const COUPONS_GET_TRASH = `COUPONS_GET_TRASH`

export const CUSTOMER_SAVE_SETUP_NOTE = `CUSTOMER_SAVE_SETUP_NOTE`
export const CUSTOMER_DELETE_SETUP_NOTE = `CUSTOMER_DELETE_SETUP_NOTE`
export const CUSTOMER_GET_ALL = `CUSTOMER_GET_ALL`
export const CUSTOMER_GET_TRASH = `CUSTOMER_GET_TRASH`
export const CUSTOMER_EDIT = `CUSTOMER_EDIT`
export const CUSTOMER_DELETE = `CUSTOMER_DELETE`
export const CUSTOMER_DROP_CUSTOMERS = `CUSTOMER_DROP_CUSTOMERS`
export const CUSTOMER_SET_QUERY_LINKS = `CUSTOMER_SET_QUERY_LINKS`
export const CUSTOMER_EDIT_NOTES = `CUSTOMER_EDIT_NOTES`
export const CUSTOMER_SET_NOTES = `CUSTOMER_SET_NOTES`

export const SET_ADMIN_UPCOMING_APPOINTMENTS = `SET_ADMIN_UPCOMING_APPOINTMENTS`
export const SET_PRE_AUTHORIZE_AMOUNT = `SET_PRE_AUTHORIZE_AMOUNT`

export const VENDOR_EDIT = `VENDOR_EDIT`
export const VENDOR_GET_ALL = `VENDOR_GET_ALL`
export const VENDOR_GET_TRASH = `VENDOR_GET_TRASH`
export const VENDOR_DELETE = `VENDOR_DELETE`
export const VENDOR_UPDATE_ONE = `VENDOR_UPDATE_ONE`
export const VENDOR_DROP_VENDORS = `VENDOR_DROP_VENDORS`
export const VENDOR_SET_LINKS = `VENDOR_SET_LINKS`

export const AFFILIATE_EDIT = `AFFILIATE_EDIT`
export const AFFILIATE_GET_ALL = `AFFILIATE_GET_ALL`
export const AFFILIATE_GET_TRASH = `AFFILIATE_GET_TRASH`
export const AFFILIATE_DELETE = `AFFILIATE_DELETE`
export const AFFILIATE_UPDATE_ONE = `AFFILIATE_UPDATE_ONE`
export const AFFILIATE_DROP_AFFILIATES = `AFFILIATE_DROP_AFFILIATES`
export const AFFILIATE_SET_AFFILIATE_LINKS = `AFFILIATE_SET_AFFILIATE_LINKS`

export const ORGANIZATION_GET_ALL = `ORGANIZATION_GET_ALL`
export const ORGANIZATION_EDIT = `ORGANIZATION_EDIT`
export const ORGANIZATION_UPDATE_ONE = `ORGANIZATION_UPDATE_ONE`
export const ORGANIZATION_GET_TRASH = `ORGANIZATION_GET_TRASH`
export const ORGANIZATION_DELETED = `ORGANIZATION_DELETED`
export const ORGANIZATION_DROP_ORGANIZATIONS = `ORGANIZATION_DROP_ORGANIZATIONS`
export const ORGANIZATION_SET_ORGANIZATION_LINKS = `ORGANIZATION_SET_ORGANIZATION_LINKS`

export const ORDERS_GET_ALL = `ORDERS_GET_ALL`
export const ORDERS_GET_TRASH = `ORDERS_GET_TRASH`
export const ORDER_EDIT = `ORDER_EDIT`

export const SETTINGS_EDIT = `SETTINGS_EDIT`

export const SERVICES_GET_ALL = `SERVICES_GET_ALL`
export const SERVICES_GET_TRASH = `SERVICES_GET_TRASH`
export const SERVICE_CATEGORIES_GET_TRASH = `SERVICE_CATEGORIES_GET_TRASH`
export const SERVICE_CATEGORIES_GET_ALL = `SERVICE_CATEGORIES_GET_ALL`;
export const SERVICE_CATEGORY_DELETE = `SERVICE_CATEGORY_DELETE`;

export const MUTATE_HELPDESK_TICKETS = `MUTATE_HELPDESK_TICKETS`



export const ADMIN_SET_TICKETS = "ADMIN_SET_TICKETS"
export const ADMIN_SET_TICKET_LINKS = "ADMIN_SET_TICKET_LINKS"
export const ADMIN_DROP_TICKETS = "ADMIN_DROP_TICKETS"
export const ADMIN_SET_EMAIL_LIST = "ADMIN_SET_EMAIL_LIST"
export const ADMIN_SET_ONE_EMAIL = "ADMIN_SET_ONE_EMAIL"
export const ADMIN_DELETE_ONE_EMAIL = "ADMIN_DELETE_ONE_EMAIL"

export const LIST_GET_ALL = `LIST_GET_ALL`;
export const LIST_EDIT = `LIST_EDIT`;
export const LIST_UPDATE_ONE = `LIST_UPDATE_ONE`;
export const LIST_GET_TRASH = `LIST_GET_TRASH`;
export const LIST_DELETED = `LIST_DELETED`;
export const LIST_DROP_LISTS = `LIST_DROP_LISTS`;
export const LIST_SET_LIST_LINKS = `LIST_SET_LIST_LINKS`;
export const SET_LIST_EDIT_FIELD = `SET_LIST_EDIT_FIELD`;
export const SET_EDIT_ADD_LIST_QUERY = `SET_EDIT_ADD_LIST_QUERY`;
export const SET_EDIT_REMOVE_LIST_QUERY = `SET_EDIT_REMOVE_LIST_QUERY`;

export const COMMUNICATION_GET_ALL = `COMMUNICATION_GET_ALL`;
export const COMMUNICATION_EDIT = `COMMUNICATION_EDIT`;
export const COMMUNICATION_UPDATE_ONE = `COMMUNICATION_UPDATE_ONE`;
export const COMMUNICATION_GET_TRASH = `COMMUNICATION_GET_TRASH`;
export const COMMUNICATION_DELETED = `COMMUNICATION_DELETED`;
export const COMMUNICATION_DROP_COMMUNICATIONS = `COMMUNICATION_DROP_COMMUNICATIONS`;
export const COMMUNICATION_SET_COMMUNICATION_LINKS = `COMMUNICATION_SET_COMMUNICATION_LINKS`;

export const ACTIVITY_GET_ALL = `ACTIVITY_GET_ALL`;
export const ACTIVITY_DROP_ACTIVITY = `ACTIVITY_DROP_ACTIVITY`;
export const ACTIVITY_SET_ACTIVITY_LINKS = `ACTIVITY_SET_ACTIVITY_LINKS`;
export const ACTIVITY_GET_ALL_SMS = `ACTIVITY_GET_ALL_SMS`;
export const ACTIVITY_DROP_ACTIVITY_SMS = `ACTIVITY_DROP_ACTIVITY_SMS`;
export const ACTIVITY_SET_ACTIVITY_LINKS_SMS = `ACTIVITY_SET_ACTIVITY_LINKS_SMS`;
export const ACTIVITY_SET_SUMMARY = `ACTIVITY_SET_SUMMARY`;

export const ADMIN_SET_LOADED_DASHBOARD_INIT = "ADMIN_SET_LOADED_DASHBOARD_INIT"
export const CLEAR_ADMINS_DATA = "CLEAR_ADMINS_DATA"

export const ZIPCODES_ADD_ONE = "ZIPCODES_ADD_ONE"
export const ZIPCODES_GET_ALL= `ZIPCODES_GET_ALL`
export const ZIPCODES_CLEAR_STORE = "ZIPCODES_CLEAR_STORE"
export const ZIPCODES_GET_TRASH = "ZIPCODES_GET_TRASH"
export const ZIPCODES_SET_LINKS= "ZIPCODES_SET_LINKS"

export const REVIEWS_GET_ALL = `REVIEWS_GET_ALL`
export const REVIEWS_CLEAR_STORE = `REVIEWS_CLEAR_STORE`
export const REVIEWS_GET_TRASH = `REVIEWS_GET_TRASH`
export const REVIEWS_SET_LINKS = `REVIEWS_SET_LINKS`
export const SET_CHART_IDS= "SET_CHART_IDS"
