export const tickets = {
    appointmentFollowUp: "Seguimiento a la cita",
    subscriptionFollowUp: "Seguimiento a la suscripción",
    attachedFiles: "Archivos adjuntos",
    noCommentsAvailable: "No hay comentarios disponibles Crea tu primer comentario.",
    ticketStatus: "Este",
    ticket: "boleto | boletos",
    noSupportTickets: "No Hay Tickets de Soporte",
    helpDesk: "Servicio de Asistencia",
    ticketsClosed: "Tickets cerrados en los últimos 7 días.",
    openTickets: "Tickets Abiertos",
    draftTickets: "Tickets en borrador",
    fileType: "Archivos jpg / png con un tamaño menos de 500 kb",
    helpDeskTicket: "Ticket de Servicio de Asistencia",
    ticketUpdatesAlert: "Recibir Actualizaciones de Tickets",
    activeHelpdeskTicket: "Ticket activo del servicio de ayuda",
    viewSupportTickets: "Ver sus tickets de soporte",
    activeTickets: "Entradas activas",
    createdOn: "Creado el",
    updatedOn: "Actualizado el",
    priority: {
        low: "Bajo",
        normal: "Normal",
        high: "Alto",
        urgent: "Urgente"
    },
    type: {
        general: "General",
        appointment: "Cita",
        subscription: "Suscripción"
    },
    status: {
        closed: "cerrado",
        open: "abierto",
        draft: "sequía"
    },
    noNewTickets: "No tienes entradas recientes.",
    swipeToEnableFullHistory: "Deslice el botón del interruptor hacia arriba para habilitar el historial completo de tickets.",
    viewHelpdeskTicket: "Ver ticket relacionado",
}