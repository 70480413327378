import Push from 'push.js'


const pluginPush = {
    install(Vue, options) {
    Vue.prototype.$pushNotificationsEnabled = false
    Vue.prototype.$sendPushNotification = async function(title, {body, link} ) {

      if(!Push.Permission.has() || Push.Permission.get() !== 'granted'){
        return
      }

      const icon = "https://assets.wemow.com/images/web-notification-icon.png";

      const onClick = async function() {
        options.router.push(link)
        this.close();
      }

      Push.create(title, {
        title, body, link, icon, onClick
      })
      // try {
      //   const {notification_id} = notifObj
      //   const getNotification = await options.store.dispatch(`notifications/${GET_NOTIFICATION}`, {notification_id})
      //   if(getNotification && getNotification.is_push){
      //     Push.create(getNotification.title, {
      //       body: getNotification.message || '',
      //       link: getNotification.actions && getNotification.actions.length ? getNotification.actions[0].link : '',
          //   onClick: async function () {
          //     if(getNotification._id){
          //       await options.store.dispatch(`notifications/${MARK_NOTIFICATION_AS_READ}`, {notification_id})
          //     }
          //     if(getNotification.actions && getNotification.actions.length){
          //       options.router.push(getNotification.actions[0].link)
          //     }
          //     this.close();
          // }
          // })
      //   }
      //   return true
      // } catch (e) {
      //   throw new Error(e.message)
      // }
    }
    Vue.prototype.$triggerNotificationPermissionPrompt = async function() {
      if(!Push.Permission.has() && Push.Permission.get() !== 'denied'){
        Push.Permission.request(() => {
          this.$pushNotificationsEnabled = true
        }, () => {
          this.$pushNotificationsEnabled = false
        });
      } else {
        // maybe here IF notifications are denied, we could create a notification telling the user
        // to enable notifications?
      }
    }
    }
  };

  export default pluginPush;
