var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CustomDropdownRoot", {
    attrs: {
      dropDownItems: _vm.dropDownItems,
      selectedValue: _vm.selectedValue,
      preventPopover: _vm.preventPopover,
    },
    on: {
      onClickPlaceholder: function ($event) {
        return _vm.$emit("onClickPlaceholder", $event)
      },
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      customAction: function ($event) {
        return _vm.$emit("customAction", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "labelHasValue",
        fn: function (ref) {
          var selected = ref.selected
          return [
            _c("div", { staticClass: "flex items-center justify-start" }, [
              _c(
                "div",
                {
                  staticClass: "mr-4 relative overflow-hidden",
                  staticStyle: { width: "38px", height: "27px" },
                },
                [
                  _c("img", {
                    staticClass: "top-0 absolute max-w-none",
                    style: _vm.getCardTypeStyle(selected.brand),
                    attrs: {
                      src: require("@/assets/web/images/creditcard-icons.svg"),
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "flex justify-between items-center " }, [
                _c("span", { staticClass: "text-gray-900" }, [
                  _vm._v("**** **** **** "),
                  selected
                    ? _c("span", [_vm._v(_vm._s(selected.label))])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "text-xs text-gray-600 ml-3" }, [
                  _vm._v(
                    "Expires: " +
                      _vm._s(selected.data.exp_month) +
                      "/" +
                      _vm._s(selected.data.exp_year)
                  ),
                ]),
              ]),
            ]),
          ]
        },
      },
      {
        key: "labelNoValue",
        fn: function () {
          return [_vm._v("Add Payment Method")]
        },
        proxy: true,
      },
      {
        key: "innerDropdownItem",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "flex justify-start items-center" }, [
              _c(
                "div",
                {
                  staticClass: "mr-4 relative overflow-hidden",
                  staticStyle: { width: "38px", height: "27px" },
                },
                [
                  _c("img", {
                    staticClass: "top-0 left-0 absolute max-w-none",
                    style: _vm.getCardTypeStyle(item.data.brand),
                    attrs: {
                      src: require("@/assets/web/images/creditcard-icons.svg"),
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex justify-between items-center flex-grow" },
                [
                  _c("div", [_vm._v("**** **** **** " + _vm._s(item.label))]),
                  _c("div", { staticClass: "text-xs text-gray-600 ml-3" }, [
                    _vm._v(
                      "Expires: " +
                        _vm._s(item.data.exp_month) +
                        "/" +
                        _vm._s(item.data.exp_year)
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        },
      },
      {
        key: "innerContent",
        fn: function (ref) {
          var closePopover = ref.closePopover
          var emitCustomAction = ref.emitCustomAction
          return [
            _c(
              "div",
              {
                staticClass:
                  "cursor-pointer py-4 px-4 hover:bg-gray-200 flex justify-start items-center text-gray-600",
                on: {
                  click: function ($event) {
                    emitCustomAction({ key: "addNewCard" })
                    closePopover()
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-3",
                  attrs: { icon: "plus" },
                }),
                _c("span", { staticClass: "text-md" }, [_vm._v("Add New")]),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }