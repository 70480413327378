export const successMessages = {
    verificationCodeSent: "El código de verificación se ha enviado correctamente",
    phoneNumberVerified: "Número de teléfono verificado con éxito",
    updatedMessage: "{colección} actualizada",
    deletedMessage: "{colección} eliminada",
    canceledMessage: "{colección} cancelada",
    savedText: "¡{text} guardado!",
    updatedText: "{text} actualizado con éxito",
    sentSuccessful: "¡{text} enviado con éxito!",
    scheduleSavedText: "La programación de {text} se guardó correctamente",
    scheduleRemovedText: "La programación de {text} se eliminó correctamente",
    scheduleApprovedText: "{text} programa aprobado",
    timeOffMessages: "Su solicitud de tiempo libre ha sido enviada con éxito. Por favor espera la aprobación",
    accountConfirmed: "¡Éxito! Su cuenta ha sido confirmada. Ahora puede iniciar sesión a continuación con su correo electrónico y contraseña.",
    paymentSourceRemoved: "La fuente de pago se ha eliminado correctamente",
    defaultPaymentSourceUpdated: "La fuente de pago predeterminada se actualizó correctamente",
    paymentSourceAdded: "La fuente de pago se ha agregado correctamente",
    success: "Éxito",
    orderProcessedSuccessfully: "Pedido procesado con éxito.",
    phoneNumberChanged: "La solicitud para cambiar el número de teléfono y reenviar el código de verificación fue exitosa",
    accountVerification: "Verificación de cuenta exitosa",
    profileImageChange: "Imagen de perfil modificada",
    profileImageRemoved: "Imagen de perfil eliminada",
    profileUpdated: "Perfil actualizado",
    scheduleUnapprovedText: "Programación del proveedor no aprobada",
    invoiceCreationSuccess: "Factura creada con éxito",
    vendorsInLocationText: "No {text} proveedores en la ubicación especificada",
    vendorsInLocationTextSingle: "Hay 1 proveedor en la ubicación especificada",
    restoredMessage: "Suscripción restaurada con éxito",
    suspendMessage: "Suscripción suspendida con éxito"
}