import store from "@/store/store";

// Guards the onboarding screen(s).
// Redirect the user to dashboard if onboarding is already complete and they try to access the page
export const organizationBeforeResolveAwaitingApproval = async (
  _toRoute,
  _fromRoute,
  next
) => {
  const { meta = {} } = store.getters["getUserActiveAccount"];
  const { isApproved = "" } = meta;
  // if (!initialSetup || !stripe_id || !agreedToTAndC) {
  if (!isApproved) {
    // store.commit(APP_STATE_WEB_CONTENT_WIDTH, "960px")

    // store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    return next();
  } else {
    return next("/organization");
  }
};
