<template web>
  <div class="flex flex-col pb-32 overflow-auto">

               <span v-if="showMobileHeaderPhone" class="absolute top-0 left-0 bg-green-900 text-white text-center w-full  text-xl md:text-2xl py-2 z-10"
                      >Call Us: <strong><a href="tel:1-800-246-6690">1-800-246-6690</a></strong></span
                    >

    <div :class="{
      'top-12 md:top-0': showMobileHeaderPhone
    }" class="bg-white w-full py-3 px-4 md:py-4 md:px-4 fixed z-10 md:h-16d shadow-sm md:shadow-none md:relative md:h-auto">
      <div
        :style="`max-width:${maxWidthHeader ? maxWidthHeader : maxWidth};`"
        class="w-full mx-auto flex justify-between items-center transition relative wemow-header"
        :class="headerClasses"
      >
      <HeaderLogo class="header-logo" :class="headerLogoClass" :showHeaderLogoAsLink="showHeaderLogoAsLink" :isLoading="asyncComponentLoading" />



        <div class="mt-0 md:mt-0 h-10 flex items-center" :class="headerRightClasses">
          <!-- <HeaderRightLoading  v-if="false || asyncComponentLoading" class="absolute right-0 top-0" /> -->
          <div  class="flex md:items-center">
            <slot name="header"></slot>
          </div>
        </div>
      </div>
    </div>















    <div id="background-stripe">
      <div id="background-stripe-inner">
        <div id="background-stripe-background"></div>
      </div>
    </div>

    <div class="flex items-center justify-center flex-col pt-8 lg:pt-16 w-full">
      <div
        class="bg-white px-5 py-12 md:px-12 rounded-lg shadow-md w-full relative"
        :style="
          `min-height:400px;`
        "
      >
      <transition name="fade">
        <div v-if="asyncComponentLoading" v-loading="asyncComponentLoading" class="absolute top-0 left-0 w-full h-full background-white bg-opacity-25"></div>
      </transition>

        <slot name="body"></slot>
      </div>
    </div>

    <transition name="fade">
    <div v-show="!asyncComponentLoading">
      <slot name="after"></slot>
    </div>
    </transition>

    <div class="mt-8">
      <div
        class="w-full mx-auto flex justify-center items-center text-center"
        :style="`max-width:${maxWidthFooter ? maxWidthFooter : maxWidth};`"
      >
      <div>
        <p class="text-xs text-gray-600">&copy; 2022 Wemow</p>
        <p class="text-xs text-gray-600">web version {{appVersion}}</p>
      </div>
      </div>
    </div>
  </div>
</template> 

<script>
import HeaderLogo from "./parts/HeaderLogo";
import HeaderRightLoading from "./parts/HeaderRightLoading";
import { mapState } from "vuex";
import { version } from '../../../../package.json'

export default {
  components: {
    HeaderLogo,
    HeaderRightLoading
  },
  data(){
    return {
      appVersion: version,
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "1240px",
    },
    maxWidthHeader: {
      type: String,
    },
    maxWidthContent: {
      type: String,
    },
    maxWidthFooter: {
      type: String,
    },
    showHeaderLogoAsLink: {
      type: Boolean,
      default: true,
    },
    headerClasses: {
      type: String,
      default: ""
    },
    headerRightClasses: {
      type: String,
      default: ""
    },
    headerLogoClass: {
      type: String,
      default: ""
    },
    showMobileHeaderPhone: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      asyncComponentLoading: state => state.app.asyncComponentLoading
    }),
  }
};
</script>

<style scoped>
#background-stripe {
  background-image: linear-gradient(210deg, #068942 20%, #3fbb68 86%);
  border-bottom-color: rgb(0, 0, 0);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
}

#background-stripe-background {
  /* background-image: url(); */
  background-position-x: center;
  background-position-y: -60px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  height: 25%;
  max-height: 500px;
  margin-left: -37px;
  margin-right: -37px;
  width: calc(100% + 74px);
}

#background-stripe-inner {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 320px;
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  #background-stripe-inner {
    min-width: 768px;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  #background-stripe-inner {
    min-width: 1024px;
    max-width: 100%;
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media (min-width: 1280px) {
  #background-stripe-inner {
    min-width: 1280px;
    max-width: 1280px;
    padding-left: 37px;
    padding-right: 37px;
  }
}

.big-check {
  font-size: 60px;
  background: #03733d;
  color: #fff;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link-style {
  color: blue;
}

.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.fade-enter-active #register-sidebar {
  transition: all 0.3s ease;
}
.fade-leave-active #register-sidebar {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-sidebar,
.fade-leave-to #register-sidebar {
  transform: translateX(-500px);
  opacity: 0;
}

.fade-enter-active #register-area {
  transition: all 0.3s ease;
}
.fade-leave-active #register-area {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-area,
.fade-leave-to #register-area {
  transform: translateX(500px);
  opacity: 0;
}

.pulse {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
