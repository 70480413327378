<template>
    <div v-if="loading" class="loading-shimmer-background my-1 px-2" :class="textClass"><component :is="textTag" class="opacity-0">{{text}}<slot></slot></component></div>
    <div ref="defaultArea" :class="textClass" v-else><component :is="textTag" >{{text}}</component><slot></slot></div>
</template>


<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        text: {
            type: [String, Number],
            default: ""
        },
        textClass: {
            type: String,
            default: ""
        },
        textTag: {
            type: String,
            default: "span"
        }
    },
    mounted(){}
}
</script>