import axios from '../lib/axios'
import auth from '../auth'
import { get } from "lodash-es"

const {
  VUE_APP_API_SERVER: DB_URL,
  VUE_APP_API_SERVER_SERVERLESS,
} = process.env;


function parseAWSApiErrorMessage(error){
  return get(error, "response.data.data.Error", error.message | "Unknown error 34" )
}

/*
 * Users
 */
export function loadUserData() {
  return axios.get(DB_URL + 'user', {
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function loadUserAccounts() {
  return axios.get(DB_URL + 'user/accounts', {
    headers: auth.getAuthHeader()
  })
    .then((accounts) => Promise.resolve(accounts))
    .catch((error) => Promise.reject(error))
}

export function userUpdatePassword(password) {
  return axios.put(DB_URL + 'users/password/update', password, {
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function userUpdateDeviceToken(device_token) {
  return axios.put(DB_URL + 'users/update', { key: 'device_token', val: device_token }, {
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function userRemoveDeviceToken(device_token) {
  return axios.put(DB_URL + 'users/update', { key: 'remove_device_token', val: device_token }, {
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function userUpdate(data) {
  return axios.put(DB_URL + 'users/update', data, {
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}


/*
 * Customer
 */

// Save
export function customerSaveOne(customer) {
  return axios.post(DB_URL + 'customer/new', customer, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerInitDashboard(customer_id) {
  return axios.get(DB_URL + 'customers/' + customer_id + '/init', {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetOne(id) {
  return axios.get(DB_URL + 'customers/' + id, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetPaymentInfo(customer_id) {
  return axios.get(DB_URL + `customer/${customer_id}/billing/list`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerSavePaymentInfo({ stripe_src, customer_id, existingStripeSrc }) {
  let token = { stripe_src: stripe_src, customer_id: customer_id, existingStripeSrc: existingStripeSrc }
  return axios.post(DB_URL + `customer/${customer_id}/billing/update`, token, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerDeletePaymentInfo({ stripe_src, customer_id }) {
  let token = { stripe_src: stripe_src, customer_id: customer_id }
  return axios.put(DB_URL + `customer/${customer_id}/billing/remove`, token, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerSetDefaultPaymentMethod({ stripe_src_id, customer_id }) {
  let token = { stripe_src_id: stripe_src_id, customer_id: customer_id }
  return axios.put(DB_URL + `customer/${customer_id}/billing/default`, token, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function customerGetPaymentHistory(customer_id) {
  return axios.get(DB_URL + `customer/${customer_id}/billing/history`, {
    headers: auth.getAuthHeader(),
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function customerGetUpcomingInvoice({ customer_id, subscription_id }) {
  return axios.get(DB_URL + `customer/${customer_id}/invoice/list/${subscription_id}`, {
    headers: auth.getAuthHeader(),
  })
}

export function customerGetInvoice({ invoice_id }) {
  return axios.get(DB_URL + `invoice/${invoice_id}`, {
    headers: auth.getAuthHeader(),
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function customerGetAffiliateContributions(customerID, query) {
  return axios.get(DB_URL + `customers/${customerID}/contributions`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetAllAffiliates(customerID) {
  return axios
    .get(DB_URL + `customers/${customerID}/affiliates`, {
      headers: auth.getAuthHeader(),
    })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error));
}

export function customerManageAffiliate(customerID, affiliateBody) {
  return axios.put(DB_URL + `customers/${customerID}/affiliate/manage`, affiliateBody, {
    headers: auth.getAuthHeader(),
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerUpdateAffiliates(customerID, affiliatesArray) {
  return axios.put(DB_URL + `customers/${customerID}/affiliate/update`, { affiliatesArray }, {
    headers: auth.getAuthHeader(),
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function customerReadNote(customerID, note_id) {
  return axios.post(DB_URL + `customer/${customerID}/read-note`, note_id, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerDismissNote(customerID, note_id) {
  return axios.post(DB_URL + `customer/${customerID}/dismiss-note`, note_id, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function customerSaveUpdateData(id, customer) {
  return axios.put(DB_URL + `customers/update/${id}`, customer, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerUpdatePublicReviews(id, data) {
  return axios.put(DB_URL + `customers/update/public-reviews/${id}`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerAddSubscription(saveOrderObject) {
  return axios.post(DB_URL + `subscriptions/new`, saveOrderObject, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerUpdateSubscription(saveOrderObject) {
  return axios.put(DB_URL + `subscriptions/update`, saveOrderObject, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerCancelSubscription({ subscription_id }) {
  return axios.put(DB_URL + `subscriptions/cancel`, { subscription_id }, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerPauseSubscription(data) {
  return axios.post(DB_URL + `subscriptions/pause`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerUnpauseSubscription({ orderId, beforeSchedule }) {
  return axios.post(DB_URL + `subscriptions/unpause`, { orderId, beforeSchedule }, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerSuspendSubscription(data) {
  return axios.post(DB_URL + `subscriptions/suspend`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerRestoreSubscription(data) {
  return axios.post(DB_URL + `subscriptions/restore`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

// Get one
// export function customerAppointmentGetVendor(vendor_id) {
//   return axios.get(DB_URL + `customer/appointments/vendor/${vendor_id}`, {
//       headers: auth.getAuthHeader()
//     })
//     .then((vendor) => Promise.resolve(vendor))
//     .catch((error) => Promise.reject(error))
// }

export function customerGetUpcomingAppointments({ customer_id, query }) {
  return axios.get(DB_URL + `appointments`, {
    headers: auth.getAuthHeader(),
    params: { ...query, customer_id }
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetOneUpcomingAppointment(customer_id, appointment_id) {
  return axios.get(DB_URL + `appointments/${appointment_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetOneSubscription(subscription_id, query) {
  return axios.get(DB_URL + `subscriptions/${subscription_id}`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetSubscriptionsFromStripe({ customer_id, query }) {
  return axios.get(DB_URL + `subscriptions`, {
    headers: auth.getAuthHeader(),
    params: Object.assign({
      count: 100,
      starting_after_id: 0,
      customer_id: customer_id
    }, query)
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerVerifyPhone(phone) {
  return axios.put(DB_URL + `account/verify-phone`, phone, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerConfirmPhone(verificationData) {
  return axios.put(DB_URL + `account/confirm-phone`, verificationData, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerApproveAddOnService({ appointment_id, approved, order_id }) {
  const data = { approved, order_id, appointment_id };
  return axios.put(DB_URL + `appointments/manage/add-on/approve`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function customerGetAffiliates(customer_id) {
  return axios
    .get(DB_URL + `customers/affiliates-list/${customer_id}`, {
      headers: auth.getAuthHeader(),
    })
    .then((affiliates) => Promise.resolve(affiliates))
    .catch((error) => Promise.reject(error));
}

export function customerGetAffiliate(id) {
  return axios
    .get(DB_URL + `customers/affiliate/${id}`, {
      headers: auth.getAuthHeader(),
    })
    .then((affiliates) => Promise.resolve(affiliates))
    .catch((error) => Promise.reject(error));
}

export function customerCreateAffiliate(id, affiliate) {
  return axios.post(DB_URL + `customers/${id}/affiliate/new`, {affiliate}, {
    headers: auth.getAuthHeader()
  })
    .then((aff) => Promise.resolve(aff))
    .catch((error) => Promise.reject(error))
}

export function customerSaveReview(data) {
  return axios.post(DB_URL + `review`, data, {
    headers: auth.getAuthHeader()
  })
    .then((aff) => Promise.resolve(aff))
    .catch((error) => Promise.reject(error))
}

/*
 * Help Desk
*/

export function getAllTickets(ticketQuery) {
  return axios.get(DB_URL + `tickets`, {
    headers: auth.getAuthHeader(),
    params: ticketQuery,
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function getOneTicket(ticket_id) {
  return axios.get(DB_URL + `tickets/${ticket_id}`, {
    headers: auth.getAuthHeader(),
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function helpdeskAddTicket({ ticket }) {
  const payload = {
    ticket: ticket
  }
  return axios.post(DB_URL + `tickets`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function ticketAddComment({ account_id, ticket_id, account_type, comment }) {
  const payload = {
    comment: comment,
    ticket_id: ticket_id,
    account_id: account_id,
    account_type: account_type
  }
  return axios.put(DB_URL + `tickets/comment`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function updateTicket({ ticket_id, fields }) {
  return axios.put(DB_URL + `tickets`, { ticket_id, fields }, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}


export function deleteTicket(ticket_id) {
  return axios.put(DB_URL + `tickets/${ticket_id}`, {}, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function getVendorResponses() {
  return axios.get(DB_URL + `tickets/responses`, {
    headers: auth.getAuthHeader(),
  })
    .then((responses) => Promise.resolve(responses))
    .catch((error) => Promise.reject(error))
}



/*
 * Services
*/
// Get All
export function servicesGetAll(serviceQuery) {
  return axios.get(DB_URL + 'services', {
    headers: auth.getAuthHeader(),
    params: serviceQuery
  })
    .then(customer => Promise.resolve(customer))
    .catch(error => Promise.reject(error))
}

/*
 * Services
 * assignAddonToAppointment
*/
export function assignAddOnToAppointment({ payload, appointment_id, subscription_id }) {

  console.log("in service",payload )
  const data = { ...payload, appointment_id: appointment_id, subscription_id }
  return axios.post(DB_URL + `appointments/manage/add-on/add`, data, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export function updateAppointmentAddons({ addons, appointment_id, subscription_id, customer_id }) {
  const data = { addons, appointment_id, subscription_id, customer_id };
  return axios.post(DB_URL + `appointments/manage/add-ons/update`, data, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

// Get One
export function servicesGetOne(id) {
  return axios.get(DB_URL + 'services/' + id, {
    headers: auth.getAuthHeader()
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function servicesGetByOrder(id) {
  return axios.get(DB_URL + 'services/order/' + id, {
    headers: auth.getAuthHeader()
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function servicesGetFeaturedImage(media_id) {
  return axios.get(DB_URL + 'services/media/' + media_id, {
    headers: auth.getAuthHeader()
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function orderGetOne(order_id) {
  return axios.get(DB_URL + `subscriptions/${order_id}`, {
    headers: auth.getAuthHeader()
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function appointmentsGetOne(appointment_id) {
  return axios.get(DB_URL + `appointments/${appointment_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function appointmentsGetQuery(query) {
  return axios.get(DB_URL + `appointments`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

export function appointmentsCheckAvailability({ start_time, duration, vendor_id, zip_code }, exclude_id) {
  return axios.get(DB_URL + `appointments/availablity/check`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time,
      duration,
      vendor_id,
      exclude_id,
      zip_code
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function appointmentsGetAvailability({ start_time, vendor_id, zip_code }) {
  return axios.get(DB_URL + `appointments/availablity/day`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time,
      vendor_id,
      zip_code
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function appointmentsGetAvailabilityRange({ start_time, end_time, vendor_id, zip_code }) {
  return axios.get(DB_URL + `appointments/availablity/range`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time,
      end_time,
      vendor_id,
      zip_code
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function appointmentsGetNextAvailability({ start_time, duration, vendor_id, requested_day, day_of_week, zip_code }) {
  return axios.get(DB_URL + `appointments/availablity/next`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time,
      duration,
      vendor_id,
      requested_day,
      day_of_week,
      zip_code,
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function appointmentsGetNextAvailabilityPublic({ start_time, duration, vendor_id, requested_day, day_of_week, zip_code }) {
  return axios.get(DB_URL + `public/availability/next`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time,
      duration,
      vendor_id,
      requested_day,
      day_of_week,
      zip_code,
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function removeAddOnAppointment({ order_id, appointment_id }) {
  const data = { order_id, appointment_id: appointment_id }
  return axios.post(DB_URL + `appointments/manage/add-on/remove`, data, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}



/*
* Vendor
*/

export function vendorOnboarding1GetStripeID(id) {
  return axios.get(DB_URL + `vendors/${id}/onboarding/authorize`, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}
export function vendorOnboarding2AuthorizeResponse(id, code, state_key) {
  return axios.post(DB_URL + `vendors/${id}/onboarding/token`, { code, state_key }, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function vendorSaveUpdateProfile(id, vendor) {
  return axios.put(DB_URL + `vendors/${id}/update`, vendor, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function vendorVerifyPhone(phone) {
  return axios.put(DB_URL + `account/verify-phone`, phone, {
    headers: auth.getAuthHeader()
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

export function vendorConfirmPhone(verificationData) {
  return axios.put(DB_URL + `account/confirm-phone`, verificationData, {
    headers: auth.getAuthHeader()
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

export function vendorGetPaymentsBalance(id) {
  return axios.get(DB_URL + `vendors/${id}/payments/balance`, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

//This is the endpoint to save vendor request time off
export function vendorSaveRequestTimeOff(vendor_id, schedule) {
  return axios.put(DB_URL + `vendors/${vendor_id}/request-time-off`, schedule, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function vendorGetUpcomingAppointments(query) {
  return axios.get(DB_URL + `appointments`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

export function vendorGetOneUpcomingAppointment(appointment_id) {
  return axios.get(DB_URL + `appointments/${appointment_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function vendorGetTodaysAppointment() {
  return axios.get(DB_URL + `appointments/today`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function vendorUpdateTodaysAppointment({ note, status, date }) {
  return axios.post(DB_URL + `appointments/today`, { note, status, date }, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

// Update Organizations' Vendor Schedule
export function vendorUpdateSchedule(vendor_id, schedule) {
  return axios.put(DB_URL + `vendors/${vendor_id}/update-availability`, schedule, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

export function vendorSaveLocation(locationData) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}location/ping`, locationData, {
    headers: auth.getAuthHeader()
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

export function getCheckListQuestions() {
  return axios.get(DB_URL + `vendors/checklist`, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export function updateUpdateCustomerCheckListAnswer(checkListData) {
  return axios.put(DB_URL + `vendors/checklist/update`, checkListData, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export function vendorInitDashboard(id, query) {
  return axios.get(DB_URL + `vendor/${id}/init`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((vendor) => Promise.resolve(vendor))
    .catch((error) => Promise.reject(error))
}

/*
* Affiliate
*/
export function affiliateOnboarding1GetStripeID(id) {
  return axios.get(DB_URL + `affiliate/${id}/onboarding/authorize`, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function affiliateOnboarding2AuthorizeResponse(id, code, state_key) {
  return axios.post(DB_URL + `affiliate/${id}/onboarding/token`, { code, state_key }, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function affiliateGetAllTransfers(id, query) {
  return axios.get(DB_URL + `affiliate/${id}/payments/transfers`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function affiliateGetAllPayouts(id, query) {
  return axios.get(DB_URL + `affiliate/${id}/payments/payouts`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function affiliateGetBalance(id) {
  return axios.get(DB_URL + `affiliate/${id}/payments/balances`, {
    headers: auth.getAuthHeader(),
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}


export function affiliateGetSignUps(id, query) {
  return axios.get(DB_URL + `affiliate/${id}/sign-ups`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function affiliateGetContributors(id, query) {
  return axios.get(DB_URL + `affiliate/${id}/contributors`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function affiliateSaveUpdateProfile(id, affiliate) {
  return axios.put(DB_URL + `affiliate/${id}/update`, affiliate, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function affiliateVerifyPhone(phone) {
  return axios.put(DB_URL + `account/verify-phone`, phone, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}

export function affiliateConfirmPhone(verificationData) {
  return axios.put(DB_URL + `account/confirm-phone`, verificationData, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}

export function affiliateAddID(id, data) {
  return axios.put(DB_URL + `affiliates/${id}/add-id`, data, {
    headers: auth.getAuthHeader(),
  })
    .then(affiliate => Promise.resolve(affiliate))
    .catch(error => Promise.reject(error))
}

export function affiliateSendBroadCastEmail(id, broadcast) {
  return axios.post(DB_URL + `affiliates/${id}/broadcast-email`, broadcast, {
    headers: auth.getAuthHeader()
  })
    .then(broadcast => Promise.resolve(broadcast))
    .catch(error => Promise.reject(error))
}

export function affiliateGetBroadCastEmails(id) {
  return axios.get(DB_URL + `affiliates/${id}/get-broadcast-emails`, {
    headers: auth.getAuthHeader()
  })
    .then(broadcast => Promise.resolve(broadcast))
    .catch(error => Promise.reject(error))
}

export function checkAffiliateIDInUse(affiliate_id) {
  return axios.post(DB_URL + `affiliates/check-id-in-use`, { affiliate_id }, {
    headers: auth.getAuthHeader(),
  })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export function affiliateUpdateID(id, data) {
  return axios.put(DB_URL + `affiliates/${id}/update-id`, data, {
    headers: auth.getAuthHeader(),
  })
    .then(affiliate => Promise.resolve(affiliate))
    .catch(error => Promise.reject(error))
}
export function affiliateDeleteID(id, data) {
  return axios.put(DB_URL + `affiliates/${id}/delete-id`, data, {
    headers: auth.getAuthHeader(),
  })
    .then(affiliate => Promise.resolve(affiliate))
    .catch(error => Promise.reject(error))
}

export function affiliateGetIDs(id) {
  return axios.get(DB_URL + `affiliates/${id}/get-ids`, {
    headers: auth.getAuthHeader(),
  })
    .then(affiliate => Promise.resolve(affiliate))
    .catch(error => Promise.reject(error))
}

export function affiliateGetPaymentTransfers(affiliateId, query) {
  return axios.get(DB_URL + `affiliates/${affiliateId}/payments/transfers`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((transfers) => Promise.resolve(transfers))
    .catch((error) => Promise.reject(error))
}

export function affiliateGetMetrics(affiliateId, query) {
  return axios.get(DB_URL + `affiliates/${affiliateId}/metrics`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((transfers) => Promise.resolve(transfers))
    .catch((error) => Promise.reject(error))
}

export function affiliateGetBankAccountDetails(affiliateId) {
  return axios.get(DB_URL + `affiliates/account/details/${affiliateId}`, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function affiliateSaveUpdateData(id, affiliate) {
  return axios.put(DB_URL + `affiliate/${id}/update`, affiliate, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}

export function affiliateInitDashboard(id){
  return axios.get(DB_URL + `affiliates/${id}/init`, {
    headers: auth.getAuthHeader(),
  })
    .then(data => Promise.resolve(data))
    .catch(error => Promise.reject(error))
}

export function affiliateSaveBankAccountDetails(id, affiliate) {
  return axios.put(DB_URL + `affiliates/bank_account/${id}`, affiliate, {
    headers: auth.getAuthHeader(),
  })
    .then(updatedAffiliate => Promise.resolve(updatedAffiliate))
    .catch(error => Promise.reject(error))
}

export function affiliateFinishAccountSetup(id, affiliate) {
  return axios.post(DB_URL + `affiliates/${id}/finish_setup`, affiliate, {
    headers: auth.getAuthHeader(),
  })
    .then(updatedAffiliate => Promise.resolve(updatedAffiliate))
    .catch(error => Promise.reject(error))
}

export function mediaGetSrcFromID(id) {
  return axios.get(DB_URL + `media/one/${id}`, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}
export function organizationInitDashboard(id) {
  return axios.get(DB_URL + `organizations/${id}/init`, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}
export function organizationOnboarding1GetStripeID(id) {
  return axios.get(DB_URL + `organizations/${id}/onboarding/authorize`, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}
export function organizationOnboarding2AuthorizeResponse(id, code, state_key) {
  return axios.post(DB_URL + `organizations/${id}/onboarding/token`, { code, state_key }, {
    headers: auth.getAuthHeader(),
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}


// Save / Update Organization's Contact
export function organizationSaveContact(id, contact) {
  return axios.put(DB_URL + `organizations/${id}/update-contact`, contact, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}

// Remove Organization's Contact
export function organizationRemoveContact(id, contact) {
  return axios.put(DB_URL + `organizations/${id}/delete-contact`, contact, {
    headers: auth.getAuthHeader()
  })
    .then((affiliate) => Promise.resolve(affiliate))
    .catch((error) => Promise.reject(error))
}

// Verify Organization's Phone
export function organizationVerifyPhone(phone) {
  return axios.put(DB_URL + `account/verify-phone`, phone, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Confirm Organization's Phone
export function organizationConfirmPhone(verificationData) {
  return axios.put(DB_URL + `account/confirm-phone`, verificationData, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Get All Vendors Belonging To An Organization
export function organizationGetAllVendors({ start, num, type, _id, organization_id }) {
  return axios.get(DB_URL + `organizations/${organization_id}/vendors`, {
    headers: auth.getAuthHeader(),
    params: { start, num, type, _id }
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Get A Particular Vendor For An Organization
export function organizationGetOneVendor(id, vendor_id) {
  return axios.get(DB_URL + `organizations/${id}/vendor/${vendor_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Update A Particular Vendor's Data
export function organizationUpdateVendor(id, vendor) {
  return axios.put(DB_URL + `vendors/${vendor._id}/update`, vendor, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Update Organizations' Vendor Schedule
export function organizationUpdateSchedule(id, vendor_id, schedule) {
  return axios.put(DB_URL + `vendors/${vendor_id}/update-availability`, schedule, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Approve Or Un-approve Organization's Vendor Schedule
export function organizationChangeVendorSchedule({ organization_id, schedule_id, vendor_id, approval }) {
  const approve = {
    approval: approval,
    schedule_id: schedule_id
  }
  return axios.put(DB_URL + `organizations/${organization_id}/vendor/${vendor_id}/change-schedule-status`, approve, {
    headers: auth.getAuthHeader(),
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

export function organizationGetServiceAreas(organization_id) {
  return axios.get(DB_URL + `organizations/${organization_id}/service-areas`, {
    headers: auth.getAuthHeader()
  })
    .then((areas) => Promise.resolve(areas))
    .catch((error) => Promise.reject(error))
}

export function organizationGetZipCodes(organization_id) {
  return axios.get(DB_URL + `organizations/${organization_id}/zip-codes`, {
    headers: auth.getAuthHeader()
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error))
}

export function organizationGetSurvey(organization_id) {
  return axios.get(DB_URL + `organizations/${organization_id}/survey`, {
    headers: auth.getAuthHeader()
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error))
}

// Remove Custom Schedule
export function organizationRemoveVendorCustomSchedule({ organization_id, schedule_id, vendor_id }) {
  return axios.delete(DB_URL + `organizations/${organization_id}/vendor/${vendor_id}/schedule/${schedule_id}`, {
    headers: auth.getAuthHeader(),
    data: {}
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Delete / Remove Vendor From Organization
export function organizationVendorDeleteOnePermanently(id, vendor_id) {
  return axios.delete(DB_URL + `organizations/${id}/vendor/${vendor_id}`, {
    headers: auth.getAuthHeader(),
    data: {}
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Create Vendor To An Organization
export function organizationCreateVendor(id, vendor) {
  return axios.post(DB_URL + `organizations/${id}/vendor/new`, vendor, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

// Get Appointments By Date Range
export function appointmentsOrgGetAppointmentsRange(query) {
  return axios.get(DB_URL + `appointments`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

// Sort Appointments
export function appointmentsGetSorted(query) {
  return axios.get(DB_URL + `sort/appointments`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

// Check Appointments availablity By Day
export function appointmentsGetAvailabilityByDay(id, start_time) {
  return axios.get(DB_URL + `organization/${id}/appointments/availablity`, {
    headers: auth.getAuthHeader(),
    params: {
      start_time: start_time,
      allow_past: true
    }
  })
    .then(service => Promise.resolve(service))
    .catch(error => Promise.reject(error))
}

export function organizationGetOneUpcomingAppointment(org_id, appointment_id) {
  return axios.get(DB_URL + `appointments/${appointment_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function organizationManageAffiliate(org_id, affiliateBody) {
  return axios.put(DB_URL + `organizations/${org_id}/affiliate/manage`, affiliateBody, {
    headers: auth.getAuthHeader(),
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

export function organizationSaveBankAccountDetails(id, organization) {
  return axios.put(DB_URL + `organizations/bank_account/${id}`, organization, {
    headers: auth.getAuthHeader(),
  })
    .then(updatedOrganization => Promise.resolve(updatedOrganization))
    .catch(error => Promise.reject(error))
}


export function organizationGetVerificationDocumentData(organizationId) {
  return axios.get(DB_URL + `organizations/${organizationId}/verification_document_data`, {
    headers: auth.getAuthHeader(),
  })
    .then(documentData => Promise.resolve(documentData))
    .catch(error => Promise.reject(error))
}

export function organizationFinishAccountSetup(id, organizationData) {
  return axios.post(DB_URL + `organizations/${id}/finish_setup`, organizationData, {
    headers: auth.getAuthHeader(),
  })
    .then(updatedOrganization => Promise.resolve(updatedOrganization))
    .catch(error => Promise.reject(error))
}

// Update Appointment Information
export function appointmentsOrgUpdateOne(org_id, appointment) {
  return axios.put(DB_URL + `organization/${org_id}/appointments/update`, appointment, {
    headers: auth.getAuthHeader()
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

// Delete an appointment
export function appointmentsOrgDeleteAppointment(org_id, appointment_id) {
  return axios.delete(DB_URL + `organization/${org_id}/appointments/${appointment_id}`, {
    headers: auth.getAuthHeader(),
    data: {}
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

// Save Appointment
export function appointmentsOrgSaveNew(org_id, appointment) {
  return axios.post(DB_URL + `organization/${org_id}/appointments/new`, appointment, {
    headers: auth.getAuthHeader()
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

export function appointmentSingleNew(appointment) {
  return axios
    .post(DB_URL + `appointments`, appointment, {
      headers: auth.getAuthHeader()
    })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error));
}

//Get Customer Info
export function organizationCustomerGetOne(org_id, customer_id) {
  return axios.get(DB_URL + `organization/${org_id}/customer/${customer_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

// Get all customers
export function organizationCustomerGetAll(customerQuery) {
  return axios.get(DB_URL + 'organization/customers', {
    headers: auth.getAuthHeader(),
    params: Object.assign({
      type: 'active',
      count: 100,
      skip: 0
    }, customerQuery)
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function organizationSaveUpdateData(id, organization) {
  return axios.put(DB_URL + `organizations/${id}/update`, organization, {
    headers: auth.getAuthHeader()
  })
    .then((organization) => Promise.resolve(organization))
    .catch((error) => Promise.reject(error))
}

export function organizationServicesGetAll(id) {
  return axios.get(DB_URL + `organization/${id}/services`, {
    headers: auth.getAuthHeader()
  })
    .then(customer => Promise.resolve(customer))
    .catch(error => Promise.reject(error))
}
export function organizationGetOnboardingSurvey() {
  return axios.get(DB_URL + `organizations/survey`, {
    headers: auth.getAuthHeader()
  })
    .then(customer => Promise.resolve(customer))
    .catch(error => Promise.reject(error))
}
export function organizationGetSurveyAnswer(organization_id) {
  return axios.get(DB_URL + `organizations/survey/${organization_id}`, {
    headers: auth.getAuthHeader()
  })
    .then(data => Promise.resolve(data))
    .catch(error => Promise.reject(error))
}

export function organizationAcceptTOSStripe(organization_id) {
  return axios.post(DB_URL + `organizations/${organization_id}/accept_agreement`, {}, {
    headers: auth.getAuthHeader()
  })
    .then(data => Promise.resolve(data))
    .catch(error => Promise.reject(error))
}

export function organizationUpdateCustomerHomeSize(customer_id, address) {
  return axios
    .put(DB_URL + `organizations/customer/update-address/${customer_id}`, address, {
      headers: auth.getAuthHeader(),
    })
    .then((address) => Promise.resolve(address))
    .catch((error) => Promise.reject(error));
}

export function appointmentsUpdateOneInstance(appointment_id, data) {
  return axios.put(DB_URL + `appointments/update/${appointment_id}`, data, {
    headers: auth.getAuthHeader()
  })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error))
}

export function appointmentCompleteAppointment({ appointment_id }) {
  return axios.post(DB_URL + `appointments/complete`, { appointment_id }, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

/**
  Notifications Route
*/

// Get all notifications by a user accounts id;
export function getNotifications() {
  return axios.get(DB_URL + `notification`, {
    headers: auth.getAuthHeader(),
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function getNotification({ notification_id }) {
  return axios.get(DB_URL + `notification/${notification_id}`, {
    headers: auth.getAuthHeader(),
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function markNotificationAsRead(notification_id) {
  return axios.put(DB_URL + `notification/${notification_id}/read`, {}, {
    headers: auth.getAuthHeader(),
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function markNotificationAsDismissed(notification_id) {
  return axios.put(DB_URL + `notification/${notification_id}/dismiss`, {}, {
    headers: auth.getAuthHeader(),
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function markNotificationAsUndismissed(notification_id) {
  return axios.put(DB_URL + `notification/${notification_id}/unread`, {}, {
    headers: auth.getAuthHeader(),
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function getNotificationAlerts(query) {
  return axios.get(DB_URL + `notification-alerts`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(notification => Promise.resolve(notification))
    .catch(error => Promise.reject(error))
}

export function organizationGetPaymentTransfers(organizationId, query) {
  return axios.get(DB_URL + `organizations/${organizationId}/payments/transfers`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((transfers) => Promise.resolve(transfers))
    .catch((error) => Promise.reject(error))
}

export function organizationGetPaymentTransferSummary(organizationId, query) {
  return axios.get(DB_URL + `organizations/${organizationId}/payments/transfers-summary`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((transfers) => Promise.resolve(transfers))
    .catch((error) => Promise.reject(error))
}

export function organizationGetPayouts(id, query) {
  return axios.get(DB_URL + `organizations/${id}/payments/payouts`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function organizationGetPayoutSummary(id, query) {
  return axios.get(DB_URL + `organizations/${id}/payments/payouts-summary`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function getZipCodes(query = {}) {
  return axios.get(DB_URL + `zip-codes`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then(zipCodes => Promise.resolve(zipCodes))
    .catch(error => Promise.reject(error))
}

export function servicesCreateInvoice(payload) {
  return axios.post(DB_URL + `invoice/new`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function finalizeInvoice(invoice_id) {
  return axios.put(DB_URL + `finalize/invoice/${invoice_id}`, {}, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function payInvoice(invoice_id,payload) {
  return axios.put(DB_URL + `customer/pay/invoice/${invoice_id}`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function getInvoices(query) {
  return axios.get(DB_URL + `invoices`, {
    headers: auth.getAuthHeader(),
    params: query
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function getInvoice(invoice_id) {
  return axios.get(DB_URL + `invoice/${invoice_id}`, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function updateInvoice(invoice_id, payload) {
  return axios.put(DB_URL + `update/invoice/${invoice_id}`, payload, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function deleteInvoice(invoice_id) {
  return axios.put(DB_URL + `invoice/delete/${invoice_id}`, {}, { 
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function declineInvoice(invoice_id) {
  return axios.put(DB_URL + `customer/decline/invoice/${invoice_id}`, {}, {
    headers: auth.getAuthHeader()
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function validatePromoCode(service,promo_code, priceOptionId) {
  return axios.get(DB_URL + `coupons/validate/${service}/${promo_code}`, {
    headers: auth.getAuthHeader(),
    params: { priceOptionId }
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function updateCustomAddOn({ appointment_id, data, order_id }) {
  const editdata = { appointment_id, data, order_id }
  return axios.post(DB_URL + `custom/add-on/update`, editdata, { headers: auth.getAuthHeader() })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error))
}

export function QuickRegisterLookupAffiliate(referral_id) {
  return axios.post(DB_URL + `public/referral-id`, referral_id)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function QuickRegisterLookupAddress(address) {
  return axios.post(DB_URL + `public/validate/address`, address)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}
export function setCurrentScreen(payload) {
  return axios.post(DB_URL + `user/screen-view`, payload ,{
    headers: auth.getAuthHeader()
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}
export function publicGetServices() {
  return axios.get(DB_URL + `public/services`)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function publicGetLotSize() {
  return axios.get(DB_URL + `public/lot-size`)
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function publicGetTaxRates({ postalCode }) {
  return axios.get(DB_URL + `public/tax-rates`, {
    params: { postalCode }
  })
    .then((user) => Promise.resolve(user))
    .catch((error) => Promise.reject(error))
}

export function healthCheck() {
  const url = DB_URL.substring(0, DB_URL.length-3)
  return axios.get(url + `health-check`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error))
}

export function accountSaveAddress(id, addressData) {
  return axios.put(DB_URL + `account/update-address/${id}`, addressData, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}

export function accountDeleteAddress(account_id, addressID, account_type) {
  return axios.put(DB_URL + `account/delete-address/${account_id}`, { address_id: addressID, account_type }, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}

export function accountSavePhone(id, phoneData) {
  return axios.put(DB_URL + `account/update-phone/${id}`, phoneData, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}

export function accountDeletePhone(id, phoneID, account_type) {
  return axios.put(DB_URL + `account/delete-phone/${id}`, { phoneID: phoneID, account_type }, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}

export function createApproval(data) {
  return axios.post(DB_URL + `approvals`, data, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}

export function updateApproval(id, data) {
  return axios.put(DB_URL + `approvals/${id}`, data, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}
export function getApproval(id) {
  return axios.get(DB_URL + `approvals/${id}`, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
    .catch((error) => Promise.reject(error))
}
export function creditCustomer(id, data) {
  return axios
    .post(DB_URL + `admin/credit/${id}`, data, {
      headers: auth.getAuthHeader()
    })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error));
}
export function listCustomerCredits(id) {
  return axios
    .get(DB_URL + `admin/credit/${id}`, {
      headers: auth.getAuthHeader()
    })
    .then(res => Promise.resolve(res))
    .catch(error => Promise.reject(error));
  }
export function getAccountConversations(accountId) {
  return axios.get(DB_URL + `account/conversations/${accountId}`, {
    headers: auth.getAuthHeader(),
    params: {}
  })
    .then((account) => Promise.resolve(account.data))
    .catch((error) => Promise.reject(error))
}
export function checkAccountCanBeDeleted(accountId) {
  return axios.get(DB_URL + `account/can-delete/${accountId}`, {
    headers: auth.getAuthHeader(),
    params: {}
  })
    .then((account) => Promise.resolve(account.data))
    .catch((error) => Promise.reject(error))
}
export function deleteAccount(id) {
  return axios.put(DB_URL + `account/delete/${id}`, {}, {
    headers: auth.getAuthHeader()
  })
    .then((account) => Promise.resolve(account))
}

export function affiliateGetFlyer(data) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}affiliates/generate-flyers`, data, {
    headers: auth.getAuthHeader()
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}

export function sendBotAppointmentActionResponseToServerless(data) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}bot/reply-to-bot`, data, {
    headers: auth.getAuthHeader()
  })
    .then(({data}) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}


export function serverlessGetWemowConversationStatus(params = {}) {
  return axios.get(`${VUE_APP_API_SERVER_SERVERLESS}bot/conversation-status`, {
    headers: auth.getAuthHeader(),
    params
  })
    .then(({data}) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}


export function serverlessCancelWemowConversation(data = {}) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}bot/cancel-conversation`, data, {
    headers: auth.getAuthHeader(),
  })
    .then(({data}) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}


export function serverlessCancelWemowConversationStep(taskId) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}bot/cancel-conversation-task`, { taskId }, {
    headers: auth.getAuthHeader(),
  })
    .then(({data}) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}

export function serverlessPromptWemowConversationResponse(data) {
  return axios.post(`${VUE_APP_API_SERVER_SERVERLESS}bot/prompt-conversation-response`, data, {
    headers: auth.getAuthHeader(),
  })
    .then(({data}) => Promise.resolve(data))
    .catch((error) => Promise.reject(parseAWSApiErrorMessage(error)))
}


export function getNewSubscriptions({ dateFrom, dateTo }) {
  return axios.get(DB_URL + `subscriptions`, {
    headers: auth.getAuthHeader(),
    params: { dateFrom, dateTo }
  })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error))
}

export function generateReviews(data) {
  return axios.put(DB_URL + `customers/generate-reviews`, data, {
    headers: auth.getAuthHeader()
  })
    .then((customer) => Promise.resolve(customer))
    .catch((error) => Promise.reject(error))
}

export function organizationExportPayouts(id, query) {
  return axios.get(DB_URL + `organizations/${id}/payments/payouts-export`, {
    headers: auth.getAuthHeader(),
    params: query,
    responseType: 'blob',
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}

export function organizationExportTransfers(id, query) {
  return axios.get(DB_URL + `organizations/${id}/payments/transfers-export`, {
    headers: auth.getAuthHeader(),
    params: query,
    responseType: 'blob',
  })
    .then(summary => Promise.resolve(summary))
    .catch(error => Promise.reject(error))
}