import {
  format,
  formatDistance
} from 'date-fns/esm'
import {
  isMorningOrAfternoon
} from '@/helpers/helpers'

const DateFormatMixin = {
  data: function() {
    return {}
  },
  methods: {
    $_dateWeekDay(date){
      return !date ? "" : format(new Date(date), "EEEE");
    },
    $_dateDayNumber(date){
      return !date ? "" : format(new Date(date), "do");
    },
    $_dateFullNiceWithDay(date){
      return !date ? "" : format(new Date(date), "PPPP");
    },
    $_dateFullNice(date){
      return !date ? "" : this.$_dateFullNiceWithDay(date).split('day, ')[1];
    },
    $_dateYear(date){
      return !date ? "" : format(new Date(date), "y");
    },
    $_dateMonth(date){
      return !date ? "" : format(new Date(date), "MMMM");
    },
    $_dateTimeOfDay(date){
      return !date ? "" : isMorningOrAfternoon(date);
    },
    $_dateTimeAmPm(date){
      return !date ? "" : format(new Date(date), "h:mm a");
    },
    $_formatDateDistance(date) {
      return formatDistance(new Date(date), new Date(), { addSuffix: true });
  },
  },
}

export { DateFormatMixin }
