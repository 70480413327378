// import store from "@/store/store";

export default {
  getPasswordResetAuthHeader() {
    let tok = localStorage.getItem('reset_token')
    // const { settings = {} } = store.getters["getUserActiveAccount"];
    return {
      'Authorization': 'Bearer ' + tok,
      'Access-Control-Allow-Origin': '*',
      // "Accept-Language": settings.language_preference || 'en',
    }
  },
  getAuthHeader(setToken) {
    let tok = setToken ? setToken : localStorage.getItem('id_token')
    // const { settings = {} } = store.getters["getUserActiveAccount"];
    return {
      Authorization: "Bearer " + tok,
      "Access-Control-Allow-Origin": "*",
      // "Accept-Language": settings.language_preference || 'en',
    };
  },
  getFormHeader() {
    let tok = localStorage.getItem('id_token')
    // const { settings = {} } = store.getters["getUserActiveAccount"];
    return {
      'Authorization': 'Bearer ' + tok,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      // "Accept-Language": settings.language_preference || 'en',
    }
  },
  getAuthSocketObject(setToken) {
    let tok = setToken ? setToken : localStorage.getItem('id_token')
    return 'Bearer ' + tok
  },
  getAWSAuthHeader(token) {
    return {
      "Access-Control-Allow-Origin": "*",
      "X-Amz-Bearer": token == 'participant' ? localStorage.getItem('participantToken') : localStorage.getItem('connectionToken')
    };
  },
}
