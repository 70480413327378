export const payment = {
    paymentTransfers: "No Hay Transferencias de Pago | Transferencias de Pago | {count} Transferencias de Pago",
    transferSuccess: "Transferencia exitosa",
    billing: "Facturación",
    noPaymentInfo: "No hay información de pago",
    payouts: "pagos",
    balance: "Balance",
    price: "precio",
    creditCard: "Tarjeta de Crédito",
    noPaymentHistory: "No hay historial de pagos.",
    invoicesAndCharges: "Sus facturas y cargos aparecerán aquí.",
    card: "tarjeta",
    paymentInformation: "Información del pago",
    paymentHistory: "historial de pagos",
    upcomingInvoice: "Próxima factura | Próximas facturas",
    payment: "pago",
    invoice: "factura",
    cardNumber: "número de tarjeta",
    noSavedPaymentInfo: "No hay información de pago guardada",
    percentage: "porcentaje | porcentajes",
    paymentSource: "Fuente de pago",
    paymentMethod: "Método de pago",
    addPaymentMethod: "Necesita agregar un método de pago.",
    billingInformation: "Datos de Facturación",
    setupBillingInfo: "Necesita configurar su información de facturación",
    coupon: "cupón | cupones",
    paymentFailed: "Pago fallido",
    paymentSuccess: "Pago exitoso",
    cvcCode: "código CVC",
    addNewCreditCard: "Agregar nueva tarjeta de crédito",
    defaultPaymentMethod: "Método de pago por defecto",
    billingInformationText: "Datos de facturación",
    invoiceDetails: "Detalles de la factura",
    amountPaid: "Cantidad pagada",
    plan: "plan",
    createInvoice: "Crear Factura",
    addInvoiceItem: "Agregar artículo de factura",
    customerInvoice: "Factura del cliente",
    chargeCustomer: "Crear y cobrar al cliente",
    payments: "Pagos",
    transactions: "Actas"
}