<template>
    <div
      class="wistia_responsive_padding"
      style="padding:56.25% 0 0 0;position:relative;"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height:100%;left:0;position:absolute;top:0;width:100%;"
      >
        <div
          class="wistia_embed wistia_async_1cv90snhsm videoFoam=true"
          style="height:100%;position:relative;width:100%"
        >
          <div
            class="wistia_swatch"
            style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
          >
            <img
              src="https://fast.wistia.com/embed/medias/1cv90snhsm/swatch"
              style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      scripts: [
        [
          "wistia-json",
          "https://fast.wistia.com/embed/medias/1cv90snhsm.jsonp",
        ],
        ["wistia-js", "https://fast.wistia.com/assets/external/E-v1.js"],
      ],
    };
  },
  beforeMount() {
    for (const script of this.scripts) {
      const s = document.createElement("script");
      const [id, src] = script;
      s.type = "text/javascript";
      s.src = src;
      s.id = id;
      document.head.appendChild(s);
    }
  },
  beforeDestroy() {
    for (const script of this.scripts) {
      const [id] = script;
      const s = document.getElementById(id);
      document.head.removeChild(s);
    }
  },
};
</script>
