
import {
  MUTATE_MODAL_STATE,
  TOGGLE_MODAL_LOADING_STATE
} from '@/store/mutation-types'


const state = {
  modal_state: false,
  loading_state: false
}

const mutations = {
  [TOGGLE_MODAL_LOADING_STATE]: (state, loading_state) => {
    state.loading_state = loading_state
  },
  [MUTATE_MODAL_STATE]: (state, modal_state) => {
    state.modal_state = modal_state
  }
}

const actions = {}
const getters = {
  getModalState: state => state.modal_state,
  getModalLoadingState: state => state.loading_state,
};

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state
}