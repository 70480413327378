import { affiliateRoutes } from "./affiliate";
import { vendorRoutes } from "./vendor";
import { organizationRoutes } from "./organization";
import { customerRoutes } from "./customer";
import { adminRoutes } from "./admin";
import { generalRoutes } from "./general";


export const routes = [
  ...affiliateRoutes,
  ...vendorRoutes,
  ...organizationRoutes,
  ...customerRoutes,
  ...adminRoutes,
  ...generalRoutes
];