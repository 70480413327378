<template>
  <div>
    <component
      :is="getRegisterTemplateVersionComponent"
      :defaultEmailValue="user.email"
      :loading="loading"
      :loaded="loaded"
      :error="error"
      :version="version"
      :referrer="referrer"
      @doAltRegisterAction="doAltRegisterAction"
      @updateUserFields="updateUserFields"
      @doQuickRegisterAction="doQuickRegisterAction"
    />
    <div class="fixed bg-white  border shadow-lg w-full sm:w-[480px] bottom-1 sm:bottom-4 right-1 sm:right-4 py-4 px-6" v-if="version !== 'default' && referrer && referrer.affiliate_id && referrer.name && !hideReferrerPopup">
      <div class="text-gray-500 hover:text-red-800 cursor-pointer absolute top-2 right-2" @click="hideReferrerPopup = true">  <font-awesome-icon icon="times"></font-awesome-icon></div>
            <div
              class="pt-2 md:pt-8 font-medium text-gray-800 mb-6 text-center"
              v-if="referrer && referrer.name"
            >
              <span class="block text-lg md:text-xl">{{ referrer.name }} wanted you to check out Wemow!</span>
              <span class="block text-lg">Best part is we give back $1 every time we service your lawn, so get started now!</span>
            </div>
            <div
              class="text-center flex flex-col justify-center items-center"
            >
              <div
                :style="`width:120px;height:120px;border-radius:999px;overflow:hidden;background-image:url(${referrer.image});background-size:cover;background-position: center;`"
              >
              </div>
            <div class="text-center text-sm font-semibold text-gray-700 mt-3 mb-2 max-w-[180px] truncate" :title="referrer.name">{{referrer.name}}</div>

            </div>
            <div
            v-if="referrer.bio"
              class="text-sm font-light text-gray-700 mt-5 mb-2 p-4 bg-gray-100 text-center"
            >
           {{referrer.bio}}
            </div>
    </div>
  </div>
</template>

<script>
import { loadingTimeout } from "@/helpers/helpers";
import { mapActions } from "vuex";
import { has, get } from "lodash-es";
import {
  AUTH_QUICK_REGISTRATION_ACTION,
  AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE,
  AUTH_ALT_REGISTRATION_ACTION
} from "@/store/action-types";
require('polyfill-object.fromentries');

const templateMap = { 
  'default': 'TemplateDefault',
  'homeSize': 'TemplateHomeSizeDoorHanger',
  '23343': 'TemplateCustomOne',
  'quote': 'TemplateQuote',
  '23f3g4': 'TemplateCustomOneWithPopover'
}

export default {
  components: Object.fromEntries(Object.entries(templateMap).map(([ , val ]) => [ val, () => import(`@/components/public/register/quick-register/templates/${val}`) ])),
  data() {
    return {
      error: "",
      message: "",
      loading: false,
      loaded: false,
      version: 'default',
      hideReferrerPopup: false,
      user: {
        email: "",
        first_name: "",
        last_name: "",
        phone_number: ""
      },
      referrer: {
        affiliate_id: "",
        name: "",
        bio: "",
        image: "",
      },
      address: {}
    };
  },
  mounted() {
    const url = new URL(window.location);
    const params = url.searchParams;

    const version = params.get("version");
    if (version && Object.keys(templateMap).includes(version) ) {
      this.version = version;
    }

    const email = params.get("email");
    if (email) {
      this.user.email = email;
    }

    this.getAffiliateData().then(() => {
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      quickRegister: AUTH_QUICK_REGISTRATION_ACTION,
      getAffiliateFromAffiliateId: AUTH_QUICK_CUSTOMER_REGISTRATION_LOOKUP_AFFILIATE,
      registerAlt: AUTH_ALT_REGISTRATION_ACTION,
    }),
    async updateUserFields({  fields }) {
      for (const field of Object.keys(fields)) {
        if(field === 'address'){
          for (const addressField of Object.keys(fields[field])) {
            this.address[addressField] = get(fields[field], addressField);
          }
        }
        else if (typeof this.user[field] !== "undefined") {
          this.user[field] = get(fields, field);
        }

        if(field === 'email'){
          this.doQuickRegisterAction();
        }
      }
      // return
    },
    async getAffiliateData() {
      const url = new URL(window.location);
      const params = url.searchParams;
      const affiliate_id = params.get("aff");
      if (!affiliate_id) {
        return;
      }
      const { response, success } = await this.getAffiliateFromAffiliateId(
        affiliate_id
      );
      if(success) {
        this.referrer.affiliate_id = affiliate_id;
        if (response) {
          const { name, image, bio } = response;
          this.referrer.name = name;
          this.referrer.image = image;
          this.referrer.bio = bio;
        }
      }
      
    },
    async doQuickRegisterAction() {
      try {
        this.loading = true;
        const { user, referrer, version, address } = this;
        const { affiliate_id } = referrer;
        const referred_by = affiliate_id ? { referred_by: affiliate_id } : {};
        await loadingTimeout(1000);
        await this.quickRegister(Object.assign({}, user, referred_by, {platform:'web', version, type: 'customer' }, { address }));
        this.$router.replace("/customer/onboarding");
        this.$sendAnalyticsEvent('sign_up', {
          method: 'email',
          type: 'customer',
          version
        });
        this.$sendAnalyticsAdWordsConversion();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async doAltRegisterAction(data) {
      try {
        this.loading = true;
        const { source_type } = data;
        const { referrer, version } = this;
        const { affiliate_id } = referrer;
        const referred_by = affiliate_id ? { referred_by: affiliate_id } : {};
        await this.registerAlt(Object.assign({}, data, referred_by, { platform:'web', version, type:"customer", registerMethod: source_type }));
        this.$router.replace("/customer/onboarding");
        this.$sendAnalyticsEvent('sign_up', {
          method: `${source_type}`,
          type: 'customer',
          version
        });
        this.$sendAnalyticsAdWordsConversion();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },
  computed: {
    getRegisterTemplateVersionComponent() {
      const { version } = this;
      if(has(templateMap, version)){
        return get(templateMap, version);
      }
      return get(templateMap, 'default')
    },
  },
};
</script>

<style scoped>
.big-check {
  font-size: 60px;
  background: #03733d;
  color: #fff;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link-style {
  color: blue;
}

.link-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-enter-active #register-sidebar {
  transition: all 0.3s ease;
}
.fade-leave-active #register-sidebar {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-sidebar,
.fade-leave-to #register-sidebar {
  transform: translateX(-500px);
  opacity: 0;
}

.fade-enter-active #register-area {
  transition: all 0.3s ease;
}
.fade-leave-active #register-area {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter #register-area,
.fade-leave-to #register-area {
  transform: translateX(500px);
  opacity: 0;
}
</style>
