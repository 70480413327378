<template>
  <div class="form-group" :class="{ 'hasError': $v.$error, 'isFocus': isFocused }">
    <div class="form-field" :class="`form-field-${type}`">
      <label v-if="label">{{ label }}</label>

      <el-input :id="id" v-if="type === 'phone'" :prefix-icon="prefixIcon"  ref="textField" :type="type" v-model="sample_text" :placeholder="placeholder" @input="$v.$touch()" @change="emitChange" auto-complete="off" v-mask="'###-###-####'" :masked="true" :readonly="readonly" :disabled="disabled" :rows="type == 'textarea'? rows : ''"></el-input>

      <el-input v-else-if="type === 'password'" :prefix-icon="prefixIcon"  ref="textField" :type="showPassword? 'text': 'password'" v-model="sample_text" :placeholder="placeholder" @input="$v.$touch()" @change="emitChange" @focus="focusField" @blur="blurField" auto-complete="off" :readonly="readonly" :disabled="disabled" >
        <font-awesome-icon 
                    v-if="showPassword" 
                    @click="showPassword = false" 
                    slot="suffix" 
                    class="mr-8 cursor-pointer el-input__icon" 
                    icon="eye-slash"
        />
        <font-awesome-icon 
            v-else slot="suffix" 
            @click="showPassword = true"  
            class="mr-8 cursor-pointer el-input__icon" 
            icon="eye"
        />
      </el-input>

      <el-input
        v-else-if="type === 'taxId'"
        :prefix-icon="prefixIcon" 
        ref="textField"
        :type="'text'"
        v-model="sample_text"
        :placeholder="placeholder"
        @input="$v.$touch()"
        @change="emitChange"
        @focus="focusField"
        @blur="blurField"
        auto-complete="off"
        v-mask="getTaxIdMaskingFormat"
        :masked="true"
        :readonly="readonly"
        :disabled="disabled"
        :rows="type == 'textarea' ? rows : ''"
        :autofocus="focusOnMount"
      >
      </el-input>

      <el-input 
        :id="id" 
        v-else 
        ref="textField"
        :prefix-icon="prefixIcon" 
        :type="type" 
        v-model="sample_text" 
        :placeholder="placeholder" 
        @input="$v.$touch()" 
        @change="emitChange" 
        @focus="focusField" 
        @blur="blurField" 
        auto-complete="off" 
        :readonly="readonly" 
        :disabled="disabled" 
        :maxlength="maxLengthValidation.value"
        :show-word-limit="maxLengthValidation.showWordLimit"
        :rows="type == 'textarea'? rows : ''">
        <template slot="prepend">
          <slot name="prepend"></slot>
        </template>
      </el-input>
      <el-tooltip :tabindex="9999" :disabled="$v.sample_text.required && $v.sample_text.required === true" :content="`Required`" placement="top" >
        <span :tabindex="9999" class="icon" :class="`icon-${displayIcon}` +' ' + `${loading ? 'fa-spin' : ''}`"><font-awesome-icon :icon="displayIcon"></font-awesome-icon></span>
      </el-tooltip>
    </div>
    <div class="error-message" v-if="displayErrorMessage && displayErrorMessage.length && (type === 'email' && !isFocused )">
      <span v-for="(err, index) in displayErrorMessage" :key="index">{{err}}</span>
    </div>
  </div>
</template>

<script>
  import i18n from "@/plugins/i18n";
  import {mask} from 'vue-the-mask';
  import { required, minLength, maxLength, minValue, maxValue, alpha, alphaNum, integer, decimal, email } from "vuelidate/lib/validators";
  const AllowedValidations = [ 'custom', 'email', 'minLength', 'maxLength', 'minValue', 'maxValue', 'alpha', 'alphaNum', 'numeric', 'integer', 'decimal','alphaBlackList']
  export default {
    directives: {mask},
    props: {
      id: {
        type: String,
        default() {
          return `field-${Math.random().toString(36).substring(2, 15)}`
        }
      },
      value: {
        type: [Number,String],
        default: ""
      },
      placeholder: {
        type: String,
        default: i18n.t('information.enterTextHere')
      },
      type: {
        type: String,
        default: "text"
      },
      rows: {
        type: Number,
        default: 0
      },
      required: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      customValidator: {
        type: Function,
        default(){
          return function(){
            return true
          }
        }
      },
      validations: {
        type: Array,
        default: function(){
          return []
        },
        validator(value){
          return !value.map(v => AllowedValidations.includes(v.method)).includes(false)
        }
      },
      focusOnMount: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ""
      },
      defaultTaxIdMask: {
        type: String,
        default: "SSN",
        validator: (val) => {
          return val === "SSN" || val === "EIN";
        },
      },
      prefixIcon: {
        type: String,
        default: ""
      }
    },
    data(){
      return {
        isFocused: false,
        showPassword:false,
      }
    },
    mounted(){
      this.$v.$touch()
      if(this.focusOnMount){
        const { textField } = this.$refs;
        if(textField && textField.$el){
          const field = textField.$el.querySelector('input')
          field.focus()
        }
      }
    },
     validations(){
      const sample_text = this.required ? {required} : {}
      this.validations.forEach(validation => {
        if(AllowedValidations.includes(validation.method)){
          Object.assign(sample_text, this.getValidationParam(validation))
        }
      });
      return { sample_text }
    },
    methods:{
     
    alphaBlackList(val){ 
              return alpha(val.replace(/[0-9`'\-, "]/g, ""))
    },
      getValidationParam(validation){
        const val = validation.value
        let validationParam = {}
        switch (validation.method) {
          case "required":
            validationParam["required"] = required;
            break;
          case "minLength":
            validationParam["minLength"] = minLength(val);
            break;
          case "maxLength":
            validationParam["maxLength"] = maxLength(val);
            break;
          case "minValue":
            validationParam["minValue"] = minValue(val);
            break;
          case "maxValue":
            validationParam["maxValue"] = maxValue(val);
            break;
          case "integer":
            validationParam["integer"] = integer;
            break;
          case "alpha":
            validationParam["alpha"] = alpha;
            break;
          case "alphaNum":
            validationParam["alphaNum"] = alphaNum;
            break;
          case "alphaBlackList":
            validationParam["alphaBlackList"] = this.alphaBlackList;
            break;
          case "decimal":
            validationParam["decimal"] = decimal;
            break;
          case "email":
            validationParam["email"] = email;
            break;
          case "custom":
            validationParam["custom"] = this.customValidator;
            break;
          default:
            break;
        }
        return validationParam
      },
      emitChange(v){
        this.$emit('change', v)
      },
      focusField(){
        this.isFocused = true
      },
      blurField(){
        this.isFocused = false
        this.$emit("blur")
      }
    },
    computed: {
      maxLengthValidation(){
        const maxLengthValidation = this.validations.find(v => v.method === "maxLength")
        return maxLengthValidation && maxLengthValidation.useInBuilt ? {value: maxLengthValidation.value, showWordLimit: true} : {value: null, showWordLimit: false}
      },
      sample_text: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      displayErrorMessage() {
        const messages = [];
        const validations = _.cloneDeep(this.validations);
        const defaultMessages = {
          email: () => "Invalid email address",
          required: () => "Text Field is required",
          minLength: val => `Minimum length is ${val}`,
          maxLength: val => `Maximum length is ${val}`,
          minValue: val => `Minimum Value is ${val}`,
          maxValue: val => `Maximum Value is ${val}`,
          alpha: () => "Only alphabetical characters are allowed",
          alphaNum: () => "Only alpha numerics are allowed",
          decimal: () => "Only decimals",
          integer: () => "Only Integers",
          custom: () => "Error"
        }
        validations.forEach(validation => {
          const value = validation.value
          const method = validation.method
          const message = validation.error ? validation.error : defaultMessages[method](value)
          if(!this.$v.sample_text[method]){
              messages.push(message)
          }
        });

        return messages;
      },
     displayIcon: function(){
       if(this.loading){
          return 'spinner'
       }else if(_.has(this.$v.sample_text, 'required') && !this.$v.sample_text.required){
          return 'asterisk'
        } else if(!this.$v.$error && !this.$v.$invalid){
          return 'check'
        }else if(this.$v.$error || this.$v.$invalid){
          return 'times'
        }else{
          return ''
        }
      },
      getTaxIdMaskingFormat: function (){
        return this.defaultTaxIdMask === "SSN" ? "###-##-####" : "##-#######";
      },
  }
};
</script>

<style scoped>
.error-message {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    margin: 4px 0 0 3px;
    position: absolute;
}

.form-field {
  position: relative;
}

.form-field input,
.form-field textarea {
  padding-right: 40ex;
}


.form-field .icon {
  position: absolute;
  z-index: 9;
  right:20px;
  bottom: 1px;
  background: inherit;
  display: flex;
  @apply h-16;
  align-items: center;
}

.form-field.form-field-textarea .icon {
  top: 0px;
}

.icon-asterisk {
  @apply text-red-300; 
}

.isFocus .icon-asterisk,
.icon-times {
  @apply text-red-600; 

}

.icon-check {
  @apply text-green-600; 
}
</style>
