export const saveQuoteToLocalStorage = (items) => {
  const key = 'wemow_quote';
  const allowed = ["service_id", "vendor_id", "start_time", "frequency", "home_size", "promo_code"]
  savePropsToLocalStorage(key, items, allowed, allowed)
  }

  export const saveDefaultsToLocalStorage = (items) => {
    const key = 'wemow_set_default';
    const allowed = ["home_size"]
    savePropsToLocalStorage(key, items, allowed, allowed)
    }

  export const savePropsToLocalStorage = (key, items, allowed = []) => {
    const el = {};
    const current = localStorage.getItem(key)
    if(current){
      try {
        const currentToObj = JSON.parse(current);
        for (const item of Object.keys(currentToObj)) {
          if(typeof currentToObj[item] === 'string' || typeof currentToObj[item] === 'number'){
            el[item] = currentToObj[item];
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    for (const item of Object.keys(items)) {
        if(allowed.includes(item)){
            if(typeof items[item] === 'string' || typeof items[item] === 'number'){
                el[item] = items[item];
            }
        }
    }

    localStorage.setItem(key, JSON.stringify(el))
  }