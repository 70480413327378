import { mapActions, mapState } from 'vuex'

import {
  CUSTOMER_GET_ONE_UPCOMING_APPOINTMENT,
  CUSTOMER_GET_SUBSCRIPTIONS_FROM_STRIPE,
  CUSTOMER_GET_ONE_SUBSCRIPTIONS_FROM_STRIPE,
  CUSTOMER_CANCEL_SUBSCRIPTION,
  SERVICES_GET_ALL,
  CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS,
  CUSTOMER_UPDATE_SUBSCRIPTION,
  SERVICES_GET_BY_ORDER,
  CUSTOMER_INIT_DASHBOARD,
  CUSTOMER_PAUSE_SUBSCRIPTION,
  CUSTOMER_UNPAUSE_SUBSCRIPTION,
  CUSTOMER_SUSPEND_SUBSCRIPTION,
  CUSTOMER_RESTORE_SUBSCRIPTION,
  CUSTOMER_ADD_CREDIT,
  CUSTOMER_LIST_CREDITS
} from '@/store/action-types'

export default {
  data: function () {
    return {
      upcomingAppointmentsLoading: false,
      subscriptionsLoading: false,
      oneSubscriptionsLoading: false,
      cancelSubscriptionLoading: false,
      updateSubscriptionLoading: false,
      contributorsLoading: false,
      pauseSubscriptionLoading: false,
      unpauseSubscriptionLoading: false,
      suspendSubscriptionLoading: false,
      restoreSubscriptionLoading: false
    }
  },
  methods: {
    ...mapActions('customers', {
      $_setupOneUpcomingAppointment: CUSTOMER_GET_ONE_UPCOMING_APPOINTMENT,
      $_setupSubscriptions: CUSTOMER_GET_SUBSCRIPTIONS_FROM_STRIPE,
      $_setupOneSubscription: CUSTOMER_GET_ONE_SUBSCRIPTIONS_FROM_STRIPE,
      $_cancelOneSubscription: CUSTOMER_CANCEL_SUBSCRIPTION,
      $_getContributions: CUSTOMER_GET_AFFILIATE_CONTRIBUTIONS,
      $_updateOneSubscription: CUSTOMER_UPDATE_SUBSCRIPTION,
      $_initCustomerDashboard: CUSTOMER_INIT_DASHBOARD,
      $_pauseOneSubscription: CUSTOMER_PAUSE_SUBSCRIPTION,
      $_unpauseOneSubscription: CUSTOMER_UNPAUSE_SUBSCRIPTION,
      $_suspendOneSubscription: CUSTOMER_SUSPEND_SUBSCRIPTION,
      $_restoreOneSubscription: CUSTOMER_RESTORE_SUBSCRIPTION,
      $_creditCustomer: CUSTOMER_ADD_CREDIT,
      $_listCustomerCredits: CUSTOMER_LIST_CREDITS
    }),
    ...mapActions('services', {
      $_getAllServices: SERVICES_GET_ALL,
      $_getServiceByOrder: SERVICES_GET_BY_ORDER,
    }),
    async $_initializeCustomerDashboard(){
      try {
        const activeAccountId = this.$_activeAccount._id
        await this.$_initCustomerDashboard(activeAccountId);
      } catch (e) {
        /* Do something... */
      }
    },
    async $_getOneUpcomingAppointment(appointment_id, force = false) {
      try {
        this.upcomingAppointmentsLoading = true
        const customer_id = this.$_activeAccount._id
        const appointment = this.$store.getters[
          'appointments/getOneAppointment'
        ](appointment_id)

        if(!force && appointment && appointment._id){
          return appointment
        }

        const fetch = await this.$_setupOneUpcomingAppointment({
          customer_id,
          appointment_id,
        })

        return fetch;
      } catch (e) {
        console.log('catch', e.message)
        throw e
      } finally {
        this.upcomingAppointmentsLoading = false
      }
    },
    async $_customerCancelSubscription(subscription_id) {
      try {
        this.cancelSubscriptionLoading = true
        await this.$_cancelOneSubscription({ subscription_id })
        await this.$_getSubscriptions({}, true)
      } catch (e) {
        throw e
      } finally {
        this.cancelSubscriptionLoading = false
      }
    },
    async $_customerPauseSubscription(pauseData){
      try {
        this.pauseSubscriptionLoading = true
        return await this.$_pauseOneSubscription(pauseData)
      } catch (e) {
        throw e
      } finally {
        this.pauseSubscriptionLoading = false
      }
    },
    async $_customerUnpauseSubscription(orderId, beforeSchedule){
      try {
        this.unpauseSubscriptionLoading = true
        await this.$_unpauseOneSubscription({ orderId: orderId, beforeSchedule: beforeSchedule })
        await this.$_getSubscriptions({}, true)
      } catch (e) {
        throw e
      } finally {
        this.unpauseSubscriptionLoading = false
      }
    },
    async $_customerUpdateSubscription(data) {
      try {
        this.updateSubscriptionLoading = true
        return await this.$_updateOneSubscription(data);
      } catch (e) {
        throw e
      } finally {
        this.updateSubscriptionLoading = false
      }
    },
    async $_getSubscriptions(query, force = false) {
      try {
        this.subscriptionsLoading = true
        return !force &&
          this.$_activeSubscriptions &&
          this.$_activeSubscriptions.length > 0
          ? true
          : await this.$_setupSubscriptions({
              customer_id: this.$_activeAccount._id,
              query,
            })
      } catch (e) {
        throw e
      } finally {
        this.subscriptionsLoading = false
      }
    },
    async $_getOneSubscription(subscription_id, force = false, query = {}) {
      try {
        this.oneSubscriptionsLoading = true
        const OneAppt = this.$store.getters['customers/getSubscription'](
          subscription_id,
        )
        return !force && OneAppt && OneAppt.id
          ? true
          : await this.$_setupOneSubscription({
              customer_id: this.$_activeAccount._id,
              subscription_id: subscription_id,
              query
            })
      } catch (e) {
        throw e
      } finally {
        this.oneSubscriptionsLoading = false
      }
    },
    async $_getServices(query = {}) {
      try {
        this.subscriptionsLoading = true
        return this.$_customerAllServices &&
          this.$_customerAllServices.length > 0
          ? true
          : await this.$_getAllServices({query})
      } catch (e) {
        throw e
      } finally {
        this.subscriptionsLoading = false
      }
    },
    async $_getServicesByOrder(qorderId) {
      try {
        this.subscriptionsLoading = true
        return await this.$_getServiceByOrder(qorderId)
      } catch (e) {
        throw e
      } finally {
        this.subscriptionsLoading = false
      }
    },
    async $_getAllContributions(query) {
      try {
        this.contributorsLoading = true
        const customerID = this.$_activeAccount._id
        const affiliates = await this.$_getContributions({ customerID, query })
        return affiliates
      } catch (e) {
        throw e
      } finally {
        this.contributorsLoading = false
      }
    },
    async $_customerSuspendSubscription(orderId){
      try {
        this.suspendSubscriptionLoading = true
        return await this.$_suspendOneSubscription({orderId})
      } catch (e) {
        throw e
      } finally {
        this.suspendSubscriptionLoading = false
      }
    },
    async $_customerRestoreSubscription(orderId){
      try {
        this.restoreSubscriptionLoading = true
        const subscription = await this.$_restoreOneSubscription({ orderId})
        await this.$_getSubscriptions({}, true)
        return subscription
      } catch (e) {
        throw e
      } finally {
        this.restoreSubscriptionLoading = false
      }
    },
  },
  computed: {
    ...mapState({
      $_activeAccount: (state) => state.user.activeAccount,
    }),
    $_customerOnboardingComplete: function () {
      return (
        this.$_activeAccount &&
        this.$_activeAccount.meta &&
        this.$_activeAccount.meta.initialSetup ||
        this.$_activeAccount.meta.skipOnboarding
      );
    },
    $_upcomingAppointments: function () {
      return this.$store.getters['appointments/allAppointments']
    },
    $_activeSubscriptions: function () {
      return this.$store.getters['customers/allSubscriptions']
    },
    $_customerAllServices: function () {
      return this.$store.getters['services/getAllServices']
    },
    $_customerAffiliates: function () {
      const affs = this.$store.getters['customers/getCustomerAffiliates']
      const affsOnAcct = this.$_activeAccount.affiliates
      if (!affs || !affs.length || !affsOnAcct || !affsOnAcct.length) {
        return []
      }

      const mergeAffData = affs.map((affiliate) =>
        Object.assign(
          {},
          affiliate,
          affsOnAcct.find((y) => y.affiliate_id === affiliate._id),
        ),
      )
      return mergeAffData
    },
    $_customerAffiliateContributions: function () {
      return this.$store.getters['customers/getAffiliateContributions']
    },
  },
}
