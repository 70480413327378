export const confirmMessages = {
    approveMessage: "Esto aprobará este {text}.",
    unApproveMessage: "Esto no aprobará este {text}.",
    removeMessage: "Esto eliminará este {text}.",
    permanentlyDeleteText: "Esto eliminará permanentemente el {text}.",
    appointmentChangingAddress: "Cambiar las direcciones afectará el tamaño de la casa, lo que cambiará este precio de suscripción. El tamaño de la vivienda está cambiando de {currentAddressSize} a {newAddressSize}, y el precio {priceDirection} de $ {currentPlan} a $ {newPlan}.",
    updateFutureInstances: "¿Desea actualizar cada instancia futura de esta cita?",
    removeAffiliate: "Esto eliminará el afiliado de sus contribuciones.",
    cancelSubscription: "¿Seguro que quieres cancelar esta suscripción? Esto cancelará la facturación y todas las citas futuras relacionadas con esta suscripción.",
    changeSubscription: "¿Seguro que quieres cambiar la frecuencia de suscripción? Esto ajustará la frecuencia de su servicio para que sea {text}ly",
    changePlan: `Cambiar el plan de servicio cambiará el precio de esta suscripción. El plan de suscripción está cambiando de {currentPlan} a {newPlan}, y el precio de $ {currentPrice} a $ {newPrice}`,
    suspendSubscription: "¿Está seguro de que desea suspender la suscripción? Esto suspenderá todas las citas futuras hasta la próxima facturación exitosa",
    restoreSubscription: "¿Está seguro de que desea restaurar la suscripción? Esto restaurará todas las citas futuras hasta la próxima facturación fallida"
}