<template>
  <div class="absolute top-0 left-0 w-screen h-screen"></div>
</template>

<script>
import { loadingTimeout } from "@/helpers/helpers";
import { AUTH_LOGOUT_ACTION } from '@/store/action-types'

export default {
  name: "Logout",
  data() {
    return {};
  },
  mounted() {
      loadingTimeout(200)
      .then(() =>  this.$store.dispatch(AUTH_LOGOUT_ACTION, true))
      .catch(() => {})
      .finally(() => {
        localStorage.removeItem('id_token')
        this.$router.replace(`/login`)
      });
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.isUserLoggedIn;
    },
  }
};
</script>

<style></style>
