<template>
  <el-popover
    placement="top"
    width="340"
    trigger="manual"
    v-model="visible"
    v-click-outside="onClickOutside"
  >
    <component
      :is="element"
      slot="reference"
      @mouseover="onMouseOver"
      @mouseleave="onMouseOut"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @click="onClickItem"
      class="inline-flex items-center"
    >
      <span v-if="!iconBefore" :class="textClass" class="cursor-pointer" @click="onClickGoTo">
        <span v-if="text">{{ text }}</span>
        <span v-else>No Title</span>
      </span>
      <span
        v-if="!disable"
        class="w-4 h-4 flex items-center justify-center cursor-pointer"
        :class="iconBefore ? 'mr-1' : 'ml-1'"
        @mouseover="onMouseOverIcon"
        @mouseleave="onMouseOutIcon"
      >
        <font-awesome-icon
          v-if="loadingResource"
          class="fa-spin text-xs h-4 w-4"
          :icon="['fa', 'spinner']"
        ></font-awesome-icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path
            fill-rule="evenodd"
            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
            clip-rule="evenodd"
          /></svg
      ></span>
      <span v-if="iconBefore" :class="textClass" class="cursor-pointer" @click="onClickGoTo">
        <span v-if="text">{{ text }}</span>
        <span v-else>No Title</span>
      </span>
      <slot></slot>
    </component>
    <component
      :is="getTemplateComponent"
      :resource="resourceData"
      :to="to"
      @close="visible = false"
      />
  </el-popover>
</template>

<script>
import { get } from "lodash-es";
import { isAfter, addSeconds } from "date-fns";
import CustomerTemplate from "./templates/Customer.vue";
import VendorTemplate from "./templates/Vendor.vue";
import AppointmentTemplate from "./templates/Appointment.vue";
import vClickOutside from "v-click-outside";

export default {
  props: {
    to: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    textClass: {
      type: String,
      default: "highlight-link",
    },
    element: {
      type: String,
      default: "span",
    },
    iconBefore: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if(this.$wemowActiveUserType() === 'super'){
      this.disable = true
    }

    const { to } = this;
    const hasObjectId = to.match(/[a-f\d]{24}/)
      ? to.match(/[a-f\d]{24}/)[0]
      : "";

    this.resourceId = hasObjectId;

    const isCustomer = to.split("/").includes("customer");
    if (isCustomer) {
      this.resourceType = "customer";
    }
    const isVendor = to.split("/").includes("vendor");
    if (isVendor) {
      this.resourceType = "vendor";
    }
    const isAppointment = to.split("/").includes("appointment");
    if (isAppointment) {
      this.resourceType = "appointment";
    }
  },
  data() {
    return {
      visible: false,
      disable: false,
      isMouseOver: false,
      isMouseFocus: false,
      isMouseOverIcon: false,
      resourceType: "",
      resourceId: "",
      resourceData: null,
      resourceDataTimestamp: null,
      loadingResource: false,
    };
  },
  methods: {
    onClickItem() {
      console.log("onClickItem");
      this.getResource();
    },
    onMouseOver() {
      console.log("onMouseOver");
      this.isMouseOver = true;
    },
    onMouseOut() {
      console.log("onMouseOut");
      this.isMouseOver = false;
    },
    onMouseDown() {
      console.log("onMouseDown");
      this.isMouseFocus = true;
    },
    onMouseUp() {
      console.log("onMouseUp");
      this.isMouseFocus = false;
    },
    onMouseOverIcon() {
      console.log("onMouseOver");
      if (!this.isMouseOverIcon) {
        this.isMouseOverIcon = true;
        this.getResource();
      }
    },
    onMouseOutIcon() {
      console.log("onMouseOut");
      this.isMouseOverIcon = false;
    },
    onClickOutside() {
      this.visible = false;
    },
    onClickGoTo() {
      this.visible = false;
      this.disable = true;
      this.$router.push(this.to);
    },
    async getResource() {
      if (this.loadingResource || this.disable) {
        console.log("skipping load");
        return;
      }

      if (
        this.resourceData &&
        isAfter(addSeconds(this.resourceDataTimestamp, 5), new Date())
      ) {
        console.log("skipping load");
        this.visible = true;
        return;
      }

      try {
        const { resourceType, resourceId } = this;
        this.loadingResource = true;
        const actions = {
          customer: `admin/CUSTOMER_GET_ONE`,
          vendor: `admin/VENDOR_GET_ONE`,
          appointment: `appointments/APPOINTMENTS_GET_ONE`
        };
        const dispatchAction = get(actions, resourceType);
        const result = await this.$store.dispatch(dispatchAction, resourceId);
        this.resourceData = result;
        this.resourceDataTimestamp = new Date();
        this.visible = true;
      } catch (error) {
        this.resourceData = null;
      } finally {
        this.loadingResource = false;
      }
    },
  },
  computed: {
    getTemplateComponent() {
      const { resourceType } = this;
      const components = {
        customer: CustomerTemplate,
        vendor: VendorTemplate,
        appointment: AppointmentTemplate,
      };
      return get(components, resourceType, "div");
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>
