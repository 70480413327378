var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CustomDropdownRoot", {
    attrs: {
      dropDownItems: _vm.dropDownItems,
      selectedValue: _vm.selectedValue,
      preventPopover: _vm.preventPopover,
    },
    on: {
      onClickPlaceholder: function ($event) {
        return _vm.$emit("onClickPlaceholder", $event)
      },
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
      customAction: function ($event) {
        return _vm.$emit("customAction", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "labelHasValue",
        fn: function (ref) {
          var selected = ref.selected
          return [
            _c("div", { staticClass: "flex items-center justify-start" }, [
              selected && selected.data
                ? _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _c("div", [
                        _c("div", [
                          _c("span", { staticClass: "text-sm font-medium" }, [
                            _vm._v(_vm._s(selected.label)),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xs text-gray-600 ml-3" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDollarAmount(selected.data.value)
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
      {
        key: "labelNoValue",
        fn: function () {
          return [_vm._v("Select an Appointment")]
        },
        proxy: true,
      },
      {
        key: "innerDropdownItem",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "flex justify-start items-center" }, [
              _c(
                "div",
                { staticClass: "flex justify-between items-center flex-grow" },
                [
                  _c("div", [
                    item && item.data
                      ? _c("div", [
                          _c("span", { staticClass: "text-sm font-medium" }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xs text-gray-600 ml-3" },
                            [
                              _vm._v(
                                _vm._s(_vm.formatDollarAmount(item.data.value))
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }