export const buttons = {
  send: "Enviar {text}",
  newText: "Nuevo {text}",
  confirmText: "Confirmar {text}",
  saveText: "Guardar {text}",
  clear: "Clarar",
  cancel: "Cancelar",
  confirm: "Confirmar",
  sendText: "Enviar {text}",
  addText: "Agregar {text}",
  createNewText: "Crear {text}",
  viewText: "Ver {text}",
  yesCancelText: "Sí, cancelar {text}",
  deleteText: "Eliminar {text}",
  replaceText: "Reemplazar {text}",
  uploadText: "Subir {text}",
  doNotCancel: "No cancele",
  noLeaveAlone: "No, dejar solo",
  enable: "habilitar",
  complete: "completar {text}",
  continue: "Seguir",
  revokeApproval: "revocar aprobación",
  ok: "Okay",
  back: "Regresar",
  viewLink: "Ver enlace",
  copyLink: "Copiar enlace",
  prev: "Anterior",
  close: "Cerrar",
  remove: "Eliminar",
  editText: "Editar {text}",
  approve: "Aprobar",
  unApprove: "No aprobar",
  clickToActivate: "Haga Clic Para Activar",
  action: "Acción | Acciónes",
  connectPayments: "Conectar Pagos",
  paymentSetup: "A continuación, establezcamos una forma para pagarle",
  addToText: "Añadir a {text}",
  addOrgAsVendor: "Agregarme como vendedor",
  viewTickets: "Ver Todos los boletos",
  submit: "Someter {text}",
  discardText: "Descartar {text}",
  ticketHistory: "Ver El Historial Completo De boletos",
  selectFile: "seleccione archivo",
  done: "hecho",
  refresh: "Actualizar",
  viewAppointments: "Ver Todas Las Citas",
  signIn: "Registrarse",
  sendReset: "Enviar Reinicio",
  reactivate: "Reactivar",
  confirmChange: "Sí, Confirmar Cambio.",
  doNotChange: "No, No Cambies",
  doNotReactivate: "No, Nor Reactives",
  updateFutureAppointments: "Actualizar Todas Las Citas Futuras",
  goBack: "Regresa",
  changeServiceFrequency: "Sí, Cambiar La Frecuencia Del Servicio",
  viewOrganization: "Ver Organizació",
  update: "Actualizar",
  finish: "Terminar",
  cancelChanges: "Cancelar cambios",
  addMyselfAsVendor: "Agregarme como vendedor",
  deny: "Negar",
  readMore: "Lee mas",
  readLess: "Leer menos",
  backTo: "De regreso",
  createNewOrganization: "Crear nueva organización",
  addNewZipCode: "agregar nuevo código postal",
  browseAffiliates: "Navegar afiliados",
  viewTransactions: "Ver transacciones",
  pause: "Pausa",
  unpause: "Deshacer la pausa",
  suspender: "Suspender",
  restaurar: "Restaurar",
  yesSuspendText: "Sí, suspender {text}",
  yesRestoreText: "Sí, restaurar {text}",
  doNotSuspend: "No, no suspenda",
  doNotRestore: "No, no restaurar",
  customerBalanceAdjustment: "Ajuste de saldo de cliente ",
  balanceAdjustment: "Ajuste de saldo",
  next: "Siguiente {text}",
  previous: "Anterior {text}",
  later: "más tarde",
}