const Strings = {
    login_db_connection_err: 'Database connection error',
    login_token_invalid: 'Token not valid',
    login_token_expired: 'Token expired. Please try again.',
    login_password_reset_email_sent: 'Success! Check your email for a link to reset your password.',
    login_password_updated: 'Password updated!',
    appointment_not_available: 'Appointment not available',
    appointment_is_available: 'Appointment is available',
    
}

export {
    Strings
}