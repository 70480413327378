var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.outerClass,
      on: {
        mouseover: function ($event) {
          _vm.isMouseOver = true
        },
        mouseleave: function ($event) {
          _vm.isMouseOver = false
        },
        mousedown: function ($event) {
          _vm.isMouseFocus = true
        },
        mouseup: function ($event) {
          _vm.isMouseFocus = false
        },
        click: _vm.handleUpdate,
      },
    },
    [
      _c("div", {
        staticClass: "absolute top-0 left-0 w-full h-full z-10",
        class: _vm.disabled ? "cursor-not-allowed" : "cursor-pointer",
      }),
      _c(
        "div",
        { class: _vm.innerClass },
        [
          _vm.showRadio
            ? _c(
                "div",
                { staticClass: "w-4" },
                [
                  _c("RadioBlockFieldInnerButton", {
                    attrs: {
                      hover: _vm.isMouseOver,
                      checked: _vm.active,
                      focus: _vm.isMouseFocus,
                      disabled: _vm.disabled,
                      showAsCheckbox: _vm.showAsCheckbox,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div"),
          _vm._t("labelHasValue", function () {
            return [
              _c("span", { class: _vm.labelClass }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }