export * from '@/router/web/middleware/beforeEnter/getCurrentUser'
export * from '@/router/web/middleware/beforeEnter/canAccessRoute'

export * from '@/router/web/middleware/beforeResolve/routerBeforeResolve'
export * from '@/router/web/middleware/beforeResolve/resolveHomeRoute'
export * from '@/router/web/middleware/beforeResolve/affiliateBeforeResolve'
export * from '@/router/web/middleware/beforeResolve/affiliateBeforeResolveOnboarding'
export * from '@/router/web/middleware/beforeResolve/customerBeforeResolve'
export * from "@/router/web/middleware/beforeResolve/customerBeforeResolveOnboarding";
export * from "@/router/web/middleware/beforeResolve/organizationBeforeResolve";
export * from "@/router/web/middleware/beforeResolve/organizationBeforeResolveOnboarding";
export * from "@/router/web/middleware/beforeResolve/organizationBeforeResolveAwaitingApproval";
export * from "@/router/web/middleware/beforeResolve/vendorBeforeResolve";
export * from "@/router/web/middleware/beforeResolve/vendorBeforeResolveOnboarding";
export * from "@/router/web/middleware/beforeResolve/registerVerifyPhoneBeforeResolve";
export * from "@/router/web/middleware/beforeResolve/customerBeforeResolveAlerts";
export * from "@/router/web/middleware/beforeResolve/adminBeforeResolve";

