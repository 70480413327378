import store from "@/store/store";
import { APP_STATE_WEB_CONTENT_WIDTH } from "@/store/mutation-types";
import {
    GET_ALERTS
  } from '@/store/action-types'


// Redirect the user to dashboard if there's no alert but users try to access the page
export const customerBeforeResolveAlerts = async (
  toRoute,
  _fromRoute,
  next
) => {
  if(toRoute.name == "PublicReviews"){
    store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
    return next();
  }else{
    store.dispatch(`notifications/${GET_ALERTS}`).then(() => {
      const hasAlert = store.getters["notifications/hasAlertNotification"];
      if(hasAlert){
        store.commit(APP_STATE_WEB_CONTENT_WIDTH, "500px")
        return next();
      } else{
          return next("/dashboard");
      }
  })
  }
};