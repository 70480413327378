import i18n from "@/plugins/i18n";
import { validateDateIsFuture } from "./helpers"

const OptionsUserStatus =  [{
  value: 'pending_email',
  label: 'Pending Email'
},{
  value: 'claim_account',
  label: 'Pending Claim'
}, {
  value: 'active',
  label: 'Active'
}, {
  value: 'inactive',
  label: 'Inactive'
}]

const OptionsUserRole = [{
  value: 'super',
  label: 'Super Admin'
}, {
  value: 'basic_user',
  label: 'Basic User'
}]

const OptionsServiceCategory = [{
  value: 'lawn',
  label: 'Lawn Care'
}, {
  value: 'home',
  label: 'Home Car'
}, {
  value: 'roof',
  label: 'Roof Care'
}, {
  value: 'pool',
  label: 'Pool Care'
}]

const OptionsServiceBillingType =  [{
  value: 'subscription',
  label: 'Subscription'
}, {
  value: 'onetime',
  label: 'One Time'
}, {
  value: 'addon',
  label: 'Add On'
}]

const OptionsServiceInterval =  [{
  value: 'week',
  label: 'Weekly'
}, {
  value: 'biweek',
  label: 'Every Other Week'
}, {
  value: 'month',
  label: 'Monthly'
}]

const OptionsServiceSubscriptionUsageType =  [{
  value: 'licensed',
  label: 'Licensed'
}, {
  value: 'metered',
  label: 'Metered'
}]

const OptionsServiceSubscriptionPricingType = [
  {
    value: "size",
    label: "Home Size",
  },
  {
    value: "frequency",
    label: "Frequency",
  },
];

const optionsForRecurringLength =  [{
  value: 6,
  label: '6 Months'
}, {
  value: 12,
  label: '1 Year'
}, {
  value: 24,
  label: '2 Year'
}, {
  value: 128,
  label: '10 Year'
}]


const OptionsAffiliateIdIsActive = [{
  value: true,
  label: 'Active'
}, {
  value: false,
  label: 'In-active'
}]


function optionsForDuration() {
  let max = 12;
  let mapQuarters = [15, 30, 45];
  let items = [];
  for (let i = 1; i < max; i++) {
    let total = i * 60;
    let item = {
      label: `${i} Hour`,
      value: total
    };
    items.push(item);
    for (let ii = 0; ii < 3; ii++) {
      let s = i > 1 ? "s" : "";
      let total = i * 60 + mapQuarters[ii];
      let item = {
        label: `${i} Hour${s} ${mapQuarters[ii]} minutes`,
        value: total
      };
      items.push(item);
    }
  }
  return items;
}

const allDayTimes = [
  "0000",
  "0015",
  "0030",
  "0045",
  "0100",
  "0115",
  "0130",
  "0145",
  "0200",
  "0215",
  "0230",
  "0245",
  "0300",
  "0315",
  "0330",
  "0345",
  "0400",
  "0415",
  "0430",
  "0445",
  "0500",
  "0515",
  "0530",
  "0545",
  "0600",
  "0615",
  "0630",
  "0645",
  "0700",
  "0715",
  "0730",
  "0745",
  "0800",
  "0815",
  "0830",
  "0845",
  "0900",
  "0915",
  "0930",
  "0945",
  "1000",
  "1015",
  "1030",
  "1045",
  "1100",
  "1115",
  "1130",
  "1145",
  "1200",
  "1215",
  "1230",
  "1245",
  "1300",
  "1315",
  "1330",
  "1345",
  "1400",
  "1415",
  "1430",
  "1445",
  "1500",
  "1515",
  "1530",
  "1545",
  "1600",
  "1615",
  "1630",
  "1645",
  "1700",
  "1715",
  "1730",
  "1745",
  "1800",
  "1815",
  "1830",
  "1845",
  "1900",
  "1915",
  "1930",
  "1945",
  "2000",
  "2015",
  "2030",
  "2045",
  "2100",
  "2115",
  "2130",
  "2145",
  "2200",
  "2215",
  "2230",
  "2245",
  "2300",
  "2315",
  "2330",
  "2345"
]

const allDayTimesWithSep = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45"
]

const OptionsDayPreference  = () => [{
  value: 'monday',
  label: i18n.t('timeOfDay.monday')
},{
  value: 'tuesday',
  label: i18n.t('timeOfDay.tuesday')
},{
  value: 'wednesday',
  label: i18n.t('timeOfDay.wednesday')
},{
  value: 'thursday',
  label: i18n.t('timeOfDay.thursday')
},{
  value: 'friday',
  label: i18n.t('timeOfDay.friday')
},{
  value: 'saturday',
  label: i18n.t('timeOfDay.saturday')
},{
  value: 'sunday',
  label: i18n.t('timeOfDay.sunday')
}]



const OptionsHomeSizePreference = [{
  value: 'small',
  label: 'Small'
},{
  value: 'medium',
  label: 'Medium'
},{
  value: 'large',
  label: 'Large'
}]

const OptionsHomeSizePreferenceNative = [{
  value: 'small',
  label: 'Small - 0 - 5k sq ft'
},{
  value: 'medium',
  label: 'Medium - 5k - 10k sq ft'
},{
  value: 'large',
  label: 'Large - 10k - 15k sq ft'
}]

const OptionsTimeofDayPreference = () => [{
  value: 'morning',
  label: i18n.t('timeOfDay.morning')
},{
  value: 'afternoon',
  label: i18n.t('timeOfDay.afternoon')
}]

const OptionsHelpdeskTypeDropdown = [
  // {
  //   value: "question",
  //   label: "Question"
  // },
  // {
  //   value: "incident",
  //   label: "Incident"
  // },
  // {
  //   value: "problem",
  //   label: "Problem"
  // },
  // {
  //   value: "task",
  //   label: "Task"
  // }
  {
    value: "general",
    label: "General"
  },
  {
    value: "appointment",
    label: "Appointment"
  },
  {
    value: "subscription",
    label: "Subscription"
  }
]

const OptionsHelpdeskPriorityDropdown =  [
  {
    value: "low",
    label: "Low"
  },
  {
    value: "normal",
    label: "Normal"
  },
  {
    value: "high",
    label: "High"
  },
  {
    value: "urgent",
    label: "Urgent"
  }
]

const OptionsDurationType =  [
  {
    value: "repeating",
    label: "Repeating"
  },
  {
    value: "forever",
    label: "Forever"
  },
  {
    value: "once",
    label: "Once"
  }
]

const datePickerAppointmentOptions = {
  shortcuts: [{
    text: 'Last month',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: 'Last 3 months',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: 'Next 3 months',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
}

const datePickerTransfersOptions = {
  disabledDate(date){
    return !validateDateIsFuture(date)
  },
  shortcuts: [{
    text: 'This month',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  },{
    text: 'Last month',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: 'Last 3 months',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    }
  }]
}

const OptionsTimeZones = () => [
  {
    value: "UTC-10",
    label: i18n.t("timezones.pacific")
  },
  {
    value: "UTC-8",
    label: i18n.t("timezones.mountain")
  },
  {
    value: "UTC-7",
    label: i18n.t("timezones.central")
  },
  {
    value: "UTC-6",
    label: i18n.t("timezones.eastern")
  },
]

const ticketStatusOptions = [
  {
    value:"draft",
    label:"Draft"
  },
  {
    value:"open",
    label:"Open"
  },
  {
    value:"closed",
    label:"Closed"
  },
]

const languageOptions = () => [
  {
    value: "en",
    label: i18n.t("languages.en"),
  },
  {
    value: "es",
    label: i18n.t("languages.es"),
  }
];

const generalFields = [
  { label: "Created", value: "created" }
]

const userFields = [
  { label: "Email", value: "email" },
  { label: "Status", value: "status" },
  ...generalFields
]

const customerFields = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Primary Email", value: "primary_email" },
  { label: "Initial Setup", value: "meta.initialSetup" },
  { label: "Zip Code", value: "address.postal_code" },
  { label: "Home Size", value: "address.address_meta.home_size" },
  ...generalFields,
  { label: "Checklist Answer 1", value: "address.address_meta.address_checklist.0.answer" },
  { label: "Checklist Answer 2", value: "address.address_meta.address_checklist.1.answer" },
  { label: "Checklist Answer 3", value: "address.address_meta.address_checklist.2.answer" },
  { label: "Checklist Answer 4", value: "address.address_meta.address_checklist.3.answer" },
  { label: "Payment method", value: "payment_info.0" },
  { label: "Affiliates", value: "affiliates.0" }
  
];

const organizationFields = [
  { label: "Company Name", value: "company_name" },
  { label: "Primary Email", value: "primary_email" },
  { label: "Initial Setup", value: "meta.initialSetup" },
  ...generalFields
]

const vendorFields = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Primary Email", value: "primary_email" },
  { label: "Is Part Of Organization", value: "is_part_of_org" },
  { label: "Initial Setup", value: "meta.initialSetup" },
  ...generalFields
]

const affiliateFields = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Primary Email", value: "primary_email" },
  { label: "Is Sales Professional", value: "isSalesProfessional"},
  { label: "Goal", value: "goal" },
  { label: "Initial Setup", value: "meta.initialSetup" },
  ...generalFields
]

const subscriptionFields = [
  { label: "Status", value: "status" },
  { label: "Service", value: "service_id" },
  ...generalFields,
];

const appointmentFields = [
  { label: "Start Time", value: "start_time" }
];

const deliveryMethods = [
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
  { label: "Push Notifications", value: "inApp" },
  { label: "Promo Notifications", value: "popup" }
];

const preDefinedMessages = [
  { label: "Message 1", value: "Message 1" },
  { label: "Message 2", value: "Message 2" },
  { label: "Message 3", value: "Message 3" }
];

const heardAboutUsOptions = [
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "Friend/Family", value: "Friend/Family" },
  { label: "TV/Radio", value: "TV/Radio" },
  { label: "Flyer/Postcard", value: "Flyer/Postcard" },
  { label: "Billboard", value: "Billboard" },
  { label: "Other", value: "Other" }
];

const queryBuilderCollectionOptions = {
  users: userFields,
  customers: customerFields,
  organizations: organizationFields,
  vendors: vendorFields,
  affiliates: affiliateFields,
  orders: subscriptionFields,
  appointments:appointmentFields
};
// "New, In Progress, Nurture, Unqualified"
const contactLeadStatus = [
  {
    label: "New",
    value: "new"
  },
  {
    label: "In Progress",
    value: "in_progress"
  },
  {
    label: "Nurture",
    value: "nurture"
  },
  {
    label: "Unqualified",
    value: "unqualified"
  },
  {
    label: "Converted",
    value: "open_deal"
  }
]

const businessTypeOptions = [
  { label: "Individual", value: "individual"},
  { label: "Company", value: "company"}
]

const companyTypeOptions = [
  {
    label: "Sole Proprietorship",
    value: "sole_proprietorship"
  },
  {
    label: "Single Member LLC",
    value: "single_member_llc"
  },
  // {
  //   label: "Multi Member LLc",
  //   value: "multi_member_llc"
  // },
  // {
  //   label: "Private Partnership",
  //   value: "private_partnership"
  // },
  // {
  //   label: "Private Corporation",
  //   value: "private_corporation"
  // },
  // {
  //   label: "Unincorporated Association",
  //   value: "unincorporated_association"
  // },
  // {
  //   label: "Public Partnership",
  //   value: "public_partnership"
  // },
  // {
  //   label: "Public Corporation",
  //   value: "public_corporation"
  // },
]

export {
  optionsForDuration,
  allDayTimes,
  allDayTimesWithSep,
  OptionsUserStatus,
  OptionsUserRole,
  OptionsDayPreference,
  OptionsServiceInterval,
  OptionsServiceSubscriptionUsageType,
  optionsForRecurringLength,
  OptionsHomeSizePreference,
  OptionsTimeofDayPreference,
  OptionsServiceCategory,
  OptionsServiceBillingType,
  OptionsHelpdeskTypeDropdown,
  OptionsHelpdeskPriorityDropdown,
  OptionsAffiliateIdIsActive,
  OptionsDurationType,
  datePickerAppointmentOptions,
  datePickerTransfersOptions,
  OptionsTimeZones,
  ticketStatusOptions,
  languageOptions,
  OptionsHomeSizePreferenceNative,
  OptionsServiceSubscriptionPricingType,
  queryBuilderCollectionOptions,
  deliveryMethods,
  preDefinedMessages,
  heardAboutUsOptions,
  contactLeadStatus,
  businessTypeOptions,
  companyTypeOptions
};
