import i18n from "@/plugins/i18n";

export const MenuItemsVendor = [{
    text: i18n.t('menu.workSchedule'),
    to: '/vendor/appointments',
    id: 'MenuItemVendorAppointments'
},{
    text: i18n.t('menu.myTickets'),
    to: "/vendor/help-desk",
    id: 'MenuItemVendorHelp',
    indicator: true
},{
    text: i18n.t('availability'),
    to: '/vendor/availability',
    id: 'MenuItemVendorAvailability'
},{
    text: "Invoices",
    to: '/vendor/list-invoices',
    id: 'MenuItemOrganizationInvoices'
}]

export const AccountMenuVendor = [{
    text: i18n.t('menu.mySettings'),
    to: '/vendor/profile',
    id: 'MenuItemVendorSettings'
}]